import { useEffect, useState } from "react";
import { useDocument } from "../../../../hooks/use-document";
import { useSelector } from "react-redux";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PAGE_SIZE } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import PropTypes from "prop-types";

export const DocumentManagement = ({ searchClient }) => {
  const { columns, sorting, setSortingState } = useDocument();

  const [data, setData] = useState([]);

  const document = useSelector((state) => state.document);
  const view = useSelector((state) => state.view);

  useEffect(() => {
    if (document.documents && view.document.success) {
      setData([...document.documents]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view.document.success]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    filterFns: {
      clientFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.getColumn("clientName")?.setFilterValue(searchClient);
  }, [searchClient, table]);

  return (
    <div>
      <DynamicTable
        table={table}
        columns={columns.length}
        records={data.length}
        isPending={view.document.pending}
        onRowsChange={(value) => table.setPageSize(value)}
      />
    </div>
  );
};

DocumentManagement.propTypes = {
  searchClient: PropTypes.string,
};
