import { nanoid } from "@reduxjs/toolkit";

const severity = "error";

export const registrationFailed = () => {
  return {
    id: nanoid(),
    content: `Unable to register user.`,
    severity,
  };
};

export const loginFailed = () => {
  return {
    id: nanoid(),
    content: `Unable to login at this moment.`,
    severity,
  };
};

export const fetchUserRoleFailed = () => {
  return {
    id: nanoid(),
    content: `Unable to retrieve user details`,
    severity,
  };
};

export const errorToSendEmailLink = () => {
  return {
    id: nanoid(),
    content: `Oops! we are unable to re-send email verification link.`,
    severity,
  };
};

export const errorToResetPassword = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to reset password at this moment. Please try again later.`,
    severity,
  };
};

export const somethingBadHappen = (params) => {
  return {
    id: nanoid(),
    content: `Something went wrong with ${params}`,
    severity,
  };
};

const contact = "Please contact support if the problem persists.";

export const failToFetchProjects = () => {
  return {
    id: nanoid(),
    content: `Sorry, We couldn't fetch the project data at this moment. ${contact}`,
    severity,
  };
};

export const failToFetchCategories = () => {
  return {
    id: nanoid(),
    content: `Sorry, We couldn't load category data at this moment. ${contact}`,
    severity,
  };
};

export const errorToAddNewCategory = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while adding the category data. Please try again later.`,
    severity,
  };
};

export const errorToCreateProject = () => {
  return {
    id: nanoid(),
    content: `Oops! Something bad happen while creating new project. Please try agin later or contact support.`,
    severity,
  };
};

export const errorToUploadMultiDocuments = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while performing operations.`,
    severity,
  };
};

export const errorToDeleteProject = () => {
  return {
    id: nanoid(),
    content: `Sorry! We are unable to delete project. Please try again later.`,
    severity,
  };
};

export const errorToFetchDDQs = () => {
  return {
    id: nanoid(),
    content: `Unable to fetch DDQ(s), try again later.  ${contact}`,
    severity,
  };
};

export const errorToCreateDDQ = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while adding DDQ(s). Please try again later.`,
    severity,
  };
};

export const errorToDeleteDDQ = () => {
  return {
    id: nanoid(),
    content: `OOps! Something bad happen while deleting DDQ. Please try again later.`,
    severity,
  };
};

export const errorToUpdateDDQ = () => {
  return {
    id: nanoid(),
    content: `Something went wrong while updating DDQ.`,
    severity,
  };
};

export const errorToUpdateStatus = () => {
  return {
    id: nanoid(),
    content: `We're unable to update the category status at the moment.`,
    severity,
  };
};

export const errorToDeleteCategory = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while deleting category.`,
    severity,
  };
};

export const errorToDeleteSubcategory = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while deleting subcategories.`,
    severity,
  };
};

export const errorToEditCategory = () => {
  return {
    id: nanoid(),
    content: `Oop! Something bad happen while editing category.`,
    severity,
  };
};

export const errorToDownload = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while downloading file.`,
    severity,
  };
};

export const errorToFetchClients = () => {
  return {
    id: nanoid(),
    content: `Sorry! we are unable to fetch client detail(s) at this moments. Please try again later.`,
    severity,
  };
};

export const errorToUpdateClientStatus = () => {
  return {
    id: nanoid(),
    content: `We're unable to update the client status at this moment.`,
    severity,
  };
};

export const errorToAddClient = () => {
  return {
    id: nanoid(),
    content: `Oops! Something bad happen while adding client. Please try again later.`,
    severity,
  };
};

export const errorToEditClient = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to edit client at this moment. Please try again later. `,
    severity,
  };
};

export const errorToDeleteClient = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to delete client at this moment. Please try again later. `,
    severity,
  };
};

export const errorToFetchDocuments = () => {
  return {
    id: nanoid(),
    content: `Oops! Something bad happen while fetching documents.`,
    severity,
  };
};

export const errorToFetchSupport = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to fetch support request(s). Please try again later.`,
    severity,
  };
};

export const errorToResolveSupport = () => {
  return {
    id: nanoid(),
    content: `Sorry! We are unable to resolve support request. Please try again later.`,
    severity,
  };
};

export const errorToSendSupportRequest = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while sending support request.`,
    severity,
  };
};

export const errorToGenerateResponse = () => {
  return {
    id: nanoid(),
    content: `Oops! Something went wrong while generating ddq response.`,
    severity,
  };
};

export const errorToUploadDocuments = () => {
  return {
    id: nanoid(),
    content: `Sorry! We are unable to upload the documents at this moments.`,
    severity,
  };
};

export const errorToDeleteDocument = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to delete this document. Please try again later.`,
    severity,
  };
};

export const errorToUpdateProject = () => {
  return {
    id: nanoid(),
    content: `Oops! we are unable to update project details. Please try again later.`,
    severity,
  };
};

export const errorToFetchDDQResponse = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to fetch Due Diligence Question's Response at this moments. `,
    severity,
  };
};
export const errorToFetchDDQDocuments = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to fetch DDQ Response Documents at this moments. `,
    severity,
  };
};

export const errorToSaveDDQResponse = () => {
  return {
    id: nanoid(),
    content: `Oops! unable to save the selected response. `,
    severity,
  };
};

export const errorToExportExcel = () => {
  return {
    id: nanoid(),
    content: `Sorry! We are unable to export the excel file for given DDQs.`,
    severity,
  };
};

export const errorToUpdateClientAdminStatus = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to update client's admin status.`,
    severity,
  };
};

export const errorToFetchClientProject = () => {
  return {
    id: nanoid(),
    content: `Oops! We are unable to fetch client's project list at this moment. Please try again later.`,
    severity,
  };
};

export const errorToSendDocParser = () => {
  return {
    id: nanoid(),
    content: `Unable to send Document Parser.`,
    severity,
  };
};

export const errorToSendSummary = () => {
  return {
    id: nanoid(),
    content: `Unable to send Summary message.`,
    severity,
  };
};

export const errorToSendChunk = () => {
  return {
    id: nanoid(),
    content: `Unable to send Chunker message.`,
    severity,
  };
};

export const errorToCreateSummaryIndex = () => {
  return {
    id: nanoid(),
    content: `Oops! something went wrong while creating Summary index. Please try again later.`,
    severity,
  };
};

export const errorToCreateChunkIndex = () => {
  return {
    id: nanoid(),
    content: `Oops! something went wrong while creating Chunk index. Please try again later.`,
    severity,
  };
};

export const errorToSendDataToSummaryIndex = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to send Summary Data index message.`,
    severity,
  };
};

export const errorToSendDataToChunkIndex = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to send Chunk Data index message.`,
    severity,
  };
};

export const errorToSendResponseGenerator = () => {
  return {
    id: nanoid(),
    content: `Oops! Unable to send Response generator message.`,
    severity,
  };
};

export const errorToSendFeedback = () => {
  return {
    id: nanoid(),
    content: `Sorry! unable to send your feedback.`,
    severity,
  };
};

export const errorToFetchFeedback = () => {
  return {
    id: nanoid(),
    content: `Oops! we are unable to fetch feedbacks.`,
    severity,
  };
};