import { useState } from "react";
import {
  MUIButton as Button,
  MUIButton,
} from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { GENERAL, LOGIN } from "../../../constant";
import { validEmail } from "../../../utils/validation-functions";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../action";
import { object } from "prop-types";

export const ForgotPassword = ({ viewResetState }) => {
  const [email, setEmail] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => {
    setIsOpen(false);
    setIsSubmitted(false);
    setEmail(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (validEmail(email).status) {
      dispatch(forgotPassword({ email })).then((value) => {
        onClose();
      });
    }
  };

  return (
    <div className="w-full">
      <div className="w-max ml-auto">
        <MUIButton onClick={() => setIsOpen(true)} variant="text" size="md">
          {LOGIN.forgotYourPassword}
        </MUIButton>
      </div>
      {isOpen && (
        <MUIDialog
          isOpen={true}
          onClose={onClose}
          content={
            <div className="text-secondary w-full mx-auto">
              <div className="text-primary text-xl mb-[16px]">
                {LOGIN.forgotPassword}
              </div>
              <div>
                <div className="mb-[16px]">{LOGIN.enterYourEmailAddress}</div>
                <div className="mb-[20px]">
                  <TextField
                    placeholder={"email@example.com*"}
                    className={"w-full"}
                    type={"email"}
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    errorText={isSubmitted ? validEmail(email).message : ""}
                    required
                  />
                </div>
                <div className="flex gap-4">
                  <Button
                    type={"submit"}
                    size="md"
                    className={"hover:bg-warning-dark hover:text-black"}
                    isLoading={viewResetState.pending}
                    onClick={handleSubmit}
                  >
                    {LOGIN.requestResetLink}
                  </Button>
                  <Button size="md" variant="text" onClick={onClose}>
                    {GENERAL.cancel}
                  </Button>
                </div>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

ForgotPassword.propTypes = {
  viewResetState: object,
};
