import { TextArea } from "../../../../components/atoms/input/text-area";
import { TextField } from "../../../../components/atoms/input/text-field";
import { useEffect, useState } from "react";
import { validPrompt } from "../../../../utils/validation-functions";
import PropTypes from "prop-types";
import { ADMIN } from "../../../../constant";

const initialPrompt = { summaryFilter: "", prompt: "" };

export const GPTProvider = ({ isReset, submit, callBack, data }) => {
  const [prompt, setPrompt] = useState(initialPrompt);

  useEffect(() => setIsSubmit(submit), [submit]);
  useEffect(() => setPrompt(initialPrompt), [isReset]);
  useEffect(() => {
    setPrompt({ prompt: data.prompt, summaryFilter: data.summaryFilter });
  }, [data]);

  const [isSubmit, setIsSubmit] = useState(submit);

  return (
    <div className="h-max">
      <div className="flex flex-col gap-[12px]">
        <TextField
          type={"text"}
          placeholder={"Summary Filter*"}
          label={<span className="font-bold">{ADMIN.summaryFilter}</span>}
          value={prompt.summaryFilter}
          onChange={(e) =>
            setPrompt({ ...prompt, summaryFilter: e.target.value })
          }
          onBlur={() => {
            callBack?.({ ...prompt });
          }}
          errorText={
            isSubmit
              ? validPrompt(prompt.summaryFilter, "Summary Filter")?.message
              : ""
          }
          data-testid="gpt-prompt-title-input"
        />
        <TextArea
          placeholder={"Prompt*"}
          value={prompt.prompt}
          label={<span className="font-bold">{ADMIN.prompt}</span>}
          rows={6}
          onChange={(e) => setPrompt({ ...prompt, prompt: e.target.value })}
          onBlur={() => {
            callBack?.({ ...prompt });
          }}
          errorText={isSubmit ? validPrompt(prompt.prompt)?.message : ""}
          data-testid="gpt-prompt-input"
        />
      </div>
    </div>
  );
};

GPTProvider.propTypes = {
  submit: PropTypes.bool,
  callBack: PropTypes.func,
  isReset: PropTypes.bool,
  data: PropTypes.shape({
    summaryFilter: PropTypes.string,
    prompts: PropTypes.array,
  }),
};
