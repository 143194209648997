import { useEffect, useState } from "react";
import { useSupport } from "../../../../hooks/use-support";
import { useSelector } from "react-redux";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PAGE_SIZE, QUERY_STATUS } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import { PendingSupportRequestDialog } from "./pending-support-request";
import { ResolvedSupportRequestDialog } from "./resolved-support-request";
import { ViewRequestDialog } from "./view-request";
import PropTypes from "prop-types";

export const SupportRequest = ({ filterClient, filterStatus }) => {
  const { columns, sorting, setSortingState, dialog, setDialog } = useSupport();

  const [data, setData] = useState([]);

  const support = useSelector((state) => state.supportRequest);
  const view = useSelector((state) => state.view);

  useEffect(() => {
    if (support.contactDetails && view.support.success)
      setData([...support.contactDetails]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view.support.success]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    filterFns: {
      nameFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
      statusFilter: (row, columnId, filterValue) => {
        const rowValue = row.original[columnId]?.toString();

        return filterValue === QUERY_STATUS.pendingId
          ? rowValue === "false"
          : filterValue === QUERY_STATUS.resolvedId
          ? rowValue === "true"
          : rowValue === "false" || rowValue === "true";
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.getColumn("name")?.setFilterValue(filterClient);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterClient]);
  useEffect(() => {
    table.getColumn("status")?.setFilterValue(filterStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatus]);

  useEffect(() => {
    if (view.supportResolve.success) {
      setDialog({ isPending: false, id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view.supportResolve.success]);

  return (
    <div>
      <DynamicTable
        table={table}
        columns={columns.length}
        records={data.length}
        isPending={view.support.pending}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      {dialog.isPending && (
        <PendingSupportRequestDialog
          onClose={() => setDialog({ isPending: false, id: null })}
          id={dialog.id}
        />
      )}
      {dialog.isResolve && (
        <ResolvedSupportRequestDialog
          onClose={() => setDialog({ isResolve: false, id: null })}
        />
      )}
      {dialog.isView && (
        <ViewRequestDialog
          id={dialog.id}
          isResolve={dialog.value}
          onClose={() => setDialog({ isView: false, id: null })}
        />
      )}
    </div>
  );
};

SupportRequest.propTypes = {
  filterClient: PropTypes.string,
  filterStatus: PropTypes.number,
};
