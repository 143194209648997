import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";
import PropTypes from "prop-types";

export const HeadText = ({ className, children, testId = "head" }) => (
  <div
    className={twMerge(
      "text-xl  tracking-wide text-secondary font-bold",
      className
    )}
    data-testid={testId}
  >
    {children}
  </div>
);
export const SubHeadText = ({ className, children }) => (
  <div className={twMerge("text-lg  text-secondary", className)}>
    {children}
  </div>
);

export const NormalText = ({ className, children, testId = "normal-text" }) => (
  <div
    className={twMerge("text-base text-secondary-light", className)}
    data-testid={testId}
  >
    {children}
  </div>
);

export const SmallText = ({ className, children }) => (
  <span className={twMerge("text-sm text-secondary-light", className)}>
    {children}
  </span>
);

const labelText = cva("text-sm text-secondary-light tracking-wide", {
  variants: {
    size: {
      sm: "text-sm",
      md: "text-base",
      lg: "text-base",
    },
  },
});

export const LabelText = ({
  className,
  children,
  labelFor,
  size,
  id,
  testId = "label",
}) => (
  <label
    id={id}
    className={twMerge(labelText({ size }), className)}
    htmlFor={labelFor}
    data-testid={testId}
  >
    {children}
  </label>
);

export const ErrorText = ({ className, children, testId }) => (
  <div
    className={twMerge("text-error text-xs p-[2px]", className)}
    data-testid={testId}
  >
    {children}
  </div>
);

// Define prop types for HeadText
HeadText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Define prop types for SubHeadText
SubHeadText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Define prop types for NormalText
NormalText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SmallText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Define prop types for LabelText
LabelText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  labelFor: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

// Define prop types for ErrorText
ErrorText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
