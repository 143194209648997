import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState, useCallback } from "react";
import { Switch } from "../components/atoms/switch";
import {
  DeleteIcon,
  DownFilledArrow,
  EditIcon,
  UpFilledArrow,
} from "../components/icons";
import { Button } from "@mui/material";
import { COLORS } from "../configuration";

export const useClients = () => {
  const columnHelper = createColumnHelper();

  const columnNames = useMemo(
    () => ({
      id: "Id",
      serialNo: "Sr. No.",
      name: "Name",
      phone: "Phone Number",
      email: "Email",
      status: "Account Status",
      adminStatus: "Admin Status",
      edit: "Edit",
      delete: "Delete",
    }),
    []
  );

  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [dialog, setDialog] = useState({
    isDelete: false,
    isStatus: false,
    isAdmin: false,
    isEdit: false,
    id: null,
    value: null,
  });

  const handleStatusChange = useCallback((id, action) => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      id,
      isStatus: true,
      value: action,
    }));
  }, []);

  const handleAdminChange = useCallback((id, action) => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      id,
      isAdmin: true,
      value: action,
    }));
  }, []);

  const handleEdit = useCallback((id) => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      id,
      isEdit: true,
    }));
  }, []);

  const handleDelete = useCallback((id) => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      id,
      isDelete: true,
    }));
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (props) => props.row.index + 1,
        enableSorting: true,
        sortingFn: "basic",
        header: (
          <span data-testid="category-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                setSortingState((prevSortingState) => {
                  const newSortingState = [...prevSortingState];
                  newSortingState[0].desc = !newSortingState[0].desc;
                  return newSortingState;
                });
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (props) => props.getValue(),
        header: columnNames.name,
        filterFn: "nameFilter",
      }),
      columnHelper.accessor("email", {
        id: "email",
        cell: (props) => props.getValue(),
        header: columnNames.email,
      }),
      columnHelper.accessor("phoneNumber", {
        id: "phoneNumber",
        cell: (props) => {
          const value = props.getValue();
          return value !== "" ? value : "-";
        },
        header: columnNames.phone,
      }),
      columnHelper.accessor(
        (row) => {
          return { isActive: row.isActive, id: row.id };
        },
        {
          id: "isActive",
          cell: (props) => (
            <div className="w-[120px]">
              <Switch
                label="Active"
                offLabel="Inactive"
                size="md"
                checked={props.getValue().isActive}
                onChange={(action) =>
                  handleStatusChange(props.getValue().id, action)
                }
                labelTestId="client-status-label"
                testId="client-status"
              />
            </div>
          ),
          header: columnNames.status,
          filterFn: "statusFilter",
        }
      ),
      columnHelper.accessor(
        (row) => {
          return { isStaff: row.isStaff, id: row.id };
        },
        {
          id: "adminStatus",
          cell: (props) => (
            <div className="w-[120px]">
              <Switch
                label="Active"
                offLabel="Inactive"
                size="md"
                checked={props.getValue().isStaff}
                onChange={(action) =>
                  handleAdminChange(props.getValue().id, action)
                }
                labelTestId="client-admin-status-label"
                testId="client-admin-status"
              />
            </div>
          ),
          header: columnNames.adminStatus,
        }
      ),
      columnHelper.accessor("id", {
        id: "edit",
        cell: (props) => (
          <Button
            className="text"
            onClick={() => handleEdit(props.getValue())}
            data-testid="edit-button"
          >
            <EditIcon strokeWidth={0.5} />
          </Button>
        ),
        header: columnNames.edit,
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (props) => (
          <Button
            className="text"
            onClick={() => handleDelete(props.getValue())}
            data-testid="delete-button"
          >
            <DeleteIcon />
          </Button>
        ),
        header: columnNames.delete,
      }),
    ],
    [
      columnHelper,
      columnNames,
      sortingState,
      handleStatusChange,
      handleAdminChange,
      handleEdit,
      handleDelete,
    ]
  );

  const sorting = useMemo(() => sortingState, [sortingState]);

  return { columns, columnNames, sorting, setSortingState, dialog, setDialog };
};
