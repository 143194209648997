import { createSlice } from "@reduxjs/toolkit";
import { ALL } from "../../constant";

const loadStateFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem("filterState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn("Could not load state from sessionStorage", e);
    return undefined;
  }
};

const saveStateToSessionStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("filterState", serializedState);
  } catch (e) {
    console.warn("Could not save state to sessionStorage", e);
  }
};

const defaultState = {
  projectList: {
    name: null,
    status: [],
    provider: [],
  },
  ddqList: {
    name: null,
    category: [],
    responseProvider: { id: ALL.id, value: ALL.text },
  },
};

// Load initial state from sessionStorage or use default
const initialState = loadStateFromSessionStorage() || defaultState;

const NAME = "filter";

const setItem = (state, { payload }, listName, key) => {
  state[listName][key] = payload;
  saveStateToSessionStorage(state);
};

const filterSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setDDQQuestion(state, action) {
      setItem(state, action, "ddqList", "name");
    },
    setDDQCategory(state, action) {
      setItem(state, action, "ddqList", "category");
    },
    setDDQResponseProvider(state, action) {
      setItem(state, action, "ddqList", "responseProvider");
    },
    setProjectFilter(state, action) {
      if (action.payload?.type)
        setItem(
          state,
          { payload: action.payload.data },
          "projectList",
          action.payload.type
        );
    },
    resetSessionFilter(state) {
      const savedState = loadStateFromSessionStorage();
      if (savedState) {
        return savedState;
      }
      return state;
    },
  },
});

export const { actions } = filterSlice;

export default filterSlice.reducer;
