import { useDispatch, useSelector } from "react-redux";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { EmailIcon } from "../../../components/icons";
import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { COLORS } from "../../../configuration";
import { DIALOGS, GENERAL, LOGIN } from "../../../constant";
import { resendEmailVerification } from "../../../action";
import { TextField } from "../../../components/atoms/input/text-field";
import { NormalText, SubHeadText } from "../../../components/typography";
import { useEffect, useState } from "react";
import { validEmail } from "../../../utils/validation-functions";

export const EmailResendDialog = ({
  email = null,
  isSuccessDialog = false,
  onClose,
}) => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.view);

  const [resendEmail, setResendEmail] = useState(email);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    setResendEmail(email);
  }, [email]);

  const handleSubmit = () => {
    setIsSubmit(true);
    if (validEmail(resendEmail).status)
      dispatch(resendEmailVerification({ email: resendEmail })).then(
        (value) => {
          onClose?.();
          setIsSubmit(false);
        }
      );
  };

  const SuccessContent = (
    <div className="text-center w-[260px] mx-auto">
      <div className="flex justify-center mb-2">
        <EmailIcon color={COLORS.primary} height={60} width={60} />
      </div>
      <h1 className="text-xl text-primary text-center font-thin mb-[10px]">
        {DIALOGS.emailSent}
      </h1>
      <div className="text-secondary text-base leading-7 mb-[10px]">
        {`${DIALOGS.emailHasBeenSent} ${DIALOGS.pleaseVerifyYourEmail}`}
      </div>
      <div>
        <Button isLoading={view.resentEmailLink.pending} onClick={handleSubmit}>
          {DIALOGS.resendVerificationLink}
        </Button>
      </div>
    </div>
  );

  const ResendContent = (
    <div className="w-[420px] mx-auto">
      <div className="mb-[20px] flex flex-col gap-4 ">
        <SubHeadText className={"text-primary"}>
          {LOGIN.resendEmail}
        </SubHeadText>
        <NormalText className={"text-secondary"}>
          {LOGIN.enterEmailAddressForResend}
        </NormalText>
      </div>
      <div className="flex flex-col gap-4">
        <TextField
          placeholder={"Enter your email address*"}
          type={"email"}
          name={"email"}
          onChange={(e) => setResendEmail(e.target.value)}
          errorText={isSubmit ? validEmail(resendEmail).message : ""}
        />
        <Button
          size="md"
          isLoading={view.resentEmailLink.pending}
          onClick={handleSubmit}
          className={"w-max"}
        >
          {GENERAL.resend}
        </Button>
      </div>
    </div>
  );

  return (
    <MUIDialog
      isOpen={true}
      width={isSuccessDialog ? "xs" : "sm"}
      content={isSuccessDialog ? SuccessContent : ResendContent}
      onClose={() => onClose?.()}
    />
  );
};
