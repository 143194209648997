import PropTypes from "prop-types";
export const PageWhite = ({ children }) => {
  return (
    <div className="bg-white shadow-md px-[30px] rounded-md py-[20px]">
      {children}
    </div>
  );
};

PageWhite.propTypes = {
  children: PropTypes.node,
};
