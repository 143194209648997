import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { DownFilledArrow, EyeIcon, UpFilledArrow } from "../components/icons";
import { COLORS } from "../configuration";
import { DOCUMENT_VIEW_SIZE } from "../constant";
import { Link } from "react-router-dom";
import { pathList } from "../routes/app-navigation";
import { Tooltip } from "@mui/material";

export const useDocument = () => {
  const columnHelper = createColumnHelper();

  const columnNames = useMemo(
    () => ({
      id: "Id",
      serialNo: "Sr. No.",
      client: "Client",
      projects: "Projects",
      documents: "Documents",
      view: "View",
    }),
    []
  );

  const [sortingState, setSortingState] = useState([
    {
      id: "lawyerId",
      desc: true,
    },
  ]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("lawyerId", {
        id: "lawyerId",
        cell: (props) => props.row.index + 1,
        enableSorting: true,
        sortingFn: "basic",
        header: (
          <span data-testid="category-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                sortingState[0].desc = !sortingState[0].desc;
                setSortingState([...sortingState]);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("clientName", {
        id: "clientName",
        cell: (props) => props.getValue(),
        header: columnNames.client,
        filterFn: "clientFilter",
      }),
      columnHelper.accessor("projects", {
        id: "projects",
        cell: (props) => props.getValue().join(" | "),
        header: columnNames.projects,
      }),
      columnHelper.accessor("pdfNames", {
        id: "pdfNames",
        cell: (props) => {
          const values = props.getValue() ?? [];
          const renderedItems = values
            .slice(0, DOCUMENT_VIEW_SIZE)
            .map((item) => (
              <div key={item}>
                <Tooltip arrow placement="top" title={item}>
                  <div
                    className="w-[120px] truncate bg-page rounded-md p-[8px]"
                    data-testid="documents-box"
                  >
                    {item}
                  </div>
                </Tooltip>
              </div>
            ));

          const moreItems = values.length > DOCUMENT_VIEW_SIZE && (
            <Tooltip
              arrow
              placement="top"
              title={`More ${values.length - DOCUMENT_VIEW_SIZE} documents`}
            >
              <div className="truncate bg-page rounded-md p-[8px]">
                +{values.length - DOCUMENT_VIEW_SIZE}
              </div>
            </Tooltip>
          );

          return (
            <div className="flex flex-wrap gap-1">
              {renderedItems}
              {moreItems}
            </div>
          );
        },
        header: columnNames.documents,
      }),
      columnHelper.accessor("lawyerId", {
        id: "view",
        cell: (props) => {
          const val = JSON.stringify(props.getValue()) ?? "";
          return (
            <Link
              to={`${pathList.admin.clientDocuments}${val}`}
              data-testid="client-document-link"
            >
              <EyeIcon strokeWidth={1.5} />
            </Link>
          );
        },
        header: columnNames.view,
      }),
    ],
    [columnHelper, columnNames, sortingState]
  );

  const sorting = useMemo(() => sortingState, [sortingState]);

  return { columns, columnNames, sorting, setSortingState };
};
