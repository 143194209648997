import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import {
  DeleteIcon,
  DownFilledArrow,
  EditIcon,
  UpFilledArrow,
} from "../components/icons";
import { Switch } from "../components/atoms/switch";
import { MUIButton as Button } from "../components/atoms/button/mui-button";
import { COLORS } from "../configuration";
import { DOCUMENT_VIEW_SIZE } from "../constant";
import { ToolTip } from "../components/atoms/tooltip";

export const useCategory = () => {
  const columnHelper = createColumnHelper();

  const columnNames = useMemo(
    () => ({
      serialNo: "Sr. No.",
      id: "Id",
      category: "Category",
      subCategories: "Sub categories",
      status: "Status",
      edit: "Edit",
      delete: "Delete",
    }),
    []
  );

  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [dialog, setDialog] = useState({
    isDeleteOpen: false,
    isOpen: false,
    isEdit: false,
    id: null,
    value: null,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (props) => props.row.index + 1,
        header: (
          <span data-testid="category-id-column">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                sortingState[0].desc = !sortingState[0].desc;
                setSortingState([...sortingState]);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("categoryName", {
        id: "categoryName",
        cell: (props) => props.getValue(),
        filterFn: "categoryFilter",
        header: (
          <span data-testid="category-name-column">{columnNames.category}</span>
        ),
      }),
      columnHelper.accessor("subCategories", {
        id: "subCategories",
        cell: (props) => {
          const values = props.getValue() ?? [];
          const renderedItems = values
            ?.slice(0, DOCUMENT_VIEW_SIZE)
            .map((item) => (
              <ToolTip key={item.id} text={item.name}>
                <div
                  className="max-w-[120px] truncate bg-page rounded-md p-[8px]"
                  data-testid="category-box"
                >
                  {item.name}
                </div>
              </ToolTip>
            ));
          const moreItems = values.length > DOCUMENT_VIEW_SIZE && (
            <ToolTip
              text={`More ${values.length - DOCUMENT_VIEW_SIZE} categories`}
            >
              <div className="truncate bg-page rounded-md p-[8px]">
                +{values.length - DOCUMENT_VIEW_SIZE}
              </div>
            </ToolTip>
          );

          return (
            <div className="flex flex-wrap gap-1">
              {renderedItems}
              {moreItems}
            </div>
          );
        },

        header: columnNames.subCategories,
      }),
      columnHelper.accessor(
        (row) => {
          return { isActive: row.isActive, id: row.id };
        },
        {
          id: "isActive",
          cell: (props) => (
            <div className="w-[120px]">
              <Switch
                label="Active"
                offLabel="Inactive"
                size="md"
                checked={props.getValue().isActive}
                onChange={(action) => {
                  setDialog({
                    id: props.getValue().id,
                    isOpen: true,
                    value: action,
                  });
                }}
                testId="status-switch"
                labelTestId="status-label"
              />
            </div>
          ),
          header: (
            <span data-testid="category-status-column">
              {columnNames.status}
            </span>
          ),
          filterFn: "statusFilter",
        }
      ),
      columnHelper.accessor("id", {
        id: "edit",
        cell: (props) => (
          <Button
            size="sm"
            variant="text"
            onClick={() =>
              setDialog({
                isEdit: true,
                isOpen: false,
                isDeleteOpen: false,
                id: props.getValue(),
              })
            }
            data-testid="edit-button"
          >
            <EditIcon strokeWidth={0.5} />
          </Button>
        ),
        header: (
          <span data-testid="category-edit-column">{columnNames.edit}</span>
        ),
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (props) => (
          <Button
            size="md"
            variant="text"
            onClick={() =>
              setDialog({
                isDeleteOpen: true,
                isOpen: false,
                isEdit: false,
                id: props.getValue(),
              })
            }
            data-testid="delete-button"
          >
            <DeleteIcon />
          </Button>
        ),
        header: (
          <span data-testid="category-delete-column">{columnNames.delete}</span>
        ),
      }),
    ],
    [columnHelper, columnNames, sortingState]
  );

  const sorting = useMemo(() => sortingState, [sortingState]);

  return { columns, columnNames, sorting, setSortingState, dialog, setDialog };
};
