import { useState } from "react";
import { TextField } from "../../../components/atoms/input/text-field";
import { SearchIcon } from "../../../components/icons";
import { HeadText } from "../../../components/typography";
import { COLORS } from "../../../configuration";
import { ADMIN, ALL, STATUS } from "../../../constant";
import { CategoryManagement } from "../../features/admin/categories-management";
import { AddCategoryDialog } from "../../features/admin/categories-management/add-category-dialog";
import { useDebounce } from "../../../hooks/use-debounce";
import { PageWhite } from "../../../components/organism/body/page-white";
import Select from "../../../components/molecules/select/select-input";

export const CategoryManagementPage = () => {
  const [filterCategory, setFilterCategory] = useState(null);

  const [statusFilter, setStatusFilter] = useState(ALL.id);

  const debouncedCategory = useDebounce(filterCategory, 500);
  return (
    <PageWhite>
      <div className="flex justify-between items-center">
        <HeadText>{ADMIN.listOfCategory}</HeadText>
        <div className="flex gap-4">
          <div className="w-[250px]">
            <TextField
              placeholder={"Search Category"}
              value={filterCategory}
              name={"category"}
              onChange={(e) => setFilterCategory(e.target.value)}
              endAdornment={
                <SearchIcon
                  width={18}
                  height={18}
                  color={COLORS.secondaryLight}
                />
              }
            />
          </div>
          <div className="w-[240px]">
            <Select
              innerLabel="Filter Status"
              values={[
                { id: ALL.id, value: ALL.text },
                { id: STATUS.active, value: STATUS.activeText },
                { id: STATUS.inActive, value: STATUS.inActiveText },
              ]}
              defaultValue={[{ id: ALL.id, value: ALL.text }]}
              onChange={(value) => setStatusFilter(value)}
              isReturnId
            />
          </div>
          <AddCategoryDialog />
        </div>
      </div>
      <div className="mt-[16px]">
        <CategoryManagement
          filterCategory={debouncedCategory}
          filterStatus={statusFilter}
        />
      </div>
    </PageWhite>
  );
};
