import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathList } from "./app-navigation";

const PrivateRoute = () => {
  const user = useSelector((state) => state.user);

  if (!user?.email) {
    return <Navigate to={pathList.login} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
