import React, { useEffect, useState } from "react";
import { TextField } from "../../../../components/atoms/input/text-field";
import PropTypes from "prop-types";
import { validVariable } from "../../../../utils/validation-functions";
import { ADMIN } from "../../../../constant";

const initialData = {
  additionalDetails: "",
  variable: "",
  isSingleDocument: false,
  keys: [],
};
const keyInitial = [{ id: 0, variableKey: "", descriptiveKey: "" }];

export const SearchLess = ({
  isReset,
  isEdit,
  submit,
  callBack,
  defaultData,
}) => {
  const [isSubmit, setIsSubmit] = useState(submit);
  const [data, setData] = useState(initialData);
  const [keys, setKeys] = useState(keyInitial);

  useEffect(() => {
    setIsSubmit(submit);
  }, [submit]);

  useEffect(() => {
    if (isReset) {
      setData(initialData);
      setKeys(keyInitial);
    }
  }, [isReset]);

  useEffect(() => {
    if (defaultData && isEdit) {
      setData({
        additionalDetails: defaultData.additionalDetails,
        isSingleDocument: defaultData.isSingleDocument,
        keys: defaultData.keys,
        variable:
          defaultData.variable?.variable ?? defaultData?.variable?.name ?? "",
      });
      setKeys(
        defaultData.keys?.length
          ? defaultData.keys.map((item, index) => ({
              ...item,
              id: item?.id ?? index,
            }))
          : isEdit
            ? [{ id: 0, variableKey: "", descriptiveKey: "" }]
            : keyInitial
      );
    }
  }, [defaultData, isEdit]);

  // const handleAdd = () => {
  //   setKeys([
  //     ...keys,
  //     { id: keys.length + 1, variableKey: "", descriptiveKey: "" },
  //   ]);
  // };

  // const handleDelete = (id) => {
  //   const updatedKeys = keys.filter((item) => item.id !== id);
  //   setKeys(updatedKeys);
  //   callBack?.({
  //     additionalDetails: data.additionalDetails,
  //     variable: { id: defaultData.variable.id, name: data.variable },
  //     responseKey: updatedKeys,
  //     isSingleDocument: data.isSingleDocument,
  //   });
  // };

  // const handleChange = (event, index) => {
  //   const { name, value } = event.target;
  //   const updatedKeys = keys.map((item, idx) =>
  //     idx === index ? { ...item, [name]: value } : item
  //   );
  //   setKeys(updatedKeys);
  // };

  const handleBlur = () => {
    const responseKey = keys
      .filter((i) => i.variableKey !== "" || i.descriptiveKey !== "")
      .map((i) => ({
        variableKey: i.variableKey,
        descriptiveKey: i.descriptiveKey,
      }));

    callBack?.({
      additionalDetails: data.additionalDetails,
      variable: { id: defaultData.variable.id, name: data.variable },
      responseKey,
      isSingleDocument: data.isSingleDocument,
    });
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <TextField
          type="text"
          value={data.additionalDetails}
          label={<span className="font-bold">{ADMIN.additionalDetails}</span>}
          name="additionalDetails"
          id="additional-details"
          placeholder="Additional Details"
          onChange={(e) =>
            setData({ ...data, additionalDetails: e.target.value })
          }
          onBlur={handleBlur}
          inputProps={{ "data-testid": "additional-input" }}
        />
        <TextField
          type="text"
          value={data.variable}
          label={<span className="font-bold">{ADMIN.variable}</span>}
          name="variable"
          id="variable"
          placeholder="Variable*"
          onChange={(e) => setData({ ...data, variable: e.target.value })}
          errorText={isSubmit ? validVariable(data.variable)?.message : ""}
          onBlur={handleBlur}
          inputProps={{ "data-testid": "variable-input" }}
        />
        {/* <div className="mt-[12px]">
          <Switch
            size="md"
            label="Fetch single document"
            offLabel="Fetch multiple document"
            labelPosition={POSITION.right}
            checked={data.isSingleDocument}
            onChange={(e) => {
              setData({ ...data, isSingleDocument: e });
              handleBlur();
            }}
            data-testid="fetch-single-doc-switch"
          />
        </div> */}
      </div>
      {/* <div className="max-h-[300px] overflow-y-auto col-span-2 mx-[20px] px-[20px] border-l border-solid border-x-0 border-y-0 border-edge">
        <div>
          {keys.map((item, index) => (
            <div
              key={item.id}
              className="flex gap-4 items-center mb-[8px] w-max"
            >
              <TextField
                type={"text"}
                placeholder="Key"
                name="variableKey"
                className="w-[200px]"
                value={item.variableKey ?? ""}
                onChange={(event) => handleChange(event, index)}
                onBlur={handleBlur}
                inputRef={(el) => (inputRefs.current[index] = el)}
                data-testid="variable-key-input"
              />
              <TextField
                type={"text"}
                placeholder="Descriptive Key"
                name="descriptiveKey"
                className="w-[200px]"
                value={item.descriptiveKey ?? ""}
                onChange={(event) => handleChange(event, index)}
                onBlur={handleBlur}
                inputRef={(el) => (inputRefs.current[index] = el)}
                data-testid="descriptive-input"
              />
              <div className="mt-[10px] flex">
                <Button
                  variant="text"
                  color="error"
                  onClick={() =>
                    keys.length > 1 ? handleDelete(item.id) : null
                  }
                >
                  <DeleteIcon />
                </Button>
                {index === keys.length - 1 && (
                  <Button variant="text" onClick={handleAdd}>
                    <AddIcon color={COLORS.primary} />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

SearchLess.propTypes = {
  isReset: PropTypes.bool,
  submit: PropTypes.bool,
  isEdit: PropTypes.bool,
  callBack: PropTypes.func,
  defaultData: PropTypes.shape({
    additionalDetails: PropTypes.string,
    isSingleDocument: PropTypes.bool,
    keys: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string,
        descriptiveKey: PropTypes.string,
      })
    ),
    variable: PropTypes.shape({
      id: PropTypes.number,
      variable: PropTypes.string,
    }),
  }),
};
