import { Link } from "react-router-dom";
import { pathList } from "../../../../routes/app-navigation";
import { COLORS } from "../../../../configuration";
import { LeftIcon } from "../../../../components/icons";
import { SmallText } from "../../../../components/typography";
import { ADMIN } from "../../../../constant";

export const BackToAllQuestions = ({ callBack }) => {
  return (
    <Link
      to={pathList.admin.ddqList}
      style={{ textDecoration: "none" }}
      onClick={() => callBack?.()}
    >
      <div className="flex items-center gap-[4px] ">
        <LeftIcon width={18} height={18} color={COLORS.secondaryLight} />
        <SmallText className={"cursor-pointer"}>
          {ADMIN.backToAllQuestions}
        </SmallText>
      </div>
    </Link>
  );
};
