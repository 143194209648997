import PropTypes from "prop-types";
import { NormalText } from "../../typography";
import { Link, useLocation } from "react-router-dom";
import { DownArrow } from "../../icons";
import React, { useContext, useState } from "react";
import { twMerge } from "tailwind-merge";
import { COLORS } from "../../../configuration";
import { MenuContext } from "../../../universal/pages/admin";
import { ToolTip } from "../../atoms/tooltip";

const recursiveMenuPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  icon: PropTypes.node,
  name: PropTypes.node.isRequired,
  active: PropTypes.bool,
  url: PropTypes.string.isRequired,
  subMenus: PropTypes.arrayOf(
    // Call the function recursively to allow for multi-level nesting
    () => recursiveMenuPropType
  ),
});

const MenuIcon = ({ icon, isActive }) => (
  <div>
    {React.cloneElement(icon, {
      color: isActive ? COLORS.primary : COLORS.secondary,
    })}
  </div>
);

const Content = ({
  menuItem,
  isActive,
  showDownArrow,
  showUpArrow,
  toggle,
}) => (
  <div
    className={twMerge(
      "flex items-center my-4 px-4 py-2 rounded-md hover:bg-primary-hover group relative",
      isActive ? "bg-primary-hover" : "bg-white",
      toggle ? "justify-center" : "justify-between"
    )}
  >
    <div className="flex gap-4 justify-center">
      <MenuIcon icon={menuItem.icon} isActive={isActive} />
      {!toggle && (
        <div>
          <NormalText
            className={
              isActive
                ? "text-primary"
                : "text-secondary-light group-hover:text-primary"
            }
          >
            {menuItem.name}
          </NormalText>
        </div>
      )}
    </div>

    <div className={`${toggle ? "absolute right-0" : "block"} `}>
      {showDownArrow && (
        <div
          className={twMerge(
            showUpArrow ? "rotate-180" : "rotate-0",
            "transition-transform duration-500 ml-[2px]"
          )}
        >
          <DownArrow height={12} width={12} />
        </div>
      )}
    </div>
  </div>
);

// Menu component
export const Menu = ({
  menuItem,
  showDownArrow,
  showUpArrow,
  onClick,
  isActive,
}) => {
  const context = useContext(MenuContext);
  const location = useLocation();
  return (
    <Link
      key={menuItem.id}
      id={menuItem.id}
      to={!showDownArrow && menuItem.url}
      onClick={onClick}
      className="no-underline w-max"
    >
      {context.toggle ? (
        // Small Icons
        <ToolTip fontSize={14} text={menuItem.name} position="right" offset={4}>
          <Content
            menuItem={menuItem}
            isActive={isActive || location.pathname.includes(menuItem.url)}
            showDownArrow={showDownArrow}
            showUpArrow={showUpArrow}
            toggle={context.toggle}
          />
        </ToolTip>
      ) : (
        // Large Icons
        <Content
          menuItem={menuItem}
          isActive={isActive || location.pathname.includes(menuItem.url)}
          showDownArrow={showDownArrow}
          showUpArrow={showUpArrow}
          toggle={context.toggle}
        />
      )}
    </Link>
  );
};

Menu.propTypes = {
  menuItem: recursiveMenuPropType.isRequired,
  showDownArrow: PropTypes.bool,
  showUpArrow: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

// NavigationMenus component
// Upto 2 level of hierarchy works fine
export const NavigationMenus = ({
  menus,
  activeParentId,
  parentActive,
  isChild = false,
}) => {
  const [openMenus, setOpenMenus] = useState({});
  const location = useLocation();
  const context = useContext(MenuContext);

  const toggleMenu = (id) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // create boolean for id  e.g menu-id : false / true
    }));
  };

  const isMenuActive = (menuItem) => {
    return location.pathname === menuItem.url;
  };

  const renderMenu = (menuItem) => {
    const isMenuOpen = openMenus[menuItem.id];
    const isActive = isMenuActive(menuItem);

    return (
      <div key={menuItem.id}>
        <Menu
          menuItem={menuItem}
          showDownArrow={!!menuItem.subMenus}
          showUpArrow={!!menuItem.subMenus && openMenus[menuItem.id] === true}
          onClick={() => toggleMenu(menuItem.id)}
          isActive={
            isActive || (parentActive && activeParentId === menuItem.id)
          }
        />
        {isMenuOpen && menuItem.subMenus && (
          <NavigationMenus
            menus={menuItem.subMenus}
            parentActive={isActive || parentActive}
            isChild={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className="relative">
      <div
        className={`list-none  ${context.toggle && isChild ? "mx-0  border-solid border-l-0 border-r-0 border-t border-b  border-edge" : "mx-[12px]"} w-max`}
      >
        {menus.map((menuItem) => renderMenu(menuItem))}
      </div>
    </div>
  );
};

NavigationMenus.propTypes = {
  menus: PropTypes.arrayOf(recursiveMenuPropType).isRequired,
  activeParentId: PropTypes.string,
  parentActive: PropTypes.bool,
};
