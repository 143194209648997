import {
  getPaginationRowModel,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useCategory } from "../../../../hooks/use-category";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PAGE_SIZE, STATUS } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import { UpdateCategoryStatusDialog } from "./update-category-status-dialog";
import PropTypes from "prop-types";
import { DeleteCategoryDialog } from "./delete-category-dialog";
import { EditCategory } from "./edit-category";

export const CategoryManagement = ({ filterCategory, filterStatus }) => {
  const { columns, sorting, setSortingState, dialog, setDialog } =
    useCategory();

  const [data, setData] = useState([]);

  const viewState = useSelector((state) => state.view);
  const categorySelector = useSelector((state) => state.category);

  useEffect(() => {
    if (viewState.category.success && categorySelector.categoryDetails) {
      setData([...categorySelector.categoryDetails]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.category.success]);


  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    filterFns: {
      categoryFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
      statusFilter: (row, columnId, filterValue) => {
        const rowValue = row.original[columnId]?.toString();

        return filterValue === STATUS.active
          ? rowValue === "true"
          : filterValue === STATUS.inActive
            ? rowValue === "false"
            : rowValue === "false" || rowValue === "true";
      },
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.getColumn("categoryName")?.setFilterValue(filterCategory);
  }, [filterCategory, table]);

  useEffect(() => {
    table.getColumn("isActive")?.setFilterValue(filterStatus);
  }, [filterStatus, table]);

  return (
    <div>
      <DynamicTable
        table={table}
        isPending={viewState.category.pending}
        columns={columns.length}
        records={data.length}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      {dialog.isOpen && (
        <UpdateCategoryStatusDialog
          id={dialog.id}
          status={dialog.value}
          onClose={() =>
            setDialog({
              id: null,
              isOpen: false,
              value: null,
              isDeleteOpen: false,
            })
          }
          callback={(isClose) => {
            if (isClose) setDialog({ id: null, isOpen: false, value: false });
          }}
        />
      )}
      {dialog.isDeleteOpen && (
        <DeleteCategoryDialog
          id={dialog.id}
          onClose={() =>
            setDialog({
              id: null,
              isOpen: false,
              value: null,
              isDeleteOpen: false,
            })
          }
        />
      )}
      {dialog.isEdit && (
        <EditCategory
          id={dialog.id}
          onClose={() => setDialog({ isEdit: false, value: null, id: null })}
        />
      )}
    </div>
  );
};

CategoryManagement.propTypes = {
  filterCategory: PropTypes.string,
};
