import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { FeedbackIcon } from "../../../components/icons";
import { HeadText } from "../../../components/typography";
import PropTypes from "prop-types";
import { COLORS } from "../../../configuration";
import { useState } from "react";
import { FeedbackDialog } from "./ddq-response-feedback-dialog";
import { MUIDrawer } from "../../../components/organism/dialog/mui-drawer";

export const DDQAnswerDrawer = ({
  isOpen,
  children,
  question,
  onClose,
  showFeedback = false,
  files = [],
  ddqId,
  categoryId,
}) => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  return (
    <>
      <MUIDrawer isOpen={isOpen} onClose={onClose} width="920px" isResizable>
        <div className="mx-[28px] my-[8px] flex flex-col gap-4">
          <HeadText className={"text-primary"}>{question}</HeadText>
          {children}
          {showFeedback && (
            <div className="w-max ml-auto">
              <Button
                startIcon={<FeedbackIcon color={COLORS.primary} />}
                variant="text"
                size="md"
                onClick={() => setShowFeedbackDialog(true)}
              >
                Send Feedback
              </Button>
            </div>
          )}
        </div>
      </MUIDrawer>
      <FeedbackDialog
        isOpen={showFeedbackDialog}
        onClose={() => setShowFeedbackDialog(false)}
        files={files}
        ddqId={ddqId}
        categoryId={categoryId}
        question={question}
      />
    </>
  );
};

DDQAnswerDrawer.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  question: PropTypes.string,
  onClose: PropTypes.func,
};
