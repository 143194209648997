import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDdqQuestionById } from "../../../../action";
import MUIDialog from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, NormalText } from "../../../../components/typography";
import { RESPONSE_PROVIDER } from "../../../../constant";
import { DelayResponseBody } from "../../../../components/organism/body/loading-responses";

const TextBox = ({ children, title }) => (
  <div>
    <NormalText>{title}</NormalText>
    <div className="border-solid p-[8px] border border-edge rounded-md min-h-[20px] max-h-[120px] overflow-auto">
      {children}
    </div>
  </div>
);

const renderTextBoxes = (provider, ddq) => {
  if (provider === RESPONSE_PROVIDER.gpt) {
    return (
      <div className="flex flex-col gap-4">
        <TextBox title="Question">{ddq.question ?? "-"}</TextBox>
        <TextBox title="Query">{ddq.gptPrompt?.gptQuery ?? "-"}</TextBox>
        <TextBox title="Category">{ddq.category?.label ?? "-"}</TextBox>
        <TextBox title="Summary Filter">
          {ddq.gptPrompt?.summaryFilter ?? "-"}
        </TextBox>
        <TextBox title="Prompt">{ddq.gptPrompt?.prompt}</TextBox>
      </div>
    );
  } else if (provider === RESPONSE_PROVIDER.searchLess) {
    return (
      <div className="flex flex-col gap-4">
        <TextBox title="Question">{ddq.question ?? "-"}</TextBox>
        <TextBox title="Category">{ddq.category?.label ?? "-"}</TextBox>
        <TextBox title="Additional Details">
          {ddq?.additionalDetail ?? "-"}
        </TextBox>
        <TextBox title="Variable">
          {ddq.variable?.variable ?? ddq.variable?.name ?? "-"}
        </TextBox>
      </div>
    );
  }
  return null;
};

export const ViewDDQ = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const { view, ddq } = useSelector((state) => ({
    view: state.view,
    ddq: state.ddq,
  }));

  useEffect(() => {
    dispatch(fetchDdqQuestionById({ id }));
  }, [dispatch, id]);

  const content = ddq.selected
    ? renderTextBoxes(ddq.selected.responseProvider, ddq.selected)
    : null;

  return (
    <MUIDialog
      isOpen={true}
      width="md"
      onClose={onClose}
      title={
        <div className="px-[20px]">
          <HeadText>View DDQ</HeadText>
        </div>
      }
      content={
        <DelayResponseBody isButton={false} isLoading={view.ddqById.pending}>
          {content}
        </DelayResponseBody>
      }
    />
  );
};
