export function convertKeysToCamelCase(obj) {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  }

  const newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, function (match, group) {
        return group.toUpperCase();
      });
      newObj[camelKey] = convertKeysToCamelCase(obj[key]);
    }
  }
  return newObj;
}

function camelToSnake(str) {
  return str.replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`);
}

export function convertObjectKeysToSnakeCase(obj) {
  const newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = camelToSnake(key);
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
}

