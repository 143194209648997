import React from "react";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

const SkeletonLoading = ({ isButton }) => {
  return (
    <div className="h-max overflow-auto">
      <div className="px-2">
        <Skeleton variant="text" width={200} height={40} />
      </div>
      <div className="px-2 mt-[20px]">
        <Skeleton variant="text" width={200} height={40} />
      </div>
      <div className="mt-[20px] px-2">
        <div className="">
          <Skeleton variant="rectangular" width={300} height={40} />
        </div>
        <div className="h-full">
          <Skeleton variant="rectangular" width={"100%"} height={300} />
        </div>
      </div>
      {isButton && (
        <div className="mt-[20px] px-2 flex gap-4">
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
        </div>
      )}
    </div>
  );
};

export const DelayPageBody = ({ children, isLoading, isButton = true }) => {
  return isLoading ? (
    <SkeletonLoading isButton={isButton} />
  ) : (
    <div>{children}</div>
  );
};

DelayPageBody.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};
