import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const SkeletonText = ({ width, height, className }) => (
  <Skeleton
    variant="text"
    width={width}
    height={height}
    className={className}
  />
);

SkeletonText.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  className: PropTypes.string,
};

SkeletonText.defaultProps = {
  width: "100%",
  height: 20,
  className: "",
};

const SkeletonRect = ({ width, height, className }) => (
  <Skeleton
    variant="rectangular"
    width={width}
    height={height}
    className={className}
  />
);

SkeletonRect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  className: PropTypes.string,
};

SkeletonRect.defaultProps = {
  width: "100%",
  height: 60,
  className: "",
};

const SkeletonCircle = ({ size, className }) => (
  <Skeleton
    variant="circular"
    width={size}
    height={size}
    className={className}
  />
);

SkeletonCircle.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
};

SkeletonCircle.defaultProps = {
  size: 40,
  className: "",
};

/**
 * MUISkeleton Component
 *
 * This component provides a unified interface to render different types of skeleton loaders:
 * - Rectangle (default)
 * - Text
 * - Circle
 *
 * Props:
 * - type: One of 'rectangle', 'text', 'circle' (default: 'rectangle').
 * - width: Width of the skeleton loader (applicable for 'rectangle' and 'text' types).
 * - height: Height of the skeleton loader (applicable for 'rectangle' and 'text' types).
 * - size: Size of the circle (applicable for 'circle' type).
 * - className: Additional class names for styling purposes.
 *
 * Example usage:
 * ```
 * <MUISkeleton type="text" width="80%" height={20} />
 * <MUISkeleton type="circle" size={40} />
 * <MUISkeleton width={200} height={100} />
 * ```
 */
export const MUISkeleton = ({
  type = "rectangle",
  width = 120,
  height = 24,
  size = 24,
  className,
}) => {
  if (type === "text")
    return <SkeletonText height={height} className={className} width={width} />;
  else if (type === "circle")
    return <SkeletonCircle className={className} size={size} />;
  else
    return <SkeletonRect className={className} height={height} width={width} />;
};

// Define prop types
MUISkeleton.propTypes = {
  type: PropTypes.oneOf(["rectangle", "text", "circle"]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default MUISkeleton;
export { SkeletonText, SkeletonRect, SkeletonCircle }; // for direct usage purpose
