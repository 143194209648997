import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const DoneIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      strokeWidth={strokeWidth}
    >
      <title>Completed</title>
      <circle
        r="10"
        cx="12"
        cy="12"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M5 14L9 17L18 6"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

DoneIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
