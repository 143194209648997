import { NormalText, SubHeadText } from "../../../components/typography";
import { PROJECTS } from "../../../constant";
import { Accordion } from "../../../components/atoms/accordion";
import { DocumentIcon } from "../../../components/icons";
import { DelayPageBody } from "../../../components/organism/body/loading-body";
import PropTypes from "prop-types";
import { ToolTip } from "../../../components/atoms/tooltip";

export const ProjectView = ({ isLoading, data }) => {
  // Calculate total file count
  const totalFilesCount = data?.categories.reduce(
    (acc, item) => acc + (item.filesData?.length || 0),
    0
  );

  return (
    <DelayPageBody isLoading={isLoading} isButton={false}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <SubHeadText className={"text-primary font-bold"}>
            {PROJECTS.description}
          </SubHeadText>
          <NormalText className={"text-justify"}>
            &emsp; {data?.description}
          </NormalText>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <SubHeadText className={"text-primary font-bold"}>
              {PROJECTS.category}
            </SubHeadText>
            <NormalText>
              Total {totalFilesCount} file{totalFilesCount > 1 ? "s" : ""}
            </NormalText>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {data?.categories.map((item) => {
              const fileCount = item.filesData?.length ?? 0;

              return (
                <Accordion
                  key={item.value}
                  title={`${item.label} (${fileCount} file${fileCount > 1 ? "s" : ""})`}
                  isBackground={false}
                  className={"h-max"}
                >
                  <div className="flex flex-wrap gap-2">
                    {item?.filesData.map((file) => (
                      <ToolTip text={file?.fileName} key={file?.fileName}>
                        <a
                          href={file?.pdfLink}
                          target="_blank"
                          rel="noreferrer"
                          className="no-underline"
                        >
                          <div className="bg-page p-[8px] rounded-md flex items-center gap-2 text-secondary-light max-w-[380px]">
                            <DocumentIcon width={18} height={18} />
                            <span className="w-full truncate overflow-hidden">
                              {file?.fileName}
                            </span>
                          </div>
                        </a>
                      </ToolTip>
                    ))}
                  </div>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
    </DelayPageBody>
  );
};

ProjectView.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    croNumber: PropTypes.number,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        filesData: PropTypes.arrayOf(
          PropTypes.shape({
            fileName: PropTypes.string,
          })
        ),
      })
    ),
    responseProvider: PropTypes.array,
  }),
};
