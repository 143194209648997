import "./App.css";
import Page from "./universal/pages"
import AppNavigation from "./routes";
import { RouterWrapper, StoreWrapper } from "./wrapper";

function App() {
  return (
    <RouterWrapper>
      <StoreWrapper>
        <Page>
          <AppNavigation />
        </Page>
      </StoreWrapper>
    </RouterWrapper>
  );
}

export default App;
