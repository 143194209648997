import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectListByClient } from "../../action";
import { SEVERITY } from "../../constant";
import { convertKeysToCamelCase } from "../../utils/camelize";

const initialState = {
  projectList: [],
};

const NAME = "ClientProjects";

export const clientProjectSlice = createSlice({
  name: NAME,
  initialState,
  extraReducers(builder) {
    builder.addCase(
      fetchProjectListByClient.fulfilled,
      (state, { payload }) => {
        if (payload?.status === SEVERITY.Success) {
          const convertedData = convertKeysToCamelCase(payload.data);
          state.projectList = convertedData;
        }
      }
    );
  },
});

export const { actions } = clientProjectSlice;

export default clientProjectSlice.reducer;
