import { useEffect, useState } from "react";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import {
  ADMIN,
  DIALOGS,
  ERROR,
  GENERAL,
  RESPONSE_PROVIDER,
  RESPONSE_PROVIDER_RADIO_LABELS,
} from "../../../../constant";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { ErrorText, HeadText } from "../../../../components/typography";
import { RadioButtons } from "../../../../components/atoms/radio-buttons";
import { FileInput } from "../../../../components/molecules/file-input";
import { useDispatch, useSelector } from "react-redux";
import { addDdqByCSV } from "../../../../action";
import { validFile } from "../../../../utils/validation-functions";
import { ErrorIcon } from "../../../../components/icons";
import { actions } from "../../../slice/view";
export const UploadCSV = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [reset, setReset] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const viewState = useSelector((state) => state.view);
  const dispatch = useDispatch();

  const initialState = { file: null, responseProvider: RESPONSE_PROVIDER.gpt };

  const [data, setData] = useState(initialState);

  const [errors, setErrors] = useState([]);

  const handleSubmit = () => {
    setIsSubmit(true);
    if (validFile(data.file).status && data.responseProvider) {
      dispatch(
        addDdqByCSV({
          file: data.file,
          responseProvider: data.responseProvider,
        })
      );
    }
  };

  useEffect(() => {
    if (viewState.ddqCreate.pending) {
      setErrors([]);
    }
    if (viewState.ddqCreate.success) {
      setShowDialog(false);
      setData(initialState);
      setReset(!reset);
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.ddqCreate]);

  useEffect(() => {
    setErrors(viewState.csvErrorMessage);
  }, [viewState.csvErrorMessage]);

  return (
    <div>
      <Button
        className="h-[40px] -mt-[4px]"
        size="md"
        onClick={() => setShowDialog(true)}
      >
        {ADMIN.importCSV}
      </Button>
      {errors.length > 0 && (
        <MUIDialog
          isOpen
          onClose={() => {
            setErrors([]);
            dispatch(actions.resetCSVMessage());
          }}
          title={
            <div className="flex items-center gap-4">
              <ErrorIcon /> {ERROR.error}
            </div>
          }
          content={
            <div className="w-[460px] mx-auto">
              <ErrorText className={"text-lg"}>{ERROR.OopsLooksLike}</ErrorText>
              <div className="bg-page-light overflow-y-auto rounded-md max-h-[490px] p-[12px] w-full text-sm">
                <ul>
                  {errors?.map((item) => (
                    <li
                      key={Object.keys(item)}
                    >{`Error(s) in Line ${Object.keys(item)} : ${
                      item[Object.keys(item)]?.toString() ?? ""
                    }`}</li>
                  ))}
                </ul>
              </div>
              <div className="text-right mt-[4px] text-sm text-secondary-light">{`${GENERAL.total} ${errors.length} ${ERROR.error}`}</div>
            </div>
          }
        />
      )}
      {showDialog && (
        <MUIDialog
          isOpen={true}
          onClose={() => {
            setShowDialog(false);
            setIsSubmit(false);
            setReset(false);
          }}
          content={
            <div className="w-max mx-auto">
              <HeadText>{ADMIN.uploadCSV}</HeadText>
              <div className="mt-[20px]">
                <RadioButtons
                  labels={RESPONSE_PROVIDER_RADIO_LABELS}
                  groupLabel={
                    <span className="font-bold">
                      {ADMIN.selectYourCSVCategory}
                    </span>
                  }
                  checked={data.responseProvider}
                  onChange={(value) =>
                    setData({ ...data, responseProvider: value })
                  }
                  isRow
                />
              </div>
              <div className="w-[420px] mt-[20px]">
                <FileInput
                  accept={[".csv", ".xlsx"]}
                  isMultiple={false}
                  errorText="Please upload .csv or .xlsx file."
                  onChange={(value) => setData({ ...data, file: value })}
                  isReset={reset}
                  className={"p-[32px]"}
                  showClear={false}
                  note={DIALOGS.pleaseUploadOnlyOne}
                />
                {isSubmit && (
                  <ErrorText>{validFile(data.file)?.message ?? ""}</ErrorText>
                )}
              </div>
              <div className="mt-[20px] w-max mx-auto">
                <Button
                  size="md"
                  onClick={() => handleSubmit()}
                  isLoading={viewState.ddqCreate.pending}
                >
                  {ADMIN.uploadCSV}
                </Button>
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
