import { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select as MUISelect,
  Chip,
} from "@mui/material";
import PropTypes from "prop-types";
import { COLORS } from "../../../configuration";
import { CustomTheme } from "../../theme/custom-theme";
import { ErrorText, LabelText, NormalText } from "../../typography";
import { CloseIcon } from "../../icons";
import { twMerge } from "tailwind-merge";
import { SkeletonRect } from "../../atoms/loader/skeleton";
import { ToolTip } from "../../atoms/tooltip";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      color: COLORS.secondary,
    },
  },
};

/**
 * Select Component:
 * A customizable dropdown select component using Material-UI (MUI).
 *
 * Props:
 * - label: Main label for the select input.
 * - innerLabel: Inner label for the select input.
 * - id: ID for the select input.
 * - onChange: Callback function triggered on value change.
 * - values: Array of objects with id, value, and optional icon.
 * - className: Additional class names for styling.
 * - isLoading: Boolean indicating if data is loading.
 * - defaultValue: Default selected values.
 * - error: Boolean to indicate if there's an error state.
 * - errorText: Error message text.
 * - isMulti: Boolean to enable multi-select.
 * - isReset: Boolean to reset selected values.
 * - isReturnId: Boolean to return selected IDs.
 * - isReturnBoth: Boolean to return both selected items and IDs.
 * - testId: Test ID for select input.
 * - valueTestId: Test ID for selected values.
 *
 * Example Usage:
 * ```
 * <Select
 *   label="Select Fruit"
 *   innerLabel="Fruit"
 *   id="select-fruit"
 *   onChange={handleFruitChange}
 *   values={[
 *     { id: 1, value: 'Apple' },
 *     { id: 2, value: 'Orange' },
 *     { id: 3, value: 'Banana' }
 *   ]}
 *   defaultValue={[{ id: 1, value: 'Apple' }]}
 *   isMulti={true}
 *   error={false}
 *   errorText=""
 *   isReturnId={true}
 *   isReturnBoth={false}
 * />
 * ```
 */
export const Select = ({
  label,
  innerLabel,
  id,
  onChange,
  values,
  className,
  isLoading = false,
  defaultValue = [],
  error = false,
  errorText = "",
  isMulti = false,
  isReset = false,
  isReturnId = true,
  isReturnBoth = false,
  testId = "select-input",
  valueTestId = "selected-value",
  isWrap = false,
  loadingHeight = 38,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (isMulti && defaultValue.length) {
      const spreadValue = defaultValue.map((item) => item?.value);
      setSelectedValue([...spreadValue]);
    } else if (!isMulti && !selectedValue?.length && defaultValue[0]?.value) {
      setSelectedValue([defaultValue[0]?.value]);
    }
    // force
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, isMulti]);

  useEffect(() => {
    if (isReset) {
      setSelectedValue([]);
      onChange?.(isMulti ? [] : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedItems = values.filter((item) =>
      isMulti ? value.includes(item.value) : item.value === value
    );

    const selectedIds = selectedItems.map((item) => item.id);
    const selectedValues = selectedItems.map((item) => item.value);

    setSelectedValue(isMulti ? value : [value]);

    onChange?.(
      isReturnBoth
        ? isMulti
          ? selectedItems
          : selectedItems[0]
        : isReturnId
          ? isMulti
            ? selectedIds
            : selectedIds[0]
          : isMulti
            ? selectedValues
            : selectedValues[0]
    );
  };

  const handleDelete = (valueToDelete) => {
    let updatedValue;
    if (isMulti) {
      updatedValue = selectedValue.filter((value) => value !== valueToDelete);
    } else {
      updatedValue = [];
    }

    const deletedItem = values.find((item) => item.value === valueToDelete);
    const selectedItems = values.filter((item) =>
      updatedValue.includes(item.value)
    );
    const selectedIds = selectedItems.map((item) => item.id);
    const selectedValues = selectedItems.map((item) => item.value);

    setSelectedValue(updatedValue);

    onChange?.(
      isReturnBoth
        ? isMulti
          ? selectedItems
          : selectedItems[0]
        : isReturnId
          ? isMulti
            ? selectedIds
            : selectedIds[0]
          : isMulti
            ? selectedValues
            : selectedValues[0],
      deletedItem?.id // return the id of the deleted item
    );
  };

  return (
    <CustomTheme>
      {isLoading ? (
        <SkeletonRect className="w-full" height={loadingHeight} />
      ) : (
        <div className="h-full w-full flex flex-col">
          {label && (
            <LabelText labelFor={id} className={"mb-[8px]"}>
              {label}
            </LabelText>
          )}
          <FormControl size="small">
            <InputLabel>{innerLabel}</InputLabel>
            <MUISelect
              id={id}
              size="small"
              multiple={isMulti}
              onChange={handleChange}
              value={selectedValue}
              MenuProps={MenuProps}
              input={<OutlinedInput label={innerLabel} />}
              data-testid={testId}
              renderValue={(selected) => {
                return isMulti ? (
                  <div
                    className={
                      isWrap ? "flex flex-wrap gap-2" : "overflow-hidden"
                    }
                  >
                    {selected.map((value) => {
                      return (
                        <ToolTip key={value} text={value}>
                          <Chip
                            label={value}
                            className="mr-2 max-w-[120px] truncate"
                            onDelete={() => handleDelete(value)}
                            data-testid={valueTestId}
                            deleteIcon={
                              <button
                                className="border-none flex cursor-pointer items-center mr-[8px] hover:bg-primary-hover rounded-full p-2"
                                onMouseDown={(event) => event.stopPropagation()}
                              >
                                <CloseIcon width={8} height={8} />
                              </button>
                            }
                          />
                        </ToolTip>
                      );
                    })}
                  </div>
                ) : (
                  <>{selectedValue}</>
                );
              }}
              className={twMerge("h-max", className)}
              error={error || errorText?.length > 0}
            >
              {values.length > 0 ? (
                values?.map((selectionItem) => (
                  <MenuItem
                    key={selectionItem.id}
                    value={selectionItem.value}
                    className="border border-black text-sm h-[40px]"
                  >
                    {isMulti && (
                      <Checkbox
                        size="small"
                        checked={
                          selectedValue.indexOf(selectionItem.value) > -1
                        }
                      />
                    )}
                    <span className="w-full truncate">
                      {selectionItem.value}
                    </span>
                  </MenuItem>
                ))
              ) : (
                <NormalText className="text-center">
                  No options available
                </NormalText>
              )}
            </MUISelect>
            {errorText && <ErrorText>{errorText}</ErrorText>}
          </FormControl>
        </div>
      )}
    </CustomTheme>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.node,
    })
  ).isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  innerLabel: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  isReset: PropTypes.any,
  isReturnId: PropTypes.bool,
  isReturnBoth: PropTypes.bool,
};

export default Select;

Select.defaultProps = {
  isMulti: false,
  error: false,
  errorText: "",
  isReturnId: true,
  isReturnBoth: false,
};
