import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbs } from "../../components/atoms/bread-crumbs";
import { PageHeader } from "../../components/organism/header/page-header";
import { pathList } from "../../routes/app-navigation";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { PAGE_SIZE, PROJECTS, SEVERITY } from "../../constant";
import { PageBody } from "../../components/organism/body/page-body";
import { DelayResponseBody } from "../../components/organism/body/loading-responses";
import { DDQResponseSelection } from "../features/ddq-response/ddq-response-selection";
import { useEffect, useState } from "react";
import { fetchDDQSelectionsChunks, generateResponse } from "../../action";
import { usePagination } from "../../hooks/use-pagination";
import { CustomPagination } from "../../components/molecules/pagination/page-number";
import { SmallText } from "../../components/typography";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";

export const DDQResponseSelectionPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { id, questionId, documentId } = useParams();
  const { documentName, projectName, categoryId } = location.state || {};

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedChunks, setSelectedChunks] = useState([]);

  const chunksState = useSelector((state) => state.ddqChunks);
  const view = useSelector((state) => state.view);

  const navigate = useNavigate();

  const fetchDDQChunks = async () => {
    if (
      chunksState.fetchedDocument !== documentId ||
      (id &&
        chunksState.nextIndex !== undefined &&
        chunksState.nextIndex <= chunksState.totalDocuments)
    ) {
      dispatch(
        fetchDDQSelectionsChunks({
          id,
          questionId,
          documentName,
          documentId,
          categoryId,
          startIndex: chunksState.nextIndex,
          endIndex: chunksState.nextIndex + PAGE_SIZE - 1,
        })
      );
    }
  };

  useEffect(() => {
    fetchDDQChunks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerate = () => {
    dispatch(generateResponse({ questionId, selectedChunks })).then(
      (response) => {
        if (response.payload.status === SEVERITY.Success) {
          navigate(
            `/ddq_response/list/${id}/${questionId}/${documentId}/filter/`,
            { replace: true, state: { documentName, projectName, categoryId } }
          ); // Navigate to filter page
        }
      }
    );
  };

  const { currentPageData, currentPageInformation, pageSize, totalPages } =
    usePagination({
      data: chunksState.chunks,
      totalRecords: chunksState.totalChunks,
      currentPageIndex,
    });

  return (
    <div className="pb-[20px]">
      <PageHeader
        title={"DDQ Response Selection"}
        backTo={
          <Link
            className="no-underline text-secondary"
            to={`/ddq_response/list/${id}/${questionId}/pdf`}
            state={{ projectName, categoryId }}
          >
            {PROJECTS.backToDocuments}
          </Link>
        }
      >
        <BreadCrumbs
          data={[
            {
              name: <span>{PROJECTS.allProjects}</span>,
              link: pathList.user.projectList,
            },
            {
              name: <span>{projectName}</span>,
              link: `/ddq_response/list/${id}/`,
              data: { projectName: projectName },
            },
            {
              name: <span>Documents</span>,
              link: `/ddq_response/list/${id}/${questionId}/pdf`,
              data: { projectName, categoryId },
            },
            {
              name: <span>Selection</span>,
            },
          ]}
        />
      </PageHeader>
      <PageBody>
        <DelayResponseBody isLoading={view.ddqChunks.pending}>
          <DDQResponseSelection
            currentPageData={currentPageData}
            id={id}
            callback={(value) => {
              setSelectedChunks([...value]);
            }}
          />
        </DelayResponseBody>
        <div className="flex justify-between items-center mb-[20px]">
          <Button
            size="md"
            onClick={handleGenerate}
            isLoading={view.ddqGenerate.pending}
          >
            Generate
          </Button>
          <div className="flex items-center gap-4">
            <SmallText>{currentPageInformation}</SmallText>
            <CustomPagination
              onChange={(value) => {
                if (chunksState.nextIndex <= value * pageSize) {
                  fetchDDQChunks();
                }
                setCurrentPageIndex(value);
              }}
              currentPage={currentPageIndex}
              totalPage={totalPages}
            />
          </div>
        </div>
      </PageBody>
    </div>
  );
};
