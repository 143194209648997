import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCategoryAndUploadDocument } from "../../../../action";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, SubHeadText } from "../../../../components/typography";
import { FileInput } from "../../../../components/molecules/file-input";
import { GENERAL, PROJECTS } from "../../../../constant";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";

export const AddFilesDialog = ({
  projectId,
  categoryId,
  categoryName,
  onClose,
  categories,
  responseProvider,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);

  const handleSubmit = async () => {
    if (selectedFiles.length) {
      dispatch(
        updateCategoryAndUploadDocument({
          projectId,
          categoryId,
          selectedFiles,
          categories,
          responseProvider,
        })
      );
    }
  };

  return (
    <MUIDialog
      isOpen
      disableEscapeKeyDown
      title={
        <>
          <HeadText>{categoryName}</HeadText>
          <SubHeadText>{PROJECTS.addFiles}</SubHeadText>
        </>
      }
      content={
        <div className="flex flex-col gap-4">
          <FileInput
            id={`file-input`}
            className="py-[32px] w-full"
            isMultiple
            accept={[".pdf", ".txt", ".csv", ".xlsx", ".msg", ".docx"]}
            errorText="Only files with .pdf, .txt, .csv, .xlsx , .msg and .docx extensions are allowed."
            onChange={(value) => setSelectedFiles([...value])}
            showClear={false}
          />
          <div className="w-max ml-auto flex gap-2">
            <Button variant="text" size="md" onClick={onClose}>
              {GENERAL.cancel}
            </Button>
            <Button
              size="md"
              onClick={handleSubmit}
              isLoading={view.uploadDocuments.pending}
            >
              {PROJECTS.addFiles}
            </Button>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};
