import { AddCategoryDialog } from "./add-category-dialog";
import PropTypes from "prop-types";

export const EditCategory = ({ id, onClose }) => {
  return (
    <AddCategoryDialog id={id} isEdit={true} isOpen={true} onClose={onClose} />
  );
};

EditCategory.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};
