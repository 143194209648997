import { createSlice } from "@reduxjs/toolkit";
import { fetchDDQDocuments } from "../../action";
import { PAGE_SIZE } from "../../constant";

const initialState = {
  fetchCategory: null,
  totalPages: 1,
  totalDocuments: 1,
  nextIndex: 1,
  documents: [],
};

const NAME = "ddq-documents";

const ddqDocumentsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetFetchCategory(state, action) {
      const { payload: id } = action;
      if (state.fetchCategory !== id) {
        return initialState;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDDQDocuments.fulfilled, (state, { payload }) => {
      if (payload.documents) {
        state.documents = [...state.documents, ...payload.documents];
        state.nextIndex = payload.nextIndex;
        state.totalDocuments = payload.totalDocuments;
        state.totalPages = Math.ceil(payload.totalDocuments / PAGE_SIZE);
        state.fetchCategory = payload.category;
      }
    });
  },
});

export const { actions } = ddqDocumentsSlice;

export const { resetDDQResponseStats } = actions;

export default ddqDocumentsSlice.reducer;
