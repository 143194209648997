import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { COLORS } from "../../../configuration";
import PropTypes from "prop-types";
import { TableBody } from "@mui/material";
import { PROJECTS } from "../../../constant";

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: COLORS.primaryXLight,
    color: COLORS.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: COLORS.tableRowOne,
  },
  "&:nth-of-type(even)": {
    backgroundColor: COLORS.tableRowTwo,
  },
}));

export const NoDataAvailable = ({ span = 0 }) => {
  return (
    <TableBody>
      <TableRow>
        <StyledTableCell colSpan={span} rowSpan={2} className="text-center">
          {PROJECTS.noDataAvailable}
        </StyledTableCell>
      </TableRow>
    </TableBody>
  );
};
NoDataAvailable.propTypes = {
  span: PropTypes.number,
};
