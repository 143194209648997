import PropTypes from "prop-types";
import { CustomTheme } from "../../components/theme/custom-theme";
import { AppHeader } from "../../components/organism/header/index";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../../components/organism/footer";
import { useDispatch, useSelector } from "react-redux";
import { actions as viewActions } from "../slice/view";
import { Toast } from "../../components/molecules/toast";
import { useEffect } from "react";
import { actions } from "../slice/user";
import { fetchCategoryAndProject, refetchUserData } from "../../action";
import { AdminPage } from "./admin";
import { MUISpinner } from "../../components/atoms/loader/spinner";
import { LOGIN_KEY } from "../../constant";
import { AppPath, pathList } from "../../routes/app-navigation";

export const Page = ({ children }) => {
  const view = useSelector((state) => state.view);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isHide =
    !user?.email ||
    location.pathname === "/" ||
    location.pathname === "/registration";

  const redirectToUserPage = () => {
    navigate(pathList.user.projectList);
  };

  useEffect(() => {
    const userData = localStorage.getItem(LOGIN_KEY);

    if (userData) {
      dispatch(refetchUserData(redirectToUserPage));
    } else if (
      AppPath.unProtected.findIndex((i) => i.path !== location.pathname) === -1
    ) {
      navigate(pathList.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.email) {
      dispatch(fetchCategoryAndProject());
    }
  }, [user?.email, dispatch]);

  const isAdmin = Object.values(pathList.admin).some((i) => {
    return location.pathname.includes(i);
  });

  useEffect(() => {
    if (user?.email && user?.isAdminActive !== isAdmin) {
      dispatch(actions.setAdminActive(isAdmin));
    }
  }, [
    dispatch,
    isAdmin,
    user?.email,
    user?.isAdminActive,
    view?.login?.pending,
  ]);

  if (view?.login?.pending) {
    return (
      <div>
        {view?.message?.length > 0 &&
          view?.message?.map((item) => (
            <Toast
              onClose={() =>
                dispatch(viewActions.deleteMessage({ id: item?.id || "" }))
              }
              severity={item?.severity}
              key={item?.id}
            >
              {item?.content}
            </Toast>
          ))}
        <div className="top-8">
          <MUISpinner
            size={42}
            thickness={4}
            className="mt-[18] text-black"
            color="success"
            text="Please Wait..."
          />
        </div>
      </div>
    );
  }

  return (
    <CustomTheme>
      <div className="flex flex-col justify-between h-screen">
        <div>
          {!isHide && <AppHeader isAdminActive={user?.isAdminActive} />}
          {user?.isAdminActive ? <AdminPage>{children}</AdminPage> : children}
        </div>
        {!isHide && !user?.isAdminActive && <Footer />}
      </div>
      {view?.message?.length > 0 &&
        view?.message?.map((item) => (
          <Toast
            onClose={() =>
              dispatch(viewActions.deleteMessage({ id: item?.id || "" }))
            }
            severity={item?.severity}
            key={item?.id}
          >
            {item?.content}
          </Toast>
        ))}
    </CustomTheme>
  );
};

Page.propTypes = {
  children: PropTypes.node,
};

export default Page;
