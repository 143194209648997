import { Link } from "react-router-dom";
import {
  ProfileIcon,
  GavelIcon,
  DDQManagementIcon,
  CategoryManagementIcon,
  SupportReqIcon,
  DocumentManagementIcon,
  FeedbackIcon,
  ListIcon,
  DocumentProcessIcon,
} from "../components/icons";
import { GeneralMenu } from "../components/molecules/menu/toggle-menu";
import { GENERAL } from "../constant";
import { LoginPage } from "../universal/pages/login";
import { PageNotFound } from "../universal/pages/not-found";
import { ProjectListPage } from "../universal/pages/project-list";
import { RegistrationPage } from "../universal/pages/registration";
import { ResetPasswordPage } from "../universal/pages/reset-password";
import { ClientManagementPage } from "../universal/pages/admin/client-management";
import { DocumentManagementPage } from "../universal/pages/admin/document-management";
import { DDQManagementPage } from "../universal/pages/admin/ddq-management";
import { CategoryManagementPage } from "../universal/pages/admin/category-management";
import { SupportRequestPage } from "../universal/pages/admin/support-request";
import { AboutUsPage } from "../universal/pages/about-us";
import { ContactUsPage } from "../universal/pages/contact-us";
import { PrivacyPolicyPage } from "../universal/pages/privacy-policy";
import { TermsAndConditionPage } from "../universal/pages/terms-and-condition";
import { MyProfilePage } from "../universal/pages/my-profile";
import { UploadPdfPage } from "../universal/pages/upload-pdf";
import { AddDDQPage } from "../universal/pages/admin/add-ddq";
import { ViewProjectPage } from "../universal/pages/view-project";
import { EditDDQPage } from "../universal/pages/admin/edit-ddq";
import { ViewDocumentPage } from "../universal/pages/admin/view-document";
import { EditProjectPage } from "../universal/pages/edit-project";
import { DDQResponsePage } from "../universal/pages/ddq-response";
import { DDQDocumentListPage } from "../universal/pages/ddq-document-list-page";
import { DDQResponseSelectionPage } from "../universal/pages/ddq-response-selection-page";
import { DDQResponseFilterPage } from "../universal/pages/ddq-response-filter-page";
import { DDQFeedbackPage } from "../universal/pages/admin/ddq-feedback";
import { LogoutMenu } from "./logout";
import { GPTProcessingPage } from "../universal/pages/admin/gpt-processing";

export const BASE_ADMIN_PATH = [
  "clients",
  "document_management",
  "ddqs",
  "categories",
  "search4less_management",
  "support_request",
];

export const pathList = {
  login: "/",
  registration: "/registration",
  resetPassword: "reset_password/:id/:token/",
  user: {
    edit: "/edit/",
    projects: "/projects",
    projectList: "/projects/list/",
    projectId: "/projects/:id/",
    projectEdit: "/projects/:id/edit/",
    projectUploadFile: "/projects/upload-file/",
    ddqResponse: "/ddq_response/list/",
    ddqResponseId: "/ddq_response/list/:id/",
    ddqResponseDocument: "/ddq_response/list/:id/:questionId/pdf/",
    ddqResponseSelect: "/ddq_response/list/:id/:questionId/:documentId/select/",
    ddqResponseFilter: "/ddq_response/list/:id/:questionId/:documentId/filter/",
    profile: "/profile/",
  },
  admin: {
    clientList: "/clients/list/",
    documentList: "/document_management/list/",
    gptProcessing: "/gpt_processing/",
    clientDocuments: "/client_documents/list/",
    clientDocumentList: "/client_documents/list/:id/",
    ddqs: "/ddqs/",
    ddqList: "/ddqs/list/",
    addDDQ: "/ddqs/add/",
    editDDQ: "/ddqs/:id/",
    ddqFeedback: "/ddqs/feedback/",
    categoriesList: "/categories/list/",
    searchLess: "/search4less_management/list/",
    supportRequest: "/support_request/",
  },
  contactUs: "/contact_us/",
  aboutUs: "/about_us/",
  termsAndCondition: "/terms_and_conditions/",
  privacyPolicy: "/privacy_policy/",
};

export const AppMenus = [
  {
    id: "home",
    name: "Home",
    url: pathList.user.projectList,
  },
  {
    id: "about-us",
    name: "About Us",
    url: pathList.aboutUs,
  },
  {
    id: "contact-us",
    name: "Contact Us",
    url: pathList.contactUs,
  },
];

export const UserMenus = [
  {
    id: "my-profile",
    content: (
      <Link
        to={pathList.user.profile}
        className="no-underline text-secondary hover:text-primary"
      >
        <GeneralMenu>
          <ProfileIcon />
          {GENERAL.myProfile}
        </GeneralMenu>
      </Link>
    ),
  },
  {
    id: "logout",
    content: <LogoutMenu />,
  },
];

export const AdminMenus = [
  {
    id: "client-management",
    icon: <GavelIcon />,
    name: "Client Management",
    url: pathList.admin.clientList,
  },
  {
    id: "document-management",
    icon: <DocumentManagementIcon />,
    name: "Document Management",
    url: pathList.admin.documentList,
  },
  {
    id: "gpt-processing",
    icon: <DocumentProcessIcon />,
    name: "GPT Processing",
    url: pathList.admin.gptProcessing,
  },
  {
    id: "ddq-management",
    icon: <DDQManagementIcon />,
    name: "DDQ Management",
    url: [
      pathList.admin.ddqList,
      pathList.admin.ddqFeedback,
      pathList.admin.addDDQ,
    ],
    subMenus: [
      {
        id: "ddq-list",
        name: "DDQ List",
        icon: <ListIcon />,
        url: pathList.admin.ddqList,
      },
      {
        id: "feedback",
        icon: <FeedbackIcon />,
        name: "DDQ Feedback",
        url: pathList.admin.ddqFeedback,
      },
    ],
  },
  {
    id: "category-management",
    icon: <CategoryManagementIcon />,
    name: "Category Management",
    url: pathList.admin.categoriesList,
  },
  {
    id: "support",
    icon: <SupportReqIcon />,
    name: "Support Request",
    url: pathList.admin.supportRequest,
  },
];

export const AppPath = {
  unProtected: [
    {
      name: "Login",
      path: pathList.login,
      page: <LoginPage />,
    },
    {
      name: "Registration",
      path: pathList.registration,
      page: <RegistrationPage />,
    },
    {
      name: "Reset password",
      path: pathList.resetPassword,
      page: <ResetPasswordPage />,
    },
    {
      name: "Privacy Policy",
      path: pathList.privacyPolicy,
      page: <PrivacyPolicyPage />,
    },
    {
      name: "Terms & Conditions",
      path: pathList.termsAndCondition,
      page: <TermsAndConditionPage />,
    },
  ],

  protected: {
    user: [
      {
        name: "Project List",
        path: pathList.user.projectList,
        page: <ProjectListPage />,
      },
      {
        name: "Edit Project",
        path: pathList.user.projectEdit,
        page: <EditProjectPage />,
      },
      {
        name: "View Project",
        path: pathList.user.projectId,
        page: <ViewProjectPage />,
      },
      {
        name: "DDQ Response",
        path: pathList.user.ddqResponseId,
        page: <DDQResponsePage />,
      },
      {
        name: "DDQ Response Documents",
        path: pathList.user.ddqResponseDocument,
        page: <DDQDocumentListPage />,
      },
      {
        name: "DDQ Response Selection",
        path: pathList.user.ddqResponseSelect,
        page: <DDQResponseSelectionPage />,
      },
      {
        name: "DDQ Response Filter",
        path: pathList.user.ddqResponseFilter,
        page: <DDQResponseFilterPage />,
      },
      {
        name: "Upload file",
        path: pathList.user.projectUploadFile,
        page: <UploadPdfPage />,
      },
      {
        name: "My Profile",
        path: pathList.user.profile,
        page: <MyProfilePage />,
      },
      {
        name: "About Us",
        path: pathList.aboutUs,
        page: <AboutUsPage />,
      },
      {
        name: "Contact Us",
        path: pathList.contactUs,
        page: <ContactUsPage />,
      },
    ],
    admin: [
      {
        name: "Client Management",
        path: pathList.admin.clientList,
        page: <ClientManagementPage />,
      },
      {
        name: "Document Management",
        path: pathList.admin.documentList,
        page: <DocumentManagementPage />,
      },
      {
        name: "GPT Processing",
        path: pathList.admin.gptProcessing,
        page: <GPTProcessingPage />,
      },
      {
        name: "Client Document List",
        path: pathList.admin.clientDocumentList,
        page: <ViewDocumentPage />,
      },
      {
        name: "DDQ Management",
        path: pathList.admin.ddqList,
        page: <DDQManagementPage />,
      },
      {
        name: "Add DDQ",
        path: pathList.admin.addDDQ,
        page: <AddDDQPage />,
      },
      {
        name: "Edit DDQ",
        path: pathList.admin.editDDQ,
        page: <EditDDQPage />,
      },
      {
        name: "Category Management",
        path: pathList.admin.categoriesList,
        page: <CategoryManagementPage />,
      },
      {
        name: "Support request",
        path: pathList.admin.supportRequest,
        page: <SupportRequestPage />,
      },
      {
        name: "Feedback",
        path: pathList.admin.ddqFeedback,
        page: <DDQFeedbackPage />,
      },
    ],
  },

  default: [
    {
      name: "Page not found",
      path: "*",
      page: <PageNotFound />,
    },
  ],
};

export const FooterNavigation = [
  {
    id: "aboutUs",
    name: "About Us",
    url: pathList.aboutUs,
  },
  {
    id: "terms-condition",
    name: "Terms and Conditions",
    url: pathList.termsAndCondition,
  },
  {
    id: "privacy-policy",
    name: "Privacy Policy",
    url: pathList.privacyPolicy,
  },
];
