import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const MaximizeIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      fill={color}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      id="maximize"
      data-name="Flat Color"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Maximize</title>
      <path
        id="primary"
        d="M10.71,14.71,5.41,20H10a1,1,0,0,1,0,2H4a2,2,0,0,1-2-2V14a1,1,0,0,1,2,0v4.59l5.29-5.3a1,1,0,0,1,1.42,1.42ZM20,2H14a1,1,0,0,0,0,2h4.59l-5.3,5.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L20,5.41V10a1,1,0,0,0,2,0V4A2,2,0,0,0,20,2Z"
        fill={color}
        strokeWidth={strokeWidth}
      ></path>
    </svg>
  );
};

MaximizeIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
