import PropTypes from "prop-types";
import React, { useState } from "react";
import { DeleteIcon, EditIcon, RightIcon } from "../../icons";
import { NormalText } from "../../typography";
import { twMerge } from "tailwind-merge";
import { MUIButton as Button } from "../button/mui-button";

/**
 * Accordion Component (with customized background)
 *
 * This component renders a customizable accordion with a title, content, and optional delete button.
 * It takes various props to customize its behavior and appearance.
 *
 * Props:
 * - title: String to be displayed as the title of the accordion (optional if titleNode is provided).
 * - titleNode: React node to be displayed as the title of the accordion (optional).
 * - children: React node to be displayed as the content of the accordion.
 * - className: Optional custom className for additional styling.
 * - onDelete: Optional callback function to be called when the delete button is clicked.
 * - isBackground: Optional boolean to set the background color of the accordion.
 *
 * Example usage:
 * ```
 * <Accordion title="Accordion Title" isBackground={true} onDelete={() => console.log('Deleted')}>
 *   <p>Accordion content goes here</p>
 * </Accordion>
 * ```
 */
export const Accordion = ({
  title,
  titleNode,
  children,
  className,
  onDelete,
  onEdit,
  isBackground = true,
}) => {
  const [visible, setVisible] = useState(false);

  // Handle click to toggle accordion visibility
  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <div
      className={twMerge(
        "border border-solid min-h-max border-edge p-2 rounded-md",
        visible ? "overflow-auto" : "overflow-hidden",
        isBackground ? "bg-page-light" : "bg-none",
        className
      )}
    >
      <div className="flex justify-between font-bold items-center">
        <div
          className="flex gap-2 items-center cursor-pointer group"
          onClick={handleClick}
          aria-hidden="true"
        >
          <div
            className={`transform ${
              visible ? "rotate-90" : "rotate-0"
            } transition-transform duration-500`}
          >
            <RightIcon
              className="fill-secondary-light group-hover:fill-primary"
              width={16}
              height={16}
            />
          </div>
          {/* Render the title node if provided, otherwise render the title text */}
          {titleNode ? (
            React.cloneElement(titleNode)
          ) : (
            <NormalText className="group-hover:text-primary">
              {title}
            </NormalText>
          )}
        </div>
        <div>
          {/* Render edit button if onEdit callback is provided */}
          {onEdit && (
            <Button variant="text" color="primary" onClick={onEdit}>
              <EditIcon width={16} height={16} strokeWidth={1} />
            </Button>
          )}
          {/* Render delete button if onDelete callback is provided */}
          {onDelete && (
            <Button variant="text" color="error" onClick={onDelete}>
              <DeleteIcon width={16} height={16} />
            </Button>
          )}
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ${
          visible ? "overflow-y-auto max-h-[240px]" : "max-h-0 overflow-hidden"
        }`}
      >
        <div className="text-sm border-t border-y-0 border-x-0 border-edge border-solid p-[8px]">
          {children}
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  titleNode: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  onDelete: PropTypes.func,
  isBackground: PropTypes.bool,
};
