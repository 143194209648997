import { PAGE_SIZE } from "../constant";

export const usePagination = ({
  data = [],
  currentPageIndex = 1,
  totalRecords,
}) => {
  // Calculate total number of pages
  const totalPages = totalRecords
    ? Math.ceil(totalRecords / PAGE_SIZE)
    : Math.ceil(data.length / PAGE_SIZE);

  const validatedPageIndex = Math.max(
    1,
    Math.min(currentPageIndex, totalPages)
  );
  const startIdx = (validatedPageIndex - 1) * PAGE_SIZE;
  const endIdx = validatedPageIndex * PAGE_SIZE;

  const currentPageData = data.slice(startIdx, endIdx);

  const startItem = startIdx + 1;
  const endItem = startIdx + currentPageData.length;

  const currentPageInformation = data.length
    ? `${startItem}-${endItem} of ${totalRecords ?? data.length}`
    : `0-0 of 0`;

  return {
    currentPageData,
    totalPages,
    currentPageInformation,
    pageSize: PAGE_SIZE,
  };
};
