import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useResponsePreview } from "../../../hooks/use-response-preview";
import { PAGE_SIZE } from "../../../constant";
import { DynamicTable } from "../../../components/atoms/table";
import { MUIButton } from "../../../components/atoms/button/mui-button";
import { useSelector } from "react-redux";

// const data = [
//   {
//     id: 1,
//     question: "Constitution subsidiary",
//     response:
//       "The document lists the following current directors: Keith Gill, John Roe, Tony/Anthony Gill, Linda Gallagher, Conor Lyons, and Sarah Sheridan. Past directors include Cathal Duffy, Bernard Swierczyna, Ciaran Rooney, Patrick Mee, Michael Hennighan, Cilian O'Brolchain, Dermot Rutherford, Ian Thornton, Padraig Ahearne, Gerard Conway, Joseph O'Rourke, Sean Kavanagh, and Marc O'Connor. The document also includes dates of service and any changes in roles.",
//     vdr: "10.1.11_Statutory Registers First Ireland Risk Management Limited.pdf",
//     ddqResponse: [
//       {
//         response:
//           "The document lists the following current directors: Keith Gill, John Roe, Tony/Anthony Gill, Linda Gallagher, Conor Lyons, and Sarah Sheridan. Past directors include Cathal Duffy, Bernard Swierczyna, Ciaran Rooney, Patrick Mee, Michael Hennighan, Cilian O'Brolchain, Dermot Rutherford, Ian Thornton, Padraig Ahearne, Gerard Conway, Joseph O'Rourke, Sean Kavanagh, and Marc O'Connor. The document also includes dates of service and any changes in roles.",
//         vdr: "10.1.12_Registers Files for First Ireland Risk Management Limited.pdf",
//       },
//     ],
//   },
// ];

export const DDQResponsePreviewTable = ({ data, onloadMoreData }) => {
  const { columns, sortingState, setSortingState, expanded, setExpanded } =
    useResponsePreview();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sortingState,
      expanded: expanded,
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    getSubRows: (row) => row.ddqResponse,
    onSortingChange: setSortingState,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetPageIndex: false, //turn off auto reset of pageIndex
  });

  const view = useSelector((state) => state.view);

  return (
    <div>
      <DynamicTable
        table={table}
        columns={columns.length}
        records={data.length}
        onRowsChange={(value) => table.setPageSize(value)}
        extraButton={
          <MUIButton
            onClick={() => {
              onloadMoreData?.(table);
            }}
            size="md"
            variant="outlined"
            isLoading={view.ddqResponse.pending}
          >
            Load More
          </MUIButton>
        }
      />
    </div>
  );
};
