import { LOGIN } from "../../constant";

export const LoginRegistrationCommonTemplate = () => {
  return (
    <div className="login-registration-banner w-[960px] h-full overflow-hidden relative">
      <div className="text-xl absolute w-full min-h-screen bg-[rgba(30,58,111,0.9)] ">
        <div className=" h-[112px] font-bold w-[448px] my-[380px] ml-[166px] text-white">
          <div className="text-[48px] mb-[20px]">
            <div className="text-warning-dark inline-block mb-8">
              {LOGIN.sayGoodBye}
            </div>
            {` ${LOGIN.toManualDocument}`}
          </div>
          <div className="text-[24px]">{LOGIN.searchAndGet}</div>
        </div>
      </div>
      <img
        src="/static/img/registration/login-registration-bg.jpeg"
        alt="login-banner"
        className="object-cover w-full h-full"
      />
    </div>
  );
};
