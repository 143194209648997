import { createColumnHelper } from "@tanstack/react-table";
import { DownFilledArrow, EyeIcon, UpFilledArrow } from "../components/icons";
import { COLORS } from "../configuration";
import { useMemo, useState } from "react";
import { MUIButton as Button } from "../components/atoms/button/mui-button";

export const useFeedback = () => {
  const columnHelper = createColumnHelper();
  // #, Lawyer name, Project name, Category, DDQ, View Feedback (button)

  const columnNames = useMemo(
    () => ({
      id: "Id",
      serialNo: "Sr. No.",
      clientName: "Client Name",
      projectName: "Project Name",
      category: "Category",
      ddq: "DDQ",
      view: "View",
    }),
    []
  );
  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [dialog, setDialog] = useState({
    isView: false,
    id: null,
    llmResponse: null,
    vdr: [],
    comments: null,
    missingDocuments: null,
    question: null,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (props) => props.row.index + 1,
        sortingFn: "basic",
        header: (
          <span data-testid="feedback-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                sortingState[0].desc = !sortingState[0].desc;
                setSortingState([...sortingState]);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("clientName", {
        id: "clientName",
        cell: (props) => props.getValue(),
        filterFn: "clientFilter",
        header: columnNames.clientName,
      }),
      columnHelper.accessor("projectName", {
        id: "projectName",
        cell: (props) => props.getValue(),
        header: columnNames.projectName,
      }),
      columnHelper.accessor("categoryName", {
        id: "category",
        cell: (prop) =>
          (
            <div
              className="bg-primary-hover text-secondary-light w-max px-2 p-[4px] rounded-md"
              data-testid="ddq-categories"
            >
              {prop.getValue()}
            </div>
          ) ?? "",
        filterFn: "categoryFilter",
      }),
      columnHelper.accessor("question", {
        id: "ddq",
        cell: (props) => props.getValue(),
        header: columnNames.ddq,
      }),
      columnHelper.accessor(
        (row) => {
          return {
            llmResponse: row?.llmResponse,
            id: row.id,
            projectName: row?.projectName,
            comments: row?.gptComments,
            vdr: row?.vdrReferences,
            missingDocuments: row?.missingDocuments,
            ddq: row?.question,
          };
        },
        {
          id: "view",
          cell: (props) => (
            <Button
              variant="text"
              onClick={() =>
                setDialog({
                  id: props.getValue().id,
                  isView: true,
                  comments: props.getValue().comments,
                  llmResponse: props.getValue().llmResponse,
                  vdr: props.getValue().vdr,
                  missingDocuments: props.getValue().missingDocuments,
                  question: props.getValue().ddq,
                })
              }
            >
              <EyeIcon strokeWidth={1.5} />
            </Button>
          ),
          header: columnNames.view,
        }
      ),
    ],
    [columnHelper, columnNames, sortingState]
  );

  return {
    columnNames,
    columns,
    sortingState,
    setSortingState,
    dialog,
    setDialog,
  };
};
