import CryptoJS from "crypto-js";
import { LOGIN_KEY, SECRET_KEY } from "../constant";

export const encryptData = (data) => {
  if (data) {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();

    localStorage.setItem(LOGIN_KEY, encrypted);
  }
};

export const decryptedData = () => {
  const encrypted = localStorage.getItem(LOGIN_KEY);
  let decrypted;
  if (encrypted) {
    decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
  }
  return decrypted && JSON.parse(decrypted);
};
