import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { DownArrow, UpArrow } from "../../icons";

/**
 * AccordionV2 Component
 *
 * This component renders a collapsible accordion with a title and content.
 * It takes various props to customize its behavior and appearance.
 *
 * Props:
 * - title: React node to be displayed as the title of the accordion.
 * - children: React node to be displayed as the content of the accordion.
 * - className: Optional custom className for additional styling.
 * - callBack: Optional callback function to be called when the accordion is toggled.
 * - initial: Optional boolean to set the initial visibility of the accordion.
 *
 * Example usage:
 * ```
 * <AccordionV2 title={<h3>Title</h3>} initial={true}>
 *   <p>Accordion content goes here</p>
 * </AccordionV2>
 * ```
 */
export const AccordionV2 = ({
  title,
  children,
  className,
  callBack,
  initial = false,
}) => {
  const [visible, setVisible] = useState(initial);
  const [showMore, setShowMore] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Update content height whenever children change
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.clientHeight);
    }
  }, [children]);

  // Handle click to toggle accordion visibility
  const handleClick = () => {
    setVisible(!visible);
    setShowMore(false);
    callBack?.(!visible);
  };

  return (
    <div
      className={twMerge(
        "border border-solid h-max border-edge p-2 rounded-md",
        visible ? "overflow-auto" : "overflow-hidden",
        className
      )}
    >
      <div className="flex justify-between items-center" aria-hidden="true">
        <div>{title}</div>
        <div>
          <button
            onClick={handleClick}
            className={`bg-none bg-transparent border-none cursor-pointer transform ${
              visible ? "rotate-180" : "rotate-0"
            } transition-transform duration-500`}
          >
            <DownArrow />
          </button>
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ${
          visible ? "overflow-y-auto max-h-[240px]" : "max-h-0 overflow-hidden"
        } ${showMore && "max-h-full "} `}
      >
        <div
          ref={contentRef}
          className={`${
            showMore || contentHeight <= 120 ? "h-max" : "h-[120px]"
          } overflow-hidden text-sm border-t border-y-0 border-x-0 border-edge border-solid p-[8px]`}
        >
          {children}
        </div>
      </div>
      {contentHeight > 120 && visible && (
        <button
          className="bg-none bg-transparent cursor-pointer border-none"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? (
            <span className="flex items-center gap-2 text-primary">
              <UpArrow width={18} height={18} /> Show Less
            </span>
          ) : (
            <span className="flex items-center gap-2 text-primary">
              <DownArrow width={18} height={18} /> Show More
            </span>
          )}
        </button>
      )}
    </div>
  );
};

AccordionV2.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  callBack: PropTypes.func,
  initial: PropTypes.bool,
};
