import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

/**
 * Tag Component
 *
 * A custom styled tag component with support for filled, outlined, and text variants.
 *
 * Props:
 * - variant: One of 'primary', 'secondary', 'secondary-light', 'warning', 'error', 'success' (default: 'primary'), specifies the color variant of the tag.
 * - type: One of 'filled' or 'outlined' (default: 'filled'), specifies the style type of the tag.
 * - text: One of 'xs', 'sm', 'md', 'lg' (default: 'md'), specifies the text size of the tag.
 * - children: Required, the content to display inside the tag.
 * - className: String, additional classes to apply to the tag component.
 *
 * Example usage:
 * ```
 * <Tag
 *   variant="primary"
 *   type="filled"
 *   text="lg"
 * >
 *   Primary Filled Large Tag
 * </Tag>
 * ```
 */
export const Tag = ({
  variant = "primary",
  type = "filled",
  text = "md",
  children,
  className,
}) => {
  // Define base and variant styles using cva
  const tagStyles = cva(
    "py-1 px-2 w-max rounded-full m-1 border border-solid",
    {
      variants: {
        filled: {
          primary: " bg-primary text-white",
          secondary: " bg-secondary text-white",
          "secondary-light": " bg-secondary-light text-white",
          warning: " bg-warning text-secondary-extra-dark",
          error: " bg-error text-white",
          success: " bg-success text-white",
        },
        outlined: {
          primary: " border-primary text-primary",
          secondary: " border-secondary text-secondary",
          "secondary-light": " border-secondary-light text-secondary-light",
          warning: " border-warning text-warning-dark",
          error: " border-error text-error",
          success: " border-success text-success",
        },
        text: {
          xs: " text-xs",
          sm: " text-sm",
          md: " text-base",
          lg: " text-lg",
        },
      },
    }
  );

  // Determine the dynamic class based on variant and type
  let key = { outlined: variant, text };
  if (type === "filled") key = { text, filled: variant };

  const dynamicStyle = tagStyles(key);

  return (
    // Merge variant-specific styles with additional classes provided by the className prop
    <div className={twMerge(dynamicStyle, className)}>{children}</div>
  );
};

// PropTypes for Tag
Tag.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "secondary-light",
    "warning",
    "error",
    "success",
  ]),
  type: PropTypes.oneOf(["filled", "outlined"]),
  text: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

// Default values for optional props
Tag.defaultProps = {
  variant: "primary",
  type: "filled",
  className: "",
  text: "md",
};

export default Tag;
