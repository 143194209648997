import { useDispatch, useSelector } from "react-redux";
import { GENERAL, GREETING, USERS } from "../../../constant";
import { pathList, UserMenus } from "../../../routes/app-navigation";
import { GeneralMenu, ToggleMenu } from "../../molecules/menu/toggle-menu";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { GoToIcon } from "../../icons";
import { actions } from "../../../universal/slice/user";
import { refetchUserData } from "../../../action";

export const UserNavigation = ({ isAdminActive = false }) => {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleGoTo = (payload) => {
    dispatch(refetchUserData());
    dispatch(actions.setAdminActive(payload));
  };
  const GoToAdminMenu = {
    id: "goto-admin",
    content: (
      <Link
        to={pathList.admin.clientList}
        className="text-secondary hover:text-primary no-underline"
        onClick={() => handleGoTo(true)}
      >
        <GeneralMenu>
          <GoToIcon strokeWidth={1} />
          {GENERAL.goToAdmin}
        </GeneralMenu>
      </Link>
    ),
  };

  const GoToUserMenu = {
    id: "goto-user",
    content: (
      <Link
        to={pathList.user.projectList}
        className="text-secondary hover:text-primary no-underline"
        onClick={() => handleGoTo(false)}
      >
        <GeneralMenu>
          <GoToIcon />
          {GENERAL.goToUser}
        </GeneralMenu>
      </Link>
    ),
  };

  const selectGoTo = isAdminActive ? GoToUserMenu : GoToAdminMenu;
  return (
    <ToggleMenu
      menus={[
        userState.role === USERS.Admin || userState.role === USERS.Staff
          ? selectGoTo
          : null,
        ...UserMenus,
      ]}
    >
      <span className="text-secondary"> {`${GREETING}, `} </span>
      <span className="font-bold text-secondary-extra-dark">
        {userState.firstName}
      </span>
    </ToggleMenu>
  );
};

UserNavigation.propTypes = {
  isAdminActive: PropTypes.bool,
};
