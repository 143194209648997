import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

export const PageBody = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        "lg:w-1/2 lg:p-0 p-[12px] w-full mx-auto mt-[30px]",
        className
      )}
    >
      {children}
    </div>
  );
};

PageBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};