import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NoDataAvailable, StyledTableCell, StyledTableRow } from "./table";
import { flexRender } from "@tanstack/react-table";
import { SkeletonTableBody } from "./skeleton-table";
import { CustomPagination } from "../../molecules/pagination/page-number";
import PropTypes from "prop-types";
import { SmallText } from "../../typography";
import { GENERAL } from "../../../constant";
import React from "react";
import { COLORS } from "../../../configuration";
import { SkeletonText } from "../loader/skeleton";

export const DynamicTable = ({
  table,
  columns,
  isPending,
  extraButton,
  records = 0,
  onRowsChange = () => {},
  isPaginationRequired = true,
}) => {
  const currentPageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const startRecord =
    table.getRowCount() === 0 ? 0 : currentPageIndex * pageSize + 1;
  const endRecord = Math.min(startRecord + pageSize - 1, table.getRowCount());

  const tableBody =
    records > 0 ? (
      table.getRowCount() > 0 ? (
        <TableBody>
          {table.getRowModel().rows.map((row, rowIndex) => {
            return (
              <React.Fragment key={row.id}>
                <StyledTableRow
                  key={`${row.id}${rowIndex}`}
                  data-testid="table-row"
                  className="hover:bg-primary-hover"
                >
                  {row.getAllCells().map((cell, cellIndex) => {
                    return (
                      <StyledTableCell
                        id={cell.column.id}
                        key={`${cell.id}${cellIndex}`}
                        className="text-base"
                        sx={{
                          borderBottom:
                            endRecord ===
                            pageSize * currentPageIndex + rowIndex + 1
                              ? `1px solid ${COLORS.edge}`
                              : "none",
                          borderTop:
                            row.depth === 0 ? `1px solid ${COLORS.edge}` : "",
                          verticalAlign: "top",
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      ) : (
        <NoDataAvailable span={columns} text="No Records found" />
      )
    ) : (
      <NoDataAvailable span={columns} />
    );

  return (
    <div data-testid="data-table">
      <TableContainer>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <TableRow key={`${headerGroup.id}-${index}`}>
                {headerGroup.headers.map((header) => (
                  <StyledTableCell
                    key={header.id}
                    className="font-bold text-base capitalize"
                    data-testid="table-head"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {isPending ? (
            <SkeletonTableBody columns={columns} rows={10} />
          ) : (
            tableBody
          )}
        </Table>
      </TableContainer>
      {isPaginationRequired && (
        <div className="flex flex-wrap gap-4 items-center justify-between w-full mt-[16px]">
          {isPending ? (
            <SkeletonText width={120} height={20} />
          ) : (
            <SmallText>
              {records > 0
                ? ` ${startRecord} - ${endRecord} of ${table.getRowCount()}`
                : "0-0 of 0"}
            </SmallText>
          )}
          <div className="flex flex-wrap items-center gap-4">
            {isPending ? (
              <SkeletonText width={120} height={40} />
            ) : (
              <div>
                <SmallText>{`${GENERAL.showRows} `}</SmallText>
                <select
                  onChange={(e) => onRowsChange(e.target.value)}
                  defaultValue={pageSize}
                  className="p-[8px] text-sm bg-white text-secondary-light rounded-md border border-edge border-solid"
                >
                  {[5, 10, 20].map((i) => (
                    <option value={i} key={i}>
                      {`${i}`}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <CustomPagination
              onChange={(page) => table.setPageIndex(page - 1)}
              totalPage={table.getPageCount() || 1}
              currentPage={currentPageIndex + 1}
              isLoading={isPending}
            />
            {extraButton && React.cloneElement(extraButton)}
          </div>
        </div>
      )}
    </div>
  );
};

DynamicTable.propTypes = {
  table: PropTypes.object,
  columns: PropTypes.number,
  isPending: PropTypes.bool,
  records: PropTypes.number,
  onRowsChange: PropTypes.func,
  isPaginationRequired: PropTypes.bool,
};
