import { useSelector } from "react-redux";
import Select from "../../../../components/molecules/select/select-input";

export const CategoryFilter = ({ callback, projectId, isError }) => {
  const view = useSelector((state) => state.view);

  const projects = useSelector((state) => state.clientProjects);

  const category = projects.projectList.find((i) => i.projectId === projectId);
  const categoryList =
    category?.categories?.map((i) => ({
      id: i?.id ?? "",
      value: i?.categoryName ?? "",
    })) ?? [];

  return (
    <Select
      values={[...categoryList]}
      innerLabel={"Filter Category"}
      isLoading={view?.clientProjects?.pending}
      isReset={view.clientProjects?.success}
      label={"Select Category"}
      onChange={(value) => {
        callback?.({ ...value, isError: false });
      }}
      errorText={isError ? "Category is required." : ""}
      isReturnBoth
    />
  );
};
