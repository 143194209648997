import { useSelector } from "react-redux";
import { ToolTip } from "../../../../components/atoms/tooltip";
import { DocumentIcon } from "../../../../components/icons";
import { NormalText, SmallText } from "../../../../components/typography";
import { COLORS } from "../../../../configuration";
import { useEffect, useState } from "react";
import { GENERAL } from "../../../../constant";
import { DelayResponseBody } from "../../../../components/organism/body/loading-responses";

const Document = ({ title, link }) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <ToolTip text={title}>
      <button
        className="bg-page rounded-md p-2 flex items-center gap-2 border-none cursor-pointer"
        onClick={handleClick}
      >
        <DocumentIcon color={COLORS.successDark} />
        <NormalText className={"w-[240px] truncate overflow-x-hidden"}>
          {title}
        </NormalText>
      </button>
    </ToolTip>
  );
};

const DocumentData = ({ title, documents }) => {
  return (
    <div className="border border-solid border-edge p-2 rounded-md">
      <div className=" border-edge border-solid border-y-0 border-x-0 border-b flex justify-between items-center">
        <NormalText>{title}</NormalText>
        <SmallText>{`${GENERAL.showing} ${documents?.length} ${GENERAL.document}`}</SmallText>
      </div>
      <div className="max-h-[140px] flex flex-wrap gap-4 p-4 overflow-y-auto">
        {documents.map((document) => (
          <Document
            key={document.fileName}
            link={document.blobLink}
            title={document.fileName}
          />
        ))}
      </div>
    </div>
  );
};

export const ViewDocument = ({ projectIdIndex, categoryId, isLoading }) => {
  const document = useSelector((state) => state.document);

  const [project, setProject] = useState([]);

  useEffect(() => {
    if (projectIdIndex >= 0 && categoryId && document.selected?.client) {
      if (categoryId.length) {
        const categoryFilter = document.selected.projects[
          projectIdIndex
        ].categories.filter((item) => categoryId.includes(item.categoryId));

        setProject([...categoryFilter]);
      } else {
        setProject([...document.selected.projects[projectIdIndex].categories]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.selected, projectIdIndex, categoryId]);

  return (
    <DelayResponseBody isLoading={isLoading}>
      <div className="h-max flex flex-col gap-4">
        {project.map((i) => (
          <DocumentData
            key={i.categoryId}
            title={i.categoryName}
            documents={i.pdfNames}
          />
        ))}
      </div>
    </DelayResponseBody>
  );
};
