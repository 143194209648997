import { AllRightsReserved } from "../../components/general/all-rights-reserved";
import { LoginRegistrationCommonTemplate as CommonTemplate } from "../../components/general/login-registration-common";
import { ResetPassword } from "../features/reset-password";

export const ResetPasswordPage = () => {
  return (
    <div className="grid grid-cols-2">
      <div className="min-h-screen flex flex-col justify-between">
        <ResetPassword />
        <AllRightsReserved />
      </div>
      <CommonTemplate />
    </div>
  );
};
