import { createSlice } from "@reduxjs/toolkit";
import { fetchFeedbacks } from "../../action";
import { convertKeysToCamelCase } from "../../utils/camelize";

const initialState = {
  data: [
    // {
    //   id: 1,
    //   question: "What is lorem?",
    //   llmResponse: "It's bad response",
    //   gptComments: "Nothing good!",
    //   projectName: "demo",
    //   categoryName: "Corporate Information",
    //   vdrReferences: ["10.1.2_VDR_Audit_Report.pdf"],
    //   missingDocuments : "Demo.pdf"
    //   clientName: "John Doe",
    // },
  ],
};

const NAME = "feedback";

const feedbackSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchFeedbacks.fulfilled, (state, { payload }) => {
      state.data = convertKeysToCamelCase(payload.data);
    });
  },
});

export const { actions } = feedbackSlice;

export default feedbackSlice.reducer;
