import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteRequest,
  fileRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "./api/request";
import {
  DOWNLOAD_TYPE,
  PAGE_SIZE,
  PROJECTS_KEY,
  RESPONSE_PROVIDER,
  SEVERITY,
  USERS,
} from "./constant";
import { exportFilesToCSV } from "./utils/export-file";
import { convertKeysToCamelCase } from "./utils/camelize";
import { actions } from "./universal/slice/ddq-response";
import { actions as documentAction } from "./universal/slice/document";
import {
  decryptedData as decryptUserData,
  encryptData,
} from "./utils/security-functions";

const NAME = "common";

export const registerUser = createAsyncThunk(
  `${NAME}/registerUser`,
  async ({ email, firstName, lastName, password, phoneNumber }) => {
    try {
      // Create request payload
      const payload = { email, firstName, lastName, password, phoneNumber };

      // Make the post request
      const response = await postRequest("/registration", payload);

      // Return response as an object with status and data properties
      return { status: response.data.status, data: response.data };
    } catch (error) {
      // Handle errors and rethrow them for further handling by the caller

      throw new Error(`Error registering user: ${error}`);
    }
  }
);

export const loginUser = createAsyncThunk(
  `${NAME}/loginUser`,
  async ({ email, password }) => {
    try {
      const payload = { email, password };

      const response = await postRequest("/", payload);

      return { status: response.data.status, response: response.data };
    } catch (error) {
      console.error(error);

      throw new Error(`Error login user: ${error}`);
    }
  }
);

export const fetchUserRole = createAsyncThunk(
  `${NAME}/fetchUserRole`,
  async () => {
    try {
      const response = await getRequest(`/clients/role/`);
      return {
        response: response.data.client_details,
      };
    } catch (error) {
      throw new Error(`Error login user: ${error}`);
    }
  }
);

export const refetchUserData = createAsyncThunk(
  `${NAME}/refetchUserData`,
  async (callback, { dispatch }) => {
    const currentTime = new Date().getTime();
    const expirationTime = localStorage.getItem("expirationTime");

    if (expirationTime && currentTime > parseInt(expirationTime, 10)) {
      localStorage.clear();
      sessionStorage.clear();
      return null;
    }

    const decryptedData = decryptUserData();
    if (decryptedData) {
      const fetchedRole = await dispatch(fetchUserRole()).unwrap();

      if (fetchedRole.response?.is_active) {
        const newRole = fetchedRole.response?.is_staff;
        if (!newRole && decryptedData.role !== USERS.User && callback) {
          callback();
        }
        if (
          fetchedRole &&
          newRole !==
            (decryptedData.role === USERS.Staff ||
              decryptedData.role === USERS.Admin)
        ) {
          decryptedData.role = newRole ? USERS.Staff : USERS.User;
          encryptData(decryptedData);
        }

        return {
          ...decryptedData,
          isUserActive: fetchedRole.response.is_active,
          isAdminActive: sessionStorage.getItem("isAdminActive") === "true",
        };
      } else {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        return { isUserActive: false };
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  `${NAME}/forgotPassword`,
  async ({ email }) => {
    try {
      const payload = { email };

      const response = await postRequest("/forgot_password", payload);

      return {
        status: response.data.status,
        message: response.data.message,
        email: email,
      };
    } catch (error) {
      throw new Error(`Error for reset password: ${error}`);
    }
  }
);

export const resetPassword = createAsyncThunk(
  `${NAME}/resetPassword`,
  async ({ id, token, password }, { rejectWithValue }) => {
    try {
      const body = { password };
      const response = await postRequest(
        `/reset_password/${id}/${token}/`,
        body
      );

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error for reset password: ${error.message}`);
    }
  }
);

export const resendEmailVerification = createAsyncThunk(
  `${NAME}/resendEmailVerification`,
  async ({ email }) => {
    try {
      const body = { email };
      const response = await postRequest("/resend_email", body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error for resend verification link: ${error}`);
    }
  }
);

export const fetchProjectList = createAsyncThunk(
  `${NAME}/fetchProjectList`,
  async () => {
    try {
      const response = await getRequest("/projects");
      return {
        response: response.data,
      };
    } catch (error) {
      throw new Error(`Error in fetching projects : ${error}`);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  `${NAME}/fetchCategory`,
  async () => {
    try {
      const response = await getRequest("/get_categories");

      return { response: response.data };
    } catch (error) {
      throw new Error(`Error in fetching categories : ${error}`);
    }
  }
);

export const fetchCategoryAndProject = createAsyncThunk(
  `${NAME}/fetchCategoryAndProject`,
  async (_, { dispatch }) => {
    dispatch(fetchCategories());
    dispatch(fetchProjectList());
  }
);

export const deleteProject = createAsyncThunk(
  `${NAME}/deleteProject`,
  async ({ id }, { dispatch }) => {
    try {
      const response = await deleteRequest(`/projects/${id}/delete`);

      // eslint-disable-next-line eqeqeq
      if (response.data.status == SEVERITY.Success) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(fetchProjectList());
        sessionStorage.removeItem(PROJECTS_KEY);
      }

      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error to delete project : ${error}`);
    }
  }
);

export const createNewCategory = createAsyncThunk(
  `${NAME}/createNewCategory`,
  async ({ categoryName, subcategories }, { dispatch }) => {
    try {
      const response = await postRequest("/categories/add/", {
        category_name: categoryName,
        sub_categories: subcategories,
      });
      await dispatch(fetchCategories());
      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error in creating new category : ${error}`);
    }
  }
);

export const createNewProject = createAsyncThunk(
  `${NAME}/createNewProject`,
  async (
    { name, description, category = [], croNumber, responseProvider },
    { dispatch }
  ) => {
    try {
      const body = {
        name,
        description,
        category,
        cro_number: croNumber,
        response_provider: responseProvider,
      };

      const response = await postRequest("/projects/add/", body);

      if (response.data.status === SEVERITY.Success) {
        if (responseProvider?.includes(RESPONSE_PROVIDER.searchLess))
          await getDdqList({ id: response.data.project_id, dispatch });
        dispatch(fetchProjectList());
      }

      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error creating project: ${error}`);
    }
  }
);

export const uploadDocuments = createAsyncThunk(
  `${NAME}/uploadDocuments`,
  async ({ projectId, categoryId, files }, { dispatch }) => {
    try {
      const response = await fileRequest(
        `/upload_to_blob/${projectId}/${categoryId}/`,
        files
      );
      return {
        status: response.data.status,
        message: response.data.message,
        savedFiles: response.data.saved_file?.length ?? 0,
      };
    } catch (error) {
      throw new Error(`Error uploading file: ${error}`);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  `${NAME}/deleteDocument`,
  async ({ projectId, categoryId, fileName }, { dispatch }) => {
    try {
      const body = {
        project_id: projectId,
        category_id: categoryId,
        file_name: fileName?.replace(/\s+/g, "_"),
      };
      const response = await postRequest(`/delete_file/`, body);

      if (response.data.status === SEVERITY.Success)
        dispatch(fetchProjectById({ id: projectId }));

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error deleting file: ${error}`);
    }
  }
);

// Thunk to upload documents and train data
export const uploadMultiDocuments = createAsyncThunk(
  `${NAME}/uploadMultiDocuments`,
  async ({ projectId, data }, { dispatch }) => {
    try {
      let status = true;
      let message = "";

      dispatch(documentAction.resetUploadedDocumentCount());
      for (const item of data) {
        const response = await dispatch(
          uploadDocuments({
            projectId,
            categoryId: item.categoryId,
            files: item.files,
          })
        ).unwrap();

        dispatch(documentAction.setUploadedDocumentCount(response.savedFiles));

        if (response.status !== SEVERITY.Success) {
          status = false;
          message = response.message;
          break;
        } else {
          message = response.message;
        }
      }

      return { status, message };
    } catch (error) {
      throw new Error(`Unable to upload documents for categories: ${error}`);
    }
  }
);

export const getDdqList = async ({
  id,
  startIndex,
  endIndex,
  categoryId = [],
  type = "add",
  dispatch,
  isReturnOnlyDDQList = false,
}) => {
  const response = await postRequest("/get_ddq_list/", {
    project_id: id,
    category_id: [...categoryId],
    start_index: startIndex ?? null,
    last_index: endIndex ?? null,
  });
  const { status, ddq_question, length, next_index } = response.data;

  if (isReturnOnlyDDQList)
    return { data: ddq_question, ddqStatus: status, totalRecords: length };

  let ddqResponseList = [];
  let ddqStatus = "";

  if (status === SEVERITY.Success && ddq_question?.length) {
    const firstElementResponse = await getRequest(
      `/ddq_response/${id}/${ddq_question[0]}/${type}?ddq_question_count=${length}`
    );
    if (firstElementResponse.data.status === SEVERITY.Success) {
      ddqStatus = firstElementResponse.data.status;
      const questionResponse = await Promise.all(
        ddq_question.map(async (element, index) => {
          if (index === 0) return firstElementResponse.data.ddq_details[0];
          const addResponse = await getRequest(
            `/ddq_response/${id}/${element}/${type}?ddq_question_count=${length}`
          );
          ddqStatus = addResponse.data.status;
          if (addResponse.data.status === SEVERITY.Success)
            return addResponse.data.ddq_details[0];
          return {};
        })
      );
      if (questionResponse.length) ddqResponseList = questionResponse;
      dispatch?.(fetchProjectList());
    } else {
      throw new Error("Something bad happen in ddq_response");
    }
  }

  return {
    data: ddqResponseList,
    nextIndex: next_index,
    totalRecords: length,
    ddqStatus,
  };
};

export const getDDQSyncList = async ({
  id,
  startIndex,
  endIndex,
  categoryId = [],
  type = "add",
  dispatch,
}) => {
  const {
    data: ddq_question,
    ddqStatus: status,
    totalRecords: length,
  } = await getDdqList({
    id,
    startIndex,
    endIndex,
    categoryId,
    isReturnOnlyDDQList: true,
  });

  if (status === SEVERITY.Success && ddq_question?.length) {
    const firstElementResponse = await getRequest(
      `/ddq_response/${id}/${ddq_question[0]}/${type}?ddq_question_count=${length}`
    );
    if (firstElementResponse.data.status === SEVERITY.Success) {
      dispatch?.(fetchProjectList());
      Promise.all(
        ddq_question.map(async (element, index) => {
          if (index === 0) return firstElementResponse.data.ddq_details[0];
          await getRequest(
            `/ddq_response/${id}/${element}/${type}?ddq_question_count=${length}`
          );
        })
      ).then(() => dispatch?.(fetchProjectList()));
    } else {
      throw new Error("Something bad happen in ddq_response");
    }
  }
};

export const exportToExcel = createAsyncThunk(
  `${NAME}/exportToExcel`,
  async ({ id, projectName, responseProvider, categories }) => {
    try {
      const ddqResponseList = await getDdqList({
        isReturnOnlyDDQList: true,
        id,
      });

      if (ddqResponseList?.ddqStatus !== SEVERITY.Success) {
        return {
          status: SEVERITY.Error,
          message: "Unable to retrieve DDQ list. Please try again later.",
        };
      }

      const { data } = ddqResponseList;
      const length = data?.length ?? 0;
      const promises = [];

      for (let i = 0; i < length; i += PAGE_SIZE) {
        const body = {
          project_id: id,
          question_id: data.slice(i, i + PAGE_SIZE),
          response_provider: responseProvider,
          categories,
        };
        promises.push(postRequest(`/export_ddq_response/`, body));
      }

      // Execute all promises in parallel (create responses array)
      const responses = await Promise.all(promises);

      const totalResponse = { s4l: [], gpt: [] };

      let s4lIndex = 0,
        gptIndex = 0;

      for (const response of responses) {
        if (response.data.status === SEVERITY.Error) {
          return {
            status: SEVERITY.Error,
            message: response.data.message,
          };
        }
        if (response.data.message["s4l"]) {
          let modifiedS4lResponse = [];
          for (const element of response.data.message["s4l"]) {
            s4lIndex += 1;
            modifiedS4lResponse.push({ "Sr No": s4lIndex, ...element });
          }
          totalResponse["s4l"].push(...modifiedS4lResponse);
        }
        if (response.data.message["gpt"]) {
          let modifiedGptResponse = [];
          for (const element of response.data.message["gpt"]) {
            gptIndex += 1;
            modifiedGptResponse.push({ "Sr No": gptIndex, ...element });
          }
          totalResponse["gpt"].push(...modifiedGptResponse);
        }
      }

      if (
        totalResponse["s4l"]?.length > 0 ||
        totalResponse["gpt"]?.length > 0
      ) {
        exportFilesToCSV({
          data: totalResponse,
          fileName: `${projectName}`,
        });
        return {
          status: SEVERITY.Success,
          message:
            "Excel file successfully exported. Please check your downloads.",
        };
      }

      return {
        status: SEVERITY.Information,
        message: "No data available for export.",
      };
    } catch (error) {
      return {
        status: SEVERITY.Error,
        message: `An error occurred while exporting the Excel file: ${error.message}`,
      };
    }
  }
);

export const fetchDDQResponse = createAsyncThunk(
  `${NAME}/fetchDDQResponse`,
  async ({
    id,
    categoryId,
    startIndex,
    endIndex,
    type,
    isInitialFetch = false,
  }) => {
    try {
      const response = await getDdqList({
        id,
        categoryId,
        startIndex,
        endIndex,
        type,
      });
      return {
        data: convertKeysToCamelCase(response.data),
        nextIndex: response.nextIndex,
        totalRecords: response.totalRecords,
        status: response.ddqStatus,
        id,
        isInitialFetch,
      };
    } catch (error) {
      throw new Error(`Error fetching DDQ responses: ${error}`);
    }
  }
);

// Thunk to create project and upload documents
export const createProjectAndUploadDocument = createAsyncThunk(
  `${NAME}/createProjectAndUploadDocument`,
  async (
    { name, description, category, croNumber, responseProvider, data },
    { dispatch }
  ) => {
    try {
      const projectResponse = await dispatch(
        createNewProject({
          name,
          description,
          category,
          croNumber,
          responseProvider,
        })
      ).unwrap();

      let status = false,
        message = "";

      if (
        projectResponse.status === SEVERITY.Success &&
        projectResponse.data.project_id
      ) {
        const projectId = projectResponse.data.project_id;

        const response = await dispatch(
          uploadMultiDocuments({
            projectId,
            data,
          })
        ).unwrap();

        status = response.status;
        message = response.message;
      }
      return {
        status: status ? "success" : "error",
        message: message,
      };
    } catch (error) {
      throw new Error(
        `Error creating project and uploading documents: ${error}`
      );
    }
  }
);

export const updateCategoryAndUploadDocument = createAsyncThunk(
  `${NAME}/updateCategoryAndUploadDocument`,
  async (
    { projectId, categoryId, selectedFiles, categories, responseProvider },
    { dispatch }
  ) => {
    try {
      const body = { categories, response_provider: responseProvider };
      const response = await patchRequest(`/projects/${projectId}/edit/`, body);

      if (response.data.status === SEVERITY.Success) {
        const response = await dispatch(
          uploadDocuments({
            projectId,
            categoryId,
            files: selectedFiles,
          })
        ).unwrap();
        if (response.status === SEVERITY.Success)
          await dispatch(fetchProjectById({ id: projectId }));
        return { status: response.status, message: response.message };
      } else {
        return {
          status: SEVERITY.Error,
          message: "Unable to update category!",
        };
      }
    } catch (error) {
      throw new Error(
        `Error updating category and uploading documents: ${error}`
      );
    }
  }
);

export const fetchDDQDocuments = createAsyncThunk(
  `${NAME}/fetchDDQDocuments`,
  async ({ id, questionId, categoryId, startIndex, endIndex }) => {
    try {
      const body = {
        project_id: Number(id),
        question_id: Number(questionId),
        start_index: startIndex,
        end_index: endIndex,
        category_id: categoryId,
      };
      const response = await postRequest(`/ddq_response/pdf_list/`, body);
      return {
        nextIndex: response.data.next_index,
        totalDocuments: response.data.count_pdf,
        documents: response.data.data,
        category: categoryId,
      };
    } catch (error) {
      throw new Error(`Error to fetch DDQ documents: ${error}`);
    }
  }
);

export const fetchDDQSelectionsChunks = createAsyncThunk(
  `${NAME}/fetchDDQSelectionsChunks`,
  async ({
    id,
    questionId,
    documentName,
    documentId,
    categoryId,
    startIndex,
    endIndex,
  }) => {
    try {
      const body = {
        pdf_name: documentName,
        category_id: categoryId,
        project_id: Number(id),
        question_id: Number(questionId),
        start_index: startIndex,
        end_index: endIndex,
      };

      const response = await postRequest(`/ddq_response/chunk_list`, body);

      return {
        nextIndex: response.data.next_index,
        totalChunks: response.data.count_chunk,
        chunks: response.data.data,
        documentId,
      };
    } catch (error) {
      throw new Error(`Error to fetch DDQ chunks: ${error}`);
    }
  }
);

export const generateResponse = createAsyncThunk(
  `${NAME}/generateResponse`,
  async ({ questionId, selectedChunks }) => {
    try {
      const body = {
        question_id: questionId,
        chunk_id_list: [...selectedChunks],
      };
      const response = await postRequest(
        `/ddq_response/generate_gpt_response/`,
        body
      );

      return {
        status: response.data.status,
        message: response.data?.message ?? "",
      };
    } catch (error) {
      throw new Error(`Error in generate DDQ Response: ${error}`);
    }
  }
);

export const fetchDDQResponseSelectedList = createAsyncThunk(
  `${NAME}/fetchDDQResponseSelectedList`,
  async ({ id, questionId, documentName }) => {
    try {
      const body = {
        question_id: questionId,
        project_id: id,
        original_filename: documentName,
      };
      const response = await postRequest(
        `/ddq_response/list_generate_gpt_response/`,
        body
      );

      return { data: response.data.data };
    } catch (error) {
      throw new Error(`Error in fetch DDQ Response Selected List: ${error}`);
    }
  }
);

export const saveAndProcessResponse = createAsyncThunk(
  `${NAME}/saveAndProcessResponse`,
  async ({ selectedChunkId }) => {
    try {
      const body = {
        chunk_response_id: selectedChunkId,
      };

      const response = await postRequest(
        `/ddq_response/save_generate_gpt_response/`,
        body
      );

      return {
        status: response.data.status,
        message: response.data?.message ?? "",
      };
    } catch (error) {
      throw new Error(`Error to Save selected chunk: ${error}`);
    }
  }
);

export const editProject = createAsyncThunk(
  `${NAME}/editProject`,
  async (
    { projectId, name, description, croNumber, categories, responseProvider },
    { dispatch }
  ) => {
    try {
      const body = {
        name,
        description,
        cro_number: croNumber,
        categories,
        response_provider: responseProvider,
      };
      const response = await patchRequest(`/projects/${projectId}/edit/`, body);

      if (response.data.status === SEVERITY.Success) {
        if (responseProvider?.includes(RESPONSE_PROVIDER.searchLess))
          await getDdqList({ id: projectId, dispatch, type: "edit" });
        dispatch(fetchProjectList());
        dispatch(actions.resetState());
      }

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error updating project: ${error}`);
    }
  }
);

export const fetchProjectById = createAsyncThunk(
  `${NAME}/fetchProjectById`,
  async ({ id }) => {
    try {
      const response = await getRequest(`/projects/${id}`);
      return {
        status: response.data?.status ?? "",
        data: response.data.project_details,
      };
    } catch (error) {
      throw new Error(`Error to fetch particular project : ${error}`);
    }
  }
);

export const fetchDdqQuestions = createAsyncThunk(
  `${NAME}/fetchDdqQuestion`,
  async () => {
    try {
      const fetchingParams = {
        categories: [],
        response_provider: [],
      };

      const response = await postRequest("/ddqs/", fetchingParams);

      return { data: response.data.ddq_details };
    } catch (error) {
      throw new Error(`Couldn't fetch DDQ questions: ${error}`);
    }
  }
);

export const fetchDdqQuestionById = createAsyncThunk(
  `${NAME}/fetchDdqQuestionById`,
  async ({ id }) => {
    try {
      const response = await getRequest(`/ddqs/${id}`);

      return { data: response.data.ddq_detail };
    } catch (error) {
      throw new Error(`Couldn't fetch DDQ question: ${error}`);
    }
  }
);

export const addDdqQuestion = createAsyncThunk(
  `${NAME}/addDdqQuestion`,
  async (
    {
      responseProvider,
      question,
      query,
      category,
      prompt,
      summaryFilter,
      additionalDetails,
      variable,
    },
    { dispatch }
  ) => {
    try {
      let body = {
        question,
        category: category.id,
        response_provider: responseProvider,
      };

      if (responseProvider === RESPONSE_PROVIDER.searchLess) {
        body = {
          ...body,
          additional_detail: additionalDetails,
          variable: variable?.name ?? variable?.variable ?? variable,
        };
      } else if (responseProvider === RESPONSE_PROVIDER.gpt) {
        body = {
          ...body,
          query,
          summary_filter: summaryFilter,
          prompt: prompt,
        };
      } else {
        body = {
          ...body,
          query,
          summary_filter: summaryFilter,
          prompt: prompt,
          additional_detail: additionalDetails,
          variable,
        };
      }

      const response = await postRequest("/ddqs/add/", body);

      if (response.data.status === SEVERITY.Success) {
        dispatch(fetchDdqQuestions());
        dispatch(actions.resetState());
      }

      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error to create ddq question : ${error}`);
    }
  }
);

export const deleteDDQ = createAsyncThunk(
  `${NAME}/deleteDDQ`,
  async ({ id }, { dispatch }) => {
    try {
      const response = await deleteRequest(`/ddqs/${id}/delete`);

      if (response.data.status === SEVERITY.Success)
        dispatch(fetchDdqQuestions());

      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error to delete ddq question : ${error}`);
    }
  }
);

export const editDDQ = createAsyncThunk(
  `${NAME}/editDDQ`,
  async (
    {
      id,
      question,
      query,
      category,
      responseProvider,
      summaryFilter,
      promptId,
      prompt,
      additionalDetails,
      variable,
    },
    { dispatch }
  ) => {
    try {
      let body = {
        question,
        category: `${category.id}`,
        response_provider: responseProvider,
      };

      if (responseProvider === RESPONSE_PROVIDER.searchLess) {
        body = {
          ...body,
          additional_detail: additionalDetails,
          variable: variable?.name ?? variable?.variable ?? variable,
        };
      } else if (responseProvider === RESPONSE_PROVIDER.gpt) {
        body = {
          ...body,
          query,
          summary_filter: summaryFilter,
          prompt_id: promptId,
          prompt: prompt,
        };
      } else {
        body = {
          ...body,
          query,
          summary_filter: summaryFilter,
          prompt: prompt,
          additional_detail: additionalDetails,
          variable,
        };
      }

      const response = await patchRequest(`/ddqs/${id}/edit/`, body);

      if (response.data.status === SEVERITY.Success) {
        dispatch(fetchDdqQuestions());
      }

      return { status: response.data.status, data: response.data };
    } catch (error) {
      throw new Error(`Error to edit ddq question : ${error}`);
    }
  }
);

export const addDdqByCSV = createAsyncThunk(
  `${NAME}/addDdqByCSV`,
  async ({ file, responseProvider }, { dispatch }) => {
    try {
      const response = await fileRequest(`/ddqs/add/${responseProvider}`, [
        file,
      ]);

      // eslint-disable-next-line eqeqeq
      if (response.data.status == SEVERITY.Success) {
        dispatch(fetchDdqQuestions());
        dispatch(fetchCategories());
      }

      return {
        status: response.data?.status ?? SEVERITY.Error,
        message: response.data?.message ?? [],
      };
    } catch (error) {
      throw new Error(`Error to add ddq questions : ${error}`);
    }
  }
);

export const updateCategoryStatus = createAsyncThunk(
  `${NAME}/updateCategoryStatus`,
  async ({ id, status }, { dispatch }) => {
    try {
      const body = { is_active: status };
      const response = await postRequest(`/categories/${id}/status/`, body);

      if (response.data.status === SEVERITY.Success)
        dispatch(fetchCategories());
      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to update status: ${error}`);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  `${NAME}/deleteCategory`,
  async ({ id }, { dispatch }) => {
    try {
      const response = await deleteRequest(`/categories/${id}/delete/`);

      if (response.data.status === SEVERITY.Success)
        dispatch(fetchCategories());
      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to update status: ${error}`);
    }
  }
);

export const deleteSubCategories = createAsyncThunk(
  `${NAME}/deleteSubCategories`,
  async ({ id, subcategories }) => {
    try {
      const response = await deleteRequest(`/sub_categories/delete/`, {
        category_id: id,
        sub_category_ids: subcategories,
      });
      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to delete subcategories : ${error}`);
    }
  }
);

export const editCategory = createAsyncThunk(
  `${NAME}/editCategory`,
  async ({ id, value, subcategories }, { dispatch }) => {
    try {
      const response = await patchRequest(`/categories/${id}/edit/`, {
        category_name: value,
        sub_categories: subcategories,
      });
      if (response.data.status === SEVERITY.Success)
        dispatch(fetchCategories());
      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to edit category: ${error}`);
    }
  }
);

export const downloadSearchLessDocument = createAsyncThunk(
  `${NAME}/downloadSearchLessDocument`,
  async ({
    request,
    croNumber,
    type,
    name,
    documentId,
    documentDescription,
  }) => {
    const body = {
      request,
      cro_num: croNumber,
      type,
      name,
      doc_id: documentId,
      doc_type_desc: documentDescription,
    };

    try {
      const response = await postRequest(`/search4less_management/`, body);

      if (request === DOWNLOAD_TYPE.details && response.status === 200) {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const file = document.createElement("a");
        file.style.display = "none";
        file.href = url;
        file.download = "company_details.csv";
        document.body.appendChild(file);
        file.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(file);

        return { status: "success", message: "File download successfully" };
      } else if (response.data.status === SEVERITY.Success) {
        window.open(response.data.pdf, "_blank");

        return {
          status: "success",
          message: "File open successfully. Kindly check your browser tab.",
        };
      } else {
        return {
          status: response.data?.status,
          message: response.data?.message,
        };
      }
    } catch (error) {
      throw new Error(`Error to download: ${error}`);
    }
  }
);

export const fetchClients = createAsyncThunk(
  `${NAME}/fetchClients`,
  async () => {
    try {
      const response = await getRequest(`/clients/`);

      const modifyResponse = convertKeysToCamelCase(response.data);

      return { data: modifyResponse?.clientDetails ?? [] };
    } catch (error) {
      throw new Error(`Error to fetch clients: ${error}`);
    }
  }
);

export const fetchClientsById = createAsyncThunk(
  `${NAME}/fetchClientsById`,
  async ({ id }) => {
    try {
      const response = await postRequest(`/clients/${id}/`);

      const modifyResponse = convertKeysToCamelCase(response.data);

      return { data: modifyResponse?.clientDetail ?? {} };
    } catch (error) {
      throw new Error(`Error to fetch client: ${error}`);
    }
  }
);

export const addClient = createAsyncThunk(
  `${NAME}/addClient`,
  async ({ name, email, phoneNumber }, { dispatch }) => {
    try {
      const body = { name, email, phone_number: phoneNumber };
      const response = await postRequest("/clients/add/", body);
      if (response.data.status === SEVERITY.Success) dispatch(fetchClients());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to add client : ${error}`);
    }
  }
);

export const editClient = createAsyncThunk(
  `${NAME}/editClient`,
  async ({ id, name, email, phoneNumber }, { dispatch }) => {
    try {
      const body = { name, email, phone_number: phoneNumber };
      const response = await patchRequest(`/clients/${id}/edit/`, body);
      if (response.data.status === SEVERITY.Success) dispatch(fetchClients());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to edit client : ${error}`);
    }
  }
);

export const updateClientStatus = createAsyncThunk(
  `${NAME}/updateClientStatus`,
  async ({ id, status }, { dispatch }) => {
    try {
      const body = {
        is_active: status,
      };
      const response = await postRequest(`/clients/${id}/status`, body);

      if (response.data.status === SEVERITY.Success) dispatch(fetchClients());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to update client status: ${error}`);
    }
  }
);

export const updateClientAdminStatus = createAsyncThunk(
  `${NAME}/updateClientAdminStatus`,
  async ({ id, status }, { dispatch }) => {
    try {
      const body = {
        is_staff: status,
      };
      const response = await postRequest(`/clients/${id}/admin/status`, body);

      if (response.data.status === SEVERITY.Success) dispatch(fetchClients());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to update client status: ${error}`);
    }
  }
);

export const deleteClient = createAsyncThunk(
  `${NAME}/deleteClient`,
  async ({ id }, { dispatch }) => {
    try {
      const response = await deleteRequest(`/clients/${id}/delete/`);

      if (response.data.status === SEVERITY.Success) dispatch(fetchClients());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to delete client status: ${error}`);
    }
  }
);

export const fetchDocumentList = createAsyncThunk(
  `${NAME}/fetchDocumentList`,
  async () => {
    try {
      const response = await getRequest(`/document_management/`);

      const convertedData = convertKeysToCamelCase(response.data.documents);

      return { status: response.data.status, data: convertedData };
    } catch (error) {
      throw new Error(`Error to fetch documents: ${error}`);
    }
  }
);

export const fetchSupportDetails = createAsyncThunk(
  `${NAME}/fetchSupportDetails`,
  async () => {
    try {
      const response = await getRequest(`/contact_us/list/`);

      const convertedData = convertKeysToCamelCase(
        response.data.contact_details
      );

      return { data: convertedData };
    } catch (error) {
      throw new Error(`Error to fetch contact details :${error}`);
    }
  }
);

export const fetchSupportDetailsById = createAsyncThunk(
  `${NAME}/fetchSupportDetailsById`,
  async ({ id }) => {
    try {
      const response = await getRequest(`/contact_us/${id}`);
      let contact_detail = {};
      if (response.data.status === SEVERITY.Success)
        contact_detail = convertKeysToCamelCase(response.data.contact_detail);
      return {
        status: response.data.status,
        message: response.data?.message,
        data: contact_detail,
      };
    } catch (error) {
      throw new Error(`Error to fetch support details :${error}`);
    }
  }
);

export const resolveSupportRequest = createAsyncThunk(
  `${NAME}/resolveSupportRequest`,
  async ({ id }, { dispatch }) => {
    try {
      const body = { status: true };
      const response = await postRequest(`/contact_us/${id}/status/`, body);

      if (response.data.status === SEVERITY.Success)
        dispatch(fetchSupportDetails());

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to resolve support detail :${error}`);
    }
  }
);

export const sendSupportRequest = createAsyncThunk(
  `${NAME}/sendSupportRequest`,
  async ({ fullName, phoneNumber, email, message }) => {
    try {
      const body = {
        full_name: fullName,
        phone_number: phoneNumber,
        email,
        message,
      };
      const response = await postRequest(`/contact_us/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to send support detail :${error}`);
    }
  }
);

export const generateDDQResponse = createAsyncThunk(
  `${NAME}/generateDDQResponse`,
  async ({ id }, { dispatch }) => {
    try {
      const body = { project_id: id, category_id: [] };
      const response = await postRequest(`/get_ddq_list/`, body);

      if (response.data.status === SEVERITY.Success) {
        const ddq = response.data.ddq_question;
        const length = response.data.length;
        const firstElementResponse = await getRequest(
          `/ddq_response/${id}/${ddq[0]}/add?ddq_question_count=${length}`
        );
        if (firstElementResponse.data.status === SEVERITY.Success) {
          await ddq?.forEach((element, index) => {
            if (index === 0) return;
            getRequest(
              `/ddq_response/${id}/${element}/add?ddq_question_count=${length}`
            );
          });
          dispatch(fetchProjectList());
          return { status: SEVERITY.Success };
        } else {
          throw new Error("Something bad happen to generate response.");
        }
      } else {
        return {
          status: response.data.status,
        };
      }
    } catch (error) {
      throw new Error(`Error to generate response :${error}`);
    }
  }
);

export const fetchDocumentListById = createAsyncThunk(
  `${NAME}/fetchDocumentListById`,
  async ({ id }) => {
    try {
      const response = await getRequest(`/client_documents/${id}`);

      const convertedResponse = convertKeysToCamelCase(response.data.documents);
      return {
        data: convertedResponse,
      };
    } catch (error) {
      throw new Error(`Error to fetch document by id :${error}`);
    }
  }
);

export const fetchProjectListByClient = createAsyncThunk(
  `${NAME}/fetchProjectListByClient`,
  async ({ businessId }) => {
    try {
      const body = {
        business_id: businessId,
      };

      const response = await postRequest(`/project_filter/`, body);

      return { status: response.data.status, data: response.data.message };
    } catch (error) {
      throw new Error(`Error to fetch Project list for client: ${error}`);
    }
  }
);

// BusinessId = Client Id
// Step 1
export const sendDocParser = createAsyncThunk(
  `${NAME}/sendDocParser`,
  async ({ businessId, projectId, categoryId }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        category_id: categoryId,
      };

      const response = await postRequest(`/send_doc_parser/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`Error to send doc parser :${error}`);
    }
  }
);

// Step 2
export const sendSummary = createAsyncThunk(
  `${NAME}/sendSummary`,
  async ({ businessId, projectId, categoryId, projectName, categoryName }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        category_id: categoryId,
        company_name: projectName,
        category: categoryName,
      };

      const response = await postRequest(`/send_summary/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send Summary :${error}`);
    }
  }
);

// Step 3
export const sendChunker = createAsyncThunk(
  `${NAME}/sendChunker`,
  async ({ businessId, projectId, categoryId, categoryName }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        category_id: categoryId,
        category: categoryName,
      };
      const response = await postRequest(`/send_chunker/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send Summary :${error}`);
    }
  }
);

// Step 4
export const createSummaryIndex = createAsyncThunk(
  `${NAME}/createSummaryIndex`,
  async ({ projectId, projectName, isForce }) => {
    try {
      const body = {
        company_name: projectName,
        project_id: projectId,
        index_type: "summary",
        force_create: isForce,
      };

      const response = await postRequest(`/create_index/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to create Summary Index: ${error}`);
    }
  }
);

// Step 5
export const createChunkIndex = createAsyncThunk(
  `${NAME}/createChunkIndex`,
  async ({ projectName, projectId, isForce }) => {
    try {
      const body = {
        company_name: projectName,
        project_id: projectId,
        index_type: "chunk",
        force_create: isForce,
      };

      const response = await postRequest(`/create_index/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to create Summary Index: ${error}`);
    }
  }
);
// Step 6
export const sendDataToSummaryIndex = createAsyncThunk(
  `${NAME}/sendDataToSummaryIndex`,
  async ({ businessId, projectId, categoryId, projectName }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        company_name: projectName,
        category_id: categoryId,
        index_type: "summary",
      };

      const response = await postRequest(`/send_data_to_index/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send data summary index: ${error}`);
    }
  }
);
// Step 7
export const sendDataToChunkIndex = createAsyncThunk(
  `${NAME}/sendDataToChunkIndex`,
  async ({ businessId, projectId, projectName, categoryId }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        company_name: projectName,
        category_id: categoryId,
        index_type: "chunk",
      };

      const response = await postRequest(`/send_data_to_index/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send data summary index: ${error}`);
    }
  }
);

// Step 8
export const sendResponseGenerator = createAsyncThunk(
  `${NAME}/sendResponseGenerator`,
  async ({ businessId, projectId, categoryId }) => {
    try {
      const body = {
        business_id: businessId,
        project_id: projectId,
        category_id: categoryId,
      };

      const response = await postRequest(`/send_response_generator/`, body);
      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send data summary index: ${error}`);
    }
  }
);

export const sendFeedback = createAsyncThunk(
  `${NAME}/sendFeedback`,
  async ({
    projectId,
    ddqId,
    categoryId,
    files = [],
    llmResponse = null,
    comments = null,
    missingDocuments = null,
  }) => {
    try {
      const body = {
        project_id: projectId,
        category_id: categoryId,
        ddq_id: ddqId,
        llm_response: llmResponse,
        comment: comments,
        vdr_reference: files,
        missing_documents: missingDocuments,
      };
      const response = await postRequest(`/gpt_feedback/`, body);

      return { status: response.data.status, message: response.data.message };
    } catch (error) {
      throw new Error(`error to send feedback :${error}`);
    }
  }
);

export const fetchFeedbacks = createAsyncThunk(
  `${NAME}/fetchFeedbacks`,
  async () => {
    try {
      const response = await getRequest(`/gpt_feedback/list`);
      return { status: response.data?.status, data: response.data?.message };
    } catch (error) {
      throw new Error(`error to fetch feedbacks :${error}`);
    }
  }
);
