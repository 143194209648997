import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const ListIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <title>List</title>
      <path d="M6 7H18V9H6V7Z" fill={color} strokeWidth={strokeWidth} />
      <path d="M6 11H18V13H6V11Z" fill={color} strokeWidth={strokeWidth} />
      <path d="M18 15H6V17H18V15Z" fill={color} strokeWidth={strokeWidth} />
    </svg>
  );
};

ListIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
