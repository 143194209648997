import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { Welcome } from "../../../components/general/welcome";
import {
  LOGIN,
  LOGIN_KEY,
  SEVERITY,
  VERIFICATION_STATUS,
} from "../../../constant";
import { pathList } from "../../../routes/app-navigation";
import { ForgotPassword } from "../forgot-password";
import {
  validEmail,
  validLoginData,
} from "../../../utils/validation-functions";
import { loginUser } from "../../../action";
import { actions, customMessage } from "../../slice/view";
import { nanoid } from "@reduxjs/toolkit";
import { EmailIcon, EyeCloseIcon, EyeIcon } from "../../../components/icons";
import { EmailResendDialog } from "../registration/email-resend-dialog";
import PropTypes from "prop-types";

const initialLoginData = {
  email: "",
  password: "",
};

export const Login = ({ viewLoginState, viewResetState }) => {
  const [loginData, setLoginData] = useState(initialLoginData);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isResendDialogShow, setIsResendDialogShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    if (viewLoginState.success && localStorage.getItem(LOGIN_KEY)) {
      navigate(pathList.projectList);
    } else {
      navigate(pathList.login);
    }
  }, [viewLoginState, navigate]);

  useEffect(() => {
    const status = params.get("status");
    const message = VERIFICATION_STATUS[status];

    if (status && message) {
      dispatch(
        actions.addMessage(
          customMessage({
            content: message,
            severity: status === "evs" ? SEVERITY.Success : SEVERITY.Error,
            id: nanoid(),
          })
        )
      );
    }
  }, [params, dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (validLoginData(loginData)) {
      dispatch(loginUser(loginData));
    }
  };

  return (
    <div className="mt-[284px] w-max mx-auto">
      <Welcome />
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4 mt-[20px]">
          <TextField
            type={"email"}
            placeholder={"Enter Email*"}
            name="email"
            className={"w-[412px]"}
            onChange={(e) =>
              setLoginData({ ...loginData, email: e.target.value })
            }
            errorText={isSubmitted ? validEmail(loginData.email).message : ""}
            endAdornment={<EmailIcon />}
            inputProps={{ "data-testid": "email-input" }}
            errorTestId="email-validation"
            required
          />
          <TextField
            type={showPassword ? "text" : "password"}
            placeholder={"Password*"}
            name="password"
            className={"w-[412px]"}
            onChange={(e) =>
              setLoginData({ ...loginData, password: e.target.value })
            }
            errorText={
              isSubmitted && loginData.password === ""
                ? "Password is required."
                : ""
            }
            endAdornment={
              <button
                className="p-0 bg-transparent border-none cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                {showPassword ? (
                  <EyeCloseIcon />
                ) : (
                  <EyeIcon strokeWidth={1.5} />
                )}
              </button>
            }
            inputProps={{ "data-testid": "password-input" }}
            errorTestId="password-validation"
            required
          />
          <ForgotPassword viewResetState={viewResetState} />
          <div className="w-max mx-auto mt-[20px]">
            <Button
              type={"submit"}
              size="md"
              className={"hover:bg-warning-dark hover:text-black"}
              isLoading={viewLoginState.pending}
              onClick={handleSubmit}
              data-testid="login-button"
            >
              {LOGIN.login}
            </Button>
          </div>

          <div className="mt-[16px] text-center">
            <Link
              to={pathList.registration}
              className="text-warning-dark no-underline font-bold"
              data-testid="registration-link"
            >
              {LOGIN.createANewAccount}
            </Link>
          </div>
        </div>
      </form>

      <div className="text-center mt-[20px]">
        <button
          className={"text-primary cursor-pointer border-none bg-transparent"}
          onClick={() => setIsResendDialogShow(true)}
        >
          {LOGIN.troubleToGetVerificationEmail}
        </button>
      </div>
      {isResendDialogShow && (
        <EmailResendDialog onClose={() => setIsResendDialogShow(false)} />
      )}
    </div>
  );
};

Login.propTypes = {
  viewLoginState: PropTypes.object,
  viewResetState: PropTypes.object,
};
