export const COLORS = {
  primary: "#2F59A7",
  primaryXLight: "#2F59A71A",
  primaryHover: "#2F59A70F",
  primaryLight: "#2F59A799",
  warning: "#F6CF70",
  warningDark: "#E2B23B",
  secondary: "#2B2B2B",
  secondaryLight: "#656565",
  switchGreen: "#75EC58",
  error: "#BB4646",
  errorLight: "#6608081A",
  success: "#008024",
  successDark: "#005A19",
  successLight: "#008B161A",
  tableRowOne: "#CBCBCB14",
  tableRowTwo: "#CBCBCB29",
  edge: "#E5E7EB",
  edgeLight: "#CBCBCB",
  page: "#F0F0F0",
  pageLight: "#F9FAFB",
  white: "#FFFFFF",
};

export const SIZE_MAP = {
  sm: "small",
  md: "medium",
  lg: "large",
};