import { Link, useParams } from "react-router-dom";
import { PageWhite } from "../../../components/organism/body/page-white";
import { HeadText, NormalText } from "../../../components/typography";
import { ADMIN } from "../../../constant";
import { pathList } from "../../../routes/app-navigation";
import { ViewDocument } from "../../features/admin/document-management/view-documents";
import { LeftIcon } from "../../../components/icons";
import { Select } from "../../../components/molecules/select/select-input";
import { TextField } from "../../../components/atoms/input/text-field";
import { CategoryDropDown } from "../../features/admin/categories-management/category-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchDocumentListById } from "../../../action";

export const ViewDocumentPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);
  const isLoading = view.documentSelect.pending;
  const document = useSelector((state) => state.document);

  useEffect(() => {
    if (id && document.selected.client !== id)
      dispatch(fetchDocumentListById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [projects, setProjects] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);
  const [selectedProject, setSelectedProject] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);

  useEffect(() => {
    setSelectedProject(0);
    setSelectedCategory([]);
    if (document.selected?.client && document.selected?.projects.length) {
      const newProjects = document.selected.projects.map((item) => ({
        id: item.projectId,
        value: item.projectName,
      }));
      setProjects(newProjects);
      setDefaultValue([
        {
          id: document.selected.projects[0].projectId,
          value: document.selected.projects[0].projectName,
        },
      ]);
    }
  }, [document.selected]);

  return (
    <div>
      <PageWhite>
        <div className="sticky top-[74px] py-[20px] bg-white">
          <div className="mb-[20px] flex justify-between ">
            <HeadText testId="view-document-title">
              {ADMIN.viewDocument}
            </HeadText>
            <Link to={pathList.admin.documentList} className="no-underline">
              <div className="flex gap-2 items-center">
                <LeftIcon /> <NormalText>{ADMIN.backToDocuments}</NormalText>
              </div>
            </Link>
          </div>
          <div className="flex gap-2 items-center">
            <TextField
              type={"text"}
              value={document.selected?.clientName ?? ""}
              className="w-[320px]"
              placeholder="Client"
              isLoading={isLoading}
            />
            <div className="w-[320px]">
              <Select
                id={"project-filter"}
                innerLabel="Filter Projects"
                isLoading={isLoading}
                values={projects}
                defaultValue={defaultValue}
                onChange={(value) => {
                  const findIndex = document.selected?.projects.findIndex(
                    (item) => item.projectId === value
                  );
                  setSelectedProject(findIndex);
                }}
                isReset={view.documentSelect.success}
                isReturnId
              />
            </div>
            <div className="w-[320px]">
              <CategoryDropDown
                placeholder="Filter Category"
                callBack={(value) => setSelectedCategory(value)}
                isReturnId
                isReset={view.documentSelect.success}
              />
            </div>
          </div>
        </div>
        <ViewDocument
          projectIdIndex={selectedProject}
          categoryId={selectedCategory}
          isLoading={isLoading}
        />
      </PageWhite>
    </div>
  );
};
