import { useState } from "react";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { Welcome } from "../../../components/general/welcome";
import { LOGIN, SEVERITY } from "../../../constant";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../action";
import {
  validConfirmPassword,
  validPassword,
} from "../../../utils/validation-functions";
import { EyeCloseIcon, EyeIcon } from "../../../components/icons";
import { pathList } from "../../../routes/app-navigation";

export const ResetPassword = () => {
  const { token, id } = useParams();

  const [data, setData] = useState({ password: null, confirmPassword: null });
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const view = useSelector((state) => state.view);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e?.preventDefault();
    setIsSubmit(true);
    if (
      validPassword(data.password).status &&
      validConfirmPassword(data.password, data.confirmPassword).status
    ) {
      dispatch(resetPassword({ id, token, password: data.confirmPassword }))
        .then((response) => {
          setIsSubmit(false);
          if (response.payload.status === SEVERITY.Success) {
            navigate(pathList.login, { replace: true }); // Navigate to success page
          }
        })
        .catch((error) => {
          console.error("Failed to reset password:", error);
          navigate(pathList.login, { replace: true }); // Navigate to error page
        });
    }
  };

  return (
    <div className="mt-[284px] w-[412px] mx-auto">
      <Welcome />
      <form>
        <div className="mt-[20px] flex flex-col gap-4">
          <TextField
            placeholder={"New Password*"}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            errorText={isSubmit ? validPassword(data.password).message : ""}
            endAdornment={
              <button
                className="p-0 bg-transparent border-none cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                type="button"
              >
                {showPassword ? (
                  <EyeCloseIcon />
                ) : (
                  <EyeIcon strokeWidth={1.5} />
                )}
              </button>
            }
          />
          <TextField
            placeholder={"Confirm Password*"}
            type={"password"}
            onChange={(e) =>
              setData({ ...data, confirmPassword: e.target.value })
            }
            errorText={
              isSubmit
                ? validConfirmPassword(data.password, data.confirmPassword)
                    .message
                : ""
            }
          />
          <div className="mt-[20px] w-max mx-auto">
            <Button
              size="md"
              onClick={handleResetPassword}
              type={"submit"}
              isLoading={view.resetPassword.pending}
            >
              {LOGIN.updatePassword}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
