import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { CustomTheme } from "../../theme/custom-theme";
import { NormalText } from "../../typography";

/**
 * MUISpinner Component
 *
 * This component wraps Material-UI CircularProgress to display a spinner with optional text.
 *
 * Props:
 * - color: One of "primary", "secondary", "warning", "error" (default: "primary").
 * - size: Number, size of the spinner (default: 40).
 * - className: String, additional class names for styling.
 * - text: String, optional text to display below the spinner.
 * - thickness: Number, thickness of the spinner (default: 5).
 * - variant: One of "determinate", "indeterminate" (default: "indeterminate").
 *
 * Example usage:
 * ```
 * <MUISpinner
 *   color="primary"
 *   size={40}
 *   className="custom-spinner"
 *   text="Loading..."
 * />
 * ```
 */
export const MUISpinner = ({
  color = "primary",
  size = 40,
  className,
  text,
  thickness = 5,
  variant = "indeterminate",
  ...props
}) => {
  return (
    <CustomTheme>
      <div className="h-max text-center">
        <CircularProgress
          color={color}
          className={className}
          size={size}
          thickness={thickness}
          variant={variant}
          {...props}
        />
        {text && <NormalText>{text}</NormalText>}
      </div>
    </CustomTheme>
  );
};

// Define expected prop types and their constraints
MUISpinner.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "warning", "error"]),
  size: PropTypes.number,
  className: PropTypes.string,
  text: PropTypes.string,
  thickness: PropTypes.number,
  variant: PropTypes.oneOf(["determinate", "indeterminate"]),
};

// Define default prop values for optional props
MUISpinner.defaultProps = {
  color: "primary",
  size: 40,
};
