import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import {
  DeleteIcon,
  DownFilledArrow,
  EditIcon,
  EyeIcon,
  UpFilledArrow,
} from "../components/icons";
import { RESPONSE_PROVIDER } from "../constant";
import { GPT_CHIP, SEARCH_LESS_CHIP } from "./use-project-table";
import { Link } from "react-router-dom";
import { pathList } from "../routes/app-navigation";
import { COLORS } from "../configuration";
import { MUIButton } from "../components/atoms/button/mui-button";

export const useDDQTable = () => {
  const columnHelper = createColumnHelper();

  const columnNames = useMemo(
    () => ({
      id: "Id",
      serialNo: "Sr. No.",
      question: "Question",
      category: "Category",
      responseProvider: "Response provider",
      view: "View",
      edit: "Edit",
      delete: "Delete",
    }),
    []
  );

  const chipSelect = (provider) => {
    if (provider === RESPONSE_PROVIDER.gptText) return GPT_CHIP;
    else if (provider === RESPONSE_PROVIDER.searchLessText)
      return SEARCH_LESS_CHIP;
    else return <>-</>;
  };

  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);
  const [dialog, setDialog] = useState({
    isDelete: false,
    isView: false,
    id: null,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (prop) => prop.row.index + 1,
        enableSorting: true,
        sortingFn: "basic",
        header: (
          <span data-testid="category-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                sortingState[0].desc = !sortingState[0].desc;
                setSortingState([...sortingState]);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("question", {
        id: "question",
        cell: (prop) => prop.getValue(),
        header: columnNames.question,
        filterFn: "questionFilter",
      }),
      columnHelper.accessor("categories", {
        id: "categories",
        cell: (prop) =>
          (
            <div
              className="flex flex-wrap gap-2 max-w-[240px]"
              data-testid="ddq-categories"
            >
              {prop.getValue()?.map((item) => (
                <div
                  key={item}
                  className="bg-primary-hover text-secondary-light w-max px-2 p-[4px] rounded-md"
                >
                  {item}
                </div>
              ))}
            </div>
          ) ?? "",
        header: columnNames.category,
        filterFn: "categoryFilter",
      }),
      columnHelper.accessor("responseProvider", {
        id: "responseProvider",
        cell: (props) => chipSelect(props.getValue()),
        header: columnNames.responseProvider,
        filterFn: "responseFilter",
      }),
      columnHelper.accessor("id", {
        id: "view",
        cell: (props) => {
          return (
            <MUIButton
              variant="text"
              size="sm"
              onClick={() =>
                setDialog({ ...dialog, id: props.getValue(), isView: true })
              }
            >
              <EyeIcon />
            </MUIButton>
          );
        },
        header: columnNames.view,
      }),
      columnHelper.accessor("id", {
        id: "edit",
        cell: (props) => {
          const id = JSON.stringify(props.getValue());
          return (
            <Link
              to={`${pathList.admin.ddqs}${id}`}
              data-testid="edit-ddq-button"
            >
              <MUIButton variant="text" size="sm">
                <EditIcon strokeWidth={0.5} />
              </MUIButton>
            </Link>
          );
        },
        header: columnNames.edit,
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (props) => (
          <MUIButton
            variant="text"
            color="error"
            size="sm"
            onClick={() =>
              setDialog({ ...dialog, id: props.getValue(), isDelete: true })
            }
          >
            <DeleteIcon />
          </MUIButton>
        ),
        header: columnNames.delete,
      }),
    ],
    [columnHelper, columnNames, sortingState, dialog]
  );

  const sorting = useMemo(() => sortingState, [sortingState]);

  return { columnNames, columns, sorting, setSortingState, dialog, setDialog };
};
