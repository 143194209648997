import PropTypes from "prop-types";
import { Checkbox } from "@mui/material";
import { LabelText } from "../../typography";
import { CustomTheme } from "../../theme/custom-theme";
import { useEffect, useState } from "react";
import { SIZE_MAP } from "../../../configuration";

/**
 * MUICheckBox Component
 *
 * This component renders a Material-UI Checkbox with a label and additional customization options.
 * It takes various props to customize its behavior and appearance.
 *
 * Props:
 * - id: String, the ID of the checkbox.
 * - label: String, the label for the checkbox.
 * - size: String, the size of the checkbox ("sm", "md", "lg").
 * - disable: Boolean, whether the checkbox is disabled.
 * - checked: Boolean, whether the checkbox is checked, returning true or false
 * - onChange: Function, callback function to handle change events for the checkbox.
 *
 * Example usage:
 * ```
 * <MUICheckBox id="checkbox-1" label="Accept Terms" checked={true} onChange={(checked) => console.log(checked)} />
 * ```
 */
export const MUICheckBox = ({
  id,
  label,
  size = "sm",
  disable = false,
  checked = false,
  onChange = () => {},
  color = "primary",
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  // Update the state when the checked prop changes
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  // Determine the checkbox size based on the provided size prop
  const checkboxSize = SIZE_MAP[size] || "small";

  // Return a themed Checkbox component with label
  return (
    <CustomTheme>
      <div className="flex items-center">
        <Checkbox
          id={id}
          color={color}
          size={checkboxSize}
          disabled={disable}
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            onChange?.(e.target.checked);
          }}
          {...props}
        />
        <LabelText
          labelFor={id}
          className="cursor-pointer break-words overflow-hidden"
          size={size}
        >
          {label}
        </LabelText>
      </div>
    </CustomTheme>
  );
};

MUICheckBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disable: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
  ]),
};
