import PropTypes from "prop-types";
import { COLORS } from "../../../configuration";
import { ICONS } from "../../../constant";

export const EmailIcon = ({
  color = COLORS.secondaryLight,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = 2,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <g id="8200194_sendright_send_mail_email_message_icon_1">
        <g id="g2742">
          <path
            id="rect2666"
            d="M53 16.088H7.00366V43.6898H53V16.088Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="path2668"
            d="M53 16.088L7 16L29.9242 36.3936L53 16.088Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="path2671"
            d="M22.5647 29.9314L7 43.778L53 43.6899L37.3681 29.9347L29.9278 36.4818L22.5647 29.9314Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

EmailIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
