import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { LinkIcon } from "../components/icons";
export const useResponse = () => {
  const columnNames = useMemo(
    () => ({
      response: "Response",
      vdrReference: "VDR Reference No.",
      category: "Category",
    }),
    []
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("category", {
        id: "category",
        cell: (props) => props.getValue(),
        header: columnNames.category,
      }),
      columnHelper.accessor(
        (row) => ({
          fileName: row.fileName,
          fileUrl: row?.fileUrl ?? "",
        }),
        {
          id: "vdr",
          cell: (props) => (
            <a
              href={props.getValue().fileUrl}
              target="_blank"
              rel="noreferrer"
              className="flex gap-2"
            >
              <LinkIcon width={20} height={20} className="w-max h-max" />
              <span className="w-[240px] break-all overflow-hidden ">
                {props.getValue().fileName}
              </span>
            </a>
          ),
          header: columnNames.vdrReference,
        }
      ),
      columnHelper.accessor("response", {
        id: "response",
        cell: (props) => props.getValue(),
        header: (
          <div className="w-full text-center">{columnNames.response} </div>
        ),
      }),
    ],
    [columnHelper, columnNames]
  );

  return { columnNames, columns };
};
