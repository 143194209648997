import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const Bullets = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6674 15.0943L12.0472 13.6932L12.5469 14.1999L20.6201 6L22.0009 7.40006L12.5469 17L10.6674 15.0943ZM6.54685 14.1999L14.6201 6L16.0009 7.40006L6.54685 17L1.97461 12.35L3.3554 10.95L6.54685 14.1999Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

Bullets.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
