import { createSlice } from "@reduxjs/toolkit";
import { fetchDDQSelectionsChunks } from "../../action";
import { PAGE_SIZE } from "../../constant";

const initialState = {
  fetchedDocument: null,
  totalPages: 1,
  totalChunks: 1,
  nextIndex: 1,
  chunks: [],
};

const NAME = "ddq-chunks";

const ddqChunkSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetSelectedPdf() {},
  },
  extraReducers(builder) {
    builder.addCase(
      fetchDDQSelectionsChunks.fulfilled,
      (state, { payload }) => {
        if (payload.chunks) {
          state.chunks = [...state.chunks, ...payload.chunks];
          state.fetchedDocument = payload.documentId;
          state.nextIndex = payload.nextIndex;
          state.totalChunks = payload.totalChunks;
          state.totalPages = Math.ceil(payload.totalChunks / PAGE_SIZE);
        }
      }
    );
  },
});

export const { actions } = ddqChunkSlice;

export const { resetDDQResponseStats } = actions;

export default ddqChunkSlice.reducer;
