import { Alert } from "@mui/material";
import { CustomTheme } from "../../theme/custom-theme";
import PropTypes from "prop-types";

/**
 * MUIBanner Component:
 * Displays an alert banner with customizable severity, variant, action, and close behavior.
 *
 * Props:
 * - children: The content to be displayed in the alert.
 * - severity: The severity of the alert (success, warning, error, info).
 * - variant: The variant of the alert (filled, outlined, standard).
 * - action: Action button or element to be displayed in the alert.
 * - onClose: Function to handle closing the alert.
 *
 * Example Usage:
 * ```
 * <MUIBanner severity="error" onClose={handleClose}>
 *   An error occurred while processing your request.
 * </MUIBanner>
 * ```
 */
export const MUIBanner = ({
  children,
  severity = "success",
  variant = "filled",
  action,
  onClose,
}) => {
  return (
    <CustomTheme>
      <Alert
        severity={severity}
        variant={variant}
        action={action}
        onClose={onClose}
        className="w-[440px] flex items-center" // Custom styling for width
      >
        {children}
      </Alert>
    </CustomTheme>
  );
};

// PropTypes for MUIBanner component
MUIBanner.propTypes = {
  children: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(["success", "warning", "error", "info"]),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  action: PropTypes.node,
  onClose: PropTypes.func,
};

// Default values for optional props
MUIBanner.defaultProps = {
  severity: "success",
  variant: "filled",
  action: undefined,
  onClose: undefined,
};

export default MUIBanner;
