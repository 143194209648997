import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { CustomTheme } from "../../theme/custom-theme";
import { twMerge } from "tailwind-merge";
import { MUISpinner } from "../loader/spinner";
import { SIZE_MAP } from "../../../configuration";
import { SkeletonRect } from "../loader/skeleton";

/**
 * MUIButton Component
 *
 * This component renders a Material-UI Button with additional customization options
 * including loading states, size mapping, and Tailwind CSS merging.
 *
 * Props:
 * - id: String, the ID of the button.
 * - onClick: Function, callback function to handle button click events.
 * - children: React node, the content to be displayed inside the button.
 * - style: Object, inline CSS styles for the button.
 * - className: String, additional class names for styling.
 * - type: String, the type attribute of the button (e.g., "button", "submit").
 * - startIcon: React node, an icon to be displayed at the start of the button.
 * - isLoading: Boolean, whether the button's related process is in a loading state.
 * - isPending: Boolean, whether the button is in a pending state, showing a skeleton loader.
 * - size: String, the size of the button, mapped to Material-UI size ("sm", "md", "lg").
 * - disable: Boolean, whether the button is disabled.
 * - variant: String, the variant of the button ("text", "outlined", "contained").
 * - color: String, the color of the button ("primary", "secondary", "error", "warning", "success").
 *
 * Example usage:
 * ```
 * <MUIButton onClick={() => console.log('Clicked')} isLoading={true} size="md" variant="outlined">
 *   Click Me
 * </MUIButton>
 * ```
 */
export const MUIButton = ({
  id,
  onClick,
  children,
  style,
  className,
  type,
  startIcon,
  isLoading = false,
  isPending = false,
  size = "sm",
  disable = false,
  variant = "contained",
  color = "primary",
  ...props
}) => {
  // Determine the button size based on the provided size prop
  const userSize = SIZE_MAP[size] ?? "small";

  return (
    <CustomTheme>
      {isPending ? (
        // Render skeleton loader if in pending state
        <SkeletonRect width={100} height={30} />
      ) : (
        <Button
          startIcon={
            isLoading && startIcon ? (
              <MUISpinner
                size={20}
                color={variant === "contained" ? "white" : "primary"}
              />
            ) : (
              startIcon
            )
          }
          type={type}
          id={id}
          size={userSize}
          color={color}
          variant={variant}
          onClick={
            isLoading
              ? (e) => {
                  e.preventDefault();
                }
              : onClick
          }
          disabled={disable}
          style={style}
          className={twMerge("shadow-none capitalize", className)}
          {...props}
        >
          {isLoading && !startIcon ? (
            <MUISpinner
              size={20}
              color={variant === "contained" ? "white" : "primary"}
            />
          ) : (
            children
          )}
        </Button>
      )}
    </CustomTheme>
  );
};

MUIButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  startIcon: PropTypes.node,
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disable: PropTypes.bool,
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "warning",
    "success",
  ]),
};
