import { RightIcon } from "../../../components/icons";
import { PROJECTS, RESPONSE_STAGES } from "../../../constant";
import { ToolTip } from "../../../components/atoms/tooltip";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { useNavigate } from "react-router-dom";
import { DDQAnswerDrawer } from "./ddq-response-answer-dialog";
import { useState } from "react";
import PropTypes from "prop-types";
import { NormalText } from "../../../components/typography";

export const Tag = ({ stageNo }) => {
  const pendingStages = [
    RESPONSE_STAGES.INITIAL,
    RESPONSE_STAGES.RESPONSE_SELECTION,
  ];
  if (pendingStages.includes(stageNo)) {
    return (
      <ToolTip text={`Stage ${stageNo}`} offset={-8}>
        <div className="w-[60px] text-center p-[8px] h-max text-xs rounded-md bg-error-light text-error">
          {RESPONSE_STAGES.pending}
        </div>
      </ToolTip>
    );
  } else if (stageNo === RESPONSE_STAGES.COMPLETED) {
    return (
      <ToolTip text={`You have completed all stages.`} offset={-8}>
        <div className="w-[60px] text-center p-[8px] h-max text-xs rounded-md bg-success-light text-success">
          {RESPONSE_STAGES.completed}
        </div>
      </ToolTip>
    );
  }
};

export const DDQDocumentList = ({
  currentPageData = [],
  id,
  questionId,
  categoryId,
  projectName,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedDocumentResponse, setSelectedDocumentResponse] = useState({
    question: null,
    response: null,
  });

  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4 mb-[20px]">
      {currentPageData.length > 0 ? (
        currentPageData.map((item) => (
          <div
            key={item.id}
            className="flex items-center justify-between border border-solid text-secondary border-edge rounded-md px-[20px] py-[16px]"
          >
            <span className="w-full break-words pr-[4px]">
              {item.original_filename}{" "}
            </span>
            <div className="flex gap-2 items-center ">
              <Button
                onClick={() => {
                  window.open(item?.file_url ?? "", "_blank");
                }}
                className={"w-max h-max"}
              >
                {PROJECTS.openDocuments}
              </Button>
              <Tag stageNo={item.stage} />
              <Button
                variant="text"
                onClick={() => {
                  if (
                    item.response &&
                    item.stage === RESPONSE_STAGES.COMPLETED
                  ) {
                    setIsDrawerOpen(true);
                    setSelectedDocumentResponse({
                      question: item.question,
                      response: item.response,
                    });
                  } else if (item.stage === RESPONSE_STAGES.INITIAL) {
                    navigate(
                      `/ddq_response/list/${id}/${questionId}/${item.id}/select/`,
                      {
                        state: {
                          documentName: item.original_filename,
                          projectName,
                          categoryId,
                        },
                      }
                    );
                  } else if (
                    item.stage === RESPONSE_STAGES.RESPONSE_SELECTION
                  ) {
                    navigate(
                      `/ddq_response/list/${id}/${questionId}/${item.id}/filter/`,
                      {
                        state: {
                          documentName: item.original_filename,
                          projectName,
                          categoryId,
                        },
                      }
                    );
                  }
                }}
              >
                <RightIcon />
              </Button>
            </div>
          </div>
        ))
      ) : (
        <div className="border border-solid border-edge rounded-md px-[20px] py-[16px] text-center">
          <NormalText>{PROJECTS.noDocumentsAvailable}</NormalText>
        </div>
      )}
      <DDQAnswerDrawer
        question={selectedDocumentResponse.question}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <p className="text-justify">
          &emsp;{selectedDocumentResponse.response}
        </p>
      </DDQAnswerDrawer>
    </div>
  );
};

Tag.propTypes = {
  stageNo: PropTypes.number,
};
DDQDocumentList.propTypes = {
  currentPageData: PropTypes.arrayOf(), // TODO: add
  id: PropTypes.string,
  questionId: PropTypes.number,
  categoryId: PropTypes.number,
  projectName: PropTypes.string,
};
