import { Link, useParams } from "react-router-dom";
import { PageHeader } from "../../components/organism/header/page-header";
import { PROJECTS } from "../../constant";
import { ProjectView } from "../features/project-list/view-project";
import { RightIcon } from "../../components/icons";
import { COLORS } from "../../configuration";
import { pathList } from "../../routes/app-navigation";
import { PageBody } from "../../components/organism/body/page-body";
import { HeadText } from "../../components/typography";
import { SkeletonRect } from "../../components/atoms/loader/skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchProjectById } from "../../action";

const initialState = {
  id: null,
  name: "",
  description: "",
  croNumber: "",
  categories: [
    {
      label: "",
      value: null,
      filesData: [
        {
          fileName: "",
        },
      ],
    },
  ],
  responseProvider: [],
};

export const ViewProjectPage = () => {
  const [data, setData] = useState(initialState);

  const projectState = useSelector((state) => state.project);
  const view = useSelector((state) => state.view);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjectById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
   
    setData(projectState.selectedProject);
  }, [projectState.selectedProject]);

  return (
    <>
      <PageHeader>
        <div className="flex items-center gap-2">
          <Link
            to={pathList.user.projectList}
            style={{ textDecoration: "none" }}
            className="text-primary"
          >
            {PROJECTS.allProjects}
          </Link>
          <RightIcon height={24} width={24} color={COLORS.primary} />
          <HeadText className={"text-primary"}>
            {view.project.pending ? (
              <SkeletonRect width={240} height={28} />
            ) : (
              data?.name
            )}
          </HeadText>
        </div>
      </PageHeader>
      <PageBody>
        <ProjectView data={data} isLoading={view.project.pending} />
      </PageBody>
    </>
  );
};
