import { useEffect, useState } from "react";
import { TextArea } from "../../../../components/atoms/input/text-area";
import { RadioButtons } from "../../../../components/atoms/radio-buttons";
import {
  ADMIN,
  RESPONSE_PROVIDER_RADIO_LABELS,
  RESPONSE_PROVIDER,
  GENERAL,
} from "../../../../constant";
import { GPTProvider } from "./gpt";
import { SearchLess } from "./search-less";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import {
  validCategoryId,
  validDDQ,
  validQuestion,
} from "../../../../utils/validation-functions";
import { useDispatch, useSelector } from "react-redux";
import { addDdqQuestion, editDDQ, fetchDdqQuestions } from "../../../../action";
import PropTypes from "prop-types";
import { DelayPageBody } from "../../../../components/organism/body/loading-body";
import { Link, useNavigate } from "react-router-dom";
import { pathList } from "../../../../routes/app-navigation";
import { actions } from "../../../slice/ddq";
import Select from "../../../../components/molecules/select/select-input";

const initialData = {
  responseProvider: RESPONSE_PROVIDER.gpt,
  question: "",
  query: "",
  category: { id: "", value: "" },
  promptId: "",
  prompt: "",
  summaryFilter: "",
  additionalDetails: "",
  variable: { id: "", variable: "", name: "" },
};

export const AddDDQ = ({ id, isEdit = false, isLoading = false }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [ddqData, setDDQData] = useState(initialData);
  const [loading, setLoading] = useState(isLoading);
  const [categories, setCategories] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const view = useSelector((state) => state.view);
  const ddqState = useSelector((state) => state.ddq);
  const category = useSelector((state) => state.category);

  useEffect(() => {
    const categoryList = category.categoryDetails.map((i) => ({
      id: i.id,
      value: i.categoryName,
    }));
    setCategories([...categoryList]);
  }, [category.categoryDetails]);

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      setTimeout(() => setLoading(false), 500);
    }
  }, [isEdit, isLoading]);

  useEffect(() => {
    if (isEdit && ddqState.selected) {
      setDDQData({
        id: ddqState.selected?.id ?? "",
        additionalDetails: ddqState.selected?.additionalDetail ?? "",
        category: {
          id: ddqState.selected.category?.value ?? 0,
          value: ddqState.selected.category?.label ?? "",
        },
        promptId: ddqState.selected?.gptPrompt?.id ?? "",
        prompt: ddqState.selected?.gptPrompt?.prompt ?? "",
        query: ddqState.selected?.gptPrompt?.gptQuery ?? "",
        question: ddqState.selected?.question ?? "",
        responseProvider: ddqState.selected?.responseProvider ?? "",
        summaryFilter: ddqState.selected?.gptPrompt?.summaryFilter ?? "",
        variable: {
          id: ddqState.selected.variable?.id ?? "",
          name: ddqState.selected.variable?.variable ?? "",
        },
      });
    }
  }, [ddqState.selected, isEdit]);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsSubmit(true);
    const validData = validDDQ({
      responseProvider: ddqData.responseProvider,
      question: ddqData.question,
      query: ddqData.query,
      category: ddqData.category.id,
      summaryFilter: ddqData.summaryFilter,
      prompt: ddqData.prompt,
      variable: ddqData.variable?.name ?? ddqData.variable?.variable ?? "",
    });

    if (validData) {
      if (!isEdit) {
        dispatch(addDdqQuestion({ ...ddqData })).then(() => {
          setIsReset(true);
          setIsSubmit(false);
          navigate(pathList.admin.ddqList);
          setDDQData(initialData);
          setTimeout(() => setIsReset(false), 1000);
        });
      } else {
        dispatch(editDDQ({ ...ddqData, id })).then(() => {
          setIsSubmit(false);
          dispatch(fetchDdqQuestions());
          dispatch(actions.resetSelectedState());
          navigate(pathList.admin.ddqList);
        });
      }
    }
  };

  return (
    <DelayPageBody isLoading={loading}>
      <form>
        <div className="flex flex-col gap-[12px] h-max overflow-auto px-[8px]">
          <div>
            <RadioButtons
              labels={RESPONSE_PROVIDER_RADIO_LABELS}
              groupLabel={
                <span className="font-bold">
                  {ADMIN.selectExpectedResponseFrom}
                </span>
              }
              checked={ddqData.responseProvider}
              onChange={(e) =>
                setDDQData({ ...ddqData, responseProvider: parseInt(e) })
              }
              isRow
            />
          </div>
          <div>
            <TextArea
              value={ddqData.question}
              label={
                <span className="font-bold">{ADMIN.writeYourQuestion}</span>
              }
              rows={2}
              placeholder="Question*"
              name="question"
              id="question"
              onChange={(e) =>
                setDDQData({ ...ddqData, question: e.target.value })
              }
              errorText={
                isSubmit ? validQuestion(ddqData.question)?.message : ""
              }
              inputProps={{ "data-testid": "question-input" }}
            />
          </div>
          {ddqData.responseProvider === RESPONSE_PROVIDER.gpt && (
            <div>
              <TextArea
                value={ddqData.query}
                label={
                  <span className="font-bold">{ADMIN.writeYourQuery}</span>
                }
                rows={2}
                placeholder="Query*"
                name="query"
                id="query"
                onChange={(e) =>
                  setDDQData({ ...ddqData, query: e.target.value })
                }
                errorText={
                  isSubmit ? validQuestion(ddqData.query, "Query")?.message : ""
                }
                inputProps={{ "data-testid": "query-input" }}
              />
            </div>
          )}
          <div className="flex gap-4">
            <div className="w-full">
              <Select
                defaultValue={
                  isEdit
                    ? [
                        {
                          id: ddqData.category?.id ?? 0,
                          value: ddqData.category?.value ?? "",
                        },
                      ]
                    : []
                }
                values={categories}
                label={
                  <span className="font-bold">{ADMIN.selectCategory}</span>
                }
                innerLabel="Category*"
                onChange={(value) =>
                  setDDQData({ ...ddqData, category: value })
                }
                isReturnBoth
                errorText={
                  isSubmit ? validCategoryId(ddqData.category.id).message : ""
                }
                isReset={isReset}
              />
            </div>
          </div>
          <div className="h-full">
            {ddqData.responseProvider === RESPONSE_PROVIDER.gpt ? (
              <GPTProvider
                submit={isSubmit}
                callBack={({ prompt, summaryFilter }) =>
                  setDDQData({ ...ddqData, prompt, summaryFilter })
                }
                isReset={isReset}
                data={{
                  prompt: ddqData.prompt,
                  summaryFilter: ddqData.summaryFilter,
                }}
              />
            ) : (
              <SearchLess
                submit={isSubmit}
                isEdit={isEdit}
                callBack={({ additionalDetails, variable }) => {
                  setDDQData({
                    ...ddqData,
                    additionalDetails,
                    variable,
                  });
                }}
                isReset={isReset}
                defaultData={{
                  additionalDetails: ddqData.additionalDetails,
                  variable: ddqData.variable,
                }}
              />
            )}
          </div>
          <div className="flex gap-4">
            <Button
              size="md"
              onClick={handleSubmit}
              type="submit"
              isLoading={view.ddqCreate.pending || view.ddqEdit.pending}
            >
              {isEdit ? GENERAL.save : GENERAL.add}
            </Button>
            {!isEdit && (
              <Button
                size="md"
                variant="text"
                type="reset"
                onClick={() => {
                  setDDQData(initialData);
                  setIsReset(true);
                  setTimeout(() => setIsReset(false), 1000);
                }}
              >
                {GENERAL.reset}
              </Button>
            )}
            {isEdit && (
              <Link className="no-underline" to={pathList.admin.ddqList}>
                <Button size="md" variant="text">
                  {GENERAL.cancel}
                </Button>
              </Link>
            )}
          </div>
        </div>
      </form>
    </DelayPageBody>
  );
};

AddDDQ.propTypes = {
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.number,
};
