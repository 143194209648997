import BrandLogo from "../icons/Logo/brand-logo";
import { SubHeadText } from "../typography";

export const Welcome = () => {
  return (
    <div className="text-center">
      <SubHeadText className={"font-bold text-2xl"}>Welcome to</SubHeadText>
      <BrandLogo height={56} className={"mt-[20px]"} />
    </div>
  );
};
