import { createSlice } from "@reduxjs/toolkit";
import { fetchDDQResponseSelectedList } from "../../action";

const initialState = {
  responses: [],
};

const NAME = "ddq-selected-response";

const ddqSelectedResponseSlice = createSlice({
  name: NAME,
  initialState,
  extraReducers(builder) {
    builder.addCase(
      fetchDDQResponseSelectedList.fulfilled,
      (state, { payload }) => {
        state.responses = payload.data;
      }
    );
  },
});

export const { actions } = ddqSelectedResponseSlice;

export default ddqSelectedResponseSlice.reducer;
