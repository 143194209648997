import React from "react";
import PropTypes from "prop-types";
import { Divider } from "@mui/material";
import { DocumentName } from "./document-name";
import { SmallText } from "../../../../components/typography";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { PROJECTS } from "../../../../constant";

export const Categories = ({ title, files, categoryId, callback }) => {
  return (
    <div className="ml-[8px] border border-solid border-edge rounded-md p-2 mt-[8px]">
      <div className="flex justify-between mb-2">
        <SmallText>{title}</SmallText>
        <SmallText>
          {files?.length > 0
            ? `${files.length} file${files.length > 1 ? "s" : ""} uploaded`
            : ""}
        </SmallText>
      </div>
      <Divider />
      <div className="flex gap-2 flex-wrap mt-[8px]">
        {files?.length ? (
          <>
            {files.map((file) => (
              <DocumentName
                key={file.fileName}
                title={file.fileName}
                categoryId={categoryId}
              />
            ))}
            <Button
              className="ml-[8px]"
              variant="outlined"
              onClick={() => callback(true)}
            >
              {PROJECTS.addMoreFiles}
            </Button>
          </>
        ) : (
          <>
            <SmallText>{PROJECTS.noFilesAvailable}</SmallText>
            <Button
              className="ml-[8px]"
              variant="outlined"
              onClick={() => callback(true)}
            >
              {PROJECTS.addFiles}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

Categories.propTypes = {
  title: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
    })
  ).isRequired,
  categoryId: PropTypes.number,
  callback: PropTypes.func.isRequired,
};
