import { configureStore } from "@reduxjs/toolkit";
import user from "./universal/slice/user";
import view from "./universal/slice/view";
import project from "./universal/slice/project";
import category from "./universal/slice/category";
import ddq from "./universal/slice/ddq";
import clients from "./universal/slice/clients";
import document from "./universal/slice/document";
import supportRequest from "./universal/slice/support-request";
import ddqResponse from "./universal/slice/ddq-response";
import ddqDocuments from "./universal/slice/ddq-documents";
import ddqChunks from "./universal/slice/ddq-chunks";
import ddqSelectedResponse from "./universal/slice/ddq-selected-response";
import clientProjects from "./universal/slice/client-projects";
import feedback from "./universal/slice/feedback";
import filter from "./universal/slice/filters";

export const store = configureStore({
  reducer: {
    user,
    view,
    project,
    category,
    ddq,
    clients,
    document,
    supportRequest,
    ddqResponse,
    ddqDocuments,
    ddqChunks,
    ddqSelectedResponse,
    clientProjects,
    feedback,
    filter,
  },
});
