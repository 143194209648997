import { useSelector } from "react-redux";
import Select from "../../../../components/molecules/select/select-input";

export const ProjectFilter = ({ callback, isError }) => {
  const view = useSelector((state) => state.view);
  const projects = useSelector((state) => state.clientProjects);

  const projectList =
    projects?.projectList?.map((i) => ({
      id: i?.projectId ?? "",
      value: i?.projectName ?? "",
    })) ?? [];

  return (
    <div>
      <Select
        innerLabel="Filter Company Name"
        isLoading={view.clientProjects?.pending}
        isReset={view.clientProjects?.success}
        label="Select Company Name"
        onChange={(value) => {
          callback?.({ ...value, isError: false });
        }}
        values={[...projectList]}
        errorText={isError ? "Company name is required." : ""}
        isReturnBoth
      />
    </div>
  );
};
