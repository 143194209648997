import { useSelector } from "react-redux";
import { LoginRegistrationCommonTemplate as CommonTemplate } from "../../components/general/login-registration-common";
import { Login } from "../features/login";
import { AllRightsReserved } from "../../components/general/all-rights-reserved";

export const LoginPage = () => {
  const viewState = useSelector((state) => state.view);

  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-col justify-between min-h-screen">
        <Login
          viewLoginState={viewState.loginProcess}
          viewResetState={viewState.linkSentForResetPassword}
        />
        <AllRightsReserved />
      </div>
      <CommonTemplate />
    </div>
  );
};
