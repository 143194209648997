import { createSlice } from "@reduxjs/toolkit";
import { fetchDDQResponse } from "../../action";
import { PAGE_SIZE, SEVERITY } from "../../constant";

const initialState = {
  fetchedId: null,
  totalPages: 1,
  totalResponses: 1,
  nextIndex: 1,
  responses: [
    // {
    //   id: 1,
    //   question: "Constitution subsidiary",
    //   category: 1,
    //   response:
    //     "The document is a register of directors for First Ireland Risk Management Limited. It lists both current and past directors, including their names and roles. The document also provides dates of service and any changes in roles.",
    //   responses: [
    //     {
    //       category: "Corporate Information",
    //       fileName:
    //         "10.1.11_Statutory Registers First Ireland Risk Management Limited.pdf",
    //       response:
    //         "The document lists the following current directors: Keith Gill, John Roe, Tony/Anthony Gill, Linda Gallagher, Conor Lyons, and Sarah Sheridan. Past directors include Cathal Duffy, Bernard Swierczyna, Ciaran Rooney, Patrick Mee, Michael Hennighan, Cilian O'Brolchain, Dermot Rutherford, Ian Thornton, Padraig Ahearne, Gerard Conway, Joseph O'Rourke, Sean Kavanagh, and Marc O'Connor. The document also includes dates of service and any changes in roles.",
    //     },
    //   ],
    //   prompt: {
    //     null: "latest_constitution",
    //     id: "517216",
    //   },
    //   color: 1,
    //   responseProvider: 1,
    // },
  ],
  selectedCategories: [],
};

const NAME = "ddq-response";

const ddqResponseSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetDDQResponseStats(state, action) {
      const { payload: id } = action;
      if (state.fetchedId !== id || state.selectedCategories?.length !== 0) {
        return initialState;
      }
    },
    resetState(state) {
      return initialState;
    },
    setSelectedCategories(state, action) {
      state.selectedCategories = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDDQResponse.fulfilled, (state, { payload }) => {
      if (payload.status === SEVERITY.Success) {
        state.totalResponses = payload.totalRecords;
        state.nextIndex = payload.nextIndex;
        state.responses = payload.isInitialFetch
          ? [...payload.data]
          : [...state.responses, ...payload.data];
        state.totalPages = Math.ceil(payload.totalRecords / PAGE_SIZE);
        state.fetchedId = Number(payload.id);
      }
    });
  },
});

export const { actions } = ddqResponseSlice;

export default ddqResponseSlice.reducer;
