import { DELETE } from "../../../../constant";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { DeleteIcon } from "../../../../components/icons";
import { HeadText, NormalText } from "../../../../components/typography";
import { deleteDDQ } from "../../../../action";

export const DeleteDDQ = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.view);

  const handleClick = async () => {
    try {
      dispatch(deleteDDQ({ id })).then(() => onClose());
    } catch (error) {
      console.error("Error deleting ddq:", error);
    }
  };

  return (
    <MUIDialog
      isOpen={true}
      width="xs"
      onClose={() => {
        onClose?.();
      }}
      content={
        <div className="w-full flex flex-col">
          <div className="mx-auto w-max">
            <DeleteIcon height={40} width={40} />
          </div>
          <div className="text-center mt-[14px]">
            <HeadText className={"text-primary font-normal"}>
              {DELETE.areYouSure}
            </HeadText>
            <div className="mt-[16px]">
              <NormalText>{DELETE.doYouReallyWant}</NormalText>
              <NormalText testId="delete-ddq-message">
                {DELETE.thisItemCantBeRestored}
              </NormalText>
            </div>
            <div className="mt-[16px] flex flex-col">
              <Button
                size="md"
                onClick={handleClick}
                isLoading={viewState.ddqDelete.pending}
              >
                {DELETE.yesDelete}
              </Button>
              <Button
                variant="text"
                size="md"
                className={"text-primary mt-[10px]"}
                onClick={() => {
                  onClose?.(false);
                }}
              >
                {DELETE.noKeepAsItIs}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

DeleteDDQ.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func,
};
