import { useState } from "react";
import { TextField } from "../../../components/atoms/input/text-field";
import { SearchIcon } from "../../../components/icons";
import { PageWhite } from "../../../components/organism/body/page-white";
import { HeadText } from "../../../components/typography";
import { COLORS } from "../../../configuration";
import { ADMIN } from "../../../constant";
import { DocumentManagement } from "../../features/admin/document-management";
import { useDebounce } from "../../../hooks/use-debounce";

export const DocumentManagementPage = () => {
  const [searchClient, setSearchClient] = useState(null);

  const debouncedSearchClient = useDebounce(searchClient, 500);

  return (
    <PageWhite>
      <div className="mb-[20px] flex justify-between items-center">
        <HeadText>{ADMIN.listOfDocument}</HeadText>
        <div className="flex gap-4">
          <TextField
            name={"name"}
            id="client"
            placeholder={"Search Client"}
            onChange={(e) => setSearchClient(e.target.value)}
            endAdornment={
              <SearchIcon
                width={18}
                height={18}
                color={COLORS.secondaryLight}
              />
            }
          />
        </div>
      </div>
      <DocumentManagement searchClient={debouncedSearchClient} />
    </PageWhite>
  );
};
