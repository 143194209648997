import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectById, fetchProjectList } from "../../action";
import { convertKeysToCamelCase } from "../../utils/camelize";

const NAME = "Project";

const initialState = {
  selectedProject: {
    id: null,
    name: "",
    description: "",
    croNumber: "",
    categories: [
      {
        label: "",
        value: null,
        filesData: [
          {
            fileName: "",
          },
        ],
      },
    ],
    responseProvider: [],
  },
  projectDetails: [
    // {
    //   id: 1,
    //   projectName: "",
    //   description: "",
    //   categories: [
    //     {
    //       id: 3,
    //       categoryName: "GPT-3.5",
    //     },
    //     {
    //       id: 4,
    //       categoryName: "GPT-4",
    //     },
    //   ],
    //   responseProvider: [1, 2],
    //   ddqResponseStatus: "success",
    //   pdfs: ["demot.txt", "temp.txt"],
    // },
  ],
};

const projectSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchProjectList.fulfilled, (state, { payload }) => {
      state.projectDetails = convertKeysToCamelCase(
        payload.response.project_details
      );
    });
    builder.addCase(fetchProjectById.fulfilled, (state, { payload }) => {
      state.selectedProject = convertKeysToCamelCase(payload.data);
    });
  },
});

export const { actions } = projectSlice;

export default projectSlice.reducer;
