import { useState, useCallback, useMemo, useEffect } from "react";
import { CategoryDropDown } from "../admin/categories-management/category-dropdown";
import {
  ErrorText,
  LabelText,
  NormalText,
  SmallText,
} from "../../../components/typography";
import { FileInput } from "../../../components/molecules/file-input";
import { GENERAL, PROJECTS } from "../../../constant";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { useDispatch, useSelector } from "react-redux";
import { createProjectAndUploadDocument } from "../../../action";
import { useLocation, useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/app-navigation";
import { AnimatedLoader } from "../../../components/atoms/loader/animated-loader";
import MUIDialog from "../../../components/organism/dialog/mui-dialog";

const initialLog = { isError: false, message: "" };

export const UploadPdf = () => {
  const [category, setCategory] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [log, setLog] = useState(initialLog);

  const dispatch = useDispatch();
  const location = useLocation();
  const view = useSelector((state) => state.view);
  const documents = useSelector((state) => state.document);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const totalFilesCount = useMemo(
    () => selectedFiles.reduce((acc, curr) => acc + curr.files.length, 0),
    [selectedFiles]
  );

  const handleSubmit = useCallback(() => {
    if (selectedFiles.length && location.state && category.length) {
      const categoryList = category.map((i) => i.id);

      dispatch(
        createProjectAndUploadDocument({
          name: location.state.name,
          description: location.state.description,
          category: categoryList,
          croNumber: location.state.croNumber,
          responseProvider: location.state.responseProvider,
          data: selectedFiles,
        })
      )
        .then(() => {
          navigate(pathList.user.projectList);
        })
        .catch(() => {
          setLog({ isError: true, message: "Something bad happened!" });
          setTimeout(() => {
            setLog(initialLog);
          }, 5000);
        });
    } else {
      setLog({
        isError: true,
        message:
          "Please upload at least one document for at least one category.",
      });
      setTimeout(() => {
        setLog(initialLog);
      }, 5000);
    }
  }, [selectedFiles, location.state, category, dispatch, navigate]);

  const handleFileChange = useCallback((itemId, files) => {
    setSelectedFiles((prevSelectedFiles) => {
      const index = prevSelectedFiles.findIndex(
        (file) => file.categoryId === itemId
      );
      if (files.length) {
        if (index >= 0) {
          prevSelectedFiles[index].files = files;
          return [...prevSelectedFiles];
        } else {
          return [...prevSelectedFiles, { categoryId: itemId, files }];
        }
      } else {
        return prevSelectedFiles.filter((file) => file.categoryId !== itemId);
      }
    });
  }, []);

  return (
    <div>
      {view.createAndUpload.pending && (
        <MUIDialog
          isOpen
          width="sm"
          disableEscapeKeyDown={true}
          content={
            <div className="flex flex-col items-center justify-center">
              <div className="flex items-center justify-center mb-4">
                <AnimatedLoader height={64} width={64} />
              </div>
              {view.createProject.pending && (
                <NormalText className="mb-2">Creating Project...</NormalText>
              )}
              {view.createProject.success && (
                <div className="text-center">
                  <NormalText>Uploading documents...</NormalText>
                  <SmallText>{`${documents.uploadedDocuments}/${totalFilesCount} Uploaded`}</SmallText>
                </div>
              )}
              <NormalText className="mt-[12px]">
                Please do not refresh page or reload window.
              </NormalText>
            </div>
          }
        />
      )}
      <div className="flex justify-between items-center mb-4">
        <NormalText>{PROJECTS.uploadPDF}</NormalText>
        <SmallText>
          {totalFilesCount > 0 &&
            `${totalFilesCount} file${totalFilesCount > 1 ? "s" : ""} to upload`}
        </SmallText>
      </div>
      <CategoryDropDown
        callBack={setCategory}
        isReturnBoth
        isReturnId={false}
      />
      {log.isError && <ErrorText>{log.message}</ErrorText>}
      <div className="mt-[20px] flex flex-col gap-4">
        {category.length ? (
          category.map((item) => {
            const fileLength =
              selectedFiles.find((i) => i.categoryId === item.id)?.files
                ?.length ?? 0;

            return (
              <div
                key={item.id}
                className="p-[20px] border-solid border border-edge rounded-md"
              >
                <div className="flex justify-between">
                  <LabelText labelFor={`file-input-${item.id}`}>
                    {item.value}
                  </LabelText>
                  <SmallText>
                    {fileLength > 0 &&
                      `${fileLength} file${fileLength > 1 ? "s" : ""} to upload`}
                  </SmallText>
                </div>
                <FileInput
                  id={`file-input-${item.id}`}
                  className="py-[32px] w-full"
                  isMultiple
                  accept={[".pdf", ".txt", ".csv", ".xlsx", ".msg", ".docx"]}
                  errorText="Only files with .pdf, .txt, .csv, .xlsx , .msg and .docx extensions are allowed."
                  onChange={(value) => handleFileChange(item.id, value)}
                />
              </div>
            );
          })
        ) : (
          <div className="text-center rounded-md border border-edge p-4 border-solid">
            <SmallText>{PROJECTS.noCategoryChosen}</SmallText>
          </div>
        )}
      </div>
      <div className="w-max ml-auto mt-[20px] flex gap-2">
        <Button
          variant="text"
          size="md"
          onClick={() => navigate(pathList.user.projectList)}
        >
          {GENERAL.cancel}
        </Button>
        <Button
          size="md"
          onClick={handleSubmit}
          isLoading={view.createAndUpload.pending}
        >
          {GENERAL.save}
        </Button>
      </div>
    </div>
  );
};
