import { useEffect, useState } from "react";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";
import { MUIDrawer } from "../../components/organism/dialog/mui-drawer";
import { SubHeadText } from "../../components/typography";
import { PROJECTS } from "../../constant";
import { ProjectList } from "../features/project-list";
import { CreateProject } from "../features/project-list/create-project";
import { TextField } from "../../components/atoms/input/text-field";
import { SearchIcon } from "../../components/icons";
import { COLORS } from "../../configuration";
import { useDebounce } from "../../hooks/use-debounce";
import { useSelector } from "react-redux";

export const ProjectListPage = () => {
  const view = useSelector((state) => state.view);

  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState(null);
  const debouncedName = useDebounce(filterName, 500);

  useEffect(() => {
    setOpen(view.createProject.dialog);
  }, [view.createProject.dialog]);

  return (
    <div className="my-[22px] w-[1270px] mx-auto">
      <div className="flex justify-between">
        <SubHeadText>{PROJECTS.projectList}</SubHeadText>

        <div className="h-full flex gap-4 items-center">
          <div>
            <TextField
              placeholder={"Search Project"}
              onChange={(e) => setFilterName(e.target.value)}
              name={"company-name"}
              endAdornment={
                <SearchIcon
                  width={18}
                  height={18}
                  color={COLORS.secondaryLight}
                />
              }
            />
          </div>
          <div className="">
            <Button
              size="md"
              className="h-[40px]"
              onClick={() => setOpen(!open)}
            >
              {PROJECTS.createNewProject}
            </Button>
            <MUIDrawer
              width="650px"
              isOpen={open}
              onClose={(value) => setOpen(value)}
            >
              <CreateProject onClose={() => setOpen(false)} />
            </MUIDrawer>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-[20px]">
        <ProjectList filterName={debouncedName} />
      </div>
    </div>
  );
};
