import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useDDQTable } from "../../../../hooks/use-ddq";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PAGE_SIZE, RESPONSE_PROVIDER } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import PropTypes from "prop-types";
import { DeleteDDQ } from "./delete-ddq";
import { ViewDDQ } from "./view-ddq";

export const DDQManagement = ({ responseProvider, category, question }) => {
  const { columns, setSortingState, sorting, dialog, setDialog } =
    useDDQTable();

  const ddqSelector = useSelector((state) => state.ddq);
  const viewState = useSelector((state) => state.view);

  const [data, setData] = useState([]);

  useEffect(() => {
    setData([...ddqSelector.ddqDetails]);
  }, [ddqSelector.ddqDetails]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    filterFns: {
      responseFilter: (row, columnId, filterValue) => {
        if (filterValue === RESPONSE_PROVIDER.gpt)
          return row.original[columnId] === RESPONSE_PROVIDER.gptText;
        else if (filterValue === RESPONSE_PROVIDER.searchLess)
          return row.original[columnId] === RESPONSE_PROVIDER.searchLessText;
        else return row.original[columnId];
      },
      categoryFilter: (row, columnId, filterValue) => {
        const columnData = row.original[columnId];
        if (
          filterValue?.length > 0 &&
          (!columnData || columnData.length === 0)
        ) {
          return false;
        }
        return filterValue?.length > 0
          ? columnData.every((item) => filterValue.includes(item))
          : columnData;
      },

      questionFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
    },

    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.getColumn("responseProvider")?.setFilterValue(responseProvider);
  }, [responseProvider, table]);

  useEffect(() => {
    if (category) {
      table.getColumn("categories")?.setFilterValue(category);
    }
  }, [category, table]);

  useEffect(() => {
    table.getColumn("question")?.setFilterValue(question);
  }, [question, table]);

  return (
    <div>
      <DynamicTable
        columns={columns.length}
        table={table}
        records={data.length}
        isPending={viewState.ddq.pending}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      {dialog.isDelete ? (
        <DeleteDDQ
          id={dialog.id}
          onClose={() => {
            setDialog({ ...dialog, id: null, isDelete: false });
          }}
        />
      ) : null}
      {dialog.isView ? (
        <ViewDDQ
          id={dialog.id}
          onClose={() => {
            setDialog({ ...dialog, id: null, isView: false });
          }}
        />
      ) : null}
    </div>
  );
};

DDQManagement.propTypes = {
  responseProvider: PropTypes.number,
  category: PropTypes.array,
  question: PropTypes.string,
};
