import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const DownloadIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      enableBackground="new 0 0 32 32"
      xmlSpace="preserve"
      width={width}
      height={height}
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <title>Download</title>

      <line
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        x1="25"
        y1="28"
        x2="7"
        y2="28"
      />
      <line
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        x1="16"
        y1="23"
        x2="16"
        y2="4"
      />
      <polyline
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        points="9,16 16,23 23,16 "
      />
    </svg>
  );
};

DownloadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
