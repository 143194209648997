import { useState } from "react";
import { AllRightsReserved } from "../../components/general/all-rights-reserved";
import { LoginRegistrationCommonTemplate as CommonTemplate } from "../../components/general/login-registration-common";
import { Registration } from "../features/registration";
import { EmailResendDialog } from "../features/registration/email-resend-dialog";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../routes/app-navigation";

export const RegistrationPage = () => {
  const viewState = useSelector((state) => state.view);

  const [email, setEmail] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-2">
      <div className="flex flex-col justify-between min-h-screen">
        <Registration
          registration={viewState.registration}
          getEmail={(value) => setEmail(value)}
          callback={(value) => setIsOpen(value)}
        />
        <AllRightsReserved />
      </div>
      <CommonTemplate />

      {isOpen && (
        <EmailResendDialog
          email={email}
          isSuccessDialog={true}
          onClose={() => {
            setIsOpen(false);
            navigate(pathList.login);
          }}
        />
      )}
    </div>
  );
};
