import { useEffect, useState, useCallback } from "react";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { MUICheckBox } from "../../../components/atoms/checkbox";
import { TextArea } from "../../../components/atoms/input/text-area";
import { TextField } from "../../../components/atoms/input/text-field";
import { ErrorText, HeadText } from "../../../components/typography";
import { GENERAL, PROJECTS, RESPONSE_PROVIDER } from "../../../constant";

import {
  validCompanyName,
  validCRONumber,
  validDescription,
  validProjectData,
  validResponseProvider,
} from "../../../utils/validation-functions";
import { useDispatch, useSelector } from "react-redux";
import { createNewProject } from "../../../action";
import { SelectCategoryDialog } from "./select-category";
import { useNavigate } from "react-router-dom";
import { pathList } from "../../../routes/app-navigation";
import PropTypes from "prop-types";

export const CreateProject = ({ onClose }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialProjectData = {
    name: "",
    description: "",
    croNumber: "",
    responseProvider: [RESPONSE_PROVIDER.gpt],
  };

  const [projectData, setProjectData] = useState(initialProjectData);
  const viewState = useSelector((state) => state.view);

  useEffect(() => {
    if (viewState.createProject.success) {
      setOpenDialog(false);
      setProjectData(initialProjectData);
      setIsSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewState.createProject]);

  const handleInputChange = useCallback((key, value) => {
    setProjectData((prevData) => ({ ...prevData, [key]: value }));
  }, []);

  const handleCheckBoxChange = useCallback((provider) => {
    setProjectData((prevData) => {
      const responseProvider = prevData.responseProvider.includes(provider)
        ? prevData.responseProvider.filter((item) => item !== provider)
        : [...prevData.responseProvider, provider];
      return { ...prevData, responseProvider };
    });
  }, []);

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsSubmit(true);
    if (validProjectData(projectData)) {
      if (projectData.responseProvider.includes(RESPONSE_PROVIDER.gpt)) {
        navigate(pathList.user.projectUploadFile, { state: projectData });
      } else if (
        projectData.responseProvider.includes(RESPONSE_PROVIDER.searchLess)
      ) {
        setOpenDialog(true);
      }
    }
  };

  const handleCallBack = (category) => {
    const body = { ...projectData, category };
    dispatch(createNewProject(body));
  };

  return (
    <div className="mx-[50px]">
      <HeadText className="text-primary">{PROJECTS.createYourProject}</HeadText>
      <form>
        <div className="flex flex-col gap-4 w-full mt-[20px]">
          <TextField
            value={projectData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
            placeholder="Company name*"
            name={"company-name"}
            errorText={isSubmit && validCompanyName(projectData.name).message}
          />
          <TextArea
            value={projectData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            placeholder="Description*"
            errorText={
              isSubmit && validDescription(projectData.description).message
            }
            name={"description"}
          />
          <TextField
            type={"text"}
            value={projectData.croNumber}
            onChange={(e) => handleInputChange("croNumber", e.target.value)}
            placeholder="CRO number*"
            errorText={
              isSubmit && validCRONumber(projectData.croNumber).message
            }
            name={"cro-number"}
          />
          <div>
            <div className="flex gap-4 mt-[16px]">
              <MUICheckBox
                label={RESPONSE_PROVIDER.gptText}
                id="gpt"
                checked={projectData.responseProvider.includes(
                  RESPONSE_PROVIDER.gpt
                )}
                onChange={() => handleCheckBoxChange(RESPONSE_PROVIDER.gpt)}
              />
              <MUICheckBox
                label={RESPONSE_PROVIDER.searchLessText}
                id="search4less"
                checked={projectData.responseProvider.includes(
                  RESPONSE_PROVIDER.searchLess
                )}
                onChange={() =>
                  handleCheckBoxChange(RESPONSE_PROVIDER.searchLess)
                }
              />
            </div>
            <ErrorText>
              {isSubmit &&
                validResponseProvider({
                  responseProvider: projectData.responseProvider,
                })?.message}
            </ErrorText>
          </div>
          <div className="flex justify-end w-full mt-[20px]">
            <Button
              variant="text"
              size="md"
              className="mr-[20px]"
              onClick={() => onClose()}
            >
              {GENERAL.cancel}
            </Button>
            <Button size="md" type="submit" onClick={handleSubmit}>
              {GENERAL.create}
            </Button>
          </div>
        </div>
      </form>
      <SelectCategoryDialog
        isOpen={openDialog}
        callBack={handleCallBack}
        onClose={() => setOpenDialog(false)}
      />
    </div>
  );
};

CreateProject.propTypes = {
  onClose: PropTypes.func,
};
