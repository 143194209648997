import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useProjectTable } from "../../../hooks/use-project-table";

import { DynamicTable } from "../../../components/atoms/table";
import { DeleteProject } from "./delete-project";
import { SmallText } from "../../../components/typography";
import Tag from "../../../components/atoms/tags";
import PropTypes from "prop-types";
import { PAGE_SIZE, RESPONSE_PROVIDER } from "../../../constant";
import { CloseIcon } from "../../../components/icons";

export const ProjectList = ({ filterName }) => {
  const {
    columns,
    setSortingState,
    sorting,
    dialog,
    setDialog,
    filterProvider,
    setFilterProvider,
    filterResponseStatus,
    setFilterResponseStatus,
  } = useProjectTable();

  const [data, setData] = useState([]);

  const { projectDetails, projectPending, projectSuccess } = useSelector(
    (state) => ({
      projectDetails: state.project.projectDetails,
      projectPending: state.view.project.pending,
      projectSuccess: state.view.project.success,
      generateResponsePending: state.view.generateResponse.pending,
    })
  );

  useEffect(() => {
    setData([...projectDetails]);
  }, [projectDetails, projectSuccess]);

  const filterFns = useMemo(
    () => ({
      providerFilter: (row, columnId, filterValue) => {
        const value = row.original[columnId];
        return filterValue?.length
          ? filterValue.some((provider) => value.includes(provider))
          : value;
      },
      nameFilter: (row, columnId, filterValue) =>
        filterValue
          ? row.original[columnId]
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          : row.original[columnId],
      responseFilter: (row, columnId, filterValue) => {
        const value = row.original[columnId];
        return filterValue?.length && value
          ? filterValue.some((provider) => value.includes(provider))
          : value;
      },
    }),
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: { sorting },
    initialState: { pagination: { pageSize: PAGE_SIZE } },
    filterFns,
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    table.getColumn("projectName")?.setFilterValue(filterName);
  }, [filterName, table]);

  useEffect(() => {
    table.getColumn("responseProvider")?.setFilterValue(filterProvider);
  }, [filterProvider, table]);

  useEffect(() => {
    table.getColumn("ddqResponseStatus")?.setFilterValue(filterResponseStatus);
  }, [filterResponseStatus, table]);

  return (
    <div>
      {(!!filterProvider.length || !!filterResponseStatus.length) && (
        <div className="mb-2 flex gap-4 items-center">
          <SmallText className="italic">Filter Applied :</SmallText>
          {!!filterProvider.length && (
            <div className="flex">
              {filterProvider.map((provider) => (
                <Tag
                  key={provider}
                  className="bg-page text-secondary-light"
                  text="xs"
                >
                  <span>
                    {provider === RESPONSE_PROVIDER.gpt
                      ? RESPONSE_PROVIDER.gptText
                      : provider === RESPONSE_PROVIDER.searchLess
                        ? RESPONSE_PROVIDER.searchLessText
                        : ""}
                  </span>
                  <button
                    className="border-none bg-none"
                    onClick={() =>
                      setFilterProvider(
                        filterProvider.filter((i) => i !== provider)
                      )
                    }
                  >
                    <CloseIcon width={10} height={10} />
                  </button>
                </Tag>
              ))}
            </div>
          )}
          {!!filterResponseStatus.length && (
            <div className="flex">
              {filterResponseStatus.map((status) => (
                <Tag
                  key={status}
                  className="bg-page text-secondary-light"
                  text="xs"
                >
                  <span>{status}</span>
                  <button
                    className="border-none bg-none"
                    onClick={() =>
                      setFilterResponseStatus(
                        filterResponseStatus.filter((i) => i !== status)
                      )
                    }
                  >
                    <CloseIcon width={10} height={10} />
                  </button>
                </Tag>
              ))}
            </div>
          )}
        </div>
      )}
      <DynamicTable
        table={table}
        records={data.length}
        isPending={projectPending}
        columns={columns.length}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      {dialog.isDelete && (
        <DeleteProject
          id={dialog.id}
          onClose={() => setDialog({ id: null, isDelete: false })}
        />
      )}
    </div>
  );
};

ProjectList.propTypes = {
  filterName: PropTypes.string,
};
