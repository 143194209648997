import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { HeadText, NormalText } from "../../components/typography";
import { ABOUT, DEMO_PARAGRAPH } from "../../constant";

import { DocumentIcon, DownloadIcon, UploadIcon } from "../../components/icons";
import { COLORS } from "../../configuration";
import { ImageWithSkeleton } from "../../components/organism/body/loading-image";

export const AboutUsPage = () => {
  return (
    <div className="mb-[80px]">
      <PageHeader title={"About us"} />
      <PageBody className="flex flex-col-reverse w-max mx-auto justify-center  xl:flex-row xl:justify-between gap-4">
        <div className="flex flex-col gap-4 max-w-[412px]">
          <HeadText className="text-primary">
            {ABOUT.allAboutDDQResponse}
          </HeadText>
          <NormalText className="text-justify">
            &emsp;{DEMO_PARAGRAPH}
          </NormalText>
        </div>
        <div className="h-max w-max bg-edge pb-[10px] pr-[10px] rounded-lg">
          <ImageWithSkeleton
            src="/static/img/about-us/image-1.jpeg"
            alt="due-diligence"
            width={412}
            height={300}
            className="-mt-[10px] -ml-[10px]"
          />
        </div>
      </PageBody>
      <div className="bg-primary-hover w-full py-[80px] mt-[80px]">
        <PageBody className="text-center flex flex-col gap-4">
          <HeadText className="text-primary">{ABOUT.howToUse}</HeadText>
          <NormalText>{DEMO_PARAGRAPH}</NormalText>
          <div className="flex justify-around mt-[60px]">
            {[ABOUT.step1, ABOUT.step2, ABOUT.step3].map((step, index) => {
              const IconComponent = [UploadIcon, DocumentIcon, DownloadIcon][
                index
              ];
              const iconSize = index === 0 ? 50 : 60;

              return (
                <div key={step}>
                  <div className="h-max border border-primary border-solid w-max p-max pb-[10px] pr-[10px] rounded-lg">
                    <div className="bg-primary w-[180px] h-[180px] -mt-[10px] -ml-[10px] flex items-center rounded-lg relative">
                      <IconComponent
                        color={COLORS.white}
                        width={iconSize}
                        height={iconSize}
                        className="w-max mx-auto"
                      />
                    </div>
                  </div>
                  <NormalText className="text-secondary-light text-lg font-bold mt-[16px]">
                    {step}
                  </NormalText>
                </div>
              );
            })}
          </div>
        </PageBody>
      </div>
      <PageBody className="mt-[120px]">
        <div className="h-[300px] rounded-lg overflow-hidden">
          <ImageWithSkeleton
            src="/static/img/about-us/image-2.jpeg"
            className="w-full -mt-[60px]"
            alt="due-diligence"
          />
        </div>
        <div className="mt-[28px] text-center">
          <HeadText className="text-primary">{ABOUT.ddqResponse}</HeadText>
          <NormalText className="mt-[20px]">{DEMO_PARAGRAPH}</NormalText>
        </div>
      </PageBody>
    </div>
  );
};
