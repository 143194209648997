import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const GoToIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.83301 15.8333V14.1666H11.7497C12.6247 14.1666 13.3851 13.8888 14.0309 13.3333C14.6768 12.7777 14.9997 12.0833 14.9997 11.2499C14.9997 10.4166 14.6768 9.72214 14.0309 9.16659C13.3851 8.61103 12.6247 8.33325 11.7497 8.33325H6.49967L8.66634 10.4999L7.49967 11.6666L3.33301 7.49992L7.49967 3.33325L8.66634 4.49992L6.49967 6.66659H11.7497C13.0969 6.66659 14.2531 7.10409 15.2184 7.97909C16.1837 8.85409 16.6663 9.94436 16.6663 11.2499C16.6663 12.5555 16.1837 13.6458 15.2184 14.5208C14.2531 15.3958 13.0969 15.8333 11.7497 15.8333H5.83301Z"
        fill={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

GoToIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
