import { twMerge } from "tailwind-merge";
import BrandLogo from "../../icons/Logo/brand-logo";
import { MenuList } from "./app-menu-list";
import { UserNavigation } from "./user-menu";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { pathList } from "../../../routes/app-navigation";
import { useDispatch } from "react-redux";
import { actions } from "../../../universal/slice/user";

export const AppHeader = ({ isAdminActive = false }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={twMerge(
        "sticky z-[2] bg-white top-0 h-[74px] shadow-md items-center flex",
        isAdminActive ? "justify-between px-[50px]" : "justify-around"
      )}
      data-testid="brand-logo"
    >
      <Link
        to={pathList.user.projectList}
        onClick={() => dispatch(actions.setAdminActive(false))}
      >
        <BrandLogo height={36} />
      </Link>
      <div className="flex gap-16 h-full items-center">
        {!isAdminActive && <MenuList />}
        <UserNavigation isAdminActive={isAdminActive} />
      </div>
    </div>
  );
};

AppHeader.propTypes = {
  isAdminActive: PropTypes.bool,
};
