import { useNavigate } from "react-router-dom";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { SearchIcon } from "../../../components/icons";
import { Select } from "../../../components/molecules/select/select-input";
import { HeadText } from "../../../components/typography";
import { COLORS } from "../../../configuration";
import { ADMIN, ALL, RESPONSE_PROVIDER } from "../../../constant";
import { CategoryDropDown } from "../../features/admin/categories-management/category-dropdown";
import { DDQManagement } from "../../features/admin/ddq-management";
import { pathList } from "../../../routes/app-navigation";
import { useState } from "react";
import { UploadCSV } from "../../features/admin/ddq-management/upload-csv";
import { useDebounce } from "../../../hooks/use-debounce";
import { PageWhite } from "../../../components/organism/body/page-white";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../slice/filters";

export const DDQManagementPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selector = useSelector((state) => state.filter);

  const [filterQuestion, setFilterQuestion] = useState("");

  const debouncedQuestion = useDebounce(filterQuestion, 500);

  const selectedCategory =
    selector.ddqList.category?.map((i, index) => ({
      id: index,
      value: i,
    })) ?? [];

  const selectedResponseProvider = selector.ddqList.responseProvider;

  return (
    <PageWhite>
      <div className="mb-[20px] flex justify-between items-center">
        <HeadText>{ADMIN.listOfDDQ}</HeadText>
        <div className="flex gap-4 items-center">
          <div className="w-[250px]">
            <TextField
              placeholder={"Search Question"}
              value={filterQuestion}
              onChange={(e) => setFilterQuestion(e.target.value)}
              endAdornment={
                <SearchIcon
                  width={18}
                  height={18}
                  color={COLORS.secondaryLight}
                />
              }
            />
          </div>
          <div className="w-[250px]">
            <CategoryDropDown
              placeholder="Filter Category"
              callBack={(value) => {
                dispatch(actions.setDDQCategory(value));
              }}
              isReturnId={false}
              value={selectedCategory}
              isDefaultValueValidated
            />
          </div>
          <div className="w-[250px]">
            <Select
              innerLabel={"Filter Response Provider"}
              values={[
                { id: ALL.id, value: ALL.text },
                { id: RESPONSE_PROVIDER.gpt, value: RESPONSE_PROVIDER.gptText },
                {
                  id: RESPONSE_PROVIDER.searchLess,
                  value: RESPONSE_PROVIDER.searchLessText,
                },
              ]}
              defaultValue={[selectedResponseProvider]}
              onChange={(value) =>
                dispatch(actions.setDDQResponseProvider(value))
              }
              isReturnBoth
            />
          </div>

          <Button
            size="md"
            className="h-[40px] -mt-[4px]"
            onClick={() => navigate(pathList.admin.addDDQ)}
          >
            {ADMIN.addDDQ}
          </Button>

          <UploadCSV />
        </div>
      </div>
      <DDQManagement
        responseProvider={selectedResponseProvider?.id ?? ALL.id}
        category={selector.ddqList.category}
        question={debouncedQuestion}
      />
    </PageWhite>
  );
};
