import { useEffect, useState, useCallback } from "react";
import { Select } from "../../../../components/molecules/select/select-input";
import { validCategory } from "../../../../utils/validation-functions";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

export const CategoryDropDown = ({
  callBack,
  isSubmit = false,
  value = [],
  placeholder = "Select Category",
  isReset = false,
  isReturnId = true,
  isReturnBoth = false,
  id = "category-dropdown",
  isDefaultValueValidated = false,
  label = "",
  isLoading = false,
  className = "",
  isWrap = false,
}) => {
  const [category, setCategory] = useState([]);
  const [selectionCategory, setSelectionCategory] = useState([]);
  const [defaultValue, setDefaultValue] = useState([]);

  const categoryState = useSelector((state) => state.category);

  useEffect(() => {
    const activeCategories = categoryState.categoryDetails
      .filter((item) => item.isActive)
      .map((item) => ({ id: item.id, value: item.categoryName }));
    setSelectionCategory(activeCategories);
  }, [categoryState.categoryDetails]);

  const initializeDefaultValue = useCallback(() => {
    if (
      value.length &&
      value[0]?.id &&
      !defaultValue.length &&
      !isDefaultValueValidated
    ) {
      const newDefaultValues = value.map((item) => ({
        id: item?.value ?? item?.id ?? 0,
        value: item?.label ?? item?.categoryName ?? "",
      }));
      setDefaultValue(newDefaultValues);
      setCategory(newDefaultValues);
    } else if (isDefaultValueValidated) {
      setDefaultValue(value);
      setCategory(value);
    }
  }, [value, defaultValue.length, isDefaultValueValidated]);

  useEffect(() => {
    initializeDefaultValue();
  }, [initializeDefaultValue]);

  return (
    <Select
      label={label}
      className={className}
      id={id}
      isLoading={isLoading}
      defaultValue={defaultValue}
      innerLabel={placeholder}
      values={selectionCategory}
      isMulti
      onChange={(value, deletedItem) => {
        setCategory(value);
        callBack?.(value, deletedItem);
      }}
      errorText={isSubmit ? validCategory(category).message : ""}
      isReset={isReset}
      isReturnId={isReturnId}
      isReturnBoth={isReturnBoth}
      testId="category-dropdown"
      valueTestId="selected-category"
      isWrap={isWrap}
    />
  );
};

CategoryDropDown.propTypes = {
  callBack: PropTypes.func,
  isSubmit: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
  isReset: PropTypes.bool,
  isReturnId: PropTypes.bool,
  isReturnBoth: PropTypes.bool,
  id: PropTypes.string,
  isDefaultValueValidated: PropTypes.bool,
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
