import { Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MUIBanner as Banner } from "../banner/mui-banner";
import { CustomTheme } from "../../theme/custom-theme";
import { MESSAGE } from "../../../constant";

/**
 * Toast Component:
 * Displays a toast message using Snackbar from MUI with a customizable severity banner.
 *
 * Props:
 * - children: The content to be displayed inside the toast (React node).
 * - severity: Severity level of the toast message (success, warning, error, info).
 * - onClose: Function to handle closing the toast.
 *
 * Example Usage:
 * ```
 * <Toast severity="success" onClose={handleClose}>
 *   Successfully saved changes.
 * </Toast>
 * ```
 */
export const Toast = ({
  children,
  onClose,
  severity = "success",
  autoHideDuration = MESSAGE.autoHideDuration,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <CustomTheme>
      <div className="w-full">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={visible}
          autoHideDuration={autoHideDuration}
          onClose={handleClose}
          key={"top-center"}
        >
          <div className="w-full">
            <Banner
              severity={severity}
              className={"p-4"}
              label={"close"}
              onClose={onClose}
            >
              {children}
            </Banner>
          </div>
        </Snackbar>
      </div>
    </CustomTheme>
  );
};

// PropTypes for Toast component
Toast.propTypes = {
  children: PropTypes.node, // The content to be displayed inside the toast
  severity: PropTypes.oneOf(["success", "warning", "error", "info"]), // Severity level of the toast message
  onClose: PropTypes.func, // Function to handle closing the toast
};

export default Toast;
