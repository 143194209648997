import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const SupportReqIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.4402 19.9701H17.5598V16.1495H16.4402V19.9701ZM16.9957 15.1555C17.1461 15.1555 17.2778 15.0995 17.3906 14.9875C17.5034 14.8756 17.5598 14.745 17.5598 14.5957C17.5598 14.4424 17.5038 14.3108 17.3919 14.2009C17.2799 14.0909 17.1493 14.0359 17 14.0359C16.8467 14.0359 16.7151 14.0919 16.6052 14.2038C16.4952 14.3158 16.4402 14.4464 16.4402 14.5957C16.4402 14.745 16.4952 14.8756 16.6052 14.9875C16.7151 15.0995 16.8453 15.1555 16.9957 15.1555ZM7 14.018H10.6631C10.8544 13.6187 11.0782 13.2447 11.3346 12.896C11.5911 12.5473 11.8784 12.2247 12.1967 11.9283H7V14.018ZM7 18.018H10.075C10.021 17.6727 9.99402 17.3254 9.99402 16.9761C9.99402 16.6268 10.023 16.2776 10.081 15.9283H7V18.018ZM5.07175 22.2033C4.44203 22.2033 3.90543 21.9816 3.46195 21.5381C3.01847 21.0946 2.79672 20.558 2.79672 19.9283V4.07178C2.79672 3.44206 3.01847 2.90546 3.46195 2.46198C3.90543 2.01849 4.44203 1.79675 5.07175 1.79675H13.0837L19.2033 7.91633V10.3778C18.8428 10.2459 18.4719 10.1489 18.0908 10.087C17.7096 10.025 17.3221 9.99804 16.9283 10.006V9.07178H11.9282V4.07178H5.07175V19.9283H10.6391C10.8464 20.3754 11.0947 20.7895 11.3842 21.1707C11.6737 21.5518 12.0024 21.8961 12.3701 22.2033H5.07175ZM17 12C18.3833 12 19.5625 12.4875 20.5375 13.4625C21.5125 14.4375 22 15.6167 22 17C22 18.3834 21.5125 19.5625 20.5375 20.5375C19.5625 21.5125 18.3833 22 17 22C15.6167 22 14.4375 21.5125 13.4625 20.5375C12.4875 19.5625 12 18.3834 12 17C12 15.6167 12.4875 14.4375 13.4625 13.4625C14.4375 12.4875 15.6167 12 17 12Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

SupportReqIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
