import { utils } from "xlsx";
import save from "file-saver";

export const exportFilesToCSV = ({ data, fileName }) => {
  Object.keys(data).forEach((keyName) => {
    const csvData = data[keyName];
    if (csvData?.length > 0) {
      const workSheet = utils.json_to_sheet(csvData);
      const csvOutput = utils.sheet_to_csv(workSheet);
      const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
      save(
        blob,
        `${fileName}_${keyName === "s4l" ? "search4less" : keyName}_response.csv`
      );
    }
  });
};
