import {
  DOWNLOAD_TYPE,
  EMAIL_REGEX,
  IRISH_MOBILES,
  NUMBER_REGEX,
  PASSWORD_REGEX,
  RESPONSE_PROVIDER,
} from "../constant";

export const validName = (name) => {
  if (!name) return { status: false, message: "Name is required." };

  return { status: true };
};

export const validEmail = (email) => {
  if (!email) return { status: false, message: "Email is required." };

  return EMAIL_REGEX.test(email)
    ? { status: true }
    : { status: false, message: "Invalid email." };
};

export const validPassword = (password) => {
  if (!password) return { status: false, message: "Password is required." };
  return PASSWORD_REGEX.test(password)
    ? { status: true }
    : {
        status: false,
        message:
          "Password must be 8+ characters with at least one uppercase, one lowercase, one number, and one special character.",
      };
};

export const validConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword)
    return { status: false, message: "Confirm Password is required." };

  return confirmPassword === password
    ? { status: true }
    : {
        status: false,
        message: "Confirm Password and Password does not match.",
      };
};

export const validPhone = (phone, isSubmit = true) => {
  if (isSubmit && !phone)
    return { status: false, message: "Phone number is required." };

  return phone === "" || IRISH_MOBILES.test(phone)
    ? { status: true }
    : {
        status: false,
        message:
          "Please enter a valid Irish phone number without spaces or brackets. No need to include the plus sign (+).",
      };
};

export const validUserData = ({
  email,
  firstName,
  lastName,
  password,
  confirmPassword,
  phoneNumber,
  isSubmit,
}) => {
  return (
    validEmail(email).status &&
    validName(firstName).status &&
    validName(lastName).status &&
    validPassword(password).status &&
    validConfirmPassword(password, confirmPassword).status &&
    validPhone(phoneNumber, isSubmit).status
  );
};

export const validLoginData = ({ email, password }) => {
  return validEmail(email).status && password !== "";
};

export const validCategoryName = (category) => {
  if (!category)
    return { status: false, message: "Category name is required." };
  else
    return {
      status: true,
    };
};

export const validCompanyName = (name) => {
  if (!name) {
    return { status: false, message: "Company name is required." };
  } else return { status: true };
};
export const validDescription = (description) => {
  if (!description) {
    return {
      status: false,
      message: "Description of the project is required.",
    };
  } else return { status: true };
};

export const validResponseProvider = ({ responseProvider }) => {
  if (!responseProvider.length)
    return {
      status: false,
      message: "Response provider is required. Please select any one.",
    };
  else return { status: true };
};

export const validCRONumber = (croNumber) => {
  if (!croNumber) {
    return {
      status: false,
      message: "CRO number is required.",
    };
  } else if (!NUMBER_REGEX.test(croNumber)) {
    return {
      status: false,
      message: "CRO number should contains digits.",
    };
  } else {
    return { status: true };
  }
};

export const validProjectData = ({
  name,
  description,
  croNumber,
  responseProvider,
}) => {
  return (
    validCompanyName(name).status &&
    validDescription(description).status &&
    validCRONumber(croNumber).status &&
    validResponseProvider({ responseProvider }).status
  );
};

export const validCategory = (category) => {
  if (category.length === 0) {
    return {
      status: false,
      message: "Category is required. Please select any one category.",
    };
  } else {
    return { status: true };
  }
};

export const validCategoryId = (categoryId) => {
  if (!categoryId)
    return {
      status: false,
      message: "Category is required. Please select any one category.",
    };
  else return { status: true };
};

export const validTitle = (title) => {
  if (!title) {
    return {
      status: false,
      message: "Title is required.",
    };
  } else {
    return { status: true };
  }
};

export const validPrompt = (prompt, title = "Prompt") => {
  if (!prompt) {
    return {
      status: false,
      message: `${title} is required`,
    };
  } else return { status: true };
};

export const validVariable = (variable) => {
  if (!variable) {
    return { status: false, message: "Variable is required." };
  } else return { status: true };
};

export const validQuestion = (question, name = "Question") => {
  if (!question) return { status: false, message: `${name} is required.` };
  else return { status: true };
};

export const validDDQ = ({
  responseProvider,
  question,
  query,
  category,
  summaryFilter,
  prompt,
  variable,
}) => {
  const isGptResponseProvider = responseProvider === RESPONSE_PROVIDER.gpt;
  const isSearchLessResponseProvider =
    responseProvider === RESPONSE_PROVIDER.searchLess;

  if (isGptResponseProvider) {
    return (
      validQuestion(question).status &&
      validQuestion(query).status &&
      validCategoryId(category).status &&
      validPrompt(prompt).status &&
      validPrompt(summaryFilter).status
    );
  } else if (isSearchLessResponseProvider) {
    return (
      validQuestion(question).status &&
      validCategoryId(category).status &&
      validVariable(variable).status
    );
  } else return false;
};

export const validFile = (file) => {
  if (!file) {
    return { status: false, message: "Please upload file." };
  }
  return { status: true };
};

export const validType = (type) => {
  if (!type) {
    return { status: false, message: "Type is required." };
  }
  return { status: true };
};

export const validDocumentId = (id) => {
  if (!id) {
    return { status: false, message: "Document id is required." };
  } else if (!NUMBER_REGEX.test(id)) {
    return {
      status: false,
      message: "Document id should contains digits only.",
    };
  } else return { status: true };
};

export const validDocumentDescription = (description) => {
  if (!description)
    return { status: false, message: "Document type description is required." };
  else return { status: true };
};

export const validDocumentDownloadData = ({
  request,
  croNumber,
  type,
  name,
  documentId,
  documentDescription,
}) => {
  const validCommon =
    validCRONumber(croNumber).status && validType(type).status;
  if (request === DOWNLOAD_TYPE.details) {
    return validCommon && validName(name).status;
  } else if (request === DOWNLOAD_TYPE.document) {
    return (
      validCommon &&
      validDocumentId(documentId).status &&
      validDocumentDescription(documentDescription).status
    );
  } else {
    return false;
  }
};

export const validClient = ({ name, email, phone }) => {
  return (
    validName(name).status &&
    validEmail(email).status &&
    validPhone(phone).status
  );
};

export const validMessage = (message) => {
  if (!message) return { status: false, message: "Message is required." };
  else if (message?.length > 500)
    return {
      status: false,
      message: "Message should contain below 500 characters.",
    };
  else return { status: true };
};

export const validSupportDetails = ({
  fullName,
  email,
  phoneNumber,
  message,
}) => {
  return (
    validName(fullName).status &&
    validEmail(email).status &&
    validPhone(phoneNumber).status &&
    validMessage(message).status
  );
};

export const validComment = (comment) => {
  if (!comment) return { status: false, message: "Comment is required." };
  else return { status: true };
};
