import { useState } from "react";
import { SkeletonRect } from "../../atoms/loader/skeleton";
import PropTypes from "prop-types";

export const ImageWithSkeleton = ({ src, alt, width, height, className }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className="relative">
      {!loaded && (
        <SkeletonRect width={width} height={height} className="rounded-lg" />
      )}
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={`rounded-lg ${loaded ? "block" : "hidden"} ${className}`}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

ImageWithSkeleton.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};
