import { createSlice } from "@reduxjs/toolkit";
import { fetchDdqQuestionById, fetchDdqQuestions } from "../../action";
import { convertKeysToCamelCase } from "../../utils/camelize";

const initialState = {
  selected: {
    id: null,
    question: null,
    category: { label: null, value: null },
    responseProvider: null,
    gptPrompt: {
      id: null,
      prompt: null,
      summaryFilter: null,
      gptQuery: null,
    },
    variable: { id: null, variable: null },
    additionalDetail: null,
  },
  ddqDetails: [
    // {
    //   id: 1,
    //   question: "New gpt",
    //   categories: {id : "", value :""},
    //   responseProvider: RESPONSE_PROVIDER.gpt,
    // },
  ],
};

const NAME = "DDQ";
const ddqReducer = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetSelectedState(state) {
      state.selected = initialState.selected;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDdqQuestions.fulfilled, (state, { payload }) => {
      state.ddqDetails =
        convertKeysToCamelCase(payload.data) ?? initialState.ddqDetails;
    });
    builder.addCase(fetchDdqQuestionById.fulfilled, (state, { payload }) => {
      state.selected =
        convertKeysToCamelCase(payload.data) ?? initialState.editState;
    });
  },
});

export const { actions } = ddqReducer;

export default ddqReducer.reducer;
