import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const DocumentIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.21484 17.75H16.7148V16.25H9.21484V17.75ZM9.21484 13.75H16.7148V12.25H9.21484V13.75ZM7.27254 21.5C6.76741 21.5 6.33984 21.325 5.98984 20.975C5.63984 20.625 5.46484 20.1974 5.46484 19.6923V4.3077C5.46484 3.80257 5.63984 3.375 5.98984 3.025C6.33984 2.675 6.76741 2.5 7.27254 2.5H15.2148L20.4648 7.74995V19.6923C20.4648 20.1974 20.2898 20.625 19.9398 20.975C19.5898 21.325 19.1622 21.5 18.6571 21.5H7.27254ZM14.4648 8.49995V3.99998H7.27254C7.19561 3.99998 7.12509 4.03203 7.06097 4.09613C6.99687 4.16024 6.96482 4.23077 6.96482 4.3077V19.6923C6.96482 19.7692 6.99687 19.8397 7.06097 19.9038C7.12509 19.9679 7.19561 20 7.27254 20H18.6571C18.734 20 18.8046 19.9679 18.8687 19.9038C18.9328 19.8397 18.9648 19.7692 18.9648 19.6923V8.49995H14.4648Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

DocumentIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
