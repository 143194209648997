import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbs } from "../../components/atoms/bread-crumbs";
import { DelayResponseBody } from "../../components/organism/body/loading-responses";
import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import {
  GENERAL,
  PAGE_SIZE,
  PROJECTS,
  RESPONSE_PROVIDER,
} from "../../constant";
import { pathList } from "../../routes/app-navigation";
import { DDQResponse } from "../features/ddq-response";
import { useCallback, useEffect, useState } from "react";
import {
  exportToExcel,
  fetchDDQResponse,
  fetchProjectById,
} from "../../action";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomPagination } from "../../components/molecules/pagination/page-number";
import { SmallText } from "../../components/typography";
import { usePagination } from "../../hooks/use-pagination";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";
import debounce from "lodash/debounce";
import { DDQResponsePreviewDialog } from "../features/ddq-response/ddq-response-preview-dialog";
import { EyeIcon, RefreshIcon, UploadIcon } from "../../components/icons";
import { COLORS } from "../../configuration";
import { actions } from "../slice/ddq-response";
import Select from "../../components/molecules/select/select-input";
import { ToolTip } from "../../components/atoms/tooltip";

export const DDQResponsePage = () => {
  const view = useSelector((state) => state.view);

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showResponsePreview, setShowResponsePreview] = useState(false);
  const [projectName, setProjectName] = useState("");

  const ddqResponseState = useSelector((state) => state.ddqResponse);
  const projectState = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    let name =
      projectState.selectedProject?.name ?? location.state?.projectName ?? "";

    setProjectName(name);
  }, [location, projectState.selectedProject]);

  const fetchResponsesInitial = (categories = selectedCategory) => {
    setCurrentPageIndex(1);
    dispatch(
      fetchDDQResponse({
        id,
        startIndex: 1,
        endIndex: PAGE_SIZE,
        type: "add",
        categoryId: categories,
        isInitialFetch: true,
      })
    );
  };

  const fetchResponses = (table) => {
    if (
      id &&
      ddqResponseState.nextIndex !== undefined &&
      ddqResponseState.nextIndex <= ddqResponseState.totalResponses
    ) {
      dispatch(
        fetchDDQResponse({
          id,
          startIndex: ddqResponseState.nextIndex,
          endIndex: ddqResponseState.nextIndex + PAGE_SIZE - 1,
          type: "add",
          categoryId: selectedCategory,
        })
      ).then(() => {
        if (table) table?.lastPage();
      });
    }
  };

  // Fetch responses for pagination
  useEffect(() => {
    fetchResponses();
    dispatch(fetchProjectById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { totalPages, currentPageData, currentPageInformation, pageSize } =
    usePagination({
      totalRecords: ddqResponseState.totalResponses,
      data: ddqResponseState.responses,
      currentPageIndex,
    });

  // Debounced fetch responses function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchResponsesInitial = useCallback(
    debounce((categories) => {
      fetchResponsesInitial(categories);
      setCurrentPageIndex(1);
    }, 1000),
    []
  );

  const handleCategoryChange = (value) => {
    setSelectedCategory([...value]);
    dispatch(actions.setSelectedCategories([...value]));
    debouncedFetchResponsesInitial([...value]);
  };

  const handleRegenerate = () => {
    fetchResponsesInitial(selectedCategory);
  };

  const handleExport = () => {
    dispatch(
      exportToExcel({
        id,
        projectName: projectName?.replace(/\s+/g, "_") ?? "",
        responseProvider: projectState.selectedProject?.responseProvider,
        categories: projectState.selectedProject?.categories?.map(
          (i) => i.value
        ),
      })
    );
  };

  return (
    <div>
      <PageHeader
        title={"DDQ Response"}
        backTo={
          <Link
            className="no-underline text-secondary"
            to={pathList.user.projectList}
          >
            {PROJECTS.backToProjects}
          </Link>
        }
      >
        <BreadCrumbs
          data={[
            {
              name: PROJECTS.allProjects,
              link: pathList.user.projectList,
            },
            {
              name: projectName,
              data: { projectName },
            },
          ]}
          className="gap-2"
        />
      </PageHeader>
      <PageBody>
        <div className="flex justify-between gap-4 items-center mb-[20px]">
          <div className="w-[220px]">
            <Select
              onChange={handleCategoryChange}
              isLoading={view.ddqResponse.pending || view.project.pending}
              values={
                projectState.selectedProject?.categories?.map((i) => ({
                  id: i?.value ?? 0,
                  value: i?.label ?? "",
                })) ?? []
              }
              innerLabel="Filter Category"
              isReturnId
              isMulti
            />
          </div>
          <div className="flex gap-2">
            <ToolTip text={PROJECTS.refreshMessage}>
              <Button
                startIcon={
                  <RefreshIcon color={COLORS.primary} strokeWidth={1} />
                }
                variant="text"
                size="md"
                onClick={() => {
                  fetchResponsesInitial();
                }}
                isPending={view.ddqResponse.pending}
              >
                {GENERAL.refresh}
              </Button>
            </ToolTip>
            <Button
              startIcon={<EyeIcon color={COLORS.primary} />}
              variant="text"
              size="md"
              onClick={() => setShowResponsePreview(true)}
              isPending={view.ddqResponse.pending}
            >
              {PROJECTS.showAllResponses}
            </Button>
            <Button
              startIcon={
                <UploadIcon
                  strokeWidth={5}
                  color={COLORS.primary}
                  width={16}
                  height={16}
                />
              }
              isLoading={view.exportExcel.pending}
              onClick={() => handleExport()}
              variant="text"
              size="md"
              isPending={view.ddqResponse.pending}
            >
              {PROJECTS.exportToExcel}
            </Button>
          </div>
        </div>
        <DelayResponseBody isLoading={view.ddqResponse.pending}>
          <DDQResponse
            currentPageData={currentPageData}
            handleRegenerate={handleRegenerate}
            pageNumber={currentPageIndex}
            pageSize={pageSize}
          />
        </DelayResponseBody>
        <div className="flex justify-between w-full items-center mb-[12px]">
          <SmallText>{currentPageInformation}</SmallText>
          <div>
            <CustomPagination
              onChange={(value) => {
                if (ddqResponseState.nextIndex <= value * pageSize) {
                  fetchResponses();
                }
                setCurrentPageIndex(value);
              }}
              currentPage={currentPageIndex}
              totalPage={totalPages}
            />
          </div>
        </div>
        {showResponsePreview && (
          <DDQResponsePreviewDialog
            onClose={() => setShowResponsePreview(false)}
            onloadMoreData={(table) => fetchResponses(table)}
          />
        )}
      </PageBody>
    </div>
  );
};
