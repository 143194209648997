import { RightIcon } from "../../../components/icons";
import { COLORS } from "../../../configuration";
import { PAGE_SIZE, PROJECTS, RESPONSE_PROVIDER } from "../../../constant";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import PropTypes from "prop-types";
import { DDQAnswerDrawer } from "./ddq-response-answer-dialog";
import { NormalText } from "../../../components/typography";
import { DdqGptResponseTable } from "./ddq-gpt-response-table";
import { useState } from "react";
import { DdqSlResponse } from "./ddq-sl-response";

export const DDQResponse = ({
  currentPageData = [],
  handleRegenerate,
  pageNumber = 0,
  pageSize = PAGE_SIZE,
}) => {
  const [showAnswerDrawer, setShowAnswerDrawer] = useState(false);
  const [responseData, setResponseData] = useState({
    id: null,
    category: null,
    responseProvider: null,
    question: null,
    tableData: [],
    data: [],
  });

  return (
    <div className="mb-[20px]">
      <div className="flex flex-col gap-[8px] mb-[16px]">
        {currentPageData.length > 0 ? (
          currentPageData.map(
            (i, index) =>
              i?.id && (
                <Button
                  key={i.id}
                  className="flex items-center justify-between border border-solid text-secondary border-edge rounded-md px-[20px] py-[16px] "
                  variant="outlined"
                  size="md"
                  onClick={() => {
                    setShowAnswerDrawer(true);
                    setResponseData({
                      data: i?.response ?? "",
                      tableData: i?.responses ?? [],
                      responseProvider: i?.responseProvider ?? null,
                      question: i?.question ?? "",
                      category: i?.category ?? "",
                      id: i?.id ?? "",
                    });
                  }}
                >
                  <div className="flex gap-4 items-center">
                    <span className="text-base text-left normal-case">
                      {`${(pageNumber - 1) * pageSize + index + 1}.  ${i.question}`}
                    </span>
                  </div>
                  <div className="w-max h-max">
                    <RightIcon
                      color={COLORS.secondaryLight}
                      width={18}
                      height={18}
                    />
                  </div>
                </Button>
              )
          )
        ) : (
          <div className="border border-solid border-edge  text-center p-4 rounded-md ">
            <NormalText className="mb-[12px]">
              {PROJECTS.noDDQAvailable}
            </NormalText>
            <Button
              size="md"
              variant="outlined"
              onClick={() => {
                handleRegenerate?.();
              }}
            >
              {PROJECTS.regenerate}
            </Button>
          </div>
        )}
      </div>
      <DDQAnswerDrawer
        isOpen={showAnswerDrawer}
        question={responseData.question}
        onClose={() => setShowAnswerDrawer(false)}
        showFeedback={responseData.responseProvider === RESPONSE_PROVIDER.gpt}
        files={responseData.tableData.map((i, index) => ({
          id: index,
          fileName: i.fileName,
        }))}
        ddqId={responseData.id}
        categoryId={responseData.category}
      >
        {responseData?.responseProvider === RESPONSE_PROVIDER.gpt ? (
          <DdqGptResponseTable
            data={responseData.tableData}
            response={responseData.data}
          />
        ) : responseData.responseProvider === RESPONSE_PROVIDER.searchLess ? (
          <DdqSlResponse data={responseData.data} />
        ) : (
          <NormalText>No Response provider available</NormalText>
        )}
      </DDQAnswerDrawer>
    </div>
  );
};

DDQResponse.propTypes = {
  currentPageData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      question: PropTypes.string,
      category: PropTypes.number,
      responseProvider: PropTypes.number,
      color: PropTypes.number,
    })
  ),
};
