import { AppPath, pathList } from "./app-navigation";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import { LOGIN_KEY, USERS } from "../constant";
import { MUISpinner } from "../components/atoms/loader/spinner";

const AppNavigation = () => {
  const user = useSelector((state) => state.user);

    const openPath = [pathList.privacyPolicy, pathList.termsAndCondition];

  if (localStorage.getItem(LOGIN_KEY) && !user?.role) {
    return <MUISpinner size={42} thickness={4} text="Please wait..." />;
  }

  return (
    <Routes>
      <Route>
        {AppPath.unProtected.map((app) => (
          <Route
            path={app.path}
            key={app.name}
            element={
              user?.email && !openPath.includes(app.path) ? (
                <Navigate to={pathList.user.projectList} replace />
              ) : (
                app.page
              )
            }
          />
        ))}
      </Route>
      {user?.role === USERS.Admin || user?.role === USERS.Staff ? (
        <Route element={<PrivateRoute />}>
          {AppPath.protected.admin.map((app) => (
            <Route path={app.path} key={app.name} element={app.page} />
          ))}
          {AppPath.protected.user.map((app) => (
            <Route path={app.path} key={app.name} element={app.page} />
          ))}
        </Route>
      ) : (
        <Route element={<PrivateRoute />}>
          {user?.role === USERS.User &&
            AppPath.protected.user.map((app) => (
              <Route path={app.path} key={app.name} element={app.page} />
            ))}
        </Route>
      )}
      <Route>
        {AppPath.default.map((app) => (
          <Route path={app.path} element={app.page} key={app.name} />
        ))}
      </Route>
    </Routes>
  );
};

export default AppNavigation;
