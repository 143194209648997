import { useDispatch, useSelector } from "react-redux";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { DeleteIcon } from "../../../components/icons";
import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { HeadText, NormalText } from "../../../components/typography";
import { DELETE } from "../../../constant";
import { deleteProject } from "../../../action";
import PropTypes from "prop-types";
import { useEffect } from "react";

export const DeleteProject = ({ id, onClose }) => {
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.view);

  const handleClick = () => {
    try {
      dispatch(deleteProject({ id }));
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  useEffect(() => {
    if (viewState.deleteProject.success) {
      onClose?.();
    }
  }, [onClose, viewState.deleteProject]);

  return (
    <MUIDialog
      isOpen={true}
      onClose={() => onClose?.()}
      width="xs"
      content={
        <div className="flex flex-col">
          <div className="mx-auto w-max">
            <DeleteIcon height={40} width={40} />
          </div>
          <div className="w-full text-center mt-[14px]">
            <HeadText className={"text-primary font-normal"}>
              {DELETE.areYouSure}
            </HeadText>
            <div className="mt-[16px]">
              <NormalText>{DELETE.doYouReallyWant}</NormalText>
              <NormalText>{DELETE.thisItemCantBeRestored}</NormalText>
            </div>
            <div className="mt-[16px] flex flex-col">
              <Button
                size="md"
                onClick={handleClick}
                isLoading={viewState.deleteProject.pending}
              >
                {DELETE.yesDelete}
              </Button>
              <Button
                variant="text"
                size="md"
                className={"text-primary mt-[10px]"}
                onClick={() => onClose?.()} // Close dialog on "No" button click
              >
                {DELETE.noKeepAsItIs}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

DeleteProject.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};
