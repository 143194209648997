import PropTypes from "prop-types";
import { ICONS } from "../../../constant";
export const AddIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Add</title>
      <path
        d="M11.75 17H13.25V13H17.25V11.5H13.25V7.5H11.75V11.5H7.75V13H11.75V17ZM12.5016 21.75C11.1877 21.75 9.95268 21.5006 8.79655 21.002C7.6404 20.5033 6.63472 19.8265 5.7795 18.9717C4.92427 18.1169 4.24721 17.1116 3.74833 15.956C3.24944 14.8004 3 13.5656 3 12.2517C3 10.9377 3.24933 9.70268 3.748 8.54655C4.24667 7.3904 4.92342 6.38472 5.77825 5.5295C6.6331 4.67427 7.63834 3.99721 8.79398 3.49833C9.94959 2.99944 11.1844 2.75 12.4983 2.75C13.8122 2.75 15.0473 2.99933 16.2034 3.498C17.3596 3.99667 18.3652 4.67342 19.2205 5.52825C20.0757 6.3831 20.7527 7.38834 21.2516 8.54398C21.7505 9.69959 22 10.9344 22 12.2483C22 13.5622 21.7506 14.7973 21.252 15.9534C20.7533 17.1096 20.0765 18.1152 19.2217 18.9705C18.3669 19.8257 17.3616 20.5027 16.206 21.0016C15.0504 21.5005 13.8156 21.75 12.5016 21.75ZM12.5 20.25C14.7333 20.25 16.625 19.475 18.175 17.925C19.725 16.375 20.5 14.4833 20.5 12.25C20.5 10.0166 19.725 8.12498 18.175 6.57498C16.625 5.02498 14.7333 4.24998 12.5 4.24998C10.2666 4.24998 8.37498 5.02498 6.82498 6.57498C5.27498 8.12498 4.49998 10.0166 4.49998 12.25C4.49998 14.4833 5.27498 16.375 6.82498 17.925C8.37498 19.475 10.2666 20.25 12.5 20.25Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

AddIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
