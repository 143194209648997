import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import {
  DDQ_RESPONSE_STATUS,
  FILTER_RESPONSE_PROVIDER_OPTION,
  FILTER_RESPONSE_STATUS_OPTIONS,
  PROJECTS,
  RESPONSE_PROVIDER,
  STATUS_TEXT,
} from "../constant";
import {
  DeleteIcon,
  DownFilledArrow,
  EditIcon,
  EyeIcon,
  FilterLinesIcon,
  UpFilledArrow,
} from "../components/icons";
import { MUIButton as Button } from "../components/atoms/button/mui-button";
import { Link } from "react-router-dom";
import { pathList } from "../routes/app-navigation";
import { useDispatch, useSelector } from "react-redux";
import { generateDDQResponse } from "../action";
import { actions as viewActions } from "../universal/slice/view";
import { actions as responseActions } from "../universal/slice/ddq-response";
import { Tag } from "../components/atoms/tags";
import { COLORS } from "../configuration";
import { ToggleMenu } from "../components/molecules/menu/toggle-menu";
import { MUICheckBox } from "../components/atoms/checkbox";
import { SmallText } from "../components/typography";
import { actions } from "../universal/slice/filters";

export const GPT_CHIP = (
  <Tag type="outlined" variant="success" text="sm">
    {RESPONSE_PROVIDER.gptText}
  </Tag>
);
export const SEARCH_LESS_CHIP = (
  <Tag type="outlined" variant="primary" text="sm">
    {RESPONSE_PROVIDER.searchLessText}
  </Tag>
);

const getResponseProvider = (provider) => {
  const GPT =
    provider.includes(RESPONSE_PROVIDER.gpt) ||
    provider.includes(RESPONSE_PROVIDER.gptText);
  const SEARCH =
    provider.includes(RESPONSE_PROVIDER.searchLess) ||
    provider.includes(RESPONSE_PROVIDER.searchLessText);

  return (
    <div className="flex gap-2">
      {GPT && GPT_CHIP}
      {SEARCH && SEARCH_LESS_CHIP}
      {!GPT && !SEARCH && <div>-</div>}
    </div>
  );
};

const getDDQStatus = ({ status, id, dispatch, isLoading, projectName }) => {
  const linkProps = {
    to: `${pathList.user.ddqResponse}${id}`,
    style: { textDecoration: "none" },
    state: { projectName },
  };

  switch (status) {
    case DDQ_RESPONSE_STATUS.GENERATE_STATUS:
      return (
        <Button
          size="sm"
          className="bg-primary-light"
          isLoading={isLoading}
          onClick={() => {
            dispatch(viewActions.setGeneratingResponseId(id));
            dispatch(generateDDQResponse({ id }));
          }}
        >
          {STATUS_TEXT.generateResponse}
        </Button>
      );

    case DDQ_RESPONSE_STATUS.ERROR_STATUS:
      return (
        <Link
          {...linkProps}
          onClick={() => dispatch(responseActions.resetDDQResponseStats(id))}
        >
          <Button className="bg-error">{STATUS_TEXT.error}</Button>
        </Link>
      );

    case DDQ_RESPONSE_STATUS.SUCCESS_STATUS:
      return (
        <Link
          {...linkProps}
          onClick={() => dispatch(responseActions.resetDDQResponseStats(id))}
        >
          <Button className="bg-warning text-black">
            {STATUS_TEXT.viewResponse}
          </Button>
        </Link>
      );

    default:
      return (
        <div className="bg-success-light text-success w-max text-sm p-2 rounded-md">
          {STATUS_TEXT.processing}
        </div>
      );
  }
};

const createFilterMenu = (filters, setFilters, options) =>
  options.map((option) => ({
    id: option.id,
    content: (
      <span className="text-sm flex items-center">
        <MUICheckBox
          id={option.id}
          checked={filters.includes(option.value)}
          onChange={(checked) =>
            setFilters(
              checked
                ? [...filters, option.value]
                : filters.filter((i) => i !== option.value)
            )
          }
        />
        <label htmlFor={option.id}>{option.label}</label>
      </span>
    ),
  }));

export const useProjectTable = () => {
  const viewState = useSelector((state) => state.view);
  const filters = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();

  const columnNames = useMemo(
    () => ({
      serialNo: "Sr. No.",
      id: "id",
      projectName: "Project Names",
      ddqResponseStatus: "DDQ responses",
      responseProvider: "Response provider",
      view: "View",
      edit: "Edit",
      delete: "Delete",
    }),
    []
  );

  const [sortingState, setSortingState] = useState([
    { id: columnNames.id, desc: true },
  ]);
  const [dialog, setDialog] = useState({ id: null, isDelete: false });

  const filterProvider = useMemo(
    () => filters.projectList?.provider ?? [],
    [filters.projectList?.provider]
  );
  const filterResponseStatus = useMemo(
    () => filters.projectList?.status ?? [],
    [filters.projectList?.status]
  );

  const setFilterProvider = useCallback(
    (value) =>
      dispatch(actions.setProjectFilter({ data: value, type: "provider" })),
    [dispatch]
  );

  const setFilterResponseStatus = useCallback(
    (value) =>
      dispatch(actions.setProjectFilter({ data: value, type: "status" })),
    [dispatch]
  );

  const FilterProviderMenu = useMemo(
    () =>
      createFilterMenu(
        filterProvider,
        setFilterProvider,
        FILTER_RESPONSE_PROVIDER_OPTION
      ),
    [filterProvider, setFilterProvider]
  );

  const FilterResponseStatusMenu = useMemo(
    () =>
      createFilterMenu(
        filterResponseStatus,
        setFilterResponseStatus,
        FILTER_RESPONSE_STATUS_OPTIONS
      ),
    [filterResponseStatus, setFilterResponseStatus]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (props) => props.row.index + 1,
        enableSorting: true,
        sortingFn: "basic",
        header: (
          <span data-testid="category-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                const newState = [...sortingState];
                newState[0].desc = !newState[0].desc;
                setSortingState(newState);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("projectName", {
        id: "projectName",
        cell: (props) => props.getValue(),
        header: columnNames.projectName,
        filterFn: "nameFilter",
      }),
      columnHelper.accessor(
        (row) => ({
          status: row.ddqResponseStatus,
          id: row.id,
          projectName: row.projectName,
        }),
        {
          id: "ddqResponseStatus",
          filterFn: "responseFilter",
          cell: (props) =>
            getDDQStatus({
              status: props.getValue().status,
              id: props.getValue().id,
              dispatch,
              isLoading:
                viewState.generateResponse.id === props.getValue().id &&
                viewState.generateResponse.pending,
              projectName: props.getValue().projectName,
            }),
          header: (
            <div className="flex items-center gap-2">
              {columnNames.ddqResponseStatus}
              <ToggleMenu
                dense
                menus={FilterResponseStatusMenu}
                showDownIcon={false}
                closeOnClick={false}
                title={
                  <SmallText className="px-4">{PROJECTS.filter}</SmallText>
                }
              >
                {!!filterResponseStatus.length && (
                  <Tag text="xs" className="absolute -right-2 top-0">
                    {filterResponseStatus.length}
                  </Tag>
                )}
                <FilterLinesIcon strokeWidth={0.2} width={14} height={14} />
              </ToggleMenu>
            </div>
          ),
        }
      ),
      columnHelper.accessor("responseProvider", {
        id: "responseProvider",
        cell: (props) => getResponseProvider(props.getValue()),
        filterFn: "providerFilter",
        header: (
          <div className="flex items-center gap-2">
            {columnNames.responseProvider}
            <ToggleMenu
              dense
              menus={FilterProviderMenu}
              showDownIcon={false}
              closeOnClick={false}
              title={<SmallText className="px-4">{PROJECTS.filter}</SmallText>}
            >
              {!!filterProvider.length && (
                <Tag text="xs" className="absolute -right-2 top-0">
                  {filterProvider.length}
                </Tag>
              )}
              <FilterLinesIcon strokeWidth={0.2} width={14} height={14} />
            </ToggleMenu>
          </div>
        ),
      }),
      columnHelper.accessor("id", {
        id: "view",
        cell: (props) => {
          const id = JSON.stringify(props.getValue());
          const path = `${pathList.user.projects}/${id}`;
          return (
            <Link to={path}>
              <Button variant="text">
                <EyeIcon strokeWidth={1.5} />
              </Button>
            </Link>
          );
        },
        header: columnNames.view,
      }),
      columnHelper.accessor("id", {
        id: "edit",
        cell: (props) => {
          const id = JSON.stringify(props.getValue());
          const path = `${pathList.user.projects}/${id}${pathList.user.edit}`;
          return (
            <Link to={path}>
              <Button variant="text">
                <EditIcon strokeWidth={0.5} />
              </Button>
            </Link>
          );
        },
        header: columnNames.edit,
      }),
      columnHelper.accessor("id", {
        id: "delete",
        cell: (props) => (
          <Button
            onClick={() => {
              dispatch(viewActions.resetDeleteProjectStatus());
              setDialog({ id: props.getValue(), isDelete: true });
            }}
            variant="text"
          >
            <DeleteIcon color={COLORS.error} strokeWidth={3} />
          </Button>
        ),
        header: columnNames.delete,
      }),
    ],
    [
      FilterProviderMenu,
      FilterResponseStatusMenu,
      columnHelper,
      columnNames,
      dispatch,
      sortingState,
      viewState.generateResponse,
      filterProvider,
      filterResponseStatus,
    ]
  );

  return {
    columns,
    columnNames,
    sorting: sortingState,
    setSortingState,
    dialog,
    setDialog,
    filterProvider,
    filterResponseStatus,
    setFilterProvider,
    setFilterResponseStatus,
  };
};
