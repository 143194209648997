import { useState } from "react";
import { TextField } from "../../../components/atoms/input/text-field";
import { PageWhite } from "../../../components/organism/body/page-white";
import { HeadText } from "../../../components/typography";
import { ADMIN, ALL, QUERY_STATUS } from "../../../constant";
import { SupportRequest } from "../../features/admin/support-request";
import { useDebounce } from "../../../hooks/use-debounce";
import { SearchIcon } from "../../../components/icons";
import { COLORS } from "../../../configuration";
import { Select } from "../../../components/molecules/select/select-input";

export const SupportRequestPage = () => {
  const [searchClient, setSearchClient] = useState(null);
  const [filterStatus, setFilterStatus] = useState(ALL.id);

  const debouncedSearchClient = useDebounce(searchClient, 500);
  return (
    <PageWhite>
      <div className="mb-[20px] flex justify-between items-center">
        <HeadText>{ADMIN.supportRequest}</HeadText>
        <div className="flex gap-4 items-center">
          <div className="w-[250px]">
            <TextField
              placeholder={"Search Name"}
              onChange={(e) => setSearchClient(e.target.value)}
              endAdornment={
                <SearchIcon
                  width={18}
                  height={18}
                  color={COLORS.secondaryLight}
                />
              }
            />
          </div>
          <div className="w-[250px]">
            <Select
              innerLabel={"Filter Status"}
              values={[
                { id: ALL.id, value: ALL.text },
                { id: QUERY_STATUS.pendingId, value: QUERY_STATUS.pending },
                { id: QUERY_STATUS.resolvedId, value: QUERY_STATUS.resolved },
              ]}
              defaultValue={[{ id: ALL.id, value: ALL.text }]}
              onChange={(value) => setFilterStatus(value)}
            />
          </div>
        </div>
      </div>
      <div>
        <SupportRequest
          filterClient={debouncedSearchClient}
          filterStatus={filterStatus}
        />
      </div>
    </PageWhite>
  );
};
