import { createSlice } from "@reduxjs/toolkit";
import { fetchSupportDetails, fetchSupportDetailsById } from "../../action";

const initialState = {
  selected: {},
  contactDetails: [],
};

const NAME = "contact";

const contactSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchSupportDetails.fulfilled, (state, { payload }) => {
      state.contactDetails = payload.data;
    });
    builder.addCase(fetchSupportDetailsById.fulfilled, (state, { payload }) => {
      state.selected = payload.data;
    });
  },
});

export const { actions } = contactSlice;

export default contactSlice.reducer;
