import { CustomTheme } from "../../theme/custom-theme";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { CloseIcon } from "../../icons";
import { MUIButton } from "../../atoms/button/mui-button";

/**
 * MUIDialog Component:
 * A customizable dialog component using Material-UI Dialog.
 *
 * Props:
 * - controlButton: Control button to trigger opening the dialog (React node).
 * - title: Title of the dialog (React node).
 * - content: Content inside the dialog (React node).
 * - footer: Footer content of the dialog (React node).
 * - isOpen: Whether the dialog is open or closed (boolean).
 * - onClose: Function to handle closing the dialog.
 * - preventEscClose: Whether to prevent closing the dialog on "Esc" key press (boolean).
 *
 * Example Usage:
 * ```
 * <MUIDialog
 *   title="Dialog Title"
 *   content={<div>Dialog Content</div>}
 *   isOpen={isOpen}
 *   footer={<div> Footer</div>}
 *   onClose={handleClose}
 *   preventEscClose={true}
 * />
 * ```
 */
export const MUIDialog = ({
  controlButton,
  title,
  content,
  footer,
  onClose,
  isOpen = false,
  width = "sm",
  disableEscapeKeyDown = false,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <CustomTheme>
      <div>
        {!isOpen &&
          controlButton &&
          React.cloneElement(controlButton, { onClick: handleClickOpen })}
        <Dialog
          open={open}
          disableEscapeKeyDown={disableEscapeKeyDown}
          onClose={(event, reason) => {
            if (reason && reason === "backdropClick") return;
            handleClose();
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
          maxWidth={width}
          fullWidth
        >
          <DialogTitle
            id="dialog-title"
            className="relative flex justify-between items-center min-w-[240px]"
          >
            <div>{title} </div>
            {onClose && (
              <MUIButton
                className="cursor-pointer"
                onClick={() => handleClose()}
                variant="text"
                color="error"
                size="sm"
                data-testid="close-button"
              >
                <CloseIcon width={16} height={16} />
              </MUIButton>
            )}
          </DialogTitle>
          <DialogContent
            id="dialog-description"
            className="px-[60px] py-[20px]"
          >
            {content}
          </DialogContent>
          <DialogActions id="dialog-footer">{footer}</DialogActions>
        </Dialog>
      </div>
    </CustomTheme>
  );
};

// PropTypes for MUIDialog component
MUIDialog.propTypes = {
  controlButton: PropTypes.node, // Control button to trigger opening the dialog
  title: PropTypes.node, // Title of the dialog
  content: PropTypes.node, // Content inside the dialog
  footer: PropTypes.node, // Footer content of the dialog
  isOpen: PropTypes.bool, // Whether the dialog is open or closed
  onClose: PropTypes.func, // Function to handle closing the dialog
};

export default MUIDialog;
