import PropTypes from "prop-types";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { COLORS } from "../../configuration";

const rootElement = document.getElementById("root");

// Create a custom theme using createTheme
const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiModal: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: { main: COLORS.primary },
    secondary: { main: COLORS.secondary },
    error: { main: COLORS.error },
    warning: { main: COLORS.warning },
    success: { main: COLORS.success },
    edge: { main: COLORS.edgeLight },
    white: { main: COLORS.white },
  },

  typography: {
    fontFamily: "Roboto",
  },
});

// CustomTheme is a component that applies the custom theme to its children
export const CustomTheme = ({ children }) => {
  // Wrap the children in ThemeProvider and apply the custom theme
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

CustomTheme.propTypes = {
  // `children` is required and should be a valid React node
  children: PropTypes.node.isRequired,
};
