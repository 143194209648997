import { PageWhite } from "../../../components/organism/body/page-white";
import { HeadText } from "../../../components/typography";
import { ADMIN } from "../../../constant";
import { GPTProcessing } from "../../features/admin/gpt-processing";

export const GPTProcessingPage = () => {
  return (
    <PageWhite>
      <div className="flex justify-between items-center">
        <HeadText testId="edit-ddq-title">{ADMIN.gptProcessing}</HeadText>
      </div>
      <div className="mt-[12px]">
        <GPTProcessing />
      </div>
    </PageWhite>
  );
};
