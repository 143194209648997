import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbs } from "../../components/atoms/bread-crumbs";
import { PageHeader } from "../../components/organism/header/page-header";
import { PageBody } from "../../components/organism/body/page-body";
import {
  fetchDDQResponseSelectedList,
  saveAndProcessResponse,
} from "../../action";
import { DDQResponseFilter } from "../features/ddq-response/ddq-resposne-filter";
import { GENERAL, PROJECTS, SEVERITY } from "../../constant";
import { pathList } from "../../routes/app-navigation";
import { usePagination } from "../../hooks/use-pagination";
import { DelayPageBody } from "../../components/organism/body/loading-body";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";
import { SmallText } from "../../components/typography";
import { CustomPagination } from "../../components/molecules/pagination/page-number";

export const DDQResponseFilterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { documentName, projectName, categoryId } = location.state || {};
  const { id, questionId, documentId } = useParams();

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedChunkId, setSelectedChunkId] = useState(null);

  const fetchSelectedResponse = useCallback(() => {
    dispatch(fetchDDQResponseSelectedList({ id, questionId, documentName }));
  }, [dispatch, id, questionId, documentName]);

  useEffect(() => {
    fetchSelectedResponse();
  }, [fetchSelectedResponse]);

  const selectedResponseState = useSelector(
    (state) => state.ddqSelectedResponse
  );
  const view = useSelector((state) => state.view);

  const { currentPageData, currentPageInformation, totalPages } = usePagination(
    {
      data: selectedResponseState.responses,
      totalRecords: selectedResponseState.totalChunks,
      currentPageIndex,
    }
  );

  const handleSave = () => {
    dispatch(saveAndProcessResponse({ selectedChunkId })).then((response) => {
      if (response.payload.status === SEVERITY.Success)
        navigate(`/ddq_response/list/${id}/`, { state: { projectName } });
    });
  };

  return (
    <div className="mb-[20px]">
      <PageHeader
        title="DDQ Response Filter"
        backTo={
          <Link
            className="no-underline text-secondary"
            to={`/ddq_response/list/${id}/${questionId}/${documentId}/select/`}
            state={{ documentName, projectName, categoryId }}
          >
            {PROJECTS.backToSelection}
          </Link>
        }
      >
        <BreadCrumbs
          data={[
            {
              name: PROJECTS.allProjects,
              link: pathList.user.projectList,
            },
            {
              name: "Radix",
              link: `/ddq_response/list/${id}/`,
              data: { projectName },
            },
            {
              name: "document",
              link: `/ddq_response/list/${id}/${questionId}/pdf`,
              data: { projectName, categoryId },
            },
            {
              name: <span>Selection</span>,
              link: `/ddq_response/list/${id}/${questionId}/${documentId}/select/`,
              data: { documentName, projectName, categoryId },
            },
            { name: <span>Filter</span> },
          ]}
        />
      </PageHeader>
      <PageBody>
        <DelayPageBody>
          <DDQResponseFilter
            data={currentPageData}
            callback={(id) => setSelectedChunkId(id)}
          />
        </DelayPageBody>
        <div className="flex justify-between items-center mb-[20px]">
          <Button
            size="md"
            onClick={handleSave}
            isLoading={view.ddqSave.pending}
          >
            {GENERAL.saveAndProcess}
          </Button>
          <div className="flex items-center gap-4">
            <SmallText>{currentPageInformation}</SmallText>
            <CustomPagination
              onChange={(value) => {
                setCurrentPageIndex(value);
              }}
              currentPage={currentPageIndex}
              totalPage={totalPages}
            />
          </div>
        </div>
      </PageBody>
    </div>
  );
};
