import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import {
  HeadText,
  NormalText,
  SubHeadText,
} from "../../../../components/typography";
import { DELETE } from "../../../../constant";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { useDispatch, useSelector } from "react-redux";
import { deleteClient } from "../../../../action";

export const DeleteClientDialog = ({ id, onClose }) => {
  const view = useSelector((state) => state.view);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (id)
      dispatch(deleteClient({ id })).then(() => {
        onClose();
      });
  };

  return (
    <MUIDialog
      isOpen={true}
      width="xs"
      onClose={onClose}
      content={
        <div>
          <div className="w-full text-center mt-[14px]">
            <HeadText className={"text-primary"} testId="delete-dialog-title">
              {DELETE.areYouSure}
            </HeadText>
            <div className="mt-[16px] max-w-[320px] flex flex-col gap-2">
              <SubHeadText>{DELETE.doYouReallyWant}</SubHeadText>
              <NormalText>{DELETE.thisItemCantBe}</NormalText>
            </div>
            <div className="mt-[16px] flex flex-col">
              <Button
                size="md"
                onClick={handleClick}
                isLoading={view.clientDelete.pending}
                data-testid="delete-client-button"
              >
                {DELETE.yesDelete}
              </Button>
              <Button
                variant="text"
                size="md"
                className={"text-primary mt-[10px]"}
                onClick={onClose} // Close dialog on "No" button click
              >
                {DELETE.noKeepAsItIs}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};
