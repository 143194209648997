import { Link } from "react-router-dom";
import { LeftIcon } from "../../../components/icons";
import { HeadText, SmallText } from "../../../components/typography";
import { COLORS } from "../../../configuration";
import { ADMIN } from "../../../constant";
import { pathList } from "../../../routes/app-navigation";
import { AddDDQ } from "../../features/admin/ddq-management/add-ddq";
import { PageWhite } from "../../../components/organism/body/page-white";

export const AddDDQPage = () => {
  return (
    <PageWhite>
      <div className="flex justify-between items-center">
        <HeadText>{ADMIN.addDDQ}</HeadText>
        <Link to={pathList.admin.ddqList} style={{ textDecoration: "none" }}>
          <div className="flex items-center gap-[4px] ">
            <LeftIcon width={18} height={18} color={COLORS.secondaryLight} />
            <SmallText className={"cursor-pointer"}>
              {ADMIN.backToAllQuestions}
            </SmallText>
          </div>
        </Link>
      </div>
      <div className="mt-[10px] ">
        <AddDDQ />
      </div>
    </PageWhite>
  );
};
