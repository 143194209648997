import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { DownArrow, LinkIcon, UpArrow } from "../components/icons";
import { MUIButton } from "../components/atoms/button/mui-button";

export const useResponsePreview = () => {
  const columnNames = useMemo(
    () => ({
      id: "id",
      serialNo: "Sr. No.",
      question: "Question",
      response: "Response",
      vdrReference: "VDR Reference",
    }),
    []
  );

  const columnHelper = createColumnHelper();

  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [expanded, setExpanded] = useState({});

  const columns = useMemo(
    () => [
      columnHelper.accessor("srNo", {
        id: "srNo",
        cell: (props) => props.getValue(),
        header: columnNames.serialNo,
      }),
      columnHelper.accessor("question", {
        id: "question",
        cell: (props) => props.getValue(),
        header: columnNames.question,
      }),
      columnHelper.accessor("response", {
        id: "response",
        cell: (props) => props.getValue(),
        header: columnNames.response,
      }),
      columnHelper.accessor(
        (row) => ({
          fileName: row?.vdr,
          fileUrl: row?.fileUrl ?? "",
        }),
        {
          id: "vdr",
          cell: (props) => {
            const toggleExpandedHandler = props.row.getToggleExpandedHandler();
            return (
              <div className="flex justify-between items-center">
                {props.getValue().fileName ? (
                  <a
                    href={props.getValue()?.fileUrl ?? ""}
                    target="_blank"
                    rel="noreferrer"
                    className="flex gap-2"
                  >
                    <LinkIcon width={20} height={20} className="w-max h-max" />
                    <span className="w-[240px] break-all overflow-hidden ">
                      {props.getValue()?.fileName ?? ""}
                    </span>
                  </a>
                ) : (
                  ""
                )}
                {props.row.getCanExpand() && (
                  <MUIButton
                    variant="text"
                    onClick={toggleExpandedHandler}
                    className="w-max h-max"
                  >
                    {props.row.getIsExpanded() ? (
                      <UpArrow width={18} height={18} />
                    ) : (
                      <DownArrow />
                    )}
                  </MUIButton>
                )}
              </div>
            );
          },
          header: (
            <span className="w-max whitespace-nowrap">
              {columnNames.vdrReference}
            </span>
          ),
        }
      ),
    ],
    [columnHelper, columnNames]
  );

  return {
    columnNames,
    columns,
    sortingState,
    setSortingState,
    expanded,
    setExpanded,
  };
};
