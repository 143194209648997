import { COLORS } from "./configuration";

export const SECRET_KEY =
  process.env.REACT_APP_SECRET_KEY ?? "rfKKu6hZug5Mh4VPyWE7IQ==";

export const ICONS = {
  height: 22,
  width: 22,
  stroke: 2,
  color: COLORS.secondaryLight,
};

export const TEXT = {
  fontSize: 12,
};

export const PAGING = {
  previousPage: "Previous Page",
  page: "Page",
  of: "of",
  nextPage: "Next Page",
  lastPageNumber: 1,
};

export const MESSAGE = {
  autoHideDuration: 5000,
};

export const GREETING = "Welcome";

export const ALL_RIGHTS_RESERVED = "All rights reserved.";

export const COMPANY_ADDRESS = "2081 Maggio Island, Daremouth, TX 32806";

export const COMPANY_CALL = "+1 326-546-1123";

export const COMPANY_EMAIL = "john@simplifyteam.com";

export const CONTACT_MESSAGE =
  "If you are interested in more information on this service or are having problems using this site, Simplify can be contacted at";

export const SEVERITY = {
  Success: "success",
  Error: "error",
  Warning: "warning",
  Information: "info",
};

export const POSITION = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom",
};
export const SKELETON_TYPE = {
  rectangle: "rectangle",
  circle: "circle",
  text: "text",
};

export const DEMO_PARAGRAPH =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const ABOUT = {
  allAboutDDQResponse: "All about DDQ Responses",
  howToUse: "How to use this feature?",
  step1: "1. Upload Your PDF",
  step2: "2. DDQ Responses",
  step3: "3. Get your Document",
  ddqResponse: "DDQ Responses",
};

export const DIALOGS = {
  emailSent: "Email sent!",
  emailHasBeenSent: "Email has been sent on your email id.",
  pleaseVerifyYourEmail: "Please verify your email.",
  resendVerificationLink: "Resend verification link",
  pleaseUploadOnlyOne: "Please upload only one CSV file at a time",
};

export const GENERAL = {
  notification: "Notification",
  myProfile: "My Profile",
  logout: "Logout",
  goToUser: "Go to User",
  goToAdmin: "Go to Admin",
  cancel: "Cancel",
  reset: "Reset",
  clear: "Clear",
  resend: "Resend",
  add: "Add",
  create: "Create",
  update: "Update",
  dragAndDropYourFiles: "Drag and drop your file here or Click to upload.",
  or: "OR",
  browseFile: "Browse File",
  errorLog: "Error Logs",
  total: "Total",
  line: "Line",
  loading: "Loading...",
  edit: "Edit",
  show: "Show",
  save: "Save",
  showing: "Showing",
  records: "Record(s)",
  showRows: "Show Rows",
  okay: "Okay",
  name: "Name",
  email: "Email",
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  message: "Message",
  send: "Send",
  document: "Document(s)",
  ourPrivacyPolicy: "Our Privacy Policy",
  saveAndProcess: "Save and Process",
  notAvailable: "Not Applicable",
  noVDRAvailable: "No VDR Available",
  refresh: "Refresh",
};
export const PRIVACY_POLICY = `
At Simplify AI (https://simplifyai.ie/), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and any associated web pages (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.
`;

export const USERS = {
  User: "user",
  Staff: "staff",
  Admin: "admin",
};

export const LOGIN = {
  login: "Login",
  sayGoodBye: "Say Goodbye",
  toManualDocument: "to Manual Document",
  searchAndGet: "search and get DDQ responses within seconds.",
  forgotYourPassword: "Forgot your password?",
  forgotPassword: "Forgot Password?",
  createANewAccount: "Create a New Account",
  signUp: "Sign Up",
  iAgreeTo: "I agree to",
  termsOfService: "Terms of Service",
  and: "and",
  privacyPolicy: "Privacy Policy.",
  alreadyRegistered: "Already Registered?",
  enterYourEmailAddress:
    "Enter your email address and we will send you the reset password link.",
  requestResetLink: "Request Reset Link",
  updatePassword: "Update Password",
  resendEmail: "Resend Email",
  troubleToGetVerificationEmail: "Trouble to get verification email?",
  enterEmailAddressForResend:
    "Provide your email address and we will resend you verification link.",
};

export const VERIFICATION_STATUS = {
  unf: "User not found!",
  uav: "User already verified. No need to verify again.",
  evs: "Email verified successfully. Now you can login.",
  iat: "Invalid access token.",
};

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?|&~`#^_\-+=.,><"';:\\/{}[\]()]).{8,}$/;

export const EMAIL_REGEX = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const PHONE_REGEX = /^\d{10}$/;

export const IRISH_MOBILES =
  /^(353\d{8,9}|0\d{8,9}|(1800|0800|15\d{2}|1850|1890)\d{6})$/;

export const GUIDELINE_FOR_PHONE =
  "Please enter a valid Irish phone (or mobile) number without spaces or brackets. Include the trunk prefix (e.g., 01 for Dublin) for national numbers. International numbers should start with the country code (353), No need for include plus sign (+) and omit the trunk prefix.";

export const NUMBER_REGEX = /^\d+$/;

export const PAGE_SIZE = 10;

export const DOCUMENT_VIEW_SIZE = 5;

export const LOGIN_KEY = "userData";

export const PROJECTS_KEY = "projectId";

export const COUNTER_KEY = "counter_key";

export const API_CALL_INTERVAL = 10000;

export const PROJECTS = {
  projectList: "Project List",
  createNewProject: "Create New Project",
  createYourProject: "Create Your Project",
  noDataAvailable: "No data available",
  addFiles: "Add files",
  addMoreFiles: "Add More files",
  pleaseUploadPdf: "Please upload at least one pdf for each category.",
  allProjects: "All Projects",
  description: "Description",
  category: "Category",
  uploadPDF: "Upload PDF",
  saveAndProcess: "Save and Process",
  save: "Save",
  noCategoryChosen: "No Category chosen",
  pleaseDoNotReloadWindow: "Please do not reload window or change new window",
  noFilesAvailable: "No files available",
  responseProvider: "Response Provider",
  files: "Files",
  backToProjects: "Back to Projects",
  backToResponses: "Back to Responses",
  backToDocuments: "Back to Documents",
  backToSelection: "Back to Selection",
  openDocuments: "Open Documents",
  exportToExcel: "Export to Excel",
  showAllResponses: "Show all responses",
  noDDQAvailable:
    "Looks like no Due Diligence Question(s) available at this moment. Please try again later.",
  regenerate: "Regenerate Questions",
  vdrReferences: "VDR References",
  pleaseIndicate: "(Please indicate which are incorrect)",
  llmResponse: "LLM Response",
  prompt: "Prompt",
  comments: "Comments",
  generalComments: "General Comments",
  responseFeedback: "Response Feedback",
  send: "Send",
  filter: "Filter",
  noDocumentsAvailable: "No documents available for selected question",
  noResponsesAvailable: "No Responses available for selected document",
  noResponsesSelected: "No Responses selected",
  missingDocuments: "Missing Documents",
  refreshMessage:
    "If the response has not been generated yet, please click the refresh button.",
};

const itemCantBe = "This item can not be restored.";
export const DELETE = {
  areYouSure: "Are you sure?",
  doYouReallyWant: "Do you really want to delete this item?",
  thisItemCantBeRestored: itemCantBe,
  thisItemCantBe: `${itemCantBe}. If necessary, you can delete it, otherwise, consider disabling it.`,
  yesDelete: "Yes, Delete",
  noKeepAsItIs: "No, keep as it is.",
};

export const DDQ_RESPONSE_STATUS = {
  GENERATE_STATUS: "generate",
  SUCCESS_STATUS: "success",
  ERROR_STATUS: "regenerate",
  IN_PROGRESS: "in_progress",
};

export const RESPONSE_STAGES = {
  INITIAL: 1,
  RESPONSE_SELECTION: 2,
  COMPLETED: 3,
  pending: "Pending",
  completed: "Completed",
};

export const RESPONSE_STAGES_COLOR = {
  success: 1,
  fail: 2,
};

export const STATUS_TEXT = {
  viewResponse: "View Response",
  error: "Error",
  processing: "Processing...",
  generateResponse: "Generate Response",
};

export const STATUS = {
  active: 1,
  activeText: "Active",
  inActive: 2,
  inActiveText: "Inactive",
};

export const ALL = {
  id: 0,
  text: "All",
};

export const RESPONSE_PROVIDER = {
  gpt: 1,
  searchLess: 2,
  gptText: "GPT-4",
  searchLessText: "Search4less",
};

export const FILTER_RESPONSE_PROVIDER_OPTION = [
  { id: 1, value: RESPONSE_PROVIDER.gpt, label: RESPONSE_PROVIDER.gptText },
  {
    id: 2,
    value: RESPONSE_PROVIDER.searchLess,
    label: RESPONSE_PROVIDER.searchLessText,
  },
];

export const FILTER_RESPONSE_STATUS_OPTIONS = [
  {
    id: 1,
    value: DDQ_RESPONSE_STATUS.GENERATE_STATUS,
    label: STATUS_TEXT.generateResponse,
  },
  { id: 2, value: DDQ_RESPONSE_STATUS.ERROR_STATUS, label: STATUS_TEXT.error },
  {
    id: 3,
    value: DDQ_RESPONSE_STATUS.SUCCESS_STATUS,
    label: STATUS_TEXT.viewResponse,
  },
  {
    id: 4,
    value: DDQ_RESPONSE_STATUS.IN_PROGRESS,
    label: STATUS_TEXT.processing,
  },
];

export const ADMIN = {
  listOfCategory: "List of Category",
  addCategory: "Add Category",
  addSubCategory: "Add Subcategory",
  editCategory: "Edit Category",
  addNewCategory: "Add New Category",
  pleaseEnterValidCategory: "Please enter valid category.",
  saveAndProcess: "Save and Process",
  listOfDDQ: "List of DDQ",
  addDDQ: "Add DDQ",
  importCSV: "Import CSV",
  backToAllQuestions: "Back to All Questions",
  writeYourQuestion: "Write Your Question",
  selectCategory: "Select Category",
  selectSubCategory: "Select Subcategory",
  selectExpectedResponseFrom: "Select expected response from",
  noPromptsAvailable: "No Prompts Available.",
  keyword: "Keyword",
  responseGenerator: "Response Generator",
  chunksIdentifier: "Chunks Identifier",
  add: "Add",
  edit: "Edit",
  addClient: "Add Client",
  editClient: "Edit Client",
  pleaseAddPrompt: "Please add prompt.",
  uploadCSV: "Upload CSV",
  selectYourCSVCategory: "Select your CSV category",
  pleaseUpload: "Please upload CSV file.",
  doYouReallyWantToDisable: "Do you really want to disable this",
  doYouWantToEnable: "Do you want to enable this",
  yesIWant: "Yes, I want",
  editDDQ: "Edit DDQ",
  searchLess: "Search4less",
  download: "Download",
  listOfClient: "List of Client",
  listOfDocument: "List of Document",
  viewDocument: "View Document",
  viewRequest: "View request",
  supportRequest: "Support Request",
  pendingQuery: "Pending Query",
  itAppearsThatThisSupport:
    " It appears that this support request is still pending. Please take a moment to resolve it.",
  resolveNow: "Resolve Now",
  keepPending: "Keep Pending",
  queryResolved: "Query Resolved",
  theSupportRequest: "The support request has been successfully resolved.",
  okayIWillResolve: "Okay, I will resolve",
  backToDocuments: "Back to documents",
  ddqFeedbacks: "DDQ Feedbacks",
  writeYourQuery: "Write Your Query",
  summaryPrompt: "Summary Prompt",
  summaryFilter: "Summary Filter",
  prompt: "Prompt",
  additionalDetails: "Additional Details",
  variable: "Variable",
  gptProcessing: "GPT Processing",
};

export const QUERY_STATUS = {
  pendingId: 1,
  pending: "Pending",
  resolvedId: 2,
  resolved: "Resolved",
  allId: 0,
  all: "All",
};

export const DOWNLOAD_TYPE = {
  details: 1,
  document: 2,
};

export const RESPONSE_PROVIDER_RADIO_LABELS = [
  {
    id: RESPONSE_PROVIDER.gpt,
    name: RESPONSE_PROVIDER.gptText,
  },
  {
    id: RESPONSE_PROVIDER.searchLess,
    name: RESPONSE_PROVIDER.searchLessText,
  },
];

export const ERROR = {
  error: "Errors",
  OopsLooksLike:
    "Oops! Looks like there's a mistake in the file. Here's what needs fixing.",
};
