import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../components/molecules/select/select-input";
import { fetchProjectListByClient } from "../../../../action";

export const ClientFilter = ({ callback, isError }) => {
  const clients = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  const clientList =
    clients?.clientDetails?.map((item) => ({
      id: item.id,
      value: item.name,
    })) ?? [];

  return (
    <div>
      <Select
        values={clientList}
        innerLabel={"Filter User"}
        label={"Select User"}
        onChange={(value) => {
          callback?.({ ...value, isError: false });
          dispatch(fetchProjectListByClient({ businessId: value.id }));
        }}
        isReturnBoth
        errorText={isError ? "Users is required." : ""}
      />
    </div>
  );
};
