import { useSelector } from "react-redux";
import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { DDQResponsePreviewTable } from "./ddq-response-preview-table";
import { RESPONSE_PROVIDER } from "../../../constant";

export const DDQResponsePreviewDialog = ({ onClose, onloadMoreData }) => {
  const ddqResponseState = useSelector((state) => state.ddqResponse);

  const data = ddqResponseState.responses;

  const previewData =
    data?.map((item, index) => {
      return {
        srNo: index + 1,
        question: item?.question,
        response:
          item?.responseProvider === RESPONSE_PROVIDER.searchLess
            ? item.response[0][Object.keys(item.response[0])[0]]?.map((i) => {
                if (i?.startsWith("https"))
                  return (
                    <p key={i}>
                      <a target="_blank" rel="noreferrer" href={i}>
                        {i?.split("?")[0] ?? i}
                      </a>
                    </p>
                  );
                return (
                  <p key={i} className="my-0">
                    {i}
                  </p>
                );
              })
            : item?.responses[0]?.response,
        vdr: item?.responses[0]?.fileName ?? "",
        fileUrl: item?.responses[0]?.fileUrl ?? "",
        ddqResponse:
          item?.responseProvider === RESPONSE_PROVIDER.gpt
            ? item?.responses.slice(1).map((i) => ({
                response: i?.response,
                vdr: i?.fileName,
                fileUrl: i?.fileUrl,
              }))
            : [],
      };
    }) ?? [];

  return (
    <MUIDialog
      isOpen
      title={"Response Preview"}
      content={
        <div className="w-full">
          <DDQResponsePreviewTable
            data={previewData}
            onloadMoreData={(table) => onloadMoreData(table)}
          />
        </div>
      }
      onClose={onClose}
      width="xl"
    />
  );
};
