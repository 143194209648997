import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const EditIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <title>Edit</title>

      <path
        id="Vector"
        d="M18.5401 21.54H2.52012V5.52H10.5301V4.62H1.62012V22.44H19.4401V12.45H18.5401V21.54Z"
        fill={color}
        strokeWidth={strokeWidth}
        stroke={color}
      />
      <path
        id="Vector_2"
        d="M6.7799 13.86L6.1499 17.79L10.0799 17.16L10.4699 16.74L22.4099 4.83L19.1099 1.56L7.1999 13.47L6.7799 13.86ZM7.5899 14.49L9.4199 16.35L7.1999 16.71L7.5899 14.49ZM21.1199 4.83L19.9499 6.03L17.9699 4.02L19.1699 2.85L21.1199 4.83ZM17.3099 4.65L19.3199 6.66L10.1399 15.81L8.1599 13.8L17.3099 4.65Z"
        fill={color}
        strokeWidth={strokeWidth}
        stroke={color}
      />
    </svg>
  );
};

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
