import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CustomTheme } from "../../theme/custom-theme";
import { CloseIcon, MaximizeIcon, MinimizeIcon } from "../../icons";
import { MUIButton as Button } from "../../atoms/button/mui-button";
import { POSITION } from "../../../constant";

/**
 * MUIDrawer Component:
 * A customizable drawer component using Material-UI Drawer.
 *
 * Props:
 * - children: Content inside the drawer (React node).
 * - placement: Placement of the drawer (top, right, bottom, left).
 * - width: Width of the drawer (CSS string).
 * - isOpen: Whether the drawer is open or closed (boolean).
 * - onClose: Function to handle closing the drawer.
 *
 * Example Usage:
 * ```
 * <MUIDrawer
 *   placement="right"
 *   width="448px"
 *   isOpen={isOpen}
 *   onClose={handleClose}
 * >
 *   Drawer Content
 * </MUIDrawer>
 * ```
 */
export const MUIDrawer = ({
  children,
  onClose,
  isOpen,
  placement = "right",
  width = "448px",
  isResizable = false,
}) => {
  const [open, setOpen] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(width);

  useEffect(() => {
    setCurrentWidth(width);
  }, [width]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const toggleDrawer = () => {
    setOpen(!open);
    onClose?.(!open);
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setCurrentWidth(isMaximized ? width : "100vw"); // Adjust the width as needed
  };

  // Define radiusObject based on placement
  const radiusObject =
    {
      [POSITION.top]: {
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
      },
      [POSITION.right]: {
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
      },
      [POSITION.bottom]: {
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      },
      [POSITION.left]: {
        borderBottomRightRadius: "20px",
        borderTopRightRadius: "20px",
      },
    }[placement] || {};

  return (
    <CustomTheme>
      <div>
        <Drawer
          anchor={placement}
          open={open}
          onClose={toggleDrawer}
          PaperProps={{
            sx: {
              width: currentWidth,
              ...radiusObject,
            },
          }}
        >
          <div className="flex justify-end m-5">
            {isResizable && (
              <Button variant="text" onClick={handleMaximize}>
                {isMaximized ? (
                  <MinimizeIcon height={18} width={18} />
                ) : (
                  <MaximizeIcon height={18} width={18} />
                )}
              </Button>
            )}
            <Button variant="text" onClick={toggleDrawer}>
              <CloseIcon height={16} width={16} />
            </Button>
          </div>
          {children}
        </Drawer>
      </div>
    </CustomTheme>
  );
};

// PropTypes for MUIDrawer component
MUIDrawer.propTypes = {
  children: PropTypes.node, // Content inside the drawer
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]), // Placement of the drawer
  width: PropTypes.string, // Width of the drawer
  isOpen: PropTypes.bool, // Whether the drawer is open or closed
  onClose: PropTypes.func, // Function to handle closing the drawer
};

export default MUIDrawer;
