import { Radio } from "@mui/material";
import { AccordionV2 } from "../../../components/atoms/accordion/accordion-v2";
import PropTypes from "prop-types";
import { NormalText } from "../../../components/typography";
import { PROJECTS } from "../../../constant";

export const DDQResponseFilter = ({ data = [], callback }) => {
  return (
    <div className="mb-[20px]">
      {data.length > 0 ? (
        data.map((i) => (
          <AccordionV2
            title={
              <div className="flex">
                <Radio
                  size="medium"
                  onChange={(e) => {
                    if (e.target.checked) callback?.(i.id);
                  }}
                  id={`radio-${i}`}
                />
                <label htmlFor={`radio-${i}`}> {i.synthetic_filename}</label>
              </div>
            }
            key={i.id}
          >
            <p className="text-justify">&emsp;{i.response}</p>
          </AccordionV2>
        ))
      ) : (
        <div className="border border-solid border-edge rounded-md px-[20px] py-[16px] text-center">
          <NormalText>{PROJECTS.noResponsesSelected}</NormalText>
        </div>
      )}
    </div>
  );
};

DDQResponseFilter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()), // TODO: add
  callback: PropTypes.func,
};
