import { useEffect, useState } from "react";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { SearchIcon } from "../../../components/icons";
import { PageWhite } from "../../../components/organism/body/page-white";
import { HeadText } from "../../../components/typography";
import { COLORS } from "../../../configuration";
import { ADMIN, ALL, STATUS } from "../../../constant";
import { ClientManagement } from "../../features/admin/clients-management";
import { AddClientDialog } from "../../features/admin/clients-management/add-client";
import { useDebounce } from "../../../hooks/use-debounce";
import { useSelector } from "react-redux";
import { Select } from "../../../components/molecules/select/select-input";

export const ClientManagementPage = () => {
  const [searchName, setSearchName] = useState();
  const [statusFilter, setStatusFilter] = useState(ALL.id);
  const [showDialog, setShowDialog] = useState(false);

  const debouncedName = useDebounce(searchName, 500);

  const view = useSelector((state) => state.view);

  useEffect(() => {
    if (view.clientAdd.success) setShowDialog(false);
  }, [view.clientAdd.success]);

  return (
    <PageWhite>
      <div className="mb-[20px] flex justify-between items-center">
        <HeadText>{ADMIN.listOfClient}</HeadText>
        <div className="flex gap-4 items-center">
          <div className="w-[250px]">
            <TextField
              placeholder={"Search Client"}
              onChange={(e) => setSearchName(e.target.value)}
              endAdornment={
                <SearchIcon
                  width={18}
                  height={18}
                  color={COLORS.secondaryLight}
                />
              }
            />
          </div>
          <div className="w-[250px]">
            <Select
              values={[
                { id: ALL.id, value: ALL.text },
                { id: STATUS.active, value: STATUS.activeText },
                { id: STATUS.inActive, value: STATUS.inActiveText },
              ]}
              defaultValue={[{ id: ALL.id, value: ALL.text }]}
              innerLabel={"Filter Status"}
              onChange={(value) => setStatusFilter(value)}
              isReturnId
            />
          </div>
          <Button
            size="md"
            className="h-[40px]"
            onClick={() => setShowDialog(!showDialog)}
          >
            {ADMIN.addClient}
          </Button>
        </div>
      </div>
      <ClientManagement
        searchName={debouncedName}
        statusFilter={statusFilter}
      />
      <AddClientDialog
        showDialog={showDialog}
        onClose={() => setShowDialog(!showDialog)}
      />
    </PageWhite>
  );
};
