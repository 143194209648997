import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const EyeCloseIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <path
        d="M12 7C7.91992 7 5.1716 10.5514 4.29842 11.8582C4.10438 12.1486 4.10438 12.5181 4.29842 12.8085C5.1716 14.1153 7.91992 17.6667 12 17.6667C16.0801 17.6667 18.8284 14.1153 19.7016 12.8085C19.8956 12.5181 19.8956 12.1486 19.7016 11.8582C18.8284 10.5514 16.0801 7 12 7Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M14.6667 12.3333C14.6667 13.8061 13.4728 15 12 15C10.5273 15 9.33334 13.8061 9.33334 12.3333C9.33334 11.9309 9.4225 11.5492 9.58214 11.2071C9.83966 10.6552 10.2806 10.2061 10.8265 9.93808C11.1806 9.76426 11.5789 9.66666 12 9.66666C13.4728 9.66666 14.6667 10.8606 14.6667 12.3333Z"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <line
        x1="18"
        y1="6.70711"
        x2="5.70711"
        y2="19"
        stroke={color}
        strokeLinecap="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

EyeCloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
