import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { ErrorText, LabelText } from "../../../typography";
import { twMerge } from "tailwind-merge";
import { CustomTheme } from "../../../theme/custom-theme";
import { useState, useEffect } from "react";

/**
 * TextArea Component
 *
 * This component renders a Material-UI TextField as a multiline textarea with additional features
 * such as character count and error handling.
 *
 * Props:
 * - label: Node, the label for the textarea.
 * - id: String, the ID of the textarea.
 * - className: String, additional class names for styling.
 * - rows: Number, the number of rows for the textarea.
 * - onChange: Function, callback function to handle textarea value changes.
 * - error: Boolean, whether there is an error with the textarea.
 * - errorText: String, the error message to display.
 * - placeholder: String, the placeholder text for the textarea.
 * - value: String, the value of the textarea.
 * - maxSize: Number, maximum allowed length of the textarea value.
 * - isReset: Boolean, whether to reset the textarea.
 *
 * Example usage:
 * ```
 * <TextArea
 *   label="Description"
 *   id="description"
 *   rows={4}
 *   onChange={(e) => console.log(e.target.value)}
 *   errorText="This field is required."
 *   placeholder="Enter description here..."
 *   value=""
 *   maxSize={500}
 * />
 * ```
 */
export const TextArea = ({
  label,
  id = "text-area",
  className,
  rows = 4,
  onChange = () => {},
  error = false,
  errorText = "",
  placeholder,
  value,
  maxSize,
  isReset,
  disable,
  ...props
}) => {
  const [textLength, setTextLength] = useState(value?.length || 0);

  useEffect(() => {
    setTextLength(value?.length || 0);
  }, [value, maxSize]);

  return (
    <CustomTheme>
      <div className="flex flex-col relative">
        {label && (
          <LabelText labelFor={id} className="my-2 tracking-wide">
            {label}
          </LabelText>
        )}
        <div className="relative">
          <TextField
            id={id}
            value={value}
            multiline
            placeholder={placeholder}
            rows={rows}
            className={twMerge("w-full", className)}
            error={error || !!errorText}
            onChange={(e) => {
              const length = e.target.value.length;
              // Check if maxSize is defined and limit the input length
              if (!maxSize || length <= maxSize) {
                onChange(e);
                setTextLength(length);
              }
            }}
            inputProps={{ maxLength: maxSize }}
            disabled={disable}
            {...props}
          />
          {/* Display character count if maxSize is defined */}
          {maxSize && (
            <div
              className="text-xs text-secondary-light absolute bottom-2 right-2"
              style={{ position: "absolute", right: "8px", bottom: "8px" }}
            >
              {`${textLength}/${maxSize}`}
            </div>
          )}
        </div>
        {/* Display error text if there's an error */}
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </div>
    </CustomTheme>
  );
};

// Define expected prop types and their constraints
TextArea.propTypes = {
  label: PropTypes.node,
  rows: PropTypes.number,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxSize: PropTypes.number,
  id: PropTypes.string,
};
