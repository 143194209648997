import { createSlice } from "@reduxjs/toolkit";
import { loginUser, refetchUserData } from "../../action";
import { SEVERITY, USERS } from "../../constant";
import { encryptData } from "../../utils/security-functions";

const NAME = "user";

const initialState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  role: "",
  isAdminActive: false,
  loginTimestamp: null,
  expirationTime: null,
};

const EXPIRATION_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const userSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setAdminActive(state, { payload }) {
      state.isAdminActive = payload;
      sessionStorage.setItem("isAdminActive", payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        if (payload.status === SEVERITY.Success) {
          const response = {
            firstName: payload.response?.data.firstname ?? "",
            lastName: payload.response?.data.lastname ?? "",
            phoneNumber: payload.response?.data.phone_number ?? "",
            email: payload.response?.data.email ?? "",
            role: payload.response?.data.role ?? "",
          };

          state.firstName = response.firstName;
          state.lastName = response.lastName;
          state.phoneNumber = response.phoneNumber;
          state.email = response.email;
          state.role = response.role;
          encryptData(response);

          state.loginTimestamp = new Date().getTime();
          state.expirationTime = state.loginTimestamp + EXPIRATION_DURATION;
          localStorage.setItem("loginTimestamp", state.loginTimestamp);
          localStorage.setItem("expirationTime", state.expirationTime);
        }
      })
      .addCase(refetchUserData.fulfilled, (state, { payload }) => {
        if (payload?.isUserActive) {
          state.email = payload.email;
          state.firstName = payload.firstName;
          state.lastName = payload.lastName;
          state.phoneNumber = payload.phoneNumber;
          state.role = payload.role;
          state.isAdminActive = payload.isAdminActive;
        }
      });
  },
});

export const { actions } = userSlice;

export default userSlice.reducer;
