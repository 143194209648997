import { Link } from "react-router-dom";
import { FooterNavigation } from "../../../routes/app-navigation";
import { ALL_RIGHTS_RESERVED } from "../../../constant";

export const Footer = () => {
  return (
    <div className="bg-primary h-[48px] text-white flex justify-around items-center p-2">
      <div className="">
        {FooterNavigation.map((footerItem, index) => (
          <Link
            id={footerItem.id}
            to={footerItem.url}
            key={footerItem.id}
            style={{ textDecoration: "none", color: "white" }}
          >
            <span className="tracking-wide">{footerItem.name}</span>
            {index !== FooterNavigation.length - 1 && " | "}
          </Link>
        ))}
      </div>
      <div>
        <span className="text-xs">
          {ALL_RIGHTS_RESERVED} &copy; {new Date().getFullYear()}
        </span>
      </div>
    </div>
  );
};
