import { MUIButton } from "../../../../components/atoms/button/mui-button";
import { DoneIcon } from "../../../../components/icons";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, NormalText } from "../../../../components/typography";
import PropTypes from "prop-types";
import { ADMIN, GENERAL } from "../../../../constant";
import { COLORS } from "../../../../configuration";

export const ResolvedSupportRequestDialog = ({ onClose }) => {
  return (
    <MUIDialog
      isOpen
      width="xs"
      content={
        <div className="text-center">
          <DoneIcon height={48} width={48} color={COLORS.success} />
          <HeadText className="text-success mb-3" testId="resolved-title">
            {ADMIN.queryResolved}
          </HeadText>
          <NormalText className="mb-3 w-[240px] mx-auto">
            {ADMIN.theSupportRequest}
          </NormalText>
          <div className="flex flex-col">
            <MUIButton size="md" onClick={onClose}>
              {GENERAL.okay}
            </MUIButton>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

ResolvedSupportRequestDialog.propTypes = {
  onClose: PropTypes.func,
};
