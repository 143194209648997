import { Link } from "react-router-dom";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { TextField } from "../../../components/atoms/input/text-field";
import { Welcome } from "../../../components/general/welcome";
import { GUIDELINE_FOR_PHONE, LOGIN, SEVERITY } from "../../../constant";
import { pathList } from "../../../routes/app-navigation";
import { useEffect, useState } from "react";
import {
  validConfirmPassword,
  validEmail,
  validName,
  validPassword,
  validPhone,
  validUserData,
} from "../../../utils/validation-functions";
import { useDispatch } from "react-redux";
import { registerUser } from "../../../action";
import PropTypes from "prop-types";
import {
  EyeCloseIcon,
  EyeIcon,
  InformationIcon,
} from "../../../components/icons";
import { ToolTip } from "../../../components/atoms/tooltip";

const initialUserData = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  phoneNumber: "",
};

export const Registration = ({ registration, getEmail, callback }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [userData, setUserData] = useState(initialUserData);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsSubmitted(false);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    const isValid = validUserData(userData);
    if (isValid) {
      getEmail?.(userData.email);
      dispatch(
        registerUser({ ...userData, password: userData.confirmPassword })
      ).then((value) => {
        callback?.(value.payload?.status === SEVERITY.Success);
      });
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="mt-[170px] w-max mx-auto text-secondary">
      <Welcome />
      <form>
        <div className="flex flex-col gap-4 mt-[20px]">
          <TextField
            placeholder={"First Name*"}
            type={"text"}
            name="first-name"
            className={"w-[412px]"}
            onChange={(e) =>
              setUserData({ ...userData, firstName: e.target.value })
            }
            errorText={isSubmitted ? validName(userData.firstName).message : ""}
            inputProps={{ "data-testid": "first-name-input" }}
            errorTestId="first-name-validation"
            required
          />
          <TextField
            placeholder={"Last Name*"}
            type={"text"}
            name="last-name"
            className={"w-[412px]"}
            onChange={(e) =>
              setUserData({ ...userData, lastName: e.target.value })
            }
            errorText={isSubmitted ? validName(userData.lastName).message : ""}
            inputProps={{ "data-testid": "last-name-input" }}
            errorTestId="last-name-validation"
            required
          />
          <div className="flex gap-2 items-center">
            <TextField
              placeholder={"Phone Number*"}
              type={"tel"}
              name="phone-number"
              className={"w-[412px]"}
              onChange={(e) =>
                setUserData({ ...userData, phoneNumber: e.target.value })
              }
              errorText={validPhone(userData.phoneNumber, isSubmitted).message}
              inputProps={{ "data-testid": "phone-number-input" }}
              errorTestId="phone-number-validation"
            />
            <ToolTip text={GUIDELINE_FOR_PHONE}>
              <InformationIcon height={18} width={18} />
            </ToolTip>
          </div>

          <TextField
            placeholder={"Email Address*"}
            type={"email"}
            name="email"
            className={"w-[412px]"}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            errorText={isSubmitted ? validEmail(userData.email).message : ""}
            inputProps={{ "data-testid": "email-input" }}
            errorTestId="email-validation"
            required
          />
          <TextField
            placeholder={"Password*"}
            type={showPassword ? "text" : "password"}
            name="password"
            className={"w-[412px]"}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
            errorText={
              isSubmitted ? validPassword(userData.password).message : ""
            }
            endAdornment={
              <button
                type="button"
                className="p-0 bg-transparent border-none cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeCloseIcon />
                ) : (
                  <EyeIcon strokeWidth={1.5} />
                )}
              </button>
            }
            inputProps={{ "data-testid": "password-input" }}
            errorTestId="password-validation"
            required
          />
          <TextField
            placeholder={"Confirm Password*"}
            type={"password"}
            name="confirm-password"
            className={"w-[412px]"}
            onChange={(e) =>
              setUserData({ ...userData, confirmPassword: e.target.value })
            }
            errorText={
              isSubmitted
                ? validConfirmPassword(
                    userData.password,
                    userData.confirmPassword
                  ).message
                : ""
            }
            inputProps={{ "data-testid": "confirm-password-input" }}
            errorTestId="confirm-password-validation"
            required
          />
          <div className="mt-[20px] mx-auto w-max">
            <Button
              size="md"
              type={"submit"}
              onClick={handleSubmit}
              className={"hover:bg-warning-dark hover:text-black"}
              isLoading={registration.pending}
              data-testid="registration-button"
            >
              {LOGIN.signUp}
            </Button>
          </div>

          <div className="w-max mx-auto text-center">
            <div className="mt-[20px]">
              {`${LOGIN.iAgreeTo} `}
              <div className="inline-block ">
                <Link
                  className="text-warning-dark no-underline"
                  data-testid="terms-of-service-link"
                  to={pathList.termsAndCondition}
                >
                  {LOGIN.termsOfService}
                </Link>
              </div>
              {` ${LOGIN.and} `}
              <div className="inline-block text-warning-dark">
                <Link
                  className="text-warning-dark no-underline"
                  data-testid="privacy-policy-link"
                  to={pathList.privacyPolicy}
                >
                  {LOGIN.privacyPolicy}
                </Link>
              </div>
            </div>
            <div className="mt-[20px]">
              {`${LOGIN.alreadyRegistered} `}
              <Link
                to={pathList.login}
                className="text-warning-dark no-underline font-bold"
                data-testid="login-link"
              >
                {LOGIN.login}
              </Link>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

Registration.propTypes = {
  registration: PropTypes.object,
};
