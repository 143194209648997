import { SkeletonRect } from "../../atoms/loader/skeleton";

const SkeletonLoading = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <SkeletonRect key={i} className={"w-full my-[20px]"} height={48} />
      ))}
    </>
  );
};

export const DelayResponseBody = ({ children, isLoading, isButton = true }) => {
  return isLoading ? (
    <SkeletonLoading isButton={isButton} />
  ) : (
    <div>{children}</div>
  );
};
