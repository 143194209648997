import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { LabelText, SmallText } from "../../../../components/typography";
import { MUICheckBox } from "../../../../components/atoms/checkbox";
import { DelayPageBody } from "../../../../components/organism/body/loading-body";

import { GENERAL, PROJECTS, RESPONSE_PROVIDER } from "../../../../constant";
import { Categories } from "./categories-files";
import { TextField } from "../../../../components/atoms/input/text-field";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { AddFilesDialog } from "./add-files-dialog";
import { TextArea } from "../../../../components/atoms/input/text-area";
import { actions } from "../../../slice/view";
import { CategoryDropDown } from "../../admin/categories-management/category-dropdown";
import { pathList } from "../../../../routes/app-navigation";
import { deleteDocument, editProject } from "../../../../action";
import {
  validCompanyName,
  validCRONumber,
  validDescription,
  validProjectData,
} from "../../../../utils/validation-functions";

const initialState = {
  name: "",
  description: "",
  croNumber: "",
  responseProvider: [],
  categories: [],
  files: [],
};

const EditProject = () => {
  const view = useSelector((state) => state.view);
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const showDialog = (payload) => dispatch(actions.showDocumentDialog(payload));

  const [data, setData] = useState(initialState);
  const [selectedCategory, setSelectedCategory] = useState({
    id: null,
    name: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const selectedProject = project.selectedProject;

  useEffect(() => {
    if (view.project.success && selectedProject?.name) {
      const newCategories = selectedProject.categories.map((cat) => ({
        id: cat.value,
        value: cat.label,
        files: cat.filesData || [],
      }));
      setData({
        name: selectedProject.name,
        description: selectedProject.description,
        croNumber: selectedProject.croNumber,
        responseProvider: selectedProject.responseProvider,
        categories: newCategories.map((cat) => ({
          id: cat.id,
          value: cat.value,
        })),
        files: newCategories,
      });
    }
  }, [selectedProject, view.project.success]);

  const handleCheckBoxChange = useCallback((provider) => {
    setData((prevData) => {
      const isChecked = prevData.responseProvider.includes(provider);
      return {
        ...prevData,
        responseProvider: isChecked
          ? prevData.responseProvider.filter((item) => item !== provider)
          : [...prevData.responseProvider, provider],
      };
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCategoryChange = (value, deletedItem) => {
    if (
      deletedItem &&
      selectedProject.categories.findIndex((i) => i.value === deletedItem) !==
        -1
    )
      dispatch(deleteDocument({ projectId: id, categoryId: deletedItem }));
    const selectedCategories = value.map((cat) => ({
      id: cat.id,
      value: cat.value,
    }));
    const files = selectedCategories.map((cat) => {
      const existingCategory = data.files.find((file) => file.id === cat.id);
      return existingCategory
        ? { ...existingCategory, value: cat.value }
        : { ...cat, files: [] };
    });
    setData((prevData) => ({
      ...prevData,
      categories: selectedCategories,
      files,
    }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsSubmit(true);
    if (
      validProjectData({
        name: data.name,
        croNumber: data.croNumber,
        description: data.description,
        responseProvider: data.responseProvider,
      })
    )
      dispatch(
        editProject({
          projectId: id,
          name: data.name,
          description: data.description,
          croNumber: data.croNumber,
          categories: data.categories.map((i) => i.id.toString()),
          responseProvider: data.responseProvider,
        })
      ).then(() => navigate(pathList.user.projectList));
  };

  const totalFiles = useMemo(
    () => data.files.reduce((acc, curr) => acc + curr.files.length, 0),
    [data.files]
  );

  return (
    <DelayPageBody isLoading={view.project.pending}>
      <form>
        <div className="pb-[20px]">
          <div className="flex flex-col">
            <TextField
              value={data.name}
              id="name"
              type="text"
              label="Project Name"
              name="name"
              placeholder="Enter project name"
              onChange={handleInputChange}
              errorText={isSubmit ? validCompanyName(data.name).message : ""}
            />
            <TextArea
              value={data.description}
              label="Description"
              id="description"
              name="description"
              placeholder="Enter description"
              onChange={handleInputChange}
              errorText={
                isSubmit ? validDescription(data.description).message : ""
              }
            />
            <TextField
              value={data.croNumber}
              type="text"
              label="CRO Number"
              id="cro"
              name="croNumber"
              placeholder="Enter CRO number"
              onChange={handleInputChange}
              errorText={isSubmit ? validCRONumber(data.croNumber).message : ""}
            />
            <div className="mt-[8px]">
              <CategoryDropDown
                id="category-dropdown"
                label="Category"
                value={data.categories}
                callBack={handleCategoryChange}
                isReturnBoth
                isDefaultValueValidated
                isLoading={view.deleteDocument.pending}
                isWrap={true}
              />
            </div>
            <div className="mt-[8px]">
              <LabelText labelFor="checkbox">
                {PROJECTS.responseProvider}
              </LabelText>
              <div className="flex gap-2" id="checkbox">
                <MUICheckBox
                  label={RESPONSE_PROVIDER.gptText}
                  checked={data.responseProvider.includes(
                    RESPONSE_PROVIDER.gpt
                  )}
                  id="gpt"
                  onChange={() => handleCheckBoxChange(RESPONSE_PROVIDER.gpt)}
                />
                <MUICheckBox
                  label={RESPONSE_PROVIDER.searchLessText}
                  checked={data.responseProvider.includes(
                    RESPONSE_PROVIDER.searchLess
                  )}
                  id="search-less"
                  onChange={() =>
                    handleCheckBoxChange(RESPONSE_PROVIDER.searchLess)
                  }
                />
              </div>
              {data.responseProvider.includes(RESPONSE_PROVIDER.gpt) && (
                <div>
                  <div className="ml-[8px] flex justify-between mb-[8px]">
                    <SmallText>{PROJECTS.files}</SmallText>
                    <SmallText>{`Total ${totalFiles} file${totalFiles > 1 ? "s" : ""} uploaded`}</SmallText>
                  </div>
                  {data.categories.length > 0 ? (
                    data.files.map((category) => (
                      <Categories
                        key={category.id}
                        categoryId={category.id}
                        title={category.value}
                        files={category.files}
                        callback={(value) => {
                          showDialog(value);
                          setSelectedCategory(
                            value
                              ? { id: category.id, name: category.value }
                              : { id: null, name: null }
                          );
                        }}
                      />
                    ))
                  ) : (
                    <div className="border border-solid rounded-md border-edge p-2 text-center">
                      <SmallText>{PROJECTS.noCategoryChosen}</SmallText>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mt-[8px] flex gap-2 justify-end">
              <Button
                variant="text"
                size="md"
                onClick={() => navigate(pathList.user.projectList)}
              >
                {GENERAL.cancel}
              </Button>
              <Button
                size="md"
                onClick={handleSubmit}
                type="submit"
                isLoading={view.editProject.pending}
                disable={view.deleteDocument.pending}
              >
                {GENERAL.save}
              </Button>
            </div>
          </div>
        </div>
      </form>
      {view.uploadDocuments.dialog && (
        <AddFilesDialog
          categoryId={selectedCategory.id}
          categoryName={selectedCategory.name}
          projectId={id}
          onClose={() => showDialog(false)}
          categories={data.categories.map((i) => i.id.toString())}
          responseProvider={data.responseProvider}
        />
      )}
    </DelayPageBody>
  );
};

export default EditProject;
