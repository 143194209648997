import PropTypes from "prop-types";
import { TableBody, TableRow, TableCell, Skeleton } from "@mui/material";

export const SkeletonTableBody = ({ columns = 1, rows = 1 }) => {
  const renderSkeletonRow = (index) => {
    const skeletonCells = Array(columns)
      .fill()
      .map((_, cellIndex) => (
        <TableCell key={`${index}${cellIndex}`}>
          <Skeleton animation="pulse" height={20} />
        </TableCell>
      ));
    return <TableRow key={index}>{skeletonCells}</TableRow>;
  };

  const skeletonRows = Array(rows)
    .fill()
    .map((_, index) => renderSkeletonRow(index));

  return <TableBody>{skeletonRows}</TableBody>;
};

SkeletonTableBody.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
};
