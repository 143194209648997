import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import PropTypes from "prop-types";

export const StoreWrapper = ({ children }) => {
  return <Provider store={store}> {children}</Provider>;
};

StoreWrapper.propTypes = {
  children: PropTypes.node,
};

export const RouterWrapper = ({ children }) => {
  return <BrowserRouter>{children}</BrowserRouter>;
};

RouterWrapper.propTypes = {
  children: PropTypes.node,
};

