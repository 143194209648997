import React, { useState } from "react";
import { MUIButton as Button } from "../../atoms/button/mui-button";
import PropTypes from "prop-types";
import { Menu, MenuItem, MenuList, styled } from "@mui/material";

import { COLORS } from "../../../configuration";
import { DownArrow } from "../../icons";
import { CustomTheme } from "../../theme/custom-theme";

// ToggleMenu component for creating a dropdown menu with a button trigger
export const ToggleMenu = ({
  children,
  title,
  menus = [{ id: "", content: "" }],
  showDownIcon = true,
  closeOnClick = true,
  dense = false,
}) => {
  // State to manage the anchor element for the menu
  const [anchor, setAnchor] = useState(null);

  // Check if the menu is open
  const open = Boolean(anchor);

  // Function to handle button click and open the menu
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleClose = () => {
    setAnchor(null);
  };

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: dense ? "2px 8px" : "4px 16px",
    "&:hover": {
      backgroundColor: COLORS.primaryXLight,
      color: COLORS.primary,
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  }));

  // Function to handle menu item click
  const handleMenuItemClick = () => {
    if (closeOnClick) {
      handleClose();
    }
  };

  return (
    <CustomTheme>
      <div>
        {/* Button that triggers the menu */}
        <Button
          color="secondary"
          variant="text"
          size="sm"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="relative"
        >
          <div className="flex gap-4 items-center">
            <div style={{ fontSize: "16px" }}>{children}</div>
            {showDownIcon && (
              <div>
                <DownArrow height={16} width={16} />
              </div>
            )}
          </div>
        </Button>

        {/* Menu component */}
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            style: {
              width: "192px",
              color: COLORS.secondary,
              boxShadow: 1,
            },
          }}
        >
          {/* Menu list containing each menu item */}
          <div className="w-48">
            {title && React.cloneElement(title)}
            <MenuList>
              {/* TODO: Icon Color change on hover */}
              {menus.map(
                (menuItem) =>
                  menuItem && (
                    <CustomMenuItem
                      key={menuItem.id}
                      sx={{ marginBottom: dense ? "2px" : "8px" }}
                      onClick={handleMenuItemClick}
                    >
                      {React.cloneElement(menuItem.content)}
                    </CustomMenuItem>
                  )
              )}
            </MenuList>
          </div>
        </Menu>
      </div>
    </CustomTheme>
  );
};

export const GeneralMenu = ({ children }) => {
  return <div className="flex gap-4 items-center w-full">{children}</div>;
};

GeneralMenu.propTypes = {
  children: PropTypes.node,
};

// PropTypes for ToggleMenu component
ToggleMenu.propTypes = {
  // The button's content to trigger the menu
  children: PropTypes.node,

  // Array of menu items with specified shape
  menus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired, // Menu item name
    })
  ).isRequired,

  // Show down icon next to the button content
  showDownIcon: PropTypes.bool,

  // Close menu on menu item click
  closeOnClick: PropTypes.bool,

  // Make menu items dense
  dense: PropTypes.bool,
};
