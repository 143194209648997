import { useState, useEffect } from "react";
import { PAGING } from "../../../constant";
import { MUIButton as Button } from "../../atoms/button/mui-button";
import { NormalText, SmallText } from "../../typography";
import PropTypes from "prop-types";
import { CustomTheme } from "../../theme/custom-theme";
import { SkeletonRect, SkeletonText } from "../../atoms/loader/skeleton";

/**
 * CustomPagination Component:
 * Displays pagination controls with page number and navigation buttons.
 *
 * Props:
 * - onChange: Function to handle page change events (required).
 * - currentPage: Current page number (default: 1).
 * - totalPage: Total number of pages (default: 1).
 *
 * Example Usage:
 * ```
 * <CustomPagination
 *   currentPage={currentPage}
 *   totalPage={totalPages}
 *   onChange={handlePageChange}
 * />
 * ```
 */
export const CustomPagination = ({
  onChange,
  currentPage = 1,
  totalPage = 1,
  isLoading = false,
}) => {
  const [pageNumber, setPageNumber] = useState(currentPage);

  // Update pageNumber when currentPage prop changes
  useEffect(() => {
    if (currentPage <= totalPage) setPageNumber(currentPage);
    else setPageNumber(1);
  }, [currentPage, totalPage]);

  // Handle page down action
  const pageDown = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      onChange(pageNumber - 1);
    }
  };

  // Handle page up action
  const pageUp = () => {
    if (pageNumber < totalPage) {
      setPageNumber(pageNumber + 1);
      onChange(pageNumber + 1);
    }
  };

  return (
    <CustomTheme>
      {isLoading ? (
        <div className="flex gap-4">
          <SkeletonRect height={40} width={120} />
          <SkeletonText height={40} width={120} />
          <SkeletonRect height={40} width={120} />
        </div>
      ) : (
        <div className="flex items-center gap-4">
          <Button
            disabled={pageNumber <= 1}
            onClick={() => {
              pageDown();
            }}
            size="lg"
          >
            <NormalText className="text-white text-sm">
              {PAGING.previousPage}
            </NormalText>
          </Button>
          <NormalText>{PAGING.page}</NormalText>
          <div>
            <SmallText className="bg-page text-center p-3 w-5 h-5 rounded-md ">
              {pageNumber}
            </SmallText>
          </div>
          <NormalText>{`of ${totalPage}`}</NormalText>
          <Button
            size="lg"
            disabled={pageNumber >= totalPage}
            onClick={() => {
              pageUp();
            }}
          >
            <NormalText className="text-white text-sm">
              {PAGING.nextPage}
            </NormalText>
          </Button>
        </div>
      )}
    </CustomTheme>
  );
};

// PropTypes for CustomPagination component
CustomPagination.propTypes = {
  onChange: PropTypes.func.isRequired, // Function to handle page change events
  currentPage: PropTypes.number, // Current page number
  totalPage: PropTypes.number, // Total number of pages
};

// Default values for optional props
CustomPagination.defaultProps = {
  currentPage: 1,
  totalPage: 1,
};

export default CustomPagination;
