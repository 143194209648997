import { useDispatch, useSelector } from "react-redux";
import { MUIButton } from "../../../../components/atoms/button/mui-button";
import { AlertIcon } from "../../../../components/icons";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, NormalText } from "../../../../components/typography";
import { COLORS } from "../../../../configuration";
import { resolveSupportRequest } from "../../../../action";
import { ADMIN } from "../../../../constant";
import PropTypes from "prop-types";

export const PendingSupportRequestDialog = ({ id, onClose }) => {
  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);

  const handleSubmit = () => {
    dispatch(resolveSupportRequest({ id }));
  };

  return (
    <MUIDialog
      isOpen
      width="xs"
      content={
        <div className="text-center">
          <AlertIcon height={48} width={48} color={COLORS.error} />
          <HeadText className="text-error mb-3" testId="pending-query-title">
            {ADMIN.pendingQuery}
          </HeadText>
          <NormalText className="w-[320px] mb-5">
            {ADMIN.itAppearsThatThisSupport}
          </NormalText>
          <div className="flex flex-col gap-4">
            <MUIButton
              size="md"
              isLoading={view.supportResolve.pending}
              onClick={handleSubmit}
              data-testid="resolve-now-button"
            >
              {ADMIN.resolveNow}
            </MUIButton>
            <MUIButton size="md" variant="text" onClick={onClose}>
              {ADMIN.keepPending}
            </MUIButton>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

PendingSupportRequestDialog.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};
