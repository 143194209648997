import PropTypes from "prop-types";
import { AddDDQ } from "./add-ddq";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDdqQuestionById } from "../../../../action";
export const EditDDQ = ({ id }) => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.view);
  useEffect(() => {
    dispatch(fetchDdqQuestionById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <AddDDQ
      isEdit={true}
      id={id}
      isLoading={view.ddq.pending || view.ddqById.pending}
    />
  );
};

EditDDQ.propTypes = {
  id: PropTypes.number,
};
