import PropTypes from "prop-types";
import { ICONS } from "../../../constant";
export const FilterLinesIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Filter</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3H0V2H15V3ZM12 8H3V7H12V8ZM10 13H5V12H10V13Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

FilterLinesIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
