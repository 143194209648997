import { createSlice } from "@reduxjs/toolkit";
import { fetchClients, fetchClientsById } from "../../action";

const initialState = {
  selected: {},
  clientDetails: [],
};

const NAME = "Client";

export const clientSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchClients.fulfilled, (state, { payload }) => {
      state.clientDetails = payload.data;
    });
    builder.addCase(fetchClientsById.fulfilled, (state, { payload }) => {
      state.selected = payload.data;
    });
  },
});

export const { actions } = clientSlice;

export default clientSlice.reducer;
