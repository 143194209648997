import { createSlice } from "@reduxjs/toolkit";
import { fetchDocumentList, fetchDocumentListById } from "../../action";

const initialState = {
  selected: {},
  documents: [
    {
      lawyerId: null,
      clientName: "",
      projects: [],
      pdfNames: [],
    },
  ],
  uploadedDocuments: 0,
};
const NAME = "document";

const documentSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setUploadedDocumentCount(state, { payload }) {
      state.uploadedDocuments = state.uploadedDocuments + payload;
    },
    resetUploadedDocumentCount(state) {
      state.uploadedDocuments = 0;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDocumentList.fulfilled, (state, { payload }) => {
      state.documents = payload.data ?? initialState.documents;
    });
    builder.addCase(fetchDocumentListById.fulfilled, (state, { payload }) => {
      state.selected = payload.data;
    });
  },
});

export const { actions } = documentSlice;

export default documentSlice.reducer;
