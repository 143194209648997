import { useState, useEffect } from "react";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import {
  HeadText,
  NormalText,
  SmallText,
} from "../../../../components/typography";
import { MUIButton } from "../../../../components/atoms/button/mui-button";
import { SkeletonRect } from "../../../../components/atoms/loader/skeleton";
import { useDispatch, useSelector } from "react-redux";
import { fetchSupportDetailsById } from "../../../../action";
import PropTypes from "prop-types";
import { ADMIN, GENERAL } from "../../../../constant";
import { DoneIcon } from "../../../../components/icons";
import { COLORS } from "../../../../configuration";

const TextBox = ({ isLoading, children, testId = "text-box" }) => {
  return isLoading ? (
    <SkeletonRect className={"w-full"} />
  ) : (
    <NormalText
      className={
        "text-secondary border border-solid rounded-md border-edge p-[8px] max-h-[240px] overflow-auto"
      }
      testId={testId}
    >
      {children}
    </NormalText>
  );
};

TextBox.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export const ViewRequestDialog = ({ onClose, id, isResolve = false }) => {
  const [data, setData] = useState({
    id: null,
    userd: null,
    name: "",
    phonenumber: "",
    email: "",
    message: "",
    status: null,
  });

  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);
  const supportDetails = useSelector((state) => state.supportRequest);

  useEffect(() => {
    if (supportDetails.selected.id !== id)
      dispatch(fetchSupportDetailsById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setData({ ...supportDetails.selected });
  }, [supportDetails.selected]);

  const loading = view.supportSelect.pending;

  return (
    <MUIDialog
      isOpen
      content={
        <div className="w-max mx-auto">
          <HeadText
            className={"text-primary text-center"}
            testId="view-request-title"
          >
            {ADMIN.viewRequest}
          </HeadText>
          <div className="mt-[20px] flex  flex-col gap-4 w-[420px]">
            <div>
              <SmallText>{GENERAL.name}</SmallText>
              <TextBox isLoading={loading} testId="view-name">
                {data.name}
              </TextBox>
            </div>
            <div>
              <SmallText>{GENERAL.email}</SmallText>
              <TextBox isLoading={loading} testId="view-email">
                {data.email}
              </TextBox>
            </div>
            <div>
              <SmallText>{GENERAL.phoneNumber}</SmallText>
              <TextBox isLoading={loading} testId="view-phone-number">
                {data.phonenumber !== "" ? data.phonenumber : "-"}
              </TextBox>
            </div>
            <div>
              <SmallText>{GENERAL.message}</SmallText>
              <TextBox isLoading={loading} testId="view-message">
                {data.message}
              </TextBox>
            </div>
          </div>
          <div className="mt-[20px] text-right">
            <MUIButton
              size="md"
              color={isResolve ? "success" : "primary"}
              onClick={onClose}
              data-testid="resolve-status-button"
            >
              {isResolve ? (
                <span className="flex gap-2 items-center">
                  <DoneIcon color={COLORS.edge} /> {ADMIN.queryResolved}
                </span>
              ) : (
                ADMIN.okayIWillResolve
              )}
            </MUIButton>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
};

ViewRequestDialog.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.number,
};
