import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useClients } from "../../../../hooks/use-clients";
import { PAGE_SIZE, STATUS } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import { UpdateClientStatusDialog } from "./update-client-status";
import { EditClientDialog } from "./edit-client";
import { DeleteClientDialog } from "./delete-client";
import { UpdateClientRoleDialog } from "./update-client-role";

export const ClientManagement = ({ searchName, statusFilter }) => {
  const { columns, sorting, setSortingState, dialog, setDialog } = useClients();

  const [data, setData] = useState([]);

  const clients = useSelector((state) => state.clients);
  const view = useSelector((state) => state.view);

  useEffect(() => {
    if (clients.clientDetails.length && view.client.success)
      setData([...clients.clientDetails]);
  }, [clients.clientDetails, view.client.success]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    filterFns: {
      nameFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
      statusFilter: (row, columnId, filterValue) => {
        const rowValue = row.original[columnId]?.toString();

        return filterValue === STATUS.active
          ? rowValue === "true"
          : filterValue === STATUS.inActive
            ? rowValue === "false"
            : rowValue === "false" || rowValue === "true";
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.getColumn("name")?.setFilterValue(searchName);
  }, [searchName, table]);

  useEffect(() => {
    table.getColumn("isActive")?.setFilterValue(statusFilter);
  }, [statusFilter, table]);

  return (
    <div>
      <DynamicTable
        table={table}
        columns={columns.length}
        records={data.length}
        isPending={view.client.pending}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      {dialog.isStatus && (
        <UpdateClientStatusDialog
          id={dialog.id}
          status={dialog.value}
          onClose={() => setDialog({ isStatus: false, id: null, value: null })}
        />
      )}
      {dialog.isEdit && (
        <EditClientDialog
          showDialog
          id={dialog.id}
          onClose={() => setDialog({ isEdit: false, id: null, value: null })}
        />
      )}
      {dialog.isDelete && (
        <DeleteClientDialog
          id={dialog.id}
          onClose={() => setDialog({ isDelete: false, id: null, value: null })}
        />
      )}
      {dialog.isAdmin && (
        <UpdateClientRoleDialog
          id={dialog.id}
          status={dialog.value}
          onClose={() => setDialog({ isAdmin: false, id: null, value: null })}
        />
      )}
    </div>
  );
};
