import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const DownArrow = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 524 524"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      strokeWidth={strokeWidth}
    >
      <title>Down</title>
      <path
        d="M64 191L98 157 262 320 426 157 460 191 262 387 64 191Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

DownArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
