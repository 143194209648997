import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const GavelIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4 21V19H16V21H4ZM9.65 16.15L4 10.5L6.1 8.35L11.8 14L9.65 16.15ZM16 9.8L10.35 4.1L12.5 2L18.15 7.65L16 9.8ZM20.6 20L7.55 6.95L8.95 5.55L22 18.6L20.6 20Z"
        fill={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

GavelIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
