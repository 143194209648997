import { ClientFilter } from "./client-filter";
import { ProjectFilter } from "./project-filter";
import { ProcessingMenus } from "./processing-menus";
import { CategoryFilter } from "./category-filter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const initialValue = { id: "", value: "", isError: false };
export const GPTProcessing = () => {
  const [client, setClient] = useState(initialValue);
  const [project, setProject] = useState(initialValue);
  const [category, setCategory] = useState(initialValue);

  const view = useSelector((state) => state.view);

  useEffect(() => {
    setProject(initialValue);
    setCategory(initialValue);
  }, [view.clientProjects.success]);

  return (
    <div className="w-full mx-auto">
      <div
        className={`flex gap-4 justify-between ${!view?.clientProjects?.pending && (client.isError || project.isError || category.isError) ? "items-start" : "items-end"}`}
      >
        <div className="w-full">
          <ClientFilter
            callback={(value) => setClient({ ...client, ...value })}
            isError={client.isError}
          />
        </div>
        <div className="w-full">
          <ProjectFilter
            callback={(value) => setProject({ ...project, ...value })}
            isError={project.isError}
          />
        </div>
        <div className="w-full">
          <CategoryFilter
            projectId={project?.id}
            callback={(value) => setCategory({ ...category, ...value })}
            isError={category.isError}
          />
        </div>
      </div>
      <div className="mt-[20px] lg:w-1/2 w-full mx-auto">
        <ProcessingMenus
          businessId={client?.id ?? ""}
          categoryId={category?.id ?? ""}
          categoryName={category?.value ?? ""}
          projectId={project?.id ?? ""}
          projectName={project?.value ?? ""}
          callBack={(value) => {
            setClient({ ...client, isError: value?.isClient ?? false });
            setProject({ ...project, isError: value?.isProject ?? false });
            setCategory({ ...category, isError: value?.isCategory ?? false });
          }}
        />
      </div>
    </div>
  );
};
