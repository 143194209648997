import { createSlice } from "@reduxjs/toolkit";
import { fetchCategories } from "../../action";
import { convertKeysToCamelCase } from "../../utils/camelize";

const initialState = {
  categoryDetails: [
    {
      id: 1,
      categoryName: "Account",
      subCategory: [{ subCategoryId: 1, subCategoryName: "Financial Audits" }],
      isActive: true,
    },
  ],
};

const NAME = "category";

const categorySlice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.categoryDetails = convertKeysToCamelCase(
        payload.response.category_details
      );
    });
  },
});

export const { actions } = categorySlice;

export default categorySlice.reducer;
