import { Divider } from "@mui/material";
import { TextArea } from "../../components/atoms/input/text-area";
import { TextField } from "../../components/atoms/input/text-field";
import { CallIcon, EmailIcon, LocationIcon } from "../../components/icons";
import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { NormalText } from "../../components/typography";
import {
  COMPANY_ADDRESS,
  COMPANY_CALL,
  COMPANY_EMAIL,
  CONTACT_MESSAGE,
  GENERAL,
} from "../../constant";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";
import { useEffect, useState } from "react";
import {
  validEmail,
  validMessage,
  validName,
  validPhone,
  validSupportDetails,
} from "../../utils/validation-functions";
import { useDispatch, useSelector } from "react-redux";
import { sendSupportRequest } from "../../action";

const initialState = { fullName: "", phoneNumber: "", email: "", message: "" };

export const ContactUsPage = () => {
  const [contactDetails, setContactDetails] = useState(initialState);
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);

  const handleSubmit = () => {
    setIsSubmit(true);
    const isValid = validSupportDetails({ ...contactDetails });

    if (isValid) dispatch(sendSupportRequest({ ...contactDetails }));
  };

  const resetForm = () => {
    setContactDetails(initialState);
    setIsSubmit(false);
  };

  useEffect(() => {
    if (view.supportAdd.success) {
      resetForm();
    }
  }, [view.supportAdd.success]);

  return (
    <div>
      <PageHeader title={"Contact Us"}></PageHeader>
      <PageBody>
        <div className="flex justify-between gap-4">
          <div className="w-full">
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                value={contactDetails.fullName}
                placeholder={"Full Name*"}
                errorText={
                  isSubmit ? validName(contactDetails.fullName).message : ""
                }
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    fullName: e.target.value,
                  })
                }
              />
              <TextField
                value={contactDetails.phoneNumber}
                type={"tel"}
                placeholder={"Phone Number*"}
                errorText={
                  validPhone(contactDetails.phoneNumber, isSubmit).message
                }
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    phoneNumber: e.target.value,
                  })
                }
              />
              <TextField
                value={contactDetails.email}
                type={"email"}
                placeholder={"Email*"}
                errorText={
                  isSubmit ? validEmail(contactDetails.email).message : ""
                }
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    email: e.target.value,
                  })
                }
              />
              <TextArea
                value={contactDetails.message}
                placeholder={"Write Message...*"}
                onChange={(e) =>
                  setContactDetails({
                    ...contactDetails,
                    message: e.target.value,
                  })
                }
                errorText={
                  isSubmit ? validMessage(contactDetails.message).message : ""
                }
                maxSize={500}
              />
              <div className="w-max ml-auto flex gap-2">
                <Button
                  type={"reset"}
                  size="md"
                  variant="text"
                  onClick={resetForm}
                >
                  {GENERAL.reset}
                </Button>
                <Button
                  type={"submit"}
                  size="md"
                  isLoading={view.supportAdd.pending}
                >
                  {GENERAL.send}
                </Button>
              </div>
            </form>
          </div>
          <div className="bg-primary-hover flex flex-col gap-4 rounded-md ml-[8px] px-[20px] w-[420px] h-max py-[28px]">
            <div className="flex gap-2">
              <LocationIcon />
              <NormalText className={"w-max"}>{COMPANY_ADDRESS}</NormalText>
            </div>
            <div className="flex gap-2">
              <CallIcon />
              <NormalText className={"w-max"}>{COMPANY_CALL}</NormalText>
            </div>
            <Divider />
            <NormalText>{CONTACT_MESSAGE}</NormalText>
            <div className="flex gap-2">
              <EmailIcon />
              <NormalText>{COMPANY_EMAIL}</NormalText>
            </div>
          </div>
        </div>
      </PageBody>
    </div>
  );
};
