import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { AccordionV2 } from "../../../components/atoms/accordion/accordion-v2";
import { MUICheckBox } from "../../../components/atoms/checkbox";
import { NormalText } from "../../../components/typography";
import { PROJECTS } from "../../../constant";

const Chip = ({ isActive, children, onClick }) => (
  <Button
    onClick={onClick}
    variant={isActive ? "contained" : "outlined"}
    color={isActive ? "primary" : "edge"}
  >
    {children}
  </Button>
);

Chip.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

const AccordionTitle = ({ item, callback }) => {
  const chipIDs = {
    syntheticName: "synthetic-name",
    originalName: "original-name",
    keyPhrase: "key-phrase",
    aiTheme: "ai-theme",
  };

  const [selectedChip, setSelectedChip] = useState({
    id: chipIDs.syntheticName,
    text: "Synthetic File Name",
    label: item.synthetic_filename,
  });

  const chips = [
    {
      id: chipIDs.syntheticName,
      text: "Synthetic File Name",
      label: item.synthetic_filename,
    },
    {
      id: chipIDs.originalName,
      text: "Original Filename",
      label: item.original_filename,
    },
    { id: chipIDs.keyPhrase, text: "Key Phrases", label: item.keyphrases },
    {
      id: chipIDs.aiTheme,
      text: "AI Generated Themes",
      label: item.ai_generated_theme,
    },
  ];

  const handleChipClick = useCallback(
    (chip) => {
      setSelectedChip(chip);
    },
    [setSelectedChip]
  );

  return (
    <div className="flex flex-col gap-[10px]">
      <div className="flex gap-[4px]">
        {chips.map((chip) => (
          <Chip
            key={chip.id}
            isActive={selectedChip.id === chip.id}
            onClick={() => handleChipClick(chip)}
          >
            {chip.text}
          </Chip>
        ))}
      </div>
      <div className="font-semibold flex items-center">
        <MUICheckBox id={item.id} onChange={(value) => callback?.(value)} />
        <label htmlFor={item.id} className="cursor-pointer">
          {selectedChip.label}
        </label>
      </div>
    </div>
  );
};

AccordionTitle.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    synthetic_filename: PropTypes.string,
    original_filename: PropTypes.string,
    keyphrases: PropTypes.string,
    ai_generated_theme: PropTypes.string,
  }).isRequired,
  callback: PropTypes.func,
};

export const DDQResponseSelection = ({ currentPageData = [], callback }) => {
  const [selected, setSelected] = useState([]);

  const handleSelection = useCallback(
    (value, id) => {
      let updatedSelected;
      if (value) {
        updatedSelected = [...selected, id];
      } else {
        updatedSelected = selected.filter((v) => v !== id);
      }
      setSelected(updatedSelected);
      callback?.(updatedSelected);
    },
    [selected, callback]
  );

  return (
    <div className="mb-[20px]">
      {currentPageData.length > 0 ? (
        currentPageData.map((item) => (
          <AccordionV2
            title={
              <AccordionTitle
                item={item}
                callback={(value) => handleSelection(value, item.id)}
              />
            }
            key={item.id}
          >
            <p className="text-justify"> &emsp;{item.chunk}</p>
          </AccordionV2>
        ))
      ) : (
        <div className="border border-solid border-edge rounded-md px-[20px] py-[16px] text-center">
          <NormalText>{PROJECTS.noResponsesAvailable}</NormalText>
        </div>
      )}
    </div>
  );
};

DDQResponseSelection.propTypes = {
  currentPageData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      synthetic_filename: PropTypes.string,
      original_filename: PropTypes.string,
      keyphrases: PropTypes.string,
      ai_generated_theme: PropTypes.string,
      chunk: PropTypes.string,
    })
  ).isRequired,
  callback: PropTypes.func,
};
