import {
  FormControl,
  RadioGroup,
  Radio as MUIRadio,
  FormControlLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { LabelText, SmallText } from "../../typography";
import { CustomTheme } from "../../theme/custom-theme";
import { useEffect, useState } from "react";

/**
 * RadioButtons Component
 *
 * Displays a group of radio buttons with labels and handles change events.
 *
 * Props:
 * - id: String, component ID.
 * - groupLabel: Node, label for the radio button group.
 * - size: One of "sm" or "md" (default: "sm"), size of the radio buttons.
 * - labels: Array of objects { id: any, name: string } (required), labels for radio buttons.
 * - onChange: Function, callback for radio button change events.
 * - isRow: Boolean, whether to display radio buttons in a row (default: false).
 * - checked: Any, current checked value of the radio button group.
 *
 * Example usage:
 * ```
 * <RadioButtons
 *   id="radio-group"
 *   groupLabel="Select Option"
 *   labels={[
 *     { id: 1, name: "Option 1" },
 *     { id: 2, name: "Option 2" }
 *   ]}
 *   onChange={(value) => console.log("Selected value:", value)}
 * />
 * ```
 */
export const RadioButtons = ({
  id,
  groupLabel,
  size = "sm",
  labels,
  onChange = () => {},
  isRow = false,
  checked,
}) => {
  // Map size options from abbreviations to MUI sizes
  const sizeMapping = {
    sm: "small",
    md: "medium",
  };

  // Determine the radio size based on the provided size prop
  const radioSize = sizeMapping[size] || "small";

  const [checkedValue, setCheckedValue] = useState(checked);

  // Update checked value state when prop changes
  useEffect(() => {
    setCheckedValue(checked);
  }, [checked]);

  // Return the radio button group with labels
  return (
    <CustomTheme>
      <div className="flex items-center" id={id}>
        <FormControl>
          <SmallText>{groupLabel}</SmallText>
          <RadioGroup
            onChange={(event) => {
              onChange(event.target.value);
              setCheckedValue(event.target.value);
            }}
            row={isRow}
            className="mt-[4px]"
          >
            {labels?.map((radioItem) => (
              <FormControlLabel
                key={radioItem.id}
                value={radioItem.id}
                control={
                  <MUIRadio
                    data-testid="radio-input"
                    size={radioSize}
                    id={radioItem?.id?.toString() ?? ""}
                    // eslint-disable-next-line eqeqeq
                    checked={radioItem.id == checkedValue}
                  />
                }
                label={
                  <LabelText
                    labelFor={radioItem?.id?.toString() ?? ""}
                    className="capitalize cursor-pointer"
                  >
                    {radioItem.name}
                  </LabelText>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </CustomTheme>
  );
};

// Define expected prop types and their constraints
RadioButtons.propTypes = {
  id: PropTypes.string,
  groupLabel: PropTypes.node,
  size: PropTypes.oneOf(["sm", "md"]),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  isRow: PropTypes.bool,
  checked: PropTypes.any,
};

// Define default prop values for optional props
RadioButtons.defaultProps = {
  size: "sm",
  onChange: () => {},
  isRow: false,
};
