import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const LogoutIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Logout">
        <path
          id="rect1105"
          d="M19.9931 1H7.46338V20.0005H19.9931V1Z"
          stroke={color}
          strokeMiterlimit="6.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="path1107"
          d="M1 10.5H15.2588"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="path1109"
          d="M15.2586 10.5L11.0591 14.609"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
        <path
          id="path1111"
          d="M15.2586 10.5L11.0591 6.39099"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

LogoutIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};

