import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const CloseIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <title>Close</title>
      <path
        d="M1.6 16.0002L0 14.4002L6.4 8.00018L0 1.60018L1.6 0.000183105L8 6.40018L14.4 0.000183105L16 1.60018L9.6 8.00018L16 14.4002L14.4 16.0002L8 9.60018L1.6 16.0002Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

CloseIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
