import { useNavigate } from "react-router-dom";
import { LogoutIcon } from "../components/icons";
import { GeneralMenu } from "../components/molecules/menu/toggle-menu";
import { GENERAL } from "../constant";
import { useDispatch } from "react-redux";
import { actions } from "../universal/slice/user";

export function LogoutMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    dispatch(actions.setAdminActive(false));
    window.location.reload();
  };

  return (
    <GeneralMenu>
      <button
        className="-ml-2 cursor-pointer bg-none border-none bg-transparent flex text-base gap-4 text-secondary hover:text-primary"
        onClick={handleLogout}
      >
        <LogoutIcon strokeWidth={1.3} />
        {GENERAL.logout}
      </button>
    </GeneralMenu>
  );
}
