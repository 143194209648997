import { Link } from "react-router-dom";
import { MUIButton as Button } from "../../components/atoms/button/mui-button";
import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { NormalText } from "../../components/typography";
import { GENERAL } from "../../constant";
import { pathList } from "../../routes/app-navigation";
import { actions } from "../slice/user";
import { useDispatch, useSelector } from "react-redux";
import { SkeletonRect } from "../../components/atoms/loader/skeleton";
import PropTypes from "prop-types";

const TextBox = ({ isLoading, value, testId = "text-box" }) => {
  return isLoading ? (
    <SkeletonRect className={"w-full"} />
  ) : (
    <NormalText
      className={
        "text-secondary border border-solid rounded-md border-edge p-[8px] max-h-[240px] overflow-auto"
      }
      testId={testId}
    >
      {value !== "" ? (
        value
      ) : (
        <span className="text-secondary-light text-sm">
          {GENERAL.notAvailable}
        </span>
      )}
    </NormalText>
  );
};

TextBox.propTypes = {
  isLoading: PropTypes.bool,
  value: PropTypes.string,
};

export const MyProfilePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  return (
    <div>
      <PageHeader title={"My Profile"} />
      <PageBody>
        <div className="flex flex-col gap-4">
          <div>
            <NormalText>{GENERAL.firstName}</NormalText>
            <TextBox value={user.firstName} />
          </div>
          <div>
            <NormalText>{GENERAL.lastName}</NormalText>
            <TextBox value={user.lastName} />
          </div>
          <div>
            <NormalText>{GENERAL.email}</NormalText>
            <TextBox value={user.email} />
          </div>
          <div>
            <NormalText>{GENERAL.phoneNumber}</NormalText>
            <TextBox value={user.phoneNumber} />
          </div>
          <div className="flex gap-4 justify-end">
            <Link
              to={pathList.user.projectList}
              onClick={() => dispatch(actions.setAdminActive(false))}
              className="no-underline"
            >
              <Button variant="text" size="md">
                {GENERAL.cancel}
              </Button>
            </Link>
          </div>
        </div>
      </PageBody>
    </div>
  );
};
