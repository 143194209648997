import { createColumnHelper } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { MUIButton as Button } from "../components/atoms/button/mui-button";
import { QUERY_STATUS } from "../constant";
import { DownFilledArrow, EyeIcon, UpFilledArrow } from "../components/icons";
import { COLORS } from "../configuration";

export const useSupport = () => {
  const columnHelper = createColumnHelper();

  const [dialog, setDialog] = useState({
    isResolve: false,
    isPending: false,
    isView: false,
    id: null,
    value: null,
  });

  const columnNames = useMemo(
    () => ({
      id: "id",
      serialNo: "Sr. No.",
      name: "Name",
      phoneNumber: "Phone Number",
      email: "Email",
      message: "Message",
      queryStatus: "Query Status",
      view: "View",
    }),
    []
  );
  const [sortingState, setSortingState] = useState([
    {
      id: "id",
      desc: true,
    },
  ]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("id", {
        id: "id",
        cell: (props) => props.row.index + 1,
        enableSorting: true,
        sortingFn: "basic",
        header: (
          <span data-testid="category-id">
            {columnNames.serialNo}
            <button
              className="bg-none border-none cursor-pointer"
              onClick={() => {
                sortingState[0].desc = !sortingState[0].desc;
                setSortingState([...sortingState]);
              }}
            >
              {sortingState[0].desc ? (
                <DownFilledArrow
                  height={12}
                  width={12}
                  color={COLORS.primary}
                />
              ) : (
                <UpFilledArrow height={12} width={12} color={COLORS.primary} />
              )}
            </button>
          </span>
        ),
      }),
      columnHelper.accessor("name", {
        id: "name",
        cell: (props) => props.getValue(),
        header: columnNames.name,
        filterFn: "nameFilter",
      }),
      columnHelper.accessor("phonenumber", {
        id: "phone-number",
        cell: (props) => (props.getValue() !== "" ? props.getValue() : "-"),
        header: columnNames.phoneNumber,
      }),
      columnHelper.accessor("email", {
        id: "email",
        cell: (props) => props.getValue(),
        header: columnNames.email,
      }),
      columnHelper.accessor("message", {
        id: "message",
        cell: (props) => (
          <p className="w-[420px] truncate" data-testid="message-cell">
            {props.getValue()}
          </p>
        ),
        header: columnNames.message,
      }),
      columnHelper.accessor(
        (row) => {
          return { id: row.id, status: row.status };
        },
        {
          id: "status",
          cell: (props) =>
            props.getValue().status ? (
              <Button
                className={"bg-success-light text-success"}
                onClick={() => setDialog({ isResolve: true })}
                data-testid="resolved-tag-button"
              >
                {QUERY_STATUS.resolved}
              </Button>
            ) : (
              <Button
                className={"bg-error-light text-error"}
                onClick={() =>
                  setDialog({ isPending: true, id: props.getValue().id })
                }
                data-testid="pending-tag-button"
              >
                {QUERY_STATUS.pending}
              </Button>
            ),
          header: columnNames.queryStatus,
          filterFn: "statusFilter",
        }
      ),
      columnHelper.accessor(
        (row) => {
          return { id: row.id, status: row.status };
        },
        {
          id: "view",
          cell: (props) => (
            <Button
              variant="text"
              onClick={() =>
                setDialog({
                  isView: true,
                  id: props.getValue().id,
                  value: props.getValue().status,
                })
              }
              data-testid="view-button"
            >
              <EyeIcon strokeWidth={1.5} />
            </Button>
          ),
          header: columnNames.view,
        }
      ),
    ],
    [columnHelper, columnNames, sortingState]
  );

  return {
    columnNames,
    columns,
    sortingState,
    setSortingState,
    dialog,
    setDialog,
  };
};
