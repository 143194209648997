import { useEffect, useState } from "react";
import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { GENERAL, PROJECTS } from "../../../constant";
import { MUICheckBox } from "../../../components/atoms/checkbox";
import {
  HeadText,
  NormalText,
  SmallText,
  SubHeadText,
} from "../../../components/typography";
import { TextArea } from "../../../components/atoms/input/text-area";
import { MUIButton } from "../../../components/atoms/button/mui-button";
import { SendIcon } from "../../../components/icons";
import { COLORS } from "../../../configuration";
import { validComment } from "../../../utils/validation-functions";
import { useDispatch, useSelector } from "react-redux";
import { sendFeedback } from "../../../action";
import { useParams } from "react-router-dom";

const QuestionRow = ({ children }) => (
  <div className="grid grid-cols-2 gap-8 mb-[16px]">{children}</div>
);

const FilesNameCheckboxes = ({ files = [], isViewOnly, onChange }) => {
  const [selected, setSelected] = useState([]);
  return (
    <div>
      {files?.length > 0 ? (
        <div className="flex flex-col gap-2 max-h-[130px] overflow-auto border-edge border-solid border rounded-md p-[4px]">
          {files.map((i, index) => (
            <span
              key={i.id}
              className={`${
                index % 2 ? "bg-primary-hover" : "bg-white"
              } w-[362px] rounded-md hover:bg-zinc-100`}
            >
              <MUICheckBox
                checked={isViewOnly}
                label={i.fileName}
                id={`checkbox-${i.id}`}
                disable={isViewOnly}
                onChange={(value) => {
                  let selectedFiles = [...selected];
                  if (value) {
                    selectedFiles.push({ id: i.id, fileName: i.fileName });
                  } else {
                    selectedFiles = selected.filter((item) => item.id !== i.id);
                  }
                  setSelected([...selectedFiles]);
                  onChange?.(selectedFiles);
                }}
                key={i.id}
              />
            </span>
          ))}
        </div>
      ) : (
        <SmallText className="text-center p-[8px]">
          {GENERAL.noVDRAvailable}
        </SmallText>
      )}
    </div>
  );
};

export const initialFeedback = {
  files: [],
  llmResponse: null,
  comments: null,
  missingDocuments: null,
};

export const FeedbackDialog = ({
  isOpen,
  question,
  onClose,
  files = [],
  isViewOnly = false,
  data = { ...initialFeedback },
  ddqId,
  categoryId,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [isSubmit, setIsSubmit] = useState(false);
  const [feedbackData, setFeedbackData] = useState({ ...initialFeedback });

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isViewOnly) {
      setFeedbackData({
        ...data,
        files: data?.files?.map((item, index) => ({
          id: index,
          fileName: item,
        })),
      });
    }
  }, [data, isViewOnly]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const view = useSelector((state) => state.view);

  const handleClose = () => {
    setIsSubmit(false);
    setFeedbackData({ ...initialFeedback });
    onClose?.();
  };

  const handleSend = () => {
    setIsSubmit(true);
    if (
      feedbackData.files.length > 0 ||
      validComment(feedbackData.llmResponse).status ||
      validComment(feedbackData.comments).status
    ) {
      dispatch(
        sendFeedback({ ...feedbackData, projectId: id, ddqId, categoryId })
      ).then(() => {
        handleClose();
      });
    }
  };

  return (
    open && (
      <MUIDialog
        isOpen={true}
        title={
          <div>
            <HeadText>{question}</HeadText>{" "}
            <SubHeadText>{PROJECTS.responseFeedback}</SubHeadText>{" "}
          </div>
        }
        onClose={handleClose}
        width="md"
        content={
          <div className="flex flex-col">
            <QuestionRow>
              <span>
                <NormalText> {`${PROJECTS.vdrReferences} :`}</NormalText>
              </span>
              <span className="flex">
                <FilesNameCheckboxes
                  isViewOnly={isViewOnly}
                  files={isViewOnly ? feedbackData.files : files}
                  onChange={(value) =>
                    setFeedbackData({
                      ...feedbackData,
                      files: value.map((f) => f.fileName),
                    })
                  }
                />
              </span>
            </QuestionRow>
            <QuestionRow>
              <NormalText>{`${PROJECTS.llmResponse} :`}</NormalText>
              <div>
                <TextArea
                  placeholder="Provide comments on the quality of the LLM response"
                  value={feedbackData.llmResponse}
                  onChange={(e) =>
                    setFeedbackData({
                      ...feedbackData,
                      llmResponse: e.target.value,
                    })
                  }
                  errorText={
                    isSubmit && feedbackData.files.length > 0
                      ? validComment(feedbackData.llmResponse).message
                      : ""
                  }
                  disable={isViewOnly}
                />
              </div>
            </QuestionRow>
            <QuestionRow>
              <NormalText>{`${PROJECTS.missingDocuments} :`}</NormalText>
              <div>
                <TextArea
                  placeholder="Provide names for files if they are missing."
                  value={feedbackData.missingDocuments}
                  onChange={(e) =>
                    setFeedbackData({
                      ...feedbackData,
                      missingDocuments: e.target.value,
                    })
                  }
                  disable={isViewOnly}
                />
              </div>
            </QuestionRow>
            <QuestionRow>
              <NormalText>{`${PROJECTS.generalComments} :`}</NormalText>
              <div>
                <TextArea
                  value={feedbackData.comments}
                  placeholder="Provide any other general comments as appropriate"
                  onChange={(e) =>
                    setFeedbackData({
                      ...feedbackData,
                      comments: e.target.value,
                    })
                  }
                  disable={isViewOnly}
                />
              </div>
            </QuestionRow>
          </div>
        }
        footer={
          !isViewOnly && (
            <div className="flex gap-2">
              <MUIButton variant="text" size="md" onClick={handleClose}>
                {GENERAL.cancel}
              </MUIButton>
              <MUIButton
                size="md"
                startIcon={<SendIcon color={COLORS.white} />}
                onClick={handleSend}
                isLoading={view.feedback.send.pending}
              >
                {PROJECTS.send}
              </MUIButton>
            </div>
          )
        }
      />
    )
  );
};
