import { PageHeader } from "../../components/organism/header/page-header";
import { PROJECTS } from "../../constant";
import { useParams } from "react-router-dom";
import { pathList } from "../../routes/app-navigation";
import { PageBody } from "../../components/organism/body/page-body";

import { useDispatch, useSelector } from "react-redux";
import { fetchProjectById } from "../../action";
import { useEffect } from "react";
import { SkeletonRect } from "../../components/atoms/loader/skeleton";
import EditProject from "../features/project-list/edit-project/edit-project";
import { BreadCrumbs } from "../../components/atoms/bread-crumbs";

export const EditProjectPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProjectById({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const project = useSelector((state) => state.project);
  const view = useSelector((state) => state.view);

  return (
    <div>
      <PageHeader title={"Edit Project"}>
        <BreadCrumbs
          data={[
            {
              name: <span> {PROJECTS.allProjects} </span>,
              link: pathList.user.projectList,
            },
            {
              name: view.project.pending ? (
                <SkeletonRect width={48} height={24} />
              ) : (
                <span>{project.selectedProject?.name ?? ""}</span>
              ),
            },
          ]}
        />
      </PageHeader>
      <PageBody>
        <EditProject />
      </PageBody>
    </div>
  );
};
