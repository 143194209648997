import { Tooltip, tooltipClasses } from "@mui/material";
import PropTypes from "prop-types";
import { COLORS } from "../../../configuration";
import { TEXT } from "../../../constant";
import { CustomTheme } from "../../theme/custom-theme";

/**
 * ToolTip Component:
 * Displays a tooltip with custom styles and options.
 *
 * Props:
 * - text: Text to be displayed inside the tooltip.
 * - fontSize: Font size of the text inside the tooltip.
 * - textColor: Text color inside the tooltip.
 * - color: Background color of the tooltip.
 * - position: Position of the tooltip relative to the trigger element.
 * - offset: Offset value for adjusting tooltip position.
 *
 * Example Usage:
 * <ToolTip fontSize={14} text={"Tooltip text"} position="right" offset={4}>
 *   <Content/>
 * </ToolTip>
 */
export const ToolTip = ({
  children,
  text,
  fontSize = TEXT.fontSize,
  color = COLORS.secondary,
  textColor = COLORS.white,
  position = "top",
  offset = -10,
}) => {
  return (
    <CustomTheme>
      <Tooltip
        arrow // Adds an arrow pointing to the element being hovered over
        title={<span style={{ fontSize, color: `${textColor}` }}>{text}</span>} // Tooltip text with specified font size
        placement={position}
        className="cursor-pointer" // Changes cursor to pointer when hovered over the element
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, offset],
                },
              },
            ],
          },
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: color, // Background color of the tooltip
              "& .MuiTooltip-arrow": {
                color: color, // Color of the tooltip arrow
              },
              fontSize: fontSize, // Font size of the text in the tooltip
              margin: "16px",
            },
          },
        }}
      >
        {children} {/* Element that triggers the tooltip */}
      </Tooltip>
    </CustomTheme>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.number,
  offset: PropTypes.number,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};
