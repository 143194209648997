import { useEffect } from "react";
import { AddClientDialog } from "./add-client";
import PropTypes from "prop-types";
import { fetchClientsById } from "../../../../action";
import { useDispatch } from "react-redux";

export const EditClientDialog = ({ showDialog, onClose, id }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchClientsById({ id }));
  }, [dispatch, id]);

  return (
    <AddClientDialog isEdit showDialog={showDialog} onClose={onClose} id={id} />
  );
};

EditClientDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  id: PropTypes.number,
};
