import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const LeftIcon = ({
  color = ICONS.color,  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      strokeWidth={strokeWidth}
    >
      <title>Left</title>
      <path
        d="M12 5.65246L8.27848 9.46861L12 13.2848L10.8608 14.453L6 9.46861L10.8608 4.48425L12 5.65246Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

LeftIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
