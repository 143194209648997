import { useMemo, useState } from "react";
import {
  createChunkIndex,
  createSummaryIndex,
  sendChunker,
  sendDataToChunkIndex,
  sendDataToSummaryIndex,
  sendDocParser,
  sendResponseGenerator,
  sendSummary,
} from "../../../../action";
import { MUIButton } from "../../../../components/atoms/button/mui-button";
import { ProcessIcon } from "../../../../components/icons";
import { COLORS } from "../../../../configuration";
import { useDispatch, useSelector } from "react-redux";
import { MUICheckBox } from "../../../../components/atoms/checkbox";

const MenuItem = ({
  id,
  name,
  isForceView,
  onClick,
  onForceChange,
  isLoading,
}) => (
  <div className="border border-solid border-edge rounded-lg p-4 flex justify-between gap-4 items-center">
    <div>{name}</div>
    <div className="flex gap-4">
      {isForceView && (
        <div>
          <MUICheckBox
            label={"Force to Create"}
            id={`force-checkbox-${id}`}
            onChange={onForceChange}
          />
        </div>
      )}
      <MUIButton
        onClick={onClick}
        startIcon={<ProcessIcon color={COLORS.white} />}
        isLoading={isLoading}
      >
        Process
      </MUIButton>
    </div>
  </div>
);

export const ProcessingMenus = ({
  businessId = "",
  projectId = "",
  categoryId = "",
  projectName = "",
  categoryName = "",
  callBack = () => {},
}) => {
  const dispatch = useDispatch();
  const [forceFlags, setForceFlags] = useState({
    isSummaryIndexForce: false,
    isChunkIndexForce: false,
  });

  const view = useSelector((state) => state.view);

  const handleForceChange = (id, isChecked) => {
    setForceFlags((prev) => ({
      ...prev,
      isSummaryIndexForce: id === 4 ? isChecked : prev.isSummaryIndexForce,
      isChunkIndexForce: id === 5 ? isChecked : prev.isChunkIndexForce,
    }));
  };

  const createLog = () => {
    callBack?.({
      isClient: !businessId,
      isProject: !projectId,
      isCategory: !categoryId,
    });
  };

  const Menus = useMemo(
    () => [
      {
        id: 1,
        name: "Parse Document",
        isForceView: false,
        onClick: () => {
          if (businessId && projectId && categoryId)
            dispatch(sendDocParser({ businessId, projectId, categoryId }));
          else createLog();
        },
        isLoading: view.gptProcessing.parseDocument.pending,
      },
      {
        id: 2,
        name: "Create Summaries",
        isForceView: false,
        onClick: () => {
          if (
            businessId &&
            projectId &&
            categoryId &&
            projectName &&
            categoryName
          )
            dispatch(
              sendSummary({
                businessId,
                projectId,
                categoryId,
                projectName,
                categoryName,
              })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.currentSummaries.pending,
      },
      {
        id: 3,
        name: "Create Chunks",
        isForceView: false,
        onClick: () => {
          if (businessId && projectId && categoryId && categoryName)
            dispatch(
              sendChunker({ businessId, projectId, categoryId, categoryName })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.createChunks.pending,
      },
      {
        id: 4,
        name: "Create Summary Index",
        isForceView: true,
        onClick: () => {
          if (projectId && projectName)
            dispatch(
              createSummaryIndex({
                projectId,
                projectName,
                isForce: forceFlags.isSummaryIndexForce,
              })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.createSummaryIndex.pending,
      },
      {
        id: 5,
        name: "Create Chunk Index",
        isForceView: true,
        onClick: () => {
          if (projectId && projectName)
            dispatch(
              createChunkIndex({
                projectId,
                projectName,
                isForce: forceFlags.isChunkIndexForce,
              })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.createChunkIndex.pending,
      },
      {
        id: 6,
        name: "Add Data to Summary Index",
        isForceView: false,
        onClick: () => {
          if (businessId && projectId && categoryId && projectName)
            dispatch(
              sendDataToSummaryIndex({
                businessId,
                projectId,
                categoryId,
                projectName,
              })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.addDataSummary.pending,
      },
      {
        id: 7,
        name: "Add Data to Chunk Index",
        isForceView: false,
        onClick: () => {
          if (businessId && projectId && categoryId && projectName)
            dispatch(
              sendDataToChunkIndex({
                businessId,
                projectId,
                projectName,
                categoryId,
              })
            );
          else createLog();
        },
        isLoading: view.gptProcessing.addDataChunk.pending,
      },
      {
        id: 8,
        name: "Response Generator",
        isForceView: false,
        onClick: () => {
          if (businessId && projectId && categoryId) {
            dispatch(
              sendResponseGenerator({ businessId, projectId, categoryId })
            );
          } else createLog();
        },
        isLoading: view.gptProcessing.runDDQ.pending,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      businessId,
      projectId,
      categoryId,
      dispatch,
      projectName,
      categoryName,
      forceFlags,
      view.gptProcessing,
    ]
  );

  return (
    <div className="flex flex-col gap-4">
      {Menus.map((menu) => (
        <MenuItem
          key={menu.id}
          id={menu.id}
          name={menu.name}
          isForceView={menu.isForceView}
          onClick={menu.onClick}
          onForceChange={(isChecked) => handleForceChange(menu.id, isChecked)}
          isLoading={menu.isLoading}
        />
      ))}
    </div>
  );
};
