import { Divider } from "@mui/material";

import { AdminMenus } from "../../../routes/app-navigation";

import { NavigationMenus } from "../../molecules/menu/navigation-menus";
import { useLocation } from "react-router-dom";

export const AdminSideMenus = () => {
  const location = useLocation();

  const find = AdminMenus.find((i) =>
    i.subMenus?.length
      ? i.url.includes(location.pathname)
      : i.url.includes(location.pathname.split("/")[1])
  );

  return (
    <div className="pt-[20px]">
      <NavigationMenus
        menus={AdminMenus}
        activeParentId={find?.id ?? ""}
        parentActive={find}
      />
      <Divider />
    </div>
  );
};
