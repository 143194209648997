import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const UploadIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M52 36.9428L51.9999 51.3315C51.9999 51.6906 51.7604 51.9796 51.4628 51.9796H2.53713C2.23955 51.9796 2 51.6906 2 51.3315V36.9428"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="6.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.998 44.8622V2"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9986 2.00003L14.6426 14.6238"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.998 2.00003L39.3541 14.6238"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

UploadIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
