import { createSlice, nanoid } from "@reduxjs/toolkit";
import {
  addClient,
  addDdqByCSV,
  addDdqQuestion,
  createNewCategory,
  createNewProject,
  deleteCategory,
  deleteClient,
  deleteDDQ,
  deleteProject,
  downloadSearchLessDocument,
  editCategory,
  editClient,
  editDDQ,
  fetchCategories,
  fetchClients,
  fetchClientsById,
  fetchSupportDetails,
  fetchDdqQuestionById,
  fetchDdqQuestions,
  fetchDocumentList,
  fetchProjectById,
  fetchProjectList,
  forgotPassword,
  loginUser,
  registerUser,
  updateCategoryStatus,
  updateClientStatus,
  fetchSupportDetailsById,
  resolveSupportRequest,
  sendSupportRequest,
  generateDDQResponse,
  fetchDocumentListById,
  deleteDocument,
  editProject,
  updateCategoryAndUploadDocument,
  resendEmailVerification,
  resetPassword,
  fetchDDQResponse,
  fetchDDQDocuments,
  fetchDDQSelectionsChunks,
  generateResponse,
  fetchDDQResponseSelectedList,
  saveAndProcessResponse,
  exportToExcel,
  updateClientAdminStatus,
  deleteSubCategories,
  createProjectAndUploadDocument,
  fetchProjectListByClient,
  sendDocParser,
  sendSummary,
  createSummaryIndex,
  createChunkIndex,
  sendDataToSummaryIndex,
  sendDataToChunkIndex,
  sendResponseGenerator,
  sendChunker,
  sendFeedback,
  fetchFeedbacks,
  fetchUserRole,
  refetchUserData,
} from "../../action";
import { COUNTER_KEY, SEVERITY } from "../../constant";
import {
  errorToAddClient,
  errorToAddNewCategory,
  errorToCreateChunkIndex,
  errorToCreateDDQ,
  errorToCreateProject,
  errorToCreateSummaryIndex,
  errorToDeleteCategory,
  errorToDeleteClient,
  errorToDeleteDocument,
  errorToDeleteProject,
  errorToDeleteSubcategory,
  errorToDownload,
  errorToEditCategory,
  errorToEditClient,
  errorToExportExcel,
  errorToFetchClientProject,
  errorToFetchClients,
  errorToFetchDDQDocuments,
  errorToFetchDDQResponse,
  errorToFetchDDQs,
  errorToFetchDocuments,
  errorToFetchFeedback,
  errorToFetchSupport,
  errorToGenerateResponse,
  errorToResetPassword,
  errorToResolveSupport,
  errorToSaveDDQResponse,
  errorToSendChunk,
  errorToSendDataToChunkIndex,
  errorToSendDataToSummaryIndex,
  errorToSendDocParser,
  errorToSendEmailLink,
  errorToSendFeedback,
  errorToSendResponseGenerator,
  errorToSendSummary,
  errorToSendSupportRequest,
  errorToUpdateClientAdminStatus,
  errorToUpdateClientStatus,
  errorToUpdateDDQ,
  errorToUpdateProject,
  errorToUpdateStatus,
  errorToUploadDocuments,
  errorToUploadMultiDocuments,
  failToFetchCategories,
  failToFetchProjects,
  fetchUserRoleFailed,
  loginFailed,
  registrationFailed,
  somethingBadHappen,
} from "../../api/error";
import {
  categoryAddedSuccess,
  categoryDeleteSuccess,
  categoryStatusSuccess,
  clientAddSuccess,
  clientAdminStatusSuccess,
  clientEditSuccess,
  clientStatusSuccess,
  createChunkIndexSuccess,
  createDDQSuccess,
  createSummaryIndexSuccess,
  deleteClientSuccess,
  deleteDDQSuccess,
  deleteDocumentSuccess,
  deleteProjectSuccess,
  downloadSuccess,
  editCategorySuccess,
  editDDQSuccess,
  editProjectSuccess,
  forgotPasswordSuccess,
  loginSuccess,
  projectCreatedSuccess,
  resendEmailSuccess,
  resetPasswordSuccess,
  sendChunkerSuccess,
  sendDataToChunkIndexSuccess,
  sendDataToSummaryIndexSuccess,
  sendDocParserSuccess,
  sendFeedbackSuccess,
  sendResponseGeneratorSuccess,
  sendSummarySuccess,
  supportRequestSentSuccess,
  supportResolvedSuccess,
  uploadDocumentSuccess,
} from "../../api/success";

/**
 * Message Type
 * `{ id: "", content: "", severity: "" }`
 */
/** Status */
export const status = {
  pending: false,
  success: false,
};
const initialState = {
  message: [],
  csvErrorMessage: [],
  registration: status,
  loginProcess: status,
  login: status,
  linkSentForResetPassword: status,
  resetPassword: status,
  resentEmailLink: status,
  project: status,
  createProject: { ...status, dialog: false },
  createAndUpload: status,
  deleteProject: status,
  editProject: status,
  category: { ...status, dialog: false },
  categoryStatus: status,
  categoryDelete: status,
  categoryEdit: status,
  ddq: status,
  ddqById: status,
  ddqCreate: status,
  ddqDelete: status,
  ddqEdit: status,
  ddqResponse: status,
  ddqDocuments: status,
  ddqChunks: status,
  ddqGenerate: status,
  ddqSelected: status,
  ddqSave: status,
  download: status,
  client: status,
  clientSelect: status,
  clientStatus: status,
  clientAdd: status,
  clientDelete: status,
  clientEdit: status,
  document: status,
  documentSelect: status,
  exportExcel: status,
  support: status,
  supportSelect: status,
  supportResolve: status,
  supportAdd: status,
  generateResponse: { ...status, id: null },
  uploadDocuments: { ...status, dialog: false },
  deleteDocument: status,
  clientProjects: status,
  gptProcessing: {
    counter: 0,
    parseDocument: status,
    currentSummaries: status,
    createChunks: status,
    createSummaryIndex: status,
    createChunkIndex: status,
    addDataSummary: status,
    addDataChunk: status,
    runDDQ: status,
  },
  feedback: {
    fetch: status,
    send: status,
  },
};

const NAME = "view";

export const customMessage = ({ content, severity }) => {
  return {
    id: nanoid(),
    content,
    severity,
  };
};

const viewSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    addMessage(state, { payload: { id, content, severity } }) {
      if (severity && content)
        state.message.push({
          id: id,
          severity: severity,
          content: content,
        });
    },
    deleteMessage(state, { payload }) {
      if (payload.id)
        state.message = state.message.filter((m) => m.id !== payload.id);
    },

    setCategoryDialogVisibility(state, { payload }) {
      state.category.dialog = payload;
    },
    resetCSVMessage(state) {
      state.csvErrorMessage = [];
    },
    setGeneratingResponseId(state, { payload }) {
      state.generateResponse.id = payload;
    },
    showDocumentDialog(state, { payload }) {
      state.uploadDocuments.dialog = payload;
    },
    resetDeleteProjectStatus(state) {
      state.deleteProject = status;
    },
    setCreateProjectDialog(state, { payload }) {
      state.createProject.dialog = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.pending, (state) => {
        state.registration.pending = true;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        // eslint-disable-next-line eqeqeq
        if (payload.status == SEVERITY.Success) {
          state.registration.success = true;
          state.registration.pending = false;
        } else {
          state.registration.success = false;
          state.registration.pending = false;
          state.message.push(
            customMessage({
              content: payload.data.message ?? "",
              severity: payload.status,
            })
          );
        }
      })
      .addCase(registerUser.rejected, (state) => {
        const errorResponse = registrationFailed();
        state.message.push(errorResponse);
        state.registration.success = false;
        state.registration.pending = false;
      });

    builder
      .addCase(resendEmailVerification.pending, (state) => {
        state.resentEmailLink.pending = true;
        state.resentEmailLink.success = false;
      })
      .addCase(resendEmailVerification.fulfilled, (state, { payload }) => {
        state.resentEmailLink.pending = false;
        state.resentEmailLink.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(resendEmailSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(resendEmailVerification.rejected, (state) => {
        state.resentEmailLink.pending = false;
        state.resentEmailLink.success = false;
        state.message.push(errorToSendEmailLink());
      });

    builder
      .addCase(loginUser.pending, (state) => {
        state.loginProcess.pending = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        // eslint-disable-next-line eqeqeq
        if (payload.status == SEVERITY.Success) {
          state.loginProcess.success = true;
          state.loginProcess.pending = false;
          state.message.push(loginSuccess(payload.response?.data.email ?? ""));
        } else {
          state.loginProcess.success = false;
          state.loginProcess.pending = false;
          state.message.push(
            customMessage({
              content: payload.response?.message ?? "",
              severity: payload.status,
            })
          );
        }
      })
      .addCase(loginUser.rejected, (state) => {
        state.message.push(loginFailed());
        state.loginProcess.success = false;
        state.loginProcess.pending = false;
      });

    builder
      .addCase(fetchUserRole.rejected, (state) => {
        state.message.push(fetchUserRoleFailed());
      })
      .addCase(refetchUserData.pending, (state) => {
        state.login.pending = true;
        state.login.success = false;
      })
      .addCase(refetchUserData.fulfilled, (state) => {
        state.login.pending = false;
        state.login.success = true;
      })
      .addCase(refetchUserData.rejected, (state) => {
        state.login.pending = false;
        state.login.success = false;
      });

    builder
      .addCase(forgotPassword.pending, (state) => {
        state.linkSentForResetPassword.pending = true;
        state.linkSentForResetPassword.success = false;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }) => {
        state.linkSentForResetPassword.pending = false;
        state.linkSentForResetPassword.success = true;

        if (payload.status === SEVERITY.Success) {
          state.message.push(forgotPasswordSuccess(payload.email));
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.message.push(somethingBadHappen("reset password"));
        state.linkSentForResetPassword.success = false;
        state.linkSentForResetPassword.pending = false;
      });

    builder
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.pending = true;
        state.resetPassword.success = false;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        state.resetPassword.pending = false;
        state.resetPassword.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(resetPasswordSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPassword.pending = false;
        state.resetPassword.success = false;
        state.message.push(errorToResetPassword());
      });

    builder.addCase(fetchProjectList.pending, (state) => {
      state.project.pending = true;
      state.project.success = false;
    });
    builder.addCase(fetchProjectList.fulfilled, (state) => {
      state.project.pending = false;
      state.project.success = true;
    });
    builder.addCase(fetchProjectList.rejected, (state) => {
      state.message.push(failToFetchProjects());
      state.project.pending = false;
      state.project.success = false;
    });
    builder
      .addCase(fetchProjectById.pending, (state) => {
        state.project.pending = true;
        state.project.success = false;
      })
      .addCase(fetchProjectById.fulfilled, (state, { payload }) => {
        state.project.pending = false;
        state.project.success = true;
      })
      .addCase(fetchProjectById.rejected, (state) => {
        state.message.push(failToFetchProjects());
        state.project.pending = false;
        state.project.success = false;
      });

    builder.addCase(fetchCategories.pending, (state) => {
      state.category.pending = true;
      state.category.success = false;
    });
    builder.addCase(fetchCategories.fulfilled, (state) => {
      state.category.pending = false;
      state.category.success = true;
    });
    builder.addCase(fetchCategories.rejected, (state) => {
      state.message.push(failToFetchCategories());
      state.category.pending = false;
      state.category.success = false;
    });

    builder.addCase(createNewCategory.pending, (state) => {
      state.category.pending = true;
      state.category.dialog = true;
      state.category.success = false;
    });
    builder.addCase(createNewCategory.rejected, (state) => {
      state.category.pending = false;
      state.category.dialog = false;
      state.category.success = false;
      state.message.push(errorToAddNewCategory());
    });
    builder.addCase(createNewCategory.fulfilled, (state, { payload }) => {
      state.category.pending = false;
      state.category.dialog = false;
      state.category.success = true;

      if (payload.status === SEVERITY.Success) {
        state.message.push(categoryAddedSuccess(payload.data.category_id));
      } else {
        state.message.push(
          customMessage({
            id: nanoid(),
            content: payload.data.message,
            severity: payload.status,
          })
        );
      }
    });

    builder
      .addCase(createNewProject.pending, (state) => {
        state.createProject.pending = true;
        state.createProject.success = false;
        state.createProject.dialog = true;
      })
      .addCase(createNewProject.fulfilled, (state, { payload }) => {
        state.createProject.pending = false;
        state.createProject.success = true;
        state.createProject.dialog = false;
        if (payload.status === SEVERITY.Success)
          state.message.push(projectCreatedSuccess(payload.data.project_id));
        else
          state.message.push(
            customMessage({
              content: payload.data.message,
              severity: payload.status,
            })
          );
      })
      .addCase(createNewProject.rejected, (state) => {
        state.createProject.pending = false;
        state.createProject.success = false;
        state.createProject.dialog = false;
        state.message.push(errorToCreateProject());
      });

    builder
      .addCase(createProjectAndUploadDocument.pending, (state) => {
        state.createAndUpload.pending = true;
        state.createAndUpload.success = false;
      })
      .addCase(
        createProjectAndUploadDocument.fulfilled,
        (state, { payload }) => {
          state.createAndUpload.pending = false;
          state.createAndUpload.success = true;
          if (payload.status === SEVERITY.Success)
            state.message.push(uploadDocumentSuccess());
          else
            state.message.push(
              customMessage({
                content: payload.message,
                severity: payload.status,
              })
            );
        }
      )
      .addCase(createProjectAndUploadDocument.rejected, (state) => {
        state.createAndUpload.pending = false;
        state.createAndUpload.success = false;
        state.message.push(errorToUploadMultiDocuments());
      });

    builder.addCase(deleteProject.pending, (state) => {
      state.deleteProject.pending = true;
      state.deleteProject.success = false;
    });
    builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
      state.deleteProject.pending = false;
      state.deleteProject.success = true;
      if (payload.status === SEVERITY.Success) {
        state.message.push(deleteProjectSuccess());
      } else {
        state.message.push(
          customMessage({
            content: payload.data?.message ?? "",
            severity: SEVERITY.Error,
          })
        );
      }
    });
    builder.addCase(deleteProject.rejected, (state) => {
      state.deleteProject.pending = false;
      state.deleteProject.success = false;

      state.message.push(errorToDeleteProject());
    });
    builder
      .addCase(fetchDdqQuestions.pending, (state) => {
        state.ddq.pending = true;
        state.ddq.success = false;
      })
      .addCase(fetchDdqQuestions.fulfilled, (state) => {
        state.ddq.pending = false;
        state.ddq.success = true;
      })
      .addCase(fetchDdqQuestions.rejected, (state, action) => {
        state.ddq?.message?.push(errorToFetchDDQs(action.error));
        state.ddq.pending = false;
        state.ddq.success = false;
      })
      .addCase(fetchDdqQuestionById.pending, (state) => {
        state.ddqById.pending = true;
        state.ddqById.success = false;
      })
      .addCase(fetchDdqQuestionById.fulfilled, (state) => {
        state.ddqById.pending = false;
        state.ddqById.success = true;
      })
      .addCase(fetchDdqQuestionById.rejected, (state, action) => {
        state.message.push(errorToFetchDDQs(action.error));
        state.ddqById.pending = false;
        state.ddqById.success = false;
      });

    builder.addCase(editDDQ.pending, (state) => {
      state.ddqEdit.pending = true;
      state.ddqEdit.success = false;
    });
    builder.addCase(editDDQ.fulfilled, (state, { payload }) => {
      state.ddqEdit.pending = false;
      state.ddqEdit.success = true;
      if (payload.status === SEVERITY.Success) {
        state.message.push(editDDQSuccess());
      } else
        state.message.push(
          customMessage({
            content: payload.data.message,
            severity: payload.status,
          })
        );
    });
    builder.addCase(editDDQ.rejected, (state) => {
      state.ddqEdit.pending = false;
      state.ddqEdit.success = false;
      state.message.push(errorToUpdateDDQ());
    });

    builder.addCase(addDdqQuestion.pending, (state) => {
      state.ddqCreate.pending = true;
      state.ddqCreate.success = false;
    });
    builder.addCase(addDdqByCSV.pending, (state) => {
      state.ddqCreate.pending = true;
      state.ddqCreate.success = false;
    });

    builder.addCase(addDdqQuestion.fulfilled, (state, { payload }) => {
      state.ddqCreate.pending = false;
      state.ddqCreate.success = true;
      // eslint-disable-next-line eqeqeq
      if (payload.status == SEVERITY.Success)
        state.message.push(createDDQSuccess());
      else
        state.message.push(
          customMessage({
            content: payload.data.message,
            severity: payload.status,
          })
        );
    });
    builder.addCase(addDdqByCSV.fulfilled, (state, { payload }) => {
      state.ddqCreate.pending = false;
      state.ddqCreate.success = true;
      // eslint-disable-next-line eqeqeq
      if (payload.status == SEVERITY.Success)
        state.message.push(createDDQSuccess());
      else {
        state.csvErrorMessage = [...payload.message];
      }
    });

    builder.addCase(addDdqQuestion.rejected, (state) => {
      state.ddqCreate.pending = false;
      state.ddqCreate.success = false;
      state.message.push(errorToCreateDDQ());
    });
    builder.addCase(addDdqByCSV.rejected, (state) => {
      state.ddqCreate.pending = false;
      state.ddqCreate.success = false;
      state.message.push(errorToCreateDDQ());
    });

    builder.addCase(deleteDDQ.pending, (state) => {
      state.ddqDelete.pending = true;
      state.ddqDelete.success = false;
    });
    builder.addCase(deleteDDQ.fulfilled, (state, { payload }) => {
      state.ddqDelete.pending = false;
      state.ddqDelete.success = true;

      if (payload.status === SEVERITY.Success)
        state.message.push(deleteDDQSuccess());
      else
        state.message.push(
          customMessage({
            content: payload.data.message,
            severity: payload.status,
          })
        );
    });
    builder.addCase(deleteDDQ.rejected, (state) => {
      state.ddqDelete.pending = false;
      state.ddqDelete.success = false;

      state.message.push(errorToDeleteProject());
    });
    builder.addCase(updateCategoryStatus.pending, (state) => {
      state.categoryStatus.pending = true;
      state.categoryStatus.success = false;
    });
    builder.addCase(updateCategoryStatus.fulfilled, (state, { payload }) => {
      state.categoryStatus.pending = false;
      state.categoryStatus.success = true;
      if (payload.status === SEVERITY.Success) {
        state.message.push(categoryStatusSuccess());
      } else
        state.message.push(
          customMessage({ content: payload.message, severity: payload.status })
        );
    });
    builder.addCase(updateCategoryStatus.rejected, (state) => {
      state.categoryStatus.pending = false;
      state.categoryStatus.success = false;
      state.message.push(errorToUpdateStatus());
    });
    builder.addCase(deleteCategory.pending, (state) => {
      state.categoryDelete.pending = true;
      state.categoryDelete.success = false;
    });
    builder.addCase(deleteCategory.fulfilled, (state, { payload }) => {
      state.categoryDelete.pending = false;
      state.categoryDelete.success = true;
      if (payload.status === SEVERITY.Success)
        state.message.push(categoryDeleteSuccess());
      else
        state.message.push(
          customMessage({ content: payload.message, severity: payload.status })
        );
    });
    builder
      .addCase(deleteCategory.rejected, (state) => {
        state.categoryDelete.pending = false;
        state.categoryDelete.success = false;
        state.message.push(errorToDeleteCategory());
      })
      .addCase(deleteSubCategories.pending, (state) => {
        state.categoryEdit.pending = true;
        state.categoryEdit.success = false;
      })
      .addCase(deleteSubCategories.fulfilled, (state, { payload }) => {
        state.categoryEdit.pending = false;
        state.categoryEdit.success = true;
        if (payload.status !== SEVERITY.Success) {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(deleteSubCategories.rejected, (state) => {
        state.message.push(errorToDeleteSubcategory());
        state.categoryEdit.pending = false;
        state.categoryEdit.success = false;
      });

    builder.addCase(editCategory.pending, (state) => {
      state.categoryEdit.pending = true;
      state.categoryEdit.success = false;
    });
    builder.addCase(editCategory.fulfilled, (state, { payload }) => {
      state.categoryEdit.pending = false;
      state.categoryEdit.success = true;
      if (payload.status === SEVERITY.Success)
        state.message.push(editCategorySuccess());
      else
        state.message.push(
          customMessage({ content: payload.message, severity: payload.status })
        );
    });
    builder.addCase(editCategory.rejected, (state) => {
      state.categoryEdit.pending = false;
      state.categoryEdit.success = false;
      state.message.push(errorToEditCategory());
    });

    builder
      .addCase(downloadSearchLessDocument.pending, (state) => {
        state.download.pending = true;
        state.download.success = false;
      })
      .addCase(downloadSearchLessDocument.fulfilled, (state, { payload }) => {
        state.download.pending = false;
        state.download.success = true;
        if (payload.status === SEVERITY.Success) {
          state.message.push(downloadSuccess({ message: payload.message }));
        } else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(downloadSearchLessDocument.rejected, (state) => {
        state.download.pending = false;
        state.download.success = false;
        state.message.push(errorToDownload());
      });
    builder
      .addCase(fetchClients.pending, (state) => {
        state.client.pending = true;
        state.client.success = false;
      })
      .addCase(fetchClients.fulfilled, (state) => {
        state.client.pending = false;
        state.client.success = true;
      })
      .addCase(fetchClients.rejected, (state) => {
        state.client.pending = false;
        state.client.success = false;
        state.message.push(errorToFetchClients());
      });
    builder
      .addCase(updateClientStatus.pending, (state) => {
        state.clientStatus.pending = true;
        state.clientStatus.success = false;
      })
      .addCase(updateClientStatus.fulfilled, (state, { payload }) => {
        state.clientStatus.pending = false;
        state.clientStatus.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(clientStatusSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(updateClientStatus.rejected, (state) => {
        state.clientStatus.pending = false;
        state.clientStatus.success = false;
        state.message.push(errorToUpdateClientStatus());
      })
      .addCase(updateClientAdminStatus.pending, (state) => {
        state.clientStatus.pending = true;
        state.clientStatus.success = false;
      })
      .addCase(updateClientAdminStatus.fulfilled, (state, { payload }) => {
        state.clientStatus.pending = false;
        state.clientStatus.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(clientAdminStatusSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(updateClientAdminStatus.rejected, (state) => {
        state.clientStatus.pending = false;
        state.clientStatus.success = false;
        state.message.push(errorToUpdateClientAdminStatus());
      });

    builder
      .addCase(fetchClientsById.pending, (state) => {
        state.clientSelect.pending = true;
        state.clientSelect.success = false;
      })
      .addCase(fetchClientsById.fulfilled, (state, { payload }) => {
        state.clientSelect.pending = false;
        state.clientSelect.success = true;
      })
      .addCase(fetchClientsById.rejected, (state) => {
        state.clientSelect.pending = false;
        state.clientSelect.success = false;
        state.message.push(errorToFetchClients());
      });

    builder
      .addCase(addClient.pending, (state) => {
        state.clientAdd.pending = true;
        state.clientAdd.success = false;
      })
      .addCase(addClient.fulfilled, (state, { payload }) => {
        state.clientAdd.pending = false;
        state.clientAdd.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(clientAddSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(addClient.rejected, (state) => {
        state.clientAdd.pending = false;
        state.clientAdd.success = false;
        state.message.push(errorToAddClient());
      });

    builder
      .addCase(editClient.pending, (state) => {
        state.clientEdit.pending = true;
        state.clientEdit.success = false;
      })
      .addCase(editClient.fulfilled, (state, { payload }) => {
        state.clientEdit.pending = false;
        state.clientEdit.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(clientEditSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(editClient.rejected, (state) => {
        state.clientEdit.pending = false;
        state.clientEdit.success = false;
        state.message.push(errorToEditClient());
      });

    builder
      .addCase(deleteClient.pending, (state) => {
        state.clientDelete.pending = true;
        state.clientDelete.success = false;
      })
      .addCase(deleteClient.fulfilled, (state, { payload }) => {
        state.clientDelete.pending = false;
        state.clientDelete.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(deleteClientSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(deleteClient.rejected, (state) => {
        state.clientDelete.pending = false;
        state.clientDelete.success = false;
        state.message.push(errorToDeleteClient());
      });

    builder
      .addCase(fetchDocumentList.pending, (state) => {
        state.document.pending = true;
        state.document.success = false;
      })
      .addCase(fetchDocumentList.fulfilled, (state) => {
        state.document.pending = false;
        state.document.success = true;
      })
      .addCase(fetchDocumentList.rejected, (state) => {
        state.document.pending = false;
        state.document.success = false;
        state.message.push(errorToFetchDocuments());
      });

    builder
      .addCase(fetchSupportDetails.pending, (state) => {
        state.support.pending = true;
        state.support.success = false;
      })
      .addCase(fetchSupportDetails.fulfilled, (state) => {
        state.support.pending = false;
        state.support.success = true;
      })
      .addCase(fetchSupportDetails.rejected, (state) => {
        state.support.pending = false;
        state.support.success = false;
        state.message.push(errorToFetchSupport());
      });
    builder
      .addCase(fetchSupportDetailsById.pending, (state) => {
        state.supportSelect.pending = true;
        state.supportSelect.success = false;
      })
      .addCase(fetchSupportDetailsById.fulfilled, (state) => {
        state.supportSelect.pending = false;
        state.supportSelect.success = true;
      })
      .addCase(fetchSupportDetailsById.rejected, (state) => {
        state.supportSelect.pending = false;
        state.supportSelect.success = false;
        state.message.push(errorToFetchSupport());
      });
    builder
      .addCase(resolveSupportRequest.pending, (state) => {
        state.supportResolve.pending = true;
        state.supportResolve.success = false;
      })
      .addCase(resolveSupportRequest.fulfilled, (state, { payload }) => {
        state.supportResolve.pending = false;
        state.supportResolve.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(supportResolvedSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(resolveSupportRequest.rejected, (state) => {
        state.supportResolve.pending = false;
        state.supportResolve.success = false;
        state.message.push(errorToResolveSupport());
      });
    builder
      .addCase(sendSupportRequest.pending, (state) => {
        state.supportAdd.pending = true;
        state.supportAdd.success = false;
      })
      .addCase(sendSupportRequest.fulfilled, (state, { payload }) => {
        state.supportAdd.pending = false;
        state.supportAdd.success = true;
        if (payload.status === SEVERITY.Success) {
          state.message.push(supportRequestSentSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendSupportRequest.rejected, (state) => {
        state.supportAdd.pending = false;
        state.supportAdd.success = false;
        state.message.push(errorToSendSupportRequest());
      });

    builder
      .addCase(generateDDQResponse.pending, (state) => {
        state.generateResponse.pending = true;
        state.generateResponse.success = false;
      })
      .addCase(generateDDQResponse.fulfilled, (state) => {
        state.generateResponse.pending = false;
        state.generateResponse.success = true;
      })
      .addCase(generateDDQResponse.rejected, (state) => {
        state.generateResponse.pending = false;
        state.generateResponse.success = false;
        state.message.push(errorToGenerateResponse());
      });

    builder
      .addCase(fetchDDQResponse.pending, (state) => {
        state.ddqResponse.pending = true;
        state.ddqResponse.success = false;
      })
      .addCase(fetchDDQResponse.fulfilled, (state, { payload }) => {
        state.ddqResponse.pending = false;
        state.ddqResponse.success = true;
      })
      .addCase(fetchDDQResponse.rejected, (state) => {
        state.ddqResponse.pending = false;
        state.ddqResponse.success = false;
        state.message.push(errorToFetchDDQResponse());
      });

    builder
      .addCase(fetchDDQDocuments.pending, (state) => {
        state.ddqDocuments.pending = true;
        state.ddqDocuments.success = false;
      })
      .addCase(fetchDDQDocuments.fulfilled, (state) => {
        state.ddqDocuments.pending = false;
        state.ddqDocuments.success = true;
      })
      .addCase(fetchDDQDocuments.rejected, (state) => {
        state.ddqDocuments.pending = false;
        state.ddqDocuments.success = false;
        state.message.push(errorToFetchDDQDocuments());
      });

    builder
      .addCase(fetchDDQSelectionsChunks.pending, (state) => {
        state.ddqChunks.pending = true;
        state.ddqChunks.success = false;
      })
      .addCase(fetchDDQSelectionsChunks.fulfilled, (state) => {
        state.ddqChunks.pending = false;
        state.ddqChunks.success = true;
      })
      .addCase(fetchDDQSelectionsChunks.rejected, (state) => {
        state.ddqChunks.pending = false;
        state.ddqChunks.success = false;
      });

    builder
      .addCase(generateResponse.pending, (state) => {
        state.ddqGenerate.pending = true;
        state.ddqGenerate.success = false;
      })
      .addCase(generateResponse.fulfilled, (state, { payload }) => {
        state.ddqGenerate.pending = false;
        state.ddqGenerate.success = true;
        if (payload.status === SEVERITY.Error)
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(generateResponse.rejected, (state) => {
        state.ddqGenerate.pending = false;
        state.ddqGenerate.success = false;
        state.message.push(errorToGenerateResponse());
      });

    builder
      .addCase(fetchDDQResponseSelectedList.pending, (state) => {
        state.ddqSelected.pending = true;
        state.ddqSelected.success = false;
      })
      .addCase(fetchDDQResponseSelectedList.fulfilled, (state) => {
        state.ddqSelected.pending = false;
        state.ddqSelected.success = true;
      })
      .addCase(fetchDDQResponseSelectedList.rejected, (state) => {
        state.ddqSelected.pending = false;
        state.ddqSelected.success = false;
      });

    builder
      .addCase(saveAndProcessResponse.pending, (state) => {
        state.ddqSave.pending = true;
        state.ddqSave.success = false;
      })
      .addCase(saveAndProcessResponse.fulfilled, (state, { payload }) => {
        state.ddqSave.pending = false;
        state.ddqSave.success = true;
        if (payload.status === SEVERITY.Error)
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(saveAndProcessResponse.rejected, (state) => {
        state.ddqSave.pending = false;
        state.ddqSave.success = false;
        state.message.push(errorToSaveDDQResponse());
      });

    builder
      .addCase(fetchDocumentListById.pending, (state) => {
        state.documentSelect.pending = true;
        state.documentSelect.success = false;
      })
      .addCase(fetchDocumentListById.fulfilled, (state) => {
        state.documentSelect.pending = false;
        state.documentSelect.success = true;
      })
      .addCase(fetchDocumentListById.rejected, (state) => {
        state.documentSelect.pending = false;
        state.documentSelect.success = false;
      });

    builder
      .addCase(updateCategoryAndUploadDocument.pending, (state) => {
        state.uploadDocuments.pending = true;
        state.uploadDocuments.success = false;
      })
      .addCase(
        updateCategoryAndUploadDocument.fulfilled,
        (state, { payload }) => {
          state.uploadDocuments.pending = false;
          state.uploadDocuments.success = true;
          state.uploadDocuments.dialog = false;
          if (payload.status === SEVERITY.Success)
            state.message.push(uploadDocumentSuccess());
          else
            state.message.push(
              customMessage({
                content: payload.message,
                severity: payload.status,
              })
            );
        }
      )
      .addCase(updateCategoryAndUploadDocument.rejected, (state) => {
        state.uploadDocuments.pending = false;
        state.uploadDocuments.success = false;
        state.uploadDocuments.dialog = false;
        state.message.push(errorToUploadDocuments());
      });

    builder
      .addCase(deleteDocument.pending, (state) => {
        state.deleteDocument.pending = true;
        state.deleteDocument.success = false;
      })
      .addCase(deleteDocument.fulfilled, (state, { payload }) => {
        state.deleteDocument.pending = false;
        state.deleteDocument.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(deleteDocumentSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(deleteDocument.rejected, (state) => {
        state.deleteDocument.pending = false;
        state.deleteDocument.success = false;
        state.message.push(errorToDeleteDocument());
      });

    builder
      .addCase(editProject.pending, (state) => {
        state.editProject.pending = true;
        state.editProject.success = false;
      })
      .addCase(editProject.fulfilled, (state, { payload }) => {
        state.editProject.pending = false;
        state.editProject.success = true;
        if (payload.status === SEVERITY.Success)
          state.message.push(editProjectSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(editProject.rejected, (state) => {
        state.editProject.pending = false;
        state.editProject.success = false;
        state.message.push(errorToUpdateProject());
      });

    builder
      .addCase(exportToExcel.pending, (state) => {
        state.exportExcel.pending = true;
        state.exportExcel.success = false;
      })
      .addCase(exportToExcel.fulfilled, (state, { payload }) => {
        state.exportExcel.pending = false;
        state.exportExcel.success = true;

        state.message.push(
          customMessage({ content: payload.message, severity: payload.status })
        );
      })
      .addCase(exportToExcel.rejected, (state) => {
        state.exportExcel.pending = false;
        state.exportExcel.success = false;

        state.message.push(errorToExportExcel());
      });

    builder
      .addCase(fetchProjectListByClient.pending, (state) => {
        state.clientProjects.pending = true;
        state.clientProjects.success = false;
      })
      .addCase(fetchProjectListByClient.fulfilled, (state, { payload }) => {
        state.clientProjects.pending = false;
        state.clientProjects.success = true;
        if (payload.status === SEVERITY.Error)
          state.message.push(errorToFetchClientProject());
      })
      .addCase(fetchProjectListByClient.rejected, (state) => {
        state.clientProjects.pending = false;
        state.clientProjects.success = false;
        state.message.push(errorToFetchClientProject());
      });

    builder
      .addCase(sendDocParser.pending, (state) => {
        state.gptProcessing.parseDocument.pending = true;
        state.gptProcessing.parseDocument.success = false;
      })
      .addCase(sendDocParser.fulfilled, (state, { payload }) => {
        state.gptProcessing.parseDocument.pending = false;
        state.gptProcessing.parseDocument.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 1;
          localStorage.setItem(COUNTER_KEY, 1);
          state.message.push(sendDocParserSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendDocParser.rejected, (state) => {
        state.gptProcessing.parseDocument.pending = false;
        state.gptProcessing.parseDocument.success = false;
        state.message.push(errorToSendDocParser());
      });

    builder
      .addCase(sendSummary.pending, (state) => {
        state.gptProcessing.currentSummaries.pending = true;
        state.gptProcessing.currentSummaries.success = false;
      })
      .addCase(sendSummary.fulfilled, (state, { payload }) => {
        state.gptProcessing.currentSummaries.pending = false;
        state.gptProcessing.currentSummaries.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 2;
          localStorage.setItem(COUNTER_KEY, 2);
          state.message.push(sendSummarySuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendSummary.rejected, (state) => {
        state.gptProcessing.currentSummaries.pending = false;
        state.gptProcessing.currentSummaries.success = true;
        state.message.push(errorToSendSummary());
      });

    builder
      .addCase(sendChunker.pending, (state) => {
        state.gptProcessing.createChunks.pending = true;
        state.gptProcessing.createChunks.success = false;
      })
      .addCase(sendChunker.fulfilled, (state, { payload }) => {
        state.gptProcessing.createChunks.pending = false;
        state.gptProcessing.createChunks.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 3;
          localStorage.setItem(COUNTER_KEY, 3);
          state.message.push(sendChunkerSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendChunker.rejected, (state) => {
        state.gptProcessing.createChunks.pending = false;
        state.gptProcessing.createChunks.success = false;
        state.message.push(errorToSendChunk());
      });

    builder
      .addCase(createSummaryIndex.pending, (state) => {
        state.gptProcessing.createSummaryIndex.pending = true;
        state.gptProcessing.createSummaryIndex.success = false;
      })
      .addCase(createSummaryIndex.fulfilled, (state, { payload }) => {
        state.gptProcessing.createSummaryIndex.pending = false;
        state.gptProcessing.createSummaryIndex.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 4;
          localStorage.setItem(COUNTER_KEY, 4);
          state.message.push(createSummaryIndexSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(createSummaryIndex.rejected, (state) => {
        state.gptProcessing.createSummaryIndex.pending = false;
        state.gptProcessing.createSummaryIndex.success = false;
        state.message.push(errorToCreateSummaryIndex());
      });

    builder
      .addCase(createChunkIndex.pending, (state) => {
        state.gptProcessing.createChunkIndex.pending = true;
        state.gptProcessing.createChunkIndex.success = false;
      })
      .addCase(createChunkIndex.fulfilled, (state, { payload }) => {
        state.gptProcessing.createChunkIndex.pending = false;
        state.gptProcessing.createChunkIndex.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 5;
          localStorage.setItem(COUNTER_KEY, 5);
          state.message.push(createChunkIndexSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(createChunkIndex.rejected, (state) => {
        state.gptProcessing.createChunkIndex.pending = false;
        state.gptProcessing.createChunkIndex.success = false;
        state.message.push(errorToCreateChunkIndex());
      });

    builder
      .addCase(sendDataToSummaryIndex.pending, (state) => {
        state.gptProcessing.addDataSummary.pending = true;
        state.gptProcessing.addDataSummary.success = false;
      })
      .addCase(sendDataToSummaryIndex.fulfilled, (state, { payload }) => {
        state.gptProcessing.addDataSummary.pending = false;
        state.gptProcessing.addDataSummary.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 6;
          localStorage.setItem(COUNTER_KEY, 6);
          state.message.push(sendDataToSummaryIndexSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendDataToSummaryIndex.rejected, (state) => {
        state.gptProcessing.addDataSummary.pending = false;
        state.gptProcessing.addDataSummary.success = false;
        state.message.push(errorToSendDataToSummaryIndex());
      });

    builder
      .addCase(sendDataToChunkIndex.pending, (state) => {
        state.gptProcessing.addDataChunk.pending = true;
        state.gptProcessing.addDataChunk.success = false;
      })
      .addCase(sendDataToChunkIndex.fulfilled, (state, { payload }) => {
        state.gptProcessing.addDataChunk.pending = false;
        state.gptProcessing.addDataChunk.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 7;
          localStorage.setItem(COUNTER_KEY, 7);
          state.message.push(sendDataToChunkIndexSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendDataToChunkIndex.rejected, (state) => {
        state.gptProcessing.addDataChunk.pending = false;
        state.gptProcessing.addDataChunk.success = false;
        state.message.push(errorToSendDataToChunkIndex());
      });

    builder
      .addCase(sendResponseGenerator.pending, (state) => {
        state.gptProcessing.runDDQ.pending = true;
        state.gptProcessing.runDDQ.success = false;
      })
      .addCase(sendResponseGenerator.fulfilled, (state, { payload }) => {
        state.gptProcessing.runDDQ.pending = false;
        state.gptProcessing.runDDQ.success = true;
        if (payload.status === SEVERITY.Success) {
          state.gptProcessing.counter = 8;
          localStorage.setItem(COUNTER_KEY, 8);
          state.message.push(sendResponseGeneratorSuccess());
        } else {
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
        }
      })
      .addCase(sendResponseGenerator.rejected, (state) => {
        state.gptProcessing.runDDQ.pending = false;
        state.gptProcessing.runDDQ.success = true;
        state.message.push(errorToSendResponseGenerator());
      });

    builder
      .addCase(sendFeedback.pending, (state) => {
        state.feedback.send = { pending: true, success: false };
      })
      .addCase(sendFeedback.fulfilled, (state, { payload }) => {
        state.feedback.send = { pending: false, success: true };
        if (payload.status === SEVERITY.Success)
          state.message.push(sendFeedbackSuccess());
        else
          state.message.push(
            customMessage({
              content: payload.message,
              severity: payload.status,
            })
          );
      })
      .addCase(sendFeedback.rejected, (state) => {
        state.feedback.send = { pending: false, success: false };
        state.message.push(errorToSendFeedback());
      });

    builder
      .addCase(fetchFeedbacks.pending, (state) => {
        state.feedback.fetch = { pending: true, success: false };
      })
      .addCase(fetchFeedbacks.fulfilled, (state, { payload }) => {
        state.feedback.fetch = { pending: false, success: true };
      })
      .addCase(fetchFeedbacks.rejected, (state) => {
        state.feedback.fetch = { pending: false, success: false };
        state.message.push(errorToFetchFeedback());
      });
  },
});

export const { actions } = viewSlice;

export default viewSlice.reducer;
