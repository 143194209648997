import { Link, useLocation, useParams } from "react-router-dom";
import { BreadCrumbs } from "../../components/atoms/bread-crumbs";
import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { PAGE_SIZE, PROJECTS } from "../../constant";
import { pathList } from "../../routes/app-navigation";
import { DDQDocumentList } from "../features/ddq-response/ddq-document-list";
import { DelayResponseBody } from "../../components/organism/body/loading-responses";
import { SmallText } from "../../components/typography";
import { CustomPagination } from "../../components/molecules/pagination/page-number";
import { useDispatch, useSelector } from "react-redux";
import { fetchDDQDocuments } from "../../action";
import { useEffect, useState } from "react";
import { usePagination } from "../../hooks/use-pagination";

export const DDQDocumentListPage = () => {
  const { id, questionId } = useParams();

  const dispatch = useDispatch();
  const location = useLocation();
  const { categoryId, projectName } = location.state ?? {};

  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  const responseDocuments = useSelector((state) => state.ddqDocuments);
  const view = useSelector((state) => state.view);

  const fetchDocuments = () => {
    if (
      responseDocuments.fetchCategory !== categoryId ||
      (id &&
        responseDocuments.nextIndex !== undefined &&
        responseDocuments.nextIndex <= responseDocuments.totalDocuments)
    )
      dispatch(
        fetchDDQDocuments({
          id,
          questionId,
          categoryId,
          startIndex: responseDocuments.nextIndex,
          endIndex: responseDocuments.nextIndex + PAGE_SIZE - 1,
        })
      );
  };

  const { totalPages, currentPageData, currentPageInformation, pageSize } =
    usePagination({
      totalRecords: responseDocuments.totalDocuments,
      currentPageIndex,
      data: responseDocuments.documents,
    });

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pb-[20px]">
      <PageHeader
        title={"DDQ Response Documents"}
        backTo={
          <Link
            className="no-underline text-secondary"
            to={`/ddq_response/list/${id}/`}
            state={{ projectName }}
          >
            {PROJECTS.backToResponses}
          </Link>
        }
      >
        <BreadCrumbs
          data={[
            {
              name: PROJECTS.allProjects,
              link: pathList.user.projectList,
            },
            {
              name: <span>{projectName}</span>,
              link: `/ddq_response/list/${id}/`,
              data: { projectName: projectName },
            },
            {
              name: <span>Documents</span>,
              data: { projectName, categoryId },
            },
          ]}
          className="gap-2"
        />
      </PageHeader>
      <PageBody>
        <DelayResponseBody isLoading={view.ddqDocuments.pending}>
          <DDQDocumentList
            currentPageData={currentPageData}
            id={id}
            projectName={projectName}
            categoryId={categoryId}
            questionId={questionId}
          />
        </DelayResponseBody>
        <div className="flex justify-between items-center">
          <SmallText>{currentPageInformation}</SmallText>
          <CustomPagination
            onChange={(value) => {
              if (responseDocuments.nextIndex <= value * pageSize) {
                fetchDocuments();
              }
              setCurrentPageIndex(value);
            }}
            totalPage={totalPages}
            currentPage={currentPageIndex}
          />
        </div>
      </PageBody>
    </div>
  );
};
