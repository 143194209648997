import { Link } from "react-router-dom";
import { RightIcon } from "../../icons";
import { twMerge } from "tailwind-merge";
import React from "react";
import PropTypes from "prop-types";

const extractTextFromElement = (element) => {
  if (typeof element === "string") {
    return element;
  }
  if (React.isValidElement(element)) {
    const children = element.props.children;
    if (Array.isArray(children)) {
      return children.map(extractTextFromElement).join("");
    }
    return extractTextFromElement(children);
  }
  return "";
};

/**
 * BreadCrumbs Component
 *
 * This component renders a breadcrumb navigation based on the provided data.
 * It takes an array of objects containing `name`, `link`, and `data`.
 *
 * Props:
 * - data: Array of objects with the structure `{ name: React.ReactNode | string, link: string, data: object }`.
 * - className: Optional custom className for additional styling.
 *
 * Example usage:
 * ```
 * <BreadCrumbs
      data={[
      {
        link: '/',
        name: 'Home'
      },
      {
        link: '/products',
        name: 'Products'
      },      
    ]}
   />
 * ```
 */
export const BreadCrumbs = ({ data = [], className = "" }) => {
  return (
    <div className={twMerge("flex gap-2 items-center", className)}>
      {data.map((item, index) => (
        <Link
          key={`${item.name}-${index}`}
          to={item.link ?? ""}
          className={`no-underline ${
            index === data.length - 1
              ? "text-secondary"
              : "text-secondary-light hover:underline"
          }`}
          state={item.data ?? {}}
        >
          <div className="flex items-center">
            {typeof item.name === "string" ? (
              <span className="max-w-[120px] truncate ">
                <abbr title={item.name} className="no-underline">
                  {item.name}
                </abbr>
              </span>
            ) : (
              <span className="max-w-[120px] truncate">
                <abbr
                  title={extractTextFromElement(item.name)}
                  className="no-underline"
                >
                  {React.cloneElement(item.name)}
                </abbr>
              </span>
            )}
            {index < data.length - 1 && (
              <span className="ml-2 mt-[4px]">
                <RightIcon />
              </span>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
};

BreadCrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      link: PropTypes.string,
      data: PropTypes.object,
    })
  ).isRequired,
  className: PropTypes.string,
};
