import { useDispatch, useSelector } from "react-redux";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import {
  HeadText,
  NormalText,
  SubHeadText,
} from "../../../../components/typography";
import { DELETE } from "../../../../constant";
import { deleteCategory } from "../../../../action";
import PropTypes from "prop-types";

export const DeleteCategoryDialog = ({ id, onClose }) => {
  const view = useSelector((state) => state.view);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (id) {
      dispatch(deleteCategory({ id })).then(() => {
        onClose();
      });
    }
  };

  return (
    <div>
      <MUIDialog
        isOpen={true}
        width="xs"
        content={
          <div>
            <div className="w-full text-center mt-[14px]">
              <HeadText
                className={"text-primary"}
                testId="delete-category-title"
              >
                {DELETE.areYouSure}
              </HeadText>
              <div className="mt-[16px] w-[340px] flex flex-col gap-2">
                <SubHeadText>{DELETE.doYouReallyWant}</SubHeadText>
                <NormalText>{DELETE.thisItemCantBe}</NormalText>
              </div>
              <div className="mt-[16px] flex flex-col">
                <Button
                  size="md"
                  onClick={handleClick}
                  isLoading={view.categoryDelete.pending}
                  data-testid="delete-category-submit-button"
                >
                  {DELETE.yesDelete}
                </Button>
                <Button
                  variant="text"
                  size="md"
                  className={"text-primary mt-[10px]"}
                  onClick={onClose} // Close dialog on "No" button click
                  data-testid="delete-category-cancel-button"
                >
                  {DELETE.noKeepAsItIs}
                </Button>
              </div>
            </div>
          </div>
        }
        onClose={onClose}
      />
    </div>
  );
};

DeleteCategoryDialog.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
};
