import axios from "axios";
import FormData from "form-data";

const baseURL = "";

export const getRequest = async (url, params = {}) => {
  return await axios.get(`${baseURL}${url}`, { params: params });
};

export const syncPostRequest = (url, body) => {
  return axios.post(url, body, {
    headers: { "Content-Type": "application/json" },
  });
};

export const postRequest = async (url, body) => {
  return await axios.post(`${baseURL}${url}`, body, {
    headers: { "Content-Type": "application/json" },
  });
};

export const fileRequest = async (url, files) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append(`files[]`, file);
  });

  return await axios.post(`${baseURL}${url}`, formData);
};

export const patchRequest = async (url, body) => {
  return await axios.patch(`${baseURL}${url}`, body, {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteRequest = async (url, body) => {
  return await axios.delete(`${baseURL}${url}`, { data: body });
};
