import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const ProfileIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Profile">
        <g id="about">
          <path
            id="Vector"
            d="M10 10C10.8653 10 11.7112 9.74341 12.4306 9.26268C13.1501 8.78195 13.7108 8.09867 14.042 7.29924C14.3731 6.49982 14.4597 5.62015 14.2909 4.77148C14.1221 3.92281 13.7054 3.14326 13.0936 2.53141C12.4817 1.91956 11.7022 1.50288 10.8535 1.33407C10.0049 1.16526 9.12519 1.2519 8.32576 1.58303C7.52633 1.91416 6.84305 2.47492 6.36232 3.19438C5.88159 3.91385 5.625 4.75971 5.625 5.625C5.625 6.78532 6.08594 7.89812 6.90641 8.71859C7.72688 9.53907 8.83968 10 10 10ZM10 2.5C10.6181 2.5 11.2223 2.68328 11.7362 3.02666C12.2501 3.37004 12.6506 3.8581 12.8871 4.42912C13.1236 5.00013 13.1855 5.62847 13.065 6.23466C12.9444 6.84085 12.6467 7.39767 12.2097 7.83471C11.7727 8.27175 11.2158 8.56938 10.6097 8.68996C10.0035 8.81053 9.37513 8.74865 8.80411 8.51213C8.2331 8.2756 7.74504 7.87506 7.40166 7.36116C7.05828 6.84726 6.875 6.24307 6.875 5.625C6.875 4.7962 7.20424 4.00134 7.79029 3.41529C8.37634 2.82924 9.1712 2.5 10 2.5Z"
            fill={color}
            strokeWidth={strokeWidth}
          />
          <path
            id="Vector_2"
            d="M10.625 11.25H9.375C7.55164 11.25 5.80295 11.9743 4.51364 13.2636C3.22433 14.553 2.5 16.3016 2.5 18.125C2.5 18.2908 2.56585 18.4497 2.68306 18.5669C2.80027 18.6842 2.95924 18.75 3.125 18.75H16.875C17.0408 18.75 17.1997 18.6842 17.3169 18.5669C17.4342 18.4497 17.5 18.2908 17.5 18.125C17.5 16.3016 16.7757 14.553 15.4864 13.2636C14.197 11.9743 12.4484 11.25 10.625 11.25ZM3.7875 17.5C3.94118 16.1254 4.59594 14.8556 5.62667 13.9333C6.6574 13.0109 7.99184 12.5007 9.375 12.5H10.625C12.0082 12.5007 13.3426 13.0109 14.3733 13.9333C15.4041 14.8556 16.0588 16.1254 16.2125 17.5H3.7875Z"
            fill={color}
            strokeWidth={strokeWidth}
          />
        </g>
      </g>
    </svg>
  );
};

ProfileIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
