import { HeadText } from "../../../components/typography";
import { BackToAllQuestions } from "../../features/admin/ddq-management/back-to-all-question";
import { ADMIN } from "../../../constant";
import { EditDDQ } from "../../features/admin/ddq-management/edit-ddq";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../slice/ddq";
import { PageWhite } from "../../../components/organism/body/page-white";

export const EditDDQPage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const { id } = params;

  return (
    <PageWhite>
      <div className="flex justify-between items-center">
        <HeadText testId="edit-ddq-title">{ADMIN.editDDQ}</HeadText>
        <BackToAllQuestions
          callBack={() => dispatch(actions.resetSelectedState())}
        />
      </div>
      <div className="mt-[10px]">
        <EditDDQ id={Number(id)} />
      </div>
    </PageWhite>
  );
};
