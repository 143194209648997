import PropTypes from "prop-types";
import { InputAdornment, OutlinedInput } from "@mui/material";
import { ErrorText, LabelText } from "../../../typography";
import { twMerge } from "tailwind-merge";
import { CustomTheme } from "../../../theme/custom-theme";
import { SkeletonRect } from "../../loader/skeleton";
import { InformationIcon } from "../../../icons";
import { ToolTip } from "../../tooltip";

/**
 * TextField Component
 *
 * This component renders a Material-UI OutlinedInput as a text field with optional adornments,
 * error handling, and loading state.
 *
 * Props:
 * - label: String, the label for the text field.
 * - type: String, the input type for the text field (e.g., "text", "password").
 * - startAdornment: Node, start adornment element for the input field.
 * - endAdornment: Node, end adornment element for the input field.
 * - className: String, additional class names for styling.
 * - placeholder: String, placeholder text for the text field.
 * - value: Any, current value of the text field.
 * - name: String, name attribute for the input field.
 * - innerLabel: Node, inner label for accessibility.
 * - onBlur: Function, callback function for blur events.
 * - id: String, ID attribute for the text field.
 * - onChange: Function, callback function for value change events.
 * - error: Boolean, whether there's an error with the text field.
 * - errorText: String, error message text to display.
 * - isLoading: Boolean, whether the text field is in a loading state.
 * - errorTestId: String, test ID for error text.
 *
 * Example usage:
 * ```
 * <TextField
 *   label="Username"
 *   type="text"
 *   placeholder="Enter your username"
 *   value={username}
 *   onChange={(e) => setUsername(e.target.value)}
 *   error={hasError}
 *   errorText="Invalid username"
 *   isLoading={isLoading}
 * />
 * ```
 */
export const TextField = ({
  label,
  type,
  startAdornment = null,
  endAdornment = null,
  className,
  placeholder,
  value,
  name,
  innerLabel,
  onBlur = () => {},
  id = "text-field",
  onChange = () => {},
  error = false,
  errorText = "",
  isLoading = false,
  errorTestId = "error-text",
  information = "",
  disable = false,
  ...props
}) => {
  return (
    <CustomTheme>
      <div className={twMerge("flex flex-col h-max", className)}>
        {label && (
          <span className="flex items-center gap-1">
            <LabelText labelFor={id} className="my-2 ">
              {label}
            </LabelText>
            {information && (
              <ToolTip text={information}>
                <InformationIcon height={14} width={14} />
              </ToolTip>
            )}
          </span>
        )}
        {/* Display skeleton loader if isLoading is true, otherwise render the text field */}
        {isLoading ? (
          <SkeletonRect height={36} className="w-full" />
        ) : (
          <OutlinedInput
            id={id}
            label={innerLabel}
            value={value}
            size="small"
            name={name}
            placeholder={placeholder}
            type={type}
            className="w-full"
            error={error || errorText.length > 0}
            onChange={onChange}
            startAdornment={
              startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              )
            }
            endAdornment={
              endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              )
            }
            onBlur={onBlur}
            autoComplete="on"
            disabled={disable}
            {...props}
          />
        )}
        {/* Display error text if error or errorText is provided */}
        {!isLoading && errorText && (
          <ErrorText testId={errorTestId} className="w-full">
            {errorText}
          </ErrorText>
        )}
      </div>
    </CustomTheme>
  );
};

// Define the expected prop types and their constraints
TextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  innerLabel: PropTypes.node,
  onBlur: PropTypes.func,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  errorTestId: PropTypes.string,
};
