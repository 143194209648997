import { useEffect, useState } from "react";
import { useFeedback } from "../../../../hooks/use-feedback";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { PAGE_SIZE } from "../../../../constant";
import { DynamicTable } from "../../../../components/atoms/table";
import { FeedbackDialog } from "../../ddq-response/ddq-response-feedback-dialog";
import { useSelector } from "react-redux";

export const DDQFeedback = ({ filterClient }) => {
  const { columns, setSortingState, sortingState, dialog, setDialog } =
    useFeedback();

  const view = useSelector((state) => state.view);
  const feedback = useSelector((state) => state.feedback);

  const [data, setData] = useState([...feedback.data]);

  useEffect(() => {
    if (feedback.data && view.feedback.fetch.success)
      setData([...feedback.data]);
  }, [feedback, view.feedback.fetch.success]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sortingState,
    },
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
    filterFns: {
      clientFilter: (row, columnId, filterValue) => {
        return filterValue
          ? row.original[columnId]
              ?.toLocaleLowerCase()
              .includes(filterValue?.toLocaleLowerCase())
          : row.original[columnId];
      },
    },
    onSortingChange: setSortingState,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.getColumn("clientName").setFilterValue(filterClient);
  }, [filterClient, table]);

  return (
    <div>
      <DynamicTable
        table={table}
        columns={columns.length}
        records={data.length}
        isPending={view.feedback.fetch.pending}
        onRowsChange={(value) => table.setPageSize(value)}
      />
      <FeedbackDialog
        question={dialog.question}
        isOpen={dialog.isView}
        onClose={() => setDialog({ ...dialog, isView: false, id: null })}
        isViewOnly={true}
        data={{
          comments: dialog.comments,
          files: dialog.vdr,
          llmResponse: dialog.llmResponse,
          missingDocuments: dialog.missingDocuments,
        }}
      />
    </div>
  );
};
