import { useEffect, useState } from "react";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { TextField } from "../../../../components/atoms/input/text-field";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, LabelText } from "../../../../components/typography";
import { ADMIN, GENERAL } from "../../../../constant";
import {
  validCategoryName,
  validPrompt,
} from "../../../../utils/validation-functions";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewCategory,
  deleteSubCategories,
  editCategory,
} from "../../../../action";
import { actions } from "../../../slice/view";
import PropTypes from "prop-types";
import { AddIcon, DeleteIcon } from "../../../../components/icons";
import { COLORS } from "../../../../configuration";
import { TextArea } from "../../../../components/atoms/input/text-area";

export const AddCategoryDialog = ({ id, isEdit = false, isOpen, onClose }) => {
  const initialState = { value: "", subcategories: [] };
  const [category, setCategory] = useState(initialState);
  const [deletionSubcategory, setDeletionSubcategory] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);

  const viewState = useSelector((state) => state.view);
  const categoryState = useSelector((state) => state.category);

  const dispatch = useDispatch();

  const handleAddSubcategory = () => {
    setCategory({
      ...category,
      subcategories: [
        ...category.subcategories,
        {
          id: category.subcategories.length + 1,
          name: "",
          summaryPrompt: "",
          isEdit,
        },
      ],
    });
  };

  const handleDeleteSubCategory = (id) => {
    if (isEdit && id) {
      setDeletionSubcategory([...deletionSubcategory, id]);
    }
    setCategory({
      ...category,
      subcategories: category.subcategories.filter((i) => i.id !== id),
    });
  };

  const handleSubCategoryChange = (index, value) => {
    const updatedSubcategories = category.subcategories.map((sub, i) =>
      i === index ? { ...sub, name: value } : sub
    );
    setCategory({ ...category, subcategories: updatedSubcategories });
  };

  const handleSummaryPromptChange = (index, value) => {
    const updatedSubcategories = category.subcategories.map((sub, i) =>
      i === index ? { ...sub, summaryPrompt: value } : sub
    );
    setCategory({ ...category, subcategories: updatedSubcategories });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    setIsSubmit(true);
    const validSubCategories = category.subcategories.every(
      (i) => i.name !== "" && i.summaryPrompt !== ""
    );
    if (validCategoryName(category.value).status && validSubCategories) {
      if (isEdit) {
        if (deletionSubcategory.length > 0) {
          dispatch(
            deleteSubCategories({ id, subcategories: deletionSubcategory })
          );
        }
        dispatch(
          editCategory({
            id,
            value: category.value,
            subcategories: category.subcategories.map((i) => ({
              id: i?.isEdit ? "" : i.id,
              name: i?.name ?? "",
              summary_prompt: i?.summaryPrompt ?? "",
            })),
          })
        ).then(() => {
          setCategory(initialState);
          setIsSubmit(false);
          onClose?.();
        });
      } else {
        dispatch(
          createNewCategory({
            categoryName: category.value,
            subcategories: category.subcategories?.map((i) => ({
              name: i?.name ?? "",
              summary_prompt: i?.summaryPrompt ?? "",
            })),
          })
        ).then(() => {
          setCategory(initialState);
          setIsSubmit(false);
          onClose?.();
        });
      }
    }
  };

  useEffect(() => {
    if (isEdit && categoryState.categoryDetails) {
      const findCategory = categoryState.categoryDetails.find(
        (item) => item.id === id
      );
      setCategory({
        value: findCategory?.categoryName ?? "",
        subcategories: findCategory?.subCategories ?? [],
      });
    }
  }, [categoryState.categoryDetails, id, isEdit]);

  return (
    <div>
      {!isEdit && (
        <Button
          size="md"
          onClick={() => dispatch(actions.setCategoryDialogVisibility(true))}
        >
          {ADMIN.addCategory}
        </Button>
      )}
      {(viewState.category.dialog || (isEdit && isOpen)) && (
        <MUIDialog
          isOpen={true}
          width={category.subcategories.length > 0 ? "lg" : "md"}
          content={
            <div className="w-full">
              <HeadText
                className={"text-primary w-full text-center"}
                testId="category-title"
              >
                {isEdit ? ADMIN.editCategory : ADMIN.addNewCategory}
              </HeadText>
              <form>
                <div className="mt-[20px]">
                  <TextField
                    value={category.value}
                    type={"text"}
                    label={"Category"}
                    name={"category"}
                    placeholder={"Enter your category name*"}
                    onChange={(e) =>
                      setCategory({ ...category, value: e.target.value })
                    }
                    errorText={
                      isSubmit ? validCategoryName(category.value).message : ""
                    }
                    inputProps={{ "data-testid": "category-input" }}
                    errorTestId="category-validation"
                  />
                  <div className="mt-[12px]">
                    {category?.subcategories?.length > 0 && (
                      <>
                        <LabelText labelFor="Subcategory 1">
                          Sub categories
                        </LabelText>
                        <div className="max-h-[340px] mt-[8px] py-[4px] flex flex-col gap-2 overflow-y-auto">
                          {category.subcategories?.map((i, index) => (
                            <div key={i.id} className=" flex gap-2">
                              <TextArea
                                id={`Subcategory ${index + 1}`}
                                className="w-max"
                                placeholder={`Subcategory ${index + 1}*`}
                                errorText={
                                  isSubmit
                                    ? validCategoryName(i.name)?.message
                                    : ""
                                }
                                value={i.name}
                                rows={2}
                                onChange={(e) =>
                                  handleSubCategoryChange(index, e.target.value)
                                }
                              />
                              <div className="w-full">
                                <TextArea
                                  id={`SummaryPrompt ${index + 1}`}
                                  placeholder={"Summary Prompt*"}
                                  className="w-full"
                                  value={i.summaryPrompt}
                                  onChange={(e) => {
                                    handleSummaryPromptChange(
                                      index,
                                      e.target.value
                                    );
                                  }}
                                  rows={2}
                                  errorText={
                                    isSubmit
                                      ? validPrompt(i.summaryPrompt)?.message
                                      : ""
                                  }
                                />
                              </div>
                              <Button
                                color="error"
                                variant="text"
                                className="h-max w-max"
                                onClick={() => handleDeleteSubCategory(i.id)}
                              >
                                <DeleteIcon />
                              </Button>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mt-[12px] w-full">
                    <Button
                      startIcon={<AddIcon color={COLORS.primary} />}
                      variant="text"
                      className="w-full"
                      onClick={handleAddSubcategory}
                    >
                      {ADMIN.addSubCategory}
                    </Button>
                  </div>
                  <div className="w-full flex justify-center mt-[16px]">
                    <Button
                      size="md"
                      type="submit"
                      onClick={handleSubmit}
                      isLoading={
                        viewState.category.pending ||
                        viewState.categoryEdit.pending
                      }
                      data-testid="submit-category-button"
                    >
                      {isEdit ? GENERAL.save : ADMIN.addCategory}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          }
          onClose={() => {
            setCategory(initialState);
            setIsSubmit(false);
            isEdit
              ? onClose?.()
              : dispatch(actions.setCategoryDialogVisibility(false));
          }}
        />
      )}
    </div>
  );
};

AddCategoryDialog.propTypes = {
  isEdit: PropTypes.bool,
  isOpen: PropTypes.bool,
  id: PropTypes.number,
  onClose: PropTypes.func,
};
