import { useDispatch, useSelector } from "react-redux";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { HeadText, NormalText } from "../../../../components/typography";
import { ADMIN, DELETE } from "../../../../constant";
import { updateClientStatus } from "../../../../action";
import PropTypes from "prop-types";

export const UpdateClientStatusDialog = ({ id, onClose, status }) => {
  const dispatch = useDispatch();
  const view = useSelector((state) => state.view);

  const handleSubmit = () => {
    dispatch(updateClientStatus({ id, status })).then(() => {
      onClose();
    });
  };

  return (
    <MUIDialog
      isOpen
      width="xs"
      onClose={onClose}
      content={
        <div>
          <div className="w-full text-center">
            <HeadText className={"text-primary font-normal"}>
              {DELETE.areYouSure}
            </HeadText>
            <div className="mt-[16px]">
              <NormalText>
                {status
                  ? `${ADMIN.doYouWantToEnable} client.`
                  : `${ADMIN.doYouReallyWantToDisable} client.`}
              </NormalText>
            </div>
            <div className="mt-[16px] flex flex-col gap-4">
              <Button
                size="md"
                onClick={handleSubmit}
                isLoading={view.clientStatus.pending}
              >
                {ADMIN.yesIWant}
              </Button>
              <Button size="md" variant="text" onClick={() => onClose()}>
                {DELETE.noKeepAsItIs}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

UpdateClientStatusDialog.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  status: PropTypes.bool,
};
