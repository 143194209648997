import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ErrorText, NormalText, SmallText } from "../../typography";
import { CloseIcon, DocumentIcon, UploadCloudIcon } from "../../icons";
import { twMerge } from "tailwind-merge";
import { COLORS } from "../../../configuration";
import { ToolTip } from "../../atoms/tooltip";
import { MUIButton as Button } from "../../atoms/button/mui-button";
import { GENERAL } from "../../../constant";

export const FileInput = ({
  onChange,
  isReset = false,
  isMultiple = false,
  accept = ["*"],
  errorText = "",
  className,
  showClear = true,
  note,
  id = "file-input",
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setFiles([]);
    setError(false);
    setIsDragging(false);
  }, [isReset]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const acceptedExtensions = accept.map((ext) =>
    ext.toLowerCase().replace(".", "")
  );

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    validateAndSetFile(droppedFiles);
  };

  const validateAndSetFile = (selectedFiles) => {
    const validFiles = selectedFiles.filter((selectedFile) => {
      const fileName = selectedFile.name.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      return (
        acceptedExtensions.includes(fileExtension) ||
        acceptedExtensions.includes("*")
      );
    });

    if (validFiles.length > 0) {
      const filesWithUpdatedNames = validFiles.map((file) => {
        const updatedName = file.name.replace(/\s+/g, "_");
        return new File([file], updatedName, { type: file.type });
      });

      if (isMultiple) {
        const newFiles = [...files, ...filesWithUpdatedNames];
        setFiles(newFiles);
        onChange?.(newFiles);
      } else {
        setFiles([filesWithUpdatedNames[0]]);
        onChange?.(filesWithUpdatedNames[0]);
      }
    } else {
      setError(true);
      setTimeout(() => setError(false), 5000);
    }
  };

  const border = error ? "border-error" : "border-edge";

  return (
    <div
      className={`border-2 relative border-dashed py-[8px] ${
        isDragging ? "border-success" : border
      }  w-full rounded-md text-center px-[4px]`}
    >
      <div className="flex gap-4 mx-auto w-full mt-[4px]">
        <UploadCloudIcon color={COLORS.secondary} strokeWidth={1} />
        <NormalText className={"mt-[2px]"}>
          {GENERAL.dragAndDropYourFiles}
        </NormalText>
        {showClear && (
          <Button
            variant="text"
            className={"h-max"}
            onClick={() => {
              setFiles([]);
              onChange?.([]);
            }}
          >
            {GENERAL.clear}
          </Button>
        )}
      </div>
      <input
        id={id}
        type="file"
        className={twMerge("opacity-0 cursor-pointer", className)}
        onChange={(e) => {
          const selectedFiles = Array.from(e.target.files);
          validateAndSetFile(selectedFiles);
        }}
        accept={accept.join(",")}
        multiple={isMultiple}
        aria-describedby="file_input"
        tabIndex={0}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      />
      <div className="flex flex-wrap gap-2 items-center justify-center w-full max-h-[320px] overflow-auto">
        {files.map((file, index) => (
          <ToolTip key={`${file.name}-${index}`} text={file.name}>
            <div
              key={index}
              className=" bg-page-light rounded-md p-[4px] mx-auto flex items-center mt-2"
            >
              <DocumentIcon height={16} width={16} />
              <SmallText className="ml-[4px] w-[120px] truncate">
                {file.name}
              </SmallText>
              <button
                className="p-2 cursor-pointer border-none flex items-center mr-[8px] bg-page-light hover:bg-page rounded-full"
                onMouseDown={() => {
                  const newFiles = files.filter((i) => i.name !== file.name);
                  setFiles(newFiles);
                  onChange?.(newFiles);
                }}
              >
                <CloseIcon width={8} height={8} />
              </button>
            </div>
          </ToolTip>
        ))}
      </div>
      {note && <SmallText className={"text-xs"}>{note}</SmallText>}
      {(error || errorText) && <ErrorText>{errorText}</ErrorText>}
    </div>
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func,
  isReset: PropTypes.bool,
  isMultiple: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  className: PropTypes.string,
  showClear: PropTypes.bool,
  note: PropTypes.string,
  id: PropTypes.string,
};
