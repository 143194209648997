import { Bullets } from "../../components/icons";
import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { HeadText, NormalText } from "../../components/typography";
import { GENERAL, PRIVACY_POLICY } from "../../constant";

const policyList = [
  {
    id: 1,
    text: `Your privacy is important to us. It is Simplify's policy to respect your privacy regarding any information we may collect from you across our website, simplifyai.ie, and other sites we own and operate.`,
  },
  {
    id: 2,
    text: `We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.`,
  },
  {
    id: 3,
    text: `We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.`,
  },
  {
    id: 4,
    text: `We don’t share any personally identifying information publicly or with third-parties, except when required to by law.`,
  },
  {
    id: 5,
    text: `Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.`,
  },
];

export const PrivacyPolicyPage = () => {
  return (
    <div>
      <PageHeader title="Privacy Policy" />
      <PageBody className="leading-6 text-justify">
        <HeadText className="mb-5">{GENERAL.ourPrivacyPolicy}</HeadText>
        <NormalText>&emsp;{PRIVACY_POLICY}</NormalText>
        <div className="list-none mt-4 text-secondary-light">
          {policyList.map((item) => (
            <div key={item.id} className="flex gap-[18px]">
              <Bullets height={24} width={24} />
              <div>
                <span>{item.text}</span>
              </div>
            </div>
          ))}
        </div>
      </PageBody>
    </div>
  );
};
