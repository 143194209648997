import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { AppMenus as Menus } from "../../../routes/app-navigation";

export const MenuList = () => {
  const location = useLocation();

  return (
    <nav className="h-full">
      <div className="list-none flex gap-8 h-full">
        {Menus.map((menu) => (
          <Link
            key={menu.id}
            to={menu.url}
            style={{ textDecoration: "none" }}
            className="h-full"
          >
            <div className="w-max h-full flex relative items-center group">
              <div
                id={menu.id}
                className="font-bold text-primary tracking-wide  h-max"
              >
                {menu.name}
              </div>
              {/* Hover Animation in header link */}
              <div
                className={twMerge(
                  "hidden group-hover:block h-1 bg-warning-dark absolute w-full bottom-0 rounded-t-md",
                  location.pathname.includes(menu.url) && "block"
                )}
              ></div>
            </div>
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default MenuList;
