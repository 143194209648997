import PropTypes from "prop-types";
import { ImageWithSkeleton } from "../../organism/body/loading-image";
export const BrandLogo = ({ height = 36, className }) => {
  return (
    <div className={className}>
      <ImageWithSkeleton
        src={"/static/brand/simplify-brand-logo.png"}
        alt={"simplify"}
        height={height}
        className={"w-max mx-auto"}
      />
    </div>
  );
};  

BrandLogo.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
};

export default BrandLogo;
