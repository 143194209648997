import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const DeleteIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ minWidth: width, minHeight: height }}
    >
      <title>Delete</title>
      <g id="185090_delete_garbage_icon 2">
        <g id="Expanded">
          <g id="Group">
            <g id="Group_2">
              <path
                id="Vector"
                d="M19.0832 21.5833H4.9165V4.5H19.0832V21.5833ZM5.74984 20.75H18.2498V5.33333H5.74984V20.75Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
            <g id="Group_3">
              <path
                id="Vector_2"
                d="M16.5832 5.33333H7.4165V2H16.5832V5.33333ZM8.24984 4.5H15.7498V2.83333H8.24984V4.5Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
            <g id="Group_4">
              <path
                id="Vector_3"
                d="M8.66667 18.6667C8.43625 18.6667 8.25 18.4804 8.25 18.25V7.83332C8.25 7.60291 8.43625 7.41666 8.66667 7.41666C8.89708 7.41666 9.08333 7.60291 9.08333 7.83332V18.25C9.08333 18.4804 8.89708 18.6667 8.66667 18.6667Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
            <g id="Group_5">
              <path
                id="Vector_4"
                d="M12.0002 18.6667C11.7697 18.6667 11.5835 18.4804 11.5835 18.25V7.83332C11.5835 7.60291 11.7697 7.41666 12.0002 7.41666C12.2306 7.41666 12.4168 7.60291 12.4168 7.83332V18.25C12.4168 18.4804 12.2306 18.6667 12.0002 18.6667Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
            <g id="Group_6">
              <path
                id="Vector_5"
                d="M15.3332 18.6667C15.1028 18.6667 14.9165 18.4804 14.9165 18.25V7.83332C14.9165 7.60291 15.1028 7.41666 15.3332 7.41666C15.5636 7.41666 15.7498 7.60291 15.7498 7.83332V18.25C15.7498 18.4804 15.5636 18.6667 15.3332 18.6667Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
            <g id="Group_7">
              <path
                id="Vector_6"
                d="M22 4.5H2V5.33333H22V4.5Z"
                fill={color}
                strokeWidth={strokeWidth}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

DeleteIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
