import { nanoid } from "@reduxjs/toolkit";

const severity = "success";

export const loginSuccess = (email) => {
  return {
    id: nanoid(),
    content: `Successfully logged in as ${email}`,
    severity,
  };
};

export const forgotPasswordSuccess = (email) => {
  return {
    id: nanoid(),
    content: `Successfully link sent for reset password on ${email}`,
    severity,
  };
};

export const resetPasswordSuccess = () => {
  return {
    id: nanoid(),
    content: `Password reset successfully.`,
    severity,
  };
};

export const resendEmailSuccess = () => {
  return {
    id: nanoid(),
    content: `Email verification link sent successfully on given email.`,
    severity,
  };
};

export const categoryAddedSuccess = (id) => {
  return {
    id: nanoid(),
    content: `Category data has been successfully added. `,
    severity,
  };
};

export const projectCreatedSuccess = (id) => {
  return {
    id: nanoid(),
    content: `Project created successfully.`,
    severity,
  };
};

export const deleteProjectSuccess = () => {
  return {
    id: nanoid(),
    content: `Project deleted successfully.`,
    severity,
  };
};

export const createDDQSuccess = () => {
  return {
    id: nanoid(),
    content: `DDQ added successfully. `,
    severity,
  };
};

export const deleteDDQSuccess = () => {
  return {
    id: nanoid(),
    content: `DDQ deleted successfully.`,
    severity,
  };
};

export const categoryStatusSuccess = () => {
  return {
    id: nanoid(),
    content: `Category status updated successfully.`,
    severity,
  };
};

export const categoryDeleteSuccess = () => {
  return {
    id: nanoid(),
    content: `Category deleted successfully.`,
    severity,
  };
};
export const editCategorySuccess = () => {
  return {
    id: nanoid(),
    content: `Category has been successfully edited.`,
    severity,
  };
};
export const editDDQSuccess = () => {
  return {
    id: nanoid(),
    content: `DDQ successfully updated.`,
    severity,
  };
};
export const downloadSuccess = ({ message }) => {
  return {
    id: nanoid(),
    content: message,
    severity,
  };
};

export const clientStatusSuccess = () => {
  return {
    id: nanoid(),
    content: `Client status updated successfully.`,
    severity,
  };
};

export const clientAddSuccess = () => {
  return {
    id: nanoid(),
    content: `Client Added successfully. Kindly check email.`,
    severity,
  };
};

export const clientEditSuccess = () => {
  return {
    id: nanoid(),
    content: `Client Edited Successfully. `,
    severity,
  };
};
export const deleteClientSuccess = () => {
  return {
    id: nanoid(),
    content: `Client Deleted Successfully. `,
    severity,
  };
};

export const supportResolvedSuccess = () => {
  return {
    id: nanoid(),
    content: `Support request has been successfully resolved.`,
    severity,
  };
};

export const supportRequestSentSuccess = () => {
  return {
    id: nanoid(),
    content: `Support request successfully sent.`,
    severity,
  };
};

export const uploadMultipleDocuments = () => {
  return {
    id: nanoid(),
    content: `Project Document Uploaded Successfully.`,
    severity,
  };
};

export const deleteDocumentSuccess = () => {
  return {
    id: nanoid(),
    content: `Document deleted successfully.`,
    severity,
  };
};

export const uploadDocumentSuccess = () => {
  return {
    id: nanoid(),
    content: `Document uploaded successfully`,
    severity,
  };
};

export const editProjectSuccess = () => {
  return {
    id: nanoid(),
    content: `Project data updated successfully.`,
    severity,
  };
};

export const clientAdminStatusSuccess = () => {
  return {
    id: nanoid(),
    content: `Client's admin status updated successfully.`,
    severity,
  };
};

export const sendDocParserSuccess = () => {
  return {
    id: nanoid(),
    content: `Document Parser Successfully sent.`,
    severity,
  };
};

export const sendSummarySuccess = () => {
  return {
    id: nanoid(),
    content: `Summary message sent successfully.`,
    severity,
  };
};

export const sendChunkerSuccess = () => {
  return {
    id: nanoid(),
    content: `Chunker message sent successfully.`,
    severity,
  };
};

export const createSummaryIndexSuccess = () => {
  return {
    id: nanoid(),
    content: `Summary index created successfully.`,
    severity,
  };
};

export const createChunkIndexSuccess = () => {
  return {
    id: nanoid(),
    content: `Chunk index created successfully.`,
    severity,
  };
};

export const sendDataToSummaryIndexSuccess = () => {
  return {
    id: nanoid(),
    content: `Summary Data index message sent successfully.`,
    severity,
  };
};

export const sendDataToChunkIndexSuccess = () => {
  return {
    id: nanoid(),
    content: `Chunk Data index message sent successfully.`,
    severity,
  };
};

export const sendResponseGeneratorSuccess = () => {
  return {
    id: nanoid(),
    content: `Response generator message sent successfully.`,
    severity,
  };
};

export const sendFeedbackSuccess = () => {
  return {
    id: nanoid(),
    content: `Thank you for helping us. Your feedback successfully saved.`,
    severity,
  };
};