import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { PROJECTS } from "../../constant";
import { UploadPdf } from "../features/project-list/upload-pdf";

export const UploadPdfPage = () => {
  return (
    <div>
      <PageHeader title={PROJECTS.addFiles} />
      <PageBody>
        <UploadPdf />
      </PageBody>
    </div>
  );
};
