import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useResponse } from "../../../hooks/use-response";
import { DynamicTable } from "../../../components/atoms/table";
import { NormalText } from "../../../components/typography";

export const DdqGptResponseTable = ({ data = [], response = "" }) => {
  const { columns } = useResponse();

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <NormalText className="mb-[8px]">{response}</NormalText>
      <DynamicTable
        table={table}
        isPaginationRequired={false}
        columns={columns.length}
        records={data.length}
      />
    </div>
  );
};
