import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const UpArrow = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      fill={color}
      width={width}
      height={height}
      viewBox="0 -6 524 524"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      transform="matrix(1,0,0,1,0,0)"
      className={className}
      strokeWidth={strokeWidth}
    >
      <title>Up</title>
      <path
        d="M460 333L426 367 262 204 98 367 64 333 262 137 460 333Z"
        strokeWidth={strokeWidth}
      ></path>
    </svg>
  );
};

UpArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  strokeWidth: PropTypes.number,
};
