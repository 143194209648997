import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const SearchIcon = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.38745 14.6625C3.37495 14.6625 0.0749512 11.4 0.0749512 7.34998C0.0749512 3.33748 3.33745 0.0374756 7.38745 0.0374756C11.4375 0.0374756 14.7 3.29998 14.7 7.34998C14.6625 11.4 11.4 14.6625 7.38745 14.6625ZM7.38745 1.12497C3.93745 1.12497 1.12495 3.93747 1.12495 7.38747C1.12495 10.8375 3.93745 13.65 7.38745 13.65C10.8375 13.65 13.65 10.8375 13.65 7.38747C13.6124 3.93747 10.8 1.12497 7.38745 1.12497Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M17.1374 17.925L12.0374 12.8625L12.8624 12.0375L17.9249 17.1375L17.1374 17.925Z"
        fill={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

SearchIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};
