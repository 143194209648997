import { useEffect, useState } from "react";
import { MUIButton as Button } from "../../../../components/atoms/button/mui-button";
import { MUIDialog } from "../../../../components/organism/dialog/mui-dialog";
import { ADMIN, GENERAL, GUIDELINE_FOR_PHONE } from "../../../../constant";
import { HeadText } from "../../../../components/typography";
import { TextField } from "../../../../components/atoms/input/text-field";
import { useDispatch, useSelector } from "react-redux";
import { addClient, editClient } from "../../../../action";
import {
  validClient,
  validEmail,
  validName,
  validPhone,
} from "../../../../utils/validation-functions";
import PropTypes from "prop-types";

const initialData = {
  name: "",
  email: "",
  phoneNumber: "",
};

export const AddClientDialog = ({
  showDialog,
  onClose,
  isEdit = false,
  id,
}) => {
  const [data, setData] = useState(initialData);

  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const view = useSelector((state) => state.view);
  const client = useSelector((state) => state.clients);

  useEffect(() => {
    if (isEdit) {
      setData({ ...client.selected });
    }
  }, [client.selected, isEdit]);

  const handleSubmit = () => {
    setIsSubmit(true);
    const isValid = validClient({
      name: data.name,
      email: data.email,
      phone: data.phoneNumber,
    });
    if (!isEdit && isValid)
      dispatch(addClient({ ...data })).then(() => {
        setIsSubmit(false);
        setData(initialData);
        onClose?.();
      });
    else if (isEdit && id && isValid) {
      dispatch(editClient({ id, ...data })).then(() => {
        setIsSubmit(false);
        onClose?.();
      });
    }
  };

  return (
    <>
      {showDialog && (
        <MUIDialog
          isOpen={true}
          onClose={onClose}
          width="sm"
          content={
            <div className="w-max mx-auto">
              <HeadText
                className={"text-primary text-center"}
                testId="client-dialog-title"
              >
                {isEdit ? ADMIN.editClient : ADMIN.addClient}
              </HeadText>
              <div className="mt-[20px] w-[420px]">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <TextField
                    value={data.name}
                    label={"Name"}
                    name={"name"}
                    type={"text"}
                    placeholder={"Eg. John Doe*"}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    errorText={isSubmit ? validName(data.name).message : ""}
                    isLoading={view.clientSelect.pending}
                    inputProps={{ "data-testid": "name-input" }}
                    required
                  />
                  <TextField
                    value={data.email}
                    type={"email"}
                    name={"email"}
                    label={"Email"}
                    placeholder={"example@email.com*"}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    errorText={isSubmit ? validEmail(data.email).message : ""}
                    isLoading={view.clientSelect.pending}
                    inputProps={{ "data-testid": "email-input" }}
                    disable={isEdit}
                    required
                  />
                  <TextField
                    value={data.phoneNumber}
                    type={"tel"}
                    label={"Phone number"}
                    name={"phone number"}
                    placeholder={"Phone number*"}
                    errorText={
                      !view.clientSelect.pending &&
                      validPhone(data.phoneNumber, isSubmit).message
                    }
                    onChange={(e) =>
                      setData({ ...data, phoneNumber: e.target.value })
                    }
                    inputProps={{ "data-testid": "phone-input" }}
                    isLoading={view.clientSelect.pending}
                    information={GUIDELINE_FOR_PHONE}
                    required
                  />
                  <div className="mt-[20px] flex gap-2 w-max ml-auto">
                    {!isEdit && (
                      <Button
                        type={"reset"}
                        size="md"
                        variant="text"
                        onClick={() => setData(initialData)}
                      >
                        {GENERAL.reset}
                      </Button>
                    )}
                    <Button
                      type={"submit"}
                      size="md"
                      isLoading={
                        view.clientAdd.pending || view.clientEdit.pending
                      }
                      data-testid="submit-button"
                    >
                      {isEdit ? GENERAL.save : ADMIN.add}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          }
        />
      )}
    </>
  );
};

AddClientDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  isEdit: PropTypes.bool,
};
