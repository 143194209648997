import { Switch as MUISwitch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LabelText } from "../../typography";
import PropTypes from "prop-types";
import { COLORS } from "../../../configuration";
import { CustomTheme } from "../../theme/custom-theme";
import { useState } from "react";
import { POSITION } from "../../../constant";

// Define styles for the switch based on its size
const sizeStyles = {
  sm: {
    width: 28,
    height: 16,
    activeThumb: 15,
    thumbWidth: 12,
    thumbHeight: 12,
    transform: 9,
    thumbBorderRadius: 6,
    padding: 2,
    trackBorderRadius: 8,
    trackCheckedColor: COLORS.switchGreen,
  },
  md: {
    width: 40,
    height: 24,
    activeThumb: 22,
    transform: 16,
    thumbWidth: 16,
    thumbHeight: 16,
    thumbBorderRadius: 10,
    padding: 4,
    trackBorderRadius: 12,
    trackCheckedColor: COLORS.switchGreen,
  },
};

// Determine the styles based on the size prop
const getSizeStyles = (size) => sizeStyles[size] || sizeStyles.sm;

// Style the switch component based on the size and theme
const MUICustomizedSwitch = styled(MUISwitch)(({ theme, size }) => {
  const {
    width,
    height,
    activeThumb,
    transform,
    thumbWidth,
    thumbHeight,
    thumbBorderRadius,
    padding,
    trackBorderRadius,
    trackCheckedColor,
  } = getSizeStyles(size);

  return {
    width,
    height,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: activeThumb,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: `translateX(${transform}px)`,
      },
    },
    "& .MuiSwitch-switchBase": {
      padding,
      "&.Mui-checked": {
        transform: `translateX(${transform}px)`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: trackCheckedColor || "#1890ff", // Set the custom color for checked state
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: thumbWidth,
      height: thumbHeight,
      borderRadius: thumbBorderRadius,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: trackBorderRadius,
      opacity: 1,
      backgroundColor: "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  };
});

/**
 * Switch Component
 *
 * A custom styled switch component with labels for both on and off states.
 *
 * Props:
 * - label: String, label for the checked state.
 * - offLabel: String, label for the unchecked state.
 * - labelPosition: One of 'left' or 'right' (default: 'left'), position of the label relative to the switch.
 * - checked: Boolean, indicates whether the switch is checked or not.
 * - onChange: Function, callback fired when the switch state changes.
 * - size: One of 'sm' or 'md' (default: 'sm'), size of the switch.
 * - id: String, ID of the switch component.
 * - testId: String, data-testid for testing purposes.
 * - labelTestId: String, data-testid for the switch label.
 *
 * Example usage:
 * ```
 * <Switch
 *   label="Enable Notifications"
 *   offLabel="Disable Notifications"
 *   checked={true}
 *   onChange={(isChecked) => console.log("Switch checked state:", isChecked)}
 *   size="md"
 *   labelPosition="right"
 * />
 * ```
 */
export const Switch = ({
  label,
  offLabel,
  labelPosition = POSITION.left,
  checked = false,
  onChange = () => {},
  size = "sm",
  id = "switch",
  testId = "switch",
  labelTestId = "switch-label",
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  // Determine the label based on the switch state
  const switchLabel = isChecked ? (
    <LabelText labelFor={id} className="mx-2 capitalize" testId={labelTestId}>
      {label}
    </LabelText>
  ) : (
    <LabelText
      labelFor={id}
      className="text-gray-400 mx-2 capitalize"
      testId={labelTestId}
    >
      {offLabel}
    </LabelText>
  );

  return (
    <CustomTheme>
      <div className="flex items-center">
        {labelPosition === POSITION.left && switchLabel}
        <MUICustomizedSwitch
          id={id}
          size={size}
          checked={isChecked}
          onChange={() => {
            const newState = !isChecked;
            setIsChecked(newState);
            onChange(newState);
          }}
          data-testid={testId}
        />
        {labelPosition === POSITION.right && switchLabel}
      </div>
    </CustomTheme>
  );
};

// Define prop types for the Switch component
Switch.propTypes = {
  label: PropTypes.string.isRequired,
  offLabel: PropTypes.string.isRequired,
  labelPosition: PropTypes.oneOf([POSITION.left, POSITION.right]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md"]),
  id: PropTypes.string,
  testId: PropTypes.string,
  labelTestId: PropTypes.string,
};

// Define default prop values for optional props
Switch.defaultProps = {
  checked: false,
  onChange: () => {},
  size: "sm",
};

export default Switch;
