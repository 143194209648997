import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MUIButton } from "../../components/atoms/button/mui-button";
import { pathList } from "../../routes/app-navigation";
import BrandLogo from "../../components/icons/Logo/brand-logo";
import { AllRightsReserved } from "../../components/general/all-rights-reserved";

export const PageNotFound = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const goBackHome = () => {
    if (user?.email) {
      navigate(pathList.user.projectList);
    } else {
      navigate(pathList.login);
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="relative flex-grow">
        <div className="absolute top-4 left-4">
          <BrandLogo />
        </div>

        <div className="flex flex-col items-center justify-center h-full">
          <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
          <p className="text-xl text-gray-600 mb-4">
            {user?.email
              ? "Oops! It's not you, it's us. The page you're looking for isn't here."
              : "Looks like you're lost. Please log in to access the page you're looking for."}
          </p>
          <p className="text-lg text-gray-500 mb-8">
            {user?.email
              ? "Let's get you back on track."
              : "Log in to continue or return to the home page."}
          </p>
          <MUIButton onClick={goBackHome}>
            {user?.email ? "Go Back to Home" : "Log In"}
          </MUIButton>
        </div>
      </div>

      <footer className="bg-gray-200 py-4 text-center">
        <p className="text-md text-gray-500">
          If the issue persists, please contact the admin and verify your
          account status.
        </p>
        <AllRightsReserved />
      </footer>
    </div>
  );
};
