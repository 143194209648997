import { useState } from "react";
import { MUIButton as Button } from "../../../components/atoms/button/mui-button";
import { InformationIcon } from "../../../components/icons";

import { MUIDialog } from "../../../components/organism/dialog/mui-dialog";
import { ADMIN } from "../../../constant";

import PropTypes from "prop-types";
import { validCategory } from "../../../utils/validation-functions";
import { useSelector } from "react-redux";
import { CategoryDropDown } from "../admin/categories-management/category-dropdown";

export const SelectCategoryDialog = ({ isOpen, callBack, onClose }) => {
  const [category, setCategory] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const viewState = useSelector((state) => state.view);

  const handleSubmit = () => {
    setIsSubmit(true);
    if (validCategory(category).status) {
      callBack(category);
    }
  };

  return (
    isOpen && (
      <MUIDialog
        isOpen={true}
        width="xs"
        content={
          <div className="w-[310px]">
            <div className="bg-primary-hover text-primary ml-[10px] py-[10px] flex gap-4 items-center rounded-[8px]">
              <div className="ml-[32px]">
                <InformationIcon />
              </div>
              {ADMIN.pleaseEnterValidCategory}
            </div>
            <div className="mt-[10px]">
              <CategoryDropDown
                callBack={(value) => setCategory(value)}
                isSubmit={isSubmit}
              />
            </div>
            <div className="mt-[10px] mx-auto w-max">
              <Button
                size="md"
                onClick={() => handleSubmit()}
                isLoading={viewState.createProject.pending}
              >
                {ADMIN.saveAndProcess}
              </Button>
            </div>
          </div>
        }
        onClose={onClose}
      />
    )
  );
};

SelectCategoryDialog.propTypes = {
  isOpen: PropTypes.bool,
  callBack: PropTypes.func,
  onClose: PropTypes.func,
};
