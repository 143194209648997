import React from "react";
import "./animated-loader.css"; // Import the CSS file containing the animation styles

export const AnimatedLoader = ({ width = 64, height = 64 }) => {
  const circleStyle = {
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div
      className="loader"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div className="circle" style={circleStyle}></div>
      <div className="circle" style={circleStyle}></div>
      <div className="circle" style={circleStyle}></div>
      <div className="circle" style={circleStyle}></div>
    </div>
  );
};
