import { PageBody } from "../../components/organism/body/page-body";
import { PageHeader } from "../../components/organism/header/page-header";
import { NormalText } from "../../components/typography";

export const TermsAndConditionPage = () => {
  return (
    <div>
      <PageHeader title={"Terms and Condition"} />
      <PageBody className={"leading-6 text-justify"}>
        <NormalText>
          &emsp;Welcome to Simplify! These Terms and Conditions ("Terms", "Terms
          and Conditions") govern your relationship with Simplify ("us", "we",
          or "our") website and services (the "Service").
        </NormalText>
        <NormalText>
          &emsp;Please read these Terms and Conditions carefully before using
          our Service. Your access to and use of the Service is conditioned on
          your acceptance of and compliance with these Terms. These Terms apply
          to all visitors, users, and others who access or use the Service.
        </NormalText>
        <NormalText>
          &emsp;By accessing or using the Service, you agree to be bound by
          these Terms. If you disagree with any part of the terms, you may not
          access the Service.
        </NormalText>
        <NormalText>
          &emsp;When you create an account with us, you must provide us with
          information that is accurate, complete, and current at all times.
          Failure to do so constitutes a breach of the Terms, which may result
          in immediate termination of your account on our Service.
        </NormalText>
        <NormalText>
          &emsp;You are responsible for safeguarding the password that you use
          to access the Service and for any activities or actions under your
          password. You agree not to disclose your password to any third party.
          You must notify us immediately upon becoming aware of any breach of
          security or unauthorized use of your account.
        </NormalText>
        <NormalText>
          &emsp;The Service and its original content, features, and
          functionality are and will remain the exclusive property of Simplify
          and its licensors. The Service is protected by copyright, trademark,
          and other laws of both the United States and foreign countries. Our
          trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of Simplify.
        </NormalText>
        <NormalText>
          &emsp;Our Service may contain links to third-party websites or
          services that are not owned or controlled by Simplify. Simplify has no
          control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third-party websites or services. You
          further acknowledge and agree that Simplify shall not be responsible
          or liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with use of or reliance on
          any such content, goods, or services available on or through any such
          websites or services.
        </NormalText>
        <NormalText>
          &emsp;We reserve the right, at our sole discretion, to modify or
          replace these Terms at any time. If a revision is material, we will
          try to provide at least 30 days' notice prior to any new terms taking
          effect. What constitutes a material change will be determined at our
          sole discretion.
        </NormalText>
        <NormalText>
          &emsp;By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </NormalText>
        <NormalText>
          &emsp;If you have any questions about these Terms, please contact us.
        </NormalText>
      </PageBody>
    </div>
  );
};
