import PropTypes from "prop-types";
import { ICONS } from "../../../constant";

export const UpFilledArrow = ({
  color = ICONS.color,
  width = ICONS.width,
  height = ICONS.height,
  strokeWidth = ICONS.stroke,
  className = "",
}) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 52"
      xmlSpace="preserve"
      version="1.1"
      transform="matrix(1,0,0,-1,0,0)"
      className={className}
      strokeWidth={strokeWidth}
    >
      <title>Up</title>
      <path
        d="M8.3,14h35.4c1,0,1.7,1.3,0.9,2.2L27.3,37.4c-0.6,0.8-1.9,0.8-2.5,0L7.3,16.2C6.6,15.3,7.2,14,8.3,14z"
        strokeWidth={strokeWidth}
      ></path>
    </svg>
  );
};

UpFilledArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeWidth: PropTypes.number,
  className: PropTypes.string,
};
