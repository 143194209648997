import React from "react";
import PropTypes from "prop-types";
import { NormalText, SubHeadText } from "../../../components/typography";
import { DocumentIcon } from "../../../components/icons";

export const DdqSlResponse = ({ data = [] }) => {
  const renderArrayItems = (items) => {
    return items?.map((innerItem, innerIndex) => (
      <div
        key={innerIndex}
        className={`mb-[12px] p-[8px] ${innerIndex % 2 ? "bg-page" : ""}`}
      >
        <NormalText>
          <b>Id:</b> {innerItem.id}
        </NormalText>
        <NormalText>
          <b>Type:</b> {innerItem.type}
        </NormalText>
        <NormalText>
          <b>Number:</b> {innerItem.num}
        </NormalText>
        <NormalText>
          <b>Name:</b> {innerItem.name}
        </NormalText>
        <NormalText>
          <b>Percent:</b> {innerItem.percent}
        </NormalText>
      </div>
    ));
  };

  const renderStringItems = (items) => {
    let isLinkIncluded = false;
    return items?.map((item, index) => {
      isLinkIncluded = isLinkIncluded || item.startsWith("https");
      return (
        <div className="mb-[16px]" key={index}>
          {item.startsWith("https") ? (
            <a href={item} target="_blank" rel="noreferrer">
              <span className="flex gap-2 flex-shrink-0">
                <span className="w-max h-max">
                  <DocumentIcon width={24} height={24} />
                </span>
                {item.split("?")[0] ?? item}
              </span>
            </a>
          ) : (
            <div className={`${isLinkIncluded ? "ml-[34px]" : ""}`}>{item}</div>
          )}
        </div>
      );
    });
  };

  const renderContent = () => {
    return data?.map((item, index) => {
      const dynamicKey = Object.keys(item)[0];
      const value = item[dynamicKey];

      return (
        <div key={index}>
          <SubHeadText className="font-bold">{dynamicKey}</SubHeadText>
          {Array.isArray(value) && value.length > 0 ? (
            Array.isArray(value[0]) ? (
              renderArrayItems(value[0])
            ) : (
              <div className="p-2">{renderStringItems(value)}</div>
            )
          ) : null}
        </div>
      );
    });
  };

  return <div>{renderContent()}</div>;
};

DdqSlResponse.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              type: PropTypes.string,
              num: PropTypes.number,
              name: PropTypes.string,
              percent: PropTypes.number,
            })
          )
        ),
        PropTypes.arrayOf(PropTypes.string),
      ])
    )
  ),
};
