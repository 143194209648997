import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ToolTip } from "../../../../components/atoms/tooltip";
import { CloseIcon, DocumentIcon } from "../../../../components/icons";
import { COLORS } from "../../../../configuration";
import { NormalText } from "../../../../components/typography";
import { MUISpinner } from "../../../../components/atoms/loader/spinner";
import { deleteDocument } from "../../../../action";

export const DocumentName = ({ title, categoryId }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (event) => {
    event.stopPropagation();
    setIsDeleting(true);
    dispatch(
      deleteDocument({ projectId: id, categoryId, fileName: title })
    ).finally(() => setIsDeleting(false));
  };

  return (
    <ToolTip text={title}>
      <div className="w-max bg-page rounded-md p-2 flex items-center gap-2 border-none cursor-pointer">
        <DocumentIcon color={COLORS.successDark} />
        <NormalText className="w-[120px] truncate overflow-x-hidden text-sm">
          {title}
        </NormalText>

        {isDeleting ? (
          <MUISpinner size={12} />
        ) : (
          <button
            className="border-none flex items-center cursor-pointer mr-[8px] hover:bg-primary-hover rounded-full p-2"
            onMouseDown={handleDelete}
          >
            <CloseIcon width={8} height={8} />
          </button>
        )}
      </div>
    </ToolTip>
  );
};

DocumentName.propTypes = {
  title: PropTypes.string.isRequired,
  categoryId: PropTypes.number.isRequired,
};
