import PropTypes from "prop-types";
import { HeadText } from "../../typography";
import React from "react";
import { LeftIcon } from "../../icons";

export const PageHeader = ({ title, children, backTo }) => {
  return (
    <div className="bg-primary-hover py-[34px]">
      <div className="lg:w-1/2 w-full lg:p-0 p-[12px] mx-auto flex flex-col justify-center gap-2">
        <div className="flex justify-between gap-8">
          {children}
          {backTo && (
            <div className="flex items-center gap-2">
              <LeftIcon /> {React.cloneElement(backTo)}
            </div>
          )}
        </div>
        <HeadText className={"text-primary"}> {title}</HeadText>
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  backTo: PropTypes.node,
};
