import PropTypes from "prop-types";
import { Footer } from "../../../components/organism/footer";
import { AdminSideMenus } from "../../../components/organism/header/admin-menu-list";
import { createContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  fetchSupportDetails,
  fetchDdqQuestions,
  fetchDocumentList,
  fetchFeedbacks,
} from "../../../action";
import { LeftIcon, RightIcon } from "../../../components/icons";
import { USERS } from "../../../constant";

export const MenuContext = createContext();

const fetchData = (dispatch) => {
  dispatch(fetchDdqQuestions());
  dispatch(fetchClients());
  dispatch(fetchDocumentList());
  dispatch(fetchSupportDetails());
  dispatch(fetchFeedbacks());
};

export const AdminPage = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.role === USERS.Staff || user.role === USERS.Admin) fetchData(dispatch);
  }, [dispatch, user.role]);

  const [toggle, setToggle] = useState(false);

  const contextValue = useMemo(() => ({ toggle }), [toggle]);

  return (
    <div className="flex min-h-screen">
      <div
        className={`sticky top-[74px] h-[829px] transition-all duration-150  border-solid border-l-0 border-y-0 border-r border-edge bg-white ${
          toggle ? "w-[80px]" : " w-[300px]"
        }`}
      >
        <div className="relative">
          <MenuContext.Provider value={contextValue}>
            <AdminSideMenus />
          </MenuContext.Provider>
          <button
            className="absolute -mt-4 -right-4 bg-page bg-none border-none cursor-pointer rounded-md"
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? <RightIcon /> : <LeftIcon />}
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full bg-page-light">
        <div className="flex-grow p-[30px]">{children}</div>
        <Footer />
      </div>
    </div>
  );
};

AdminPage.propTypes = {
  children: PropTypes.node,
};
